import { renderDropdownField } from "../../utils/renderDropdownField";
import { renderField } from "../../utils/renderField";

export const servicesSearchFields = [
  {
    name:        'serviceId',
    component:   renderField,
    customClass: 'hide-disabled',
    label:       'Service ID',
    dynamicVisibility: [{trigger: 'filters', match: true}]
  },
  {
    name:        'scope',
    label:       'Scope',
    component:   renderDropdownField,
    customClass: 'hide-disabled',
    dynamicVisibility: [{trigger: 'filters', match: true}],
    options: [
      {
        label: 'All',
        value: 'all'
      },
      {
        label: 'Company',
        value: 'company'
      },
      {
        label: 'Location',
        value: 'location'
      }
        ]
  },
  {
    name:        'sortOrder',
    label:       'Sort',
    component:   renderDropdownField,
    customClass: 'hide-disabled',
    dynamicVisibility: [{trigger: 'filters', match: true}],
    options: [
      {
        label: 'Chronological',
        value: 'natural'
      },
      {
        label: 'Name',
        value: 'name'
      }
    ]
  },
  {
    name:        'sortDescending',
    label:       'Descending',
    component:   renderDropdownField,
    customClass: 'hide-disabled',
    dynamicVisibility: [{trigger: 'filters', match: true}],
    options: [
      {
        label: 'True',
        value: 'true'
      },
      {
        label: 'False',
        value: 'false'
      }
    ]
  },
]

export const initialServiceSearchValues = {
  sortDescending: 'true',
  sortOrder: 'natural',
  scope: 'all'
}