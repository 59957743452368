import { ADD_PARAMETER } from '../actions/notificationActions'

const INITIAL_STATE = {
  position: 0
}

const addParameterReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_PARAMETER:
      return {
          position: action.position,
          param:    action.param
        }
    default:
      return state
  }
}

export default addParameterReducer