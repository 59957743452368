import React       from 'react'
import HeaderLogin from '../HeaderLogin/HeaderLogin'

import './SignUpSuccess.css'

const SignUpSuccess = () => (
  <>
    <HeaderLogin />
    <div className="SignUpSuccess">

      <h2>That's it!</h2>
      <h3>Verify your email to continue...</h3>
      <p>An email has been sent with a verification link. Please follow this link to activate your account. </p>

    </div>
  </>
)

export default SignUpSuccess