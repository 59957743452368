import React           from 'react'
import { useHistory }  from 'react-router'
import { Link }        from 'react-router-dom'
import { useDispatch } from 'react-redux'

import SignUpForm      from './SignUpForm/SignUpForm'
import HeaderLogin     from '../HeaderLogin/HeaderLogin'
import { signUpUser }  from '../../actions/authActions'
import { toastMessages, 
         toggleError, 
         toggleToast } from '../../actions/toastActions'

import './SignUp.css'

const SignUp = () => {

  const dispatch = useDispatch()
  const history = useHistory()
  
  const handleSubmit = async (values) => {
    const { payload } = await dispatch(signUpUser(values))
    if (payload.status !== 200) {
      dispatch(toggleError(payload))
    }
    else {
      dispatch(toggleToast(toastMessages.add_account))
      history.push('/Success')
    }
  }

  return (
    <div className='SignUp'>
      <HeaderLogin />
      <SignUpForm onSubmit={handleSubmit} />
      <p>Already have an account? <Link to='/SignIn'>sign in now</Link> </p>
    </div>
  )
}

export default SignUp