import React from 'react'

import './ActivateAccount.css'

const ActivateAccount = props => {
  let showView = ''

  if ( props.hide ) showView = 'hideMobile'

  return (
    <div className={`ActivateAccount ${showView}`}>
      Activate Account
    </div>
  )
}

export default ActivateAccount