import React, { useEffect }  from 'react'
import { connect }           from 'react-redux'
import { reduxForm,
         formValueSelector} from 'redux-form'

import { loadAllocation,
         loadAllocations,
         updateAllocation,
         loadServiceById }           from '../../../actions/serviceActions'
import { loadResourceAllocation, 
         loadResourceAllocations, 
         updateResourceAllocation }  from '../../../actions/resourceActions'
import { switchView, viewTypes }     from '../../../actions/viewActions'
import { toggleToast,
         toggleError, 
         toastMessages }             from '../../../actions/toastActions'
import { toggleCheckErrors }         from '../../../utils/checkErrors'
import history                       from '../../../utils/history'
import { editServiceAllocationForm } from '../../../utils/initializeForm'
import { validateAllocation }        from '../../../utils/validate'

import Loading        from '../../Loading/Loading'
import Header         from '../../Header/Header'
import EditEventForm  from './EditEventForm/EditEventForm'

import './EditEvent.css'

const EditEvent = props => {
  const { allocation,
          form,
          handleSubmit,
          initialize,
          invalid,
          loadAllocation,
          loadAllocations,
          loadResourceAllocation,
          loadResourceAllocations,
          loading,
          match: { params },
          pristine,
          resAllocation,
          switchView,
          toggleError,
          toggleToast,
          updateAllocation,
          updateResourceAllocation,
          view
        } = props

  useEffect(() => {
    if (window.location.pathname.includes('serviceAllocations')) {
      switchView(viewTypes.services)
    }
    else if (window.location.pathname.includes('resourceAllocations')) {
      switchView(viewTypes.resources)
    }
    
    if (params) {
      if (view === viewTypes.services) {
        
        if (!allocation || allocation.id !== params.allocationId) {
          loadAllocation(params.allocationId).then(resp => {
            toggleCheckErrors(resp, toggleError, () => {
              initialize(editServiceAllocationForm(resp.payload.data))
            })
          })
        }
        else {
          initialize(editServiceAllocationForm(allocation))
        }
      }
      else if (view === viewTypes.resources) {
        if (!resAllocation || resAllocation.id !== params.allocationId) {
          loadResourceAllocation(params.allocationId).then(resp => {
            toggleCheckErrors(resp, toggleError, () => {
              initialize(editServiceAllocationForm(resp.payload.data))
            })
          })
        }
        else {
          initialize(editServiceAllocationForm(resAllocation))
        }
      }
    }
  }, [view, loadAllocation, loadResourceAllocation, params, allocation])

  const submitForm = values => {
    if (!values.repeats)
      values.repeat = {frequency: 'D', interval: 1, weekdays: '', monthDay: '', monthType: 'D'}

      values.repeat.monthType = 'D'

    if (view === viewTypes.services) {
      updateAllocation(params.allocationId, values)
      .then(resp => {
        toggleCheckErrors(resp, toggleError, () => {
          const { serviceId } = resp.payload.data
          toggleToast(toastMessages.update_allocation, resp.payload)
          loadAllocations(serviceId).then(() => {
            history.push(`/Services/edit/${serviceId}?tab=events`)
            loadAllocation(params.allocationId)
          })
        })
      })
    }
    else if (view === viewTypes.resources){
      updateResourceAllocation(params.allocationId, values)
      .then((resp) => {
        toggleCheckErrors(resp, toggleError, () => {
          const { resourceId } = resp.payload.data
          toggleToast(toastMessages.updated_resource_allocation, resp.payload)
          loadResourceAllocations(resourceId).then(() => {
            history.push(`/Resources/edit/${resourceId}?tab=availability`)
            loadResourceAllocation(params.allocationId)
          })
        })
      })
    }
  }

  let disabled = invalid || pristine

  if (loading)
    return <Loading />

  return (
    <form onSubmit={handleSubmit(submitForm)} className="EditEvent">
      <Header
        title="Edit Allocation" 
        btnType="submit" 
        cta="Save" 
        disabled={disabled}
      />

      <div className="event-content">
        <EditEventForm form={form} view={view} />
        {/* <PreviewEvent /> looks outdated*/}
      </div>
    </form>
  )
  
}

const mapStateToProps = (state, props) => ({
  allocation: state.allocation.allocation,
  resAllocation: state.resAllocationLoad.resAllocationLoad,
  frequency:  formValueSelector(props.form)(state, 'frequency'),
  interval:   formValueSelector(props.form)(state, 'interval'),
  weekdays:   formValueSelector(props.form)(state, 'weekdays'),
  repeats:    formValueSelector(props.form)(state, 'repeats'),
  loading:    state.updatedAllocation.isLoading ||
              state.allocation.isLoading ||
              state.resAllocationLoad.isLoading,
  view:       state.view.view
})

const connectEditEvent = connect(
  mapStateToProps, 
  { loadAllocation, 
    updateAllocation,
    updateResourceAllocation,
    switchView,
    toggleToast,
    toggleError,
    loadServiceById,
    loadAllocations,
    loadResourceAllocation,
    loadResourceAllocations 
  }
)(EditEvent)

export default reduxForm({validate: validateAllocation})(connectEditEvent)
