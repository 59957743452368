import axios       from 'axios'
import querystring from 'querystring'


export const LOAD_CUSTOMERS = 'LOAD_CUSTOMERS'

export const loadCustomers = ( params = {} ) => {

  const config = {}
                 
  const queryString = querystring.stringify( params )
  
  const customersRoute = `/api/onsched/consumer/customers` +
                                    `?${queryString}`

  return {
    type:    LOAD_CUSTOMERS,
    promise: axios.get( customersRoute, config )
  }

}

export const LOAD_CUSTOMER_BY_ID = 'LOAD_CUSTOMER_BY_ID'

export const loadCustomerById = (customerId,  params = {} ) => {
  const config = {}
                 
  const queryString = querystring.stringify( params )
  
  const customersRoute = `/api/onsched/setup/customers/${customerId}` +
                                    `?${queryString}`

  return {
    type:    LOAD_CUSTOMER_BY_ID,
    promise: axios.get( customersRoute, config )
  }
}


export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER'

export const updateCustomer = ( customerId, customerData = {} ) => {

  const config = {}
  
  const customersRoute = `/api/onsched/consumer/customers/${customerId}`

  return {
    type:    UPDATE_CUSTOMER,
    promise: axios.put( customersRoute, customerData, config )
  }

}
