import React from 'react'
import { Row, Col } from 'react-bootstrap'

import DomainChecking from '../DomainChecking/DomainChecking'
import SettingsFields from '../SettingsFields/SettingsFields'
import APIDocs        from '../APIDocs/APIDocs'

import './CredentialsRow.css'


const CredentialsRow = () => {
  return (
    <Row className="CredentialsRow">
      <Col md={6}>
        <DomainChecking />
      </Col>
      <Col md={6}>
        <SettingsFields />
        <APIDocs />
      </Col>
    </Row>
  )
}

export default CredentialsRow