import React from 'react'

import { toggleError } from '../actions/toastActions'

export const checkErrors = (resp, action) => {
  if (resp.payload.status === 200)
    if (action) 
      action()
  else
    toggleError(resp.payload)
    
  return null
}

export const toggleCheckErrors = (resp, toggle, action) => {
  if (resp.payload.status === 200) {
    if (action) {   
      action()
    }
  }
  else {
    toggle(resp.payload)
  }  
 
  return null
}
