import React, { Component }    from 'react'
import { connect }             from 'react-redux'
import { Modal, Form }         from 'react-bootstrap'
import { Field, 
         reduxForm }           from 'redux-form'

import { renderField }           from '../../utils/renderField'
import ResourceModalCards        from './ResourceModalCards/ResourceModalCards'
import { closed }                from '../../utils/businessHours'
import { validateResourceModal } from '../../utils/validate'
import CreateModalOption         from './CreateModalOption/CreateModalOption'
import CreateModalAction         from './CreateModalAction/CreateModalAction'
import { toggleToast,
         toastMessages,
         toggleError }           from '../../actions/toastActions'
import { toggleCreateModal, 
          sendModalData }        from '../../actions/modalActions'
import { addUser, loadUsers }    from '../../actions/userActions'
import { createResource, 
         loadResources, 
         updateResAvail }        from '../../actions/resourceActions'

import './CreateModal.css'
import { createLocation }        from '../../actions/locationActions'
import { createService }         from '../../actions/serviceActions'
import { serviceDefaults }       from '../../utils/defaults'


class CreateModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name           : '',
      email          : '',
      role           : 'bizowner',
      createManually : true,
      createCta      : '',
      resourceType   : '',
    }
  }

  componentDidMount() {
    this.props.autofill('resourceType', 'person')
    this.setState({ resourceType: 'person' })

    const forOption = this.props.modalData?.forOption
    if(forOption && forOption === 'businessuser') {
      this.props.change('type', 'businessuser')
    }
  }

  componentDidUpdate(prevProps) {
    const { createModal } = this.props

    if (prevProps.createModal !== createModal) {
      this.props.autofill('resourceType', 'person')
      this.setState({ resourceType: 'person' })
    }

    const forOption = this.props.modalData?.forOption
    if (forOption && forOption === 'businessuser') {
      this.props.change('type', 'businessuser')
    }
  }

  triggerCreate = (values) => {
    const id = this.props.selectedLocation.id
    
    let resourceData = {
      name                   : values.name,
      email                  : values.email,
      locationId             : id,
      role                   : values.role,
      sendRegistrationInvite : true,
    }

    // Create resource to be onboarded
    this.props.createResource(resourceData).then(resource => {
      let resourceId = resource.payload.data.id

      // Close availability until setup
      this.props.updateResAvail(resourceId, closed)

      // Set resourceId
      resourceData.resourceId = resourceId

      // Add user
      this.props.addUser(resourceData)
        .then((user) => {
          if (user.payload.status === 200) {
            this.props.loadUsers({locationId: id})
            this.props.toggleToast(toastMessages.add_user, user.payload)
          }
          else this.props.toggleError(user.payload)
        })
    })
  }
  
  handleSubmit = values => {
    const { forOption } = this.props.modalData
    const { toggleCreateModal, 
            history, 
            createResource, 
            toggleError,
            selectedLocation,
            createService,
            loadUsers,
            addUser,
            sendModalData,
            createLocation,
            primary,
          } = this.props
    
    // Close modal
    toggleCreateModal({}, false)

      // Create manually
      if (values.modalOptionRadio === 'manual') {
        if (forOption === 'resource') {
          createResource({ ...values, locationId: selectedLocation?.id, 'timezoneId': selectedLocation?.timezoneIana })
            .then((response) => {
              if (response.payload.status === 200) {
                history.push(`/resources/edit/${response.payload.data.id}#resource-profile`)
                toggleToast(toastMessages.create_resource)
              }
              else {
                toggleError(response.payload)
              }
            })
        }
        else if (forOption === 'location') {
          const locationData = {
            name: values.name,
            adminName: values.name,
            adminEmail: values.email,
            timezoneName: primary?.timezoneIana,
          }
          console.log(locationData);
          createLocation(locationData).then((response) => {
            if (response.payload.status === 200) {
              history.push(`/locations/edit/${response.payload.data.id}#location-profile`)
              toggleToast(toastMessages.add_location)
            }
            else {
              toggleError(response.payload)
            }
          })
        }
        else if (forOption === 'businessuser') {
          const userData = {
            ...values,
            locationId: selectedLocation?.id,
            role:  'bizadmin',
            sendRegistrationInvite: false
          }
          addUser(userData)
            .then((response) => {
              if (response.payload.status === 200) {
                loadUsers({ locationId: selectedLocation?.id })
              }
              else {
                toggleError(response)
              }
            })
        }
        else if (forOption === 'service') {
          const serviceData = {
            ...values, 
            ...serviceDefaults,
            locationId: selectedLocation?.id
          }
          createService(serviceData).then((response) => {
            if (response.payload.status === 200) {
              history.push(`/services/edit/${response.payload.data.id}#service-profile`)
              toggleToast(toastMessages.create_service)
            }
            else {
              toggleError(response.payload)
            }
          })
        }
        else {
          // Redirect to /create
        let redirect = `/${forOption}s/create`
        let timestamp = new Date().toISOString()

        sendModalData(values, redirect, timestamp)
        history.push(redirect)
        }
    }
    // Send onboarding
    else {
      if (forOption === 'resource') {
        // Set role for onboarding
        if (forOption === 'resource')
          values.role = 'bizresource'

        // Add user and trigger onboarding
        this.triggerCreate(values)
      }
    }

    // Close modal
    toggleCreateModal({}, false)
  }

  handleRadioChange = (onboardAction) => {
    if ( onboardAction === 'email' ) {
      this.setState({ createManually: false })
    }
    if ( onboardAction === 'manual' ) {
      this.setState({ createManually: true })
    }
  }

  handleChange = e => {
    this.setState({ 
      [e.target.name] : e.target.value 
    })
  }
  
  createManually = () => {
    this.setState({ 
      createManually: false, 
      resourceType: 'person' 
    })

    this.props.toggleCreateModal({} , false)
  }
  
  setResourceType = type => {
    this.setState({ resourceType: type })
  }

  render() {
    const { submitting, invalid, toggleCreateModal, handleSubmit, modalData } = this.props
    const { name, email, role, resourceType, createManually }  = this.state

    let headerTitle = ''
    let bodyTitle = ''
    let forOption = ''
    let action = ''

    if (modalData) {
      headerTitle = modalData.headerTitle
      bodyTitle = modalData.bodyTitle
      forOption = modalData.forOption
      action = modalData.action
    }

    let modalAction = action
    if ( resourceType === 'asset' || resourceType === 'person' ) modalAction = null

    let emailField = null

    if ( resourceType === 'person' &&
        (forOption === 'businessuser'      ||
         forOption === 'resource'  )) {
      emailField =                 
        <Field
          type="email"
          placeholder="Email"
          name="email"
          value={email}
          customClass="fullWidth"
          onChange={this.handleChange}
          component={renderField}
        />
    }

    return (
      <Modal
        className="CreateModal"
        show={this.props.showModal}
        onHide={() => toggleCreateModal({} , false)}
      >
        <form onSubmit={handleSubmit(this.handleSubmit)}>
          <Modal.Header>
            {headerTitle}
          </Modal.Header>
          
          <Modal.Body>
            <Form.Group>
              <ResourceModalCards
                resourceType={resourceType} 
                handleChange={this.handleChange}
                forOption={forOption}
              />

              <Field
                type="text"
                placeholder="Name"
                customClass="fullWidth"
                name="name"
                value={name}
                onChange={this.handleChange}
                component={renderField}
              />

              { emailField }
            </Form.Group>
              
            <CreateModalOption
              action={modalAction}
              bodyTitle={bodyTitle}
              forOption={forOption}
              resourceType={resourceType}
              createManually={createManually}
              handleChange={this.handleChange}
              handleRadioChange={this.handleRadioChange}
            />
              
          </Modal.Body>

          <Modal.Footer>
            <CreateModalAction
              createManually={createManually}
              name={name}
              invalid={invalid}
              email={email}
              role={role}
              submitting={submitting}
              resourceType={resourceType}
              forOption={forOption}
              triggerCreate={this.triggerCreate}
              createManuallyAction={this.createManually}
              onHide={() => toggleCreateModal({} , false)}
            />
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

function mapStateToProps(state) {
  return {
    createModal:       state.createModal,
    modalData:         state.createModal.modalData,
    showModal:         state.createModal.showModal,
    userCreated:       state.userCreated,
    primary:           state.primary.primary,
    selectedLocation:  state.selectedLocation.selectedLocation,
    destroyOnUnmount:  false
  }
}

export default connect( 
  mapStateToProps, { 
    toggleCreateModal, 
    loadUsers, 
    toggleToast,
    toggleError,
    addUser,
    sendModalData,
    createResource,
    createLocation,
    createService,
    updateResAvail,
    loadResources
  } 
)( reduxForm({ 
  form: 'create-modal',
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  initialValues: {
    role: 'bizowner',
    modalOptionRadio: 'manual',
    type: 'resource'
  },
  validate: validateResourceModal
}) (CreateModal) )
