import React       from 'react'
import { connect } from 'react-redux'

import { compressSideNav } from '../../../actions/sideNavActions'

import './ResizeToggle.css'


const ResizeToggle = ({compressed, compressSideNav}) => {
  let compressIcon = 'fad fa-compress-alt'
  let compressedClass = ''

  const toggleCompress = () => {
    compressSideNav(!compressed)
    window.localStorage.setItem(
      'onschedSideNavCompressed', 
      !compressed
    )
  }

  if (compressed) {
    compressIcon = 'fad fa-expand-alt'
    compressedClass = 'compressed'
  }

  return (
    <div className={`ResizeToggle ${compressedClass}`}>
      <i 
        onClick={() => toggleCompress()}
        className={compressIcon}
      />
    </div>
  )
}

const mapStateToProps = state => ({
  compressed: state.compressed.compressed
})

export default connect(mapStateToProps, { compressSideNav })(ResizeToggle)