export const SET_PAGE      = 'SET_PAGE'
export const SET_LAST_PAGE = 'SET_LAST_PAGE'

export const setPage = page => {
  let id = 0

  return {
    type: SET_PAGE,
    id: id++,
    page,
  }
}

export const setLastPage = total => {
  const lastPage = Math.ceil(total / 10)
  const lastPageOffset = Math.floor(total / 10) * 10

  return {
      type: SET_LAST_PAGE,
      lastPageOffset,
      lastPage
  }
}
