export const SEARCHING = 'SEARCHING'

export const stopSearch = searching => {
  let id = 0

  return {
    type: SEARCHING,
    id: id++,
    searching
  }
}

export const NAVIGATE_LIST = 'NAVIGATE_LIST'

export const navigateList = listItem => {
  let id = 0

  return {
    type: NAVIGATE_LIST,
    id: id++,
    listItem: listItem
  }
}

export const SELECT_ITEM = 'SELECT_ITEM'

export const selectItem = index => {
  let id = 0
  
  return {
    type: SELECT_ITEM,
    id: id++,
    index: index
  }
}

export const SELECT_SEARCH_CATEGORY = 'SELECT_SEARCH_CATEGORY'

export const selectSearchCategory = category => {
  let id = 0
  
  return {
    type: SELECT_SEARCH_CATEGORY,
    id: id++,
    category: category
  }
}

export const UPDATE_SEARCH_TEXT = 'UPDATE_SEARCH_TEXT'

export const updateSearchText = searchText => ({
  type: UPDATE_SEARCH_TEXT,
  searchText: searchText
})
