export const PROD = 'PROD'
export const SBOX = 'SBOX'
export const DEV = 'DEV'

export const detectEnv = () => {
  let env = PROD

  if (window.origin.includes('sandbox')) {
    env = SBOX
  }
  if (window.origin.includes('localhost') ||
      window.origin.includes('dev')
  ) {
    env = DEV
  }

  return env
}