import React                from 'react'
import { Tabs, Tab }        from 'react-bootstrap'
import { useDispatch, 
         useSelector }      from 'react-redux'
import { useHistory }       from 'react-router'

import { toggleExitIntent } from '../../../actions/exitIntentActions'
import { viewTypes }        from '../../../actions/viewActions'
import { loadServiceById }  from '../../../actions/serviceActions'
import { useView }          from '../../../hooks/useView'
import { useTabParams }     from '../../../hooks/useTabParams'
import Header               from '../../Header/Header'
import useLoadData          from '../../../hooks/useLoadData'
import ServiceProfile       from './ServiceProfile/ServiceProfile'
import ServiceAvailability  from './ServiceAvailability/ServiceAvailability'
import ServiceEvents        from '../ServiceEvents/ServiceEvents'

import './ModifyService.css'

const ModifyService = () => {
  const dispatch = useDispatch()
  const history  = useHistory()

  const { view }        = useSelector(state => state.view)
  const { unsaved }     = useSelector(state => state.unsaved)
  const { service, 
          isLoading }   = useSelector(state => state.service)

  const [ tab, setTab ] = useTabParams('service-profile')

  useView(view, viewTypes.services)
  useLoadData(service, loadServiceById, isLoading)

  const selectTabHandle = (key) => {
    if (unsaved) {
      dispatch(toggleExitIntent(history.location.pathname + `#${key}`, true, tab))
    }
    else{
      setTab(key)
      history.replace({ hash: `${key}` });
    }
  }
  
  return (
    <>
      <Header 
        title={ service?.name ?? 'Loading...' } 
        hide
      />
      <Tabs
        id="service-tabs"
        activeKey={ tab }
        onSelect={ selectTabHandle }
        unmountOnExit={true}
      >
        <Tab eventKey="service-profile" title="Profile" >
          <ServiceProfile />
        </Tab>

        <Tab eventKey="service-availability" title="Availability" >
          <ServiceAvailability />
        </Tab>
        
        <Tab
          eventKey="service-events"
          title="Events"
          disabled={service?.type !== "2"}
          tabClassName='eventsTab'
        >
          <ServiceEvents />
          
        </Tab>
      </Tabs>
    </>
  )
}

export default ModifyService
