import React, { Component } from 'react'
import { Form }             from 'react-bootstrap'
import { Field, reduxForm } from 'redux-form'

import { validateResourceModal } from '../../../utils/validate'

import './CreateModalOption.css'

class CreateModalOption extends Component {
  componentDidMount() {
    const { change } = this.props
    change('modalOptionRadio', 'manual')
  }

  render() {
    const { action, handleRadioChange, handleChange, createManually } = this.props

    let manual = ''
    if ( createManually ) manual = 'selected'

    let email = ''
    if ( !createManually ) email = 'selected'

    switch (action) {
      case 'radio':
        return (
          <Form.Group className="CreateModalOption">
            <h5>{this.props.bodyTitle}</h5>
            <div>
              <label className={`${manual} input-radio`}>
                <Field
                  name="modalOptionRadio"
                  component="input"
                  type="radio"
                  value="manual"
                  onChange={e => handleRadioChange(e.target.value)}
                  />{' '}
                Create manually
              </label>
              <label className={`${email} input-radio`}>
                <Field
                  name="modalOptionRadio"
                  component="input"
                  type="radio"
                  value="email"
                  onChange={e => handleRadioChange(e.target.value)}
                />{' '}
                Invite by email
              </label>
            </div>
          </Form.Group>
        )
    
      case 'dropdown':
        return (
          <Form.Group className="CreateModalOption">              
            <Field name="role" onChange={e => handleChange(e)} component="select">
              <option value="bizowner">Owner</option>
              <option value="bizadmin">Admin</option>
            </Field>
          </Form.Group>
        )
      
      default:
        return null
    }
  }
}

export default reduxForm({
  form: 'create-modal', //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate: validateResourceModal
})(CreateModalOption)