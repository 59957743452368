import React, { Component } from 'react'
import { connect }          from 'react-redux'

import ParameterItem            from './ParameterItem/ParameterItem'
import { reqParams, optParams } from '../../../../../../utils/masterTemplateOptions'
import { en }                   from '../../../../../../utils/languages'

import './ParametersList.css'


class ParametersList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false
    }
  }

  componentDidUpdate(prevProps) {
    const { parameter } = this.props
    const { active } = this.state
    
    if (prevProps.parameter !== parameter && active) {
      this.setState({ active: false })
    }
  }

  handleList = () => {
    this.setState({ active: !this.state.active })
  }

  render() {
    let active = ''
    let caret = 'down'
    if (this.state.active) {
      active = 'active'
      caret = 'up'
    }
  
    return (
      <li 
        className={`ParametersList ${active}`}
      >
        <i 
          className={`dropdown-caret fad fa-caret-${caret}`} 
          onClick={() => this.setState({ 
            active: !this.state.active 
          })} 
        />

        <div 
          className="ParameterItem"
          onClick={() => this.handleList()}
        >
          {en.emailTemplates.parameters}
        </div>

        <div className="divider" />

        {reqParams.map((param, i) =>
          <ParameterItem 
            key={i} 
            param={param} 
            active={this.state.active} 
          />
        )}
        
        <div className="divider" />
        
        {optParams.map((param, i) =>
          <ParameterItem 
            key={i} 
            param={param} 
            active={this.state.active} 
          />
        )}
      </li>
    )
  }
}

const mapStateToProps = state => ({
  parameter: state.parameterAdded
})

export default connect(mapStateToProps, {})(ParametersList)