import React       from 'react'
import { connect } from 'react-redux'

import { setCaretPosition } from '../../../../../actions/notificationActions'

import './CodeArea.css'


const CodeArea = props => {
  const { HTML, 
          language,
          setContent, 
          codeInputRef,
          handleKeyDown, 
          setCaretPosition
        } = props

  if (language !== "markup")
    return null

  return (
    <div id="codeArea" className="CodeArea code-edit-container">
      <textarea
        className="code-input"
        ref={codeInputRef}
        id="codeTextarea"
        spellCheck={false}
        value={HTML}
        onChange={e => setContent(e.target.value)}
        onClick={e => setCaretPosition(
          e.target.selectionStart, e.target.selectionEnd
        )}
        onKeyUp={e => setCaretPosition(
          e.target.selectionStart, e.target.selectionEnd
        )}
        onKeyDown={e => handleKeyDown(e)}
      />
      
      <pre className="code-output">
        <code className="language-markup">
          {HTML}
        </code>
      </pre>
    </div>
  )
}

export default connect(null, { setCaretPosition })(CodeArea)