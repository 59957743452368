import React, { useState } from 'react'

import './CustomField.css'

const CustomField = (props) => {

  const { customField,
          setCustomFields,
          keyProp,
          change,
          handleRemove 
        } = props

   const [ isEdit, setIsEdit ] = useState(false)
   const [ value, setValue ] = useState(customField)


   // done editing
   const handleClick = () => {
    if(value && value.length > 0) {
      setIsEdit(false)

      setCustomFields((prevCustomFields) => {
        // check if value has changed to avoid changes
        if (prevCustomFields[keyProp] !== value) {
          change('customFields', { ...prevCustomFields, [keyProp]: value })
          return { ...prevCustomFields,[keyProp]: value }
        } 
        else {
          return prevCustomFields
        }
      })
    }
  }

  // handle enter key
  const keyPress = (event) => {
    if(event.keyCode === 13){
      handleClick()
   }
  }

  return (
    <tr className='card-row'>

      { isEdit ? (
        // Editing the custom Field
        <>
          <td colSpan="2">
            <input 
              type='text' 
              autoFocus={true} 
              value={value} 
              onChange={(e) => setValue(e.target.value)}
              onKeyDown={keyPress}
            />
          </td>
          <td>
            <div className='action'>
              <i className="fad fa-check edit" 
                onClick={handleClick}
              />
            </div>
          </td>
        </>
      ) : (
        // Displaying the custom Field
        <>  
          <td colSpan="2">
            { keyProp }: &nbsp;
            { value }
          </td>
          <td >
            <div className='action'>
              <i className="fad fa-pencil edit" onClick={() => setIsEdit(true)} />
              <i className="fad fa-trash delete" onClick={() => handleRemove(keyProp)} />
            </div>
          </td>
        </>
      )}
  
    </tr>
  )
}

export default CustomField
