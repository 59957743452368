import React                        from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Modal, Button }            from 'react-bootstrap'
import Hotkeys                      from 'react-hot-keys' 
import { toggleConfirmationModal,
         ACCEPT_CONFIRMATION_MODAL } from '../../actions/modalActions'
import { en }                        from '../../utils/languages'

import './ConfirmationModal.css'

const ConfirmationModal = () => {

  const { showModal, modalData } = useSelector( state => state.confirmModalReducer )
  
  const dispatch = useDispatch()

  const hideModal = () => {
    dispatch(toggleConfirmationModal(false))
  }

  const confirmHandler = () => {
    dispatch({ type: ACCEPT_CONFIRMATION_MODAL })
  }

  let actionLowerCase = 'delete'
  if (modalData.action) {
    actionLowerCase = modalData.action.toLowerCase()
  }

    return (
      showModal && (
        <Modal
          className="ConfirmModal"
          show={showModal}
          onHide={ hideModal }
        >  
          <Hotkeys
            keyName="enter"
            onKeyUp={ confirmHandler }
          />
          <Modal.Header>
            <Modal.Title>{ en.confirmModal.header } {actionLowerCase}</Modal.Title>
          </Modal.Header>
  
          <Modal.Body>
            { en.confirmModal.body }
            { actionLowerCase } 
            <span className="name"> { modalData.name ? modalData.name : modalData.reason }</span>?
            { modalData.explanation && modalData.explanation}
          </Modal.Body>
          
          <Modal.Footer>
              <Button 
                variant="flat"
                onClick={ hideModal }
              >
                { en.confirmModal.cancel }
              </Button>
              <Button onClick={ confirmHandler }>
                
                { modalData.action ? modalData.action : en.confirmModal.delete }
              </Button>
          </Modal.Footer>
        </Modal>
      )
    )
}

export default ConfirmationModal
