import React                 from 'react'
import { reduxForm }         from 'redux-form'
import { connect }           from 'react-redux'

import WeekDayItem from './WeekDayItem/WeekDayItem'

import './WeekDays.css'


const WeekDays = ({form, repeats, repeat, isEvent}) => {
  let display = ''

  if (!repeats ||
     repeat?.frequency !== 'W'
  ) {
    display = 'hide'
  }

  let days = ['S', 'M', 'T', 'W', 'T', 'F', 'S']

  return (
    <div className={`WeekDays ${display}`}>
      <label>On</label>
      <ul>
        {days.map((day, i) => 
          <WeekDayItem 
            key={i}
            letter={day}
            number={i.toString()}
            form={form}
            weekdays={repeat?.weekdays}
            isEvent={isEvent}
          />
        )}
      </ul>
    </div>
  )
}

export default connect()(reduxForm({})(WeekDays))