import React, 
       { useEffect,
         useState }   from 'react'
import { connect }    from 'react-redux'
import { useHistory } from 'react-router-dom'

import ListGroupDetails        from '../../../ListGroupDetails/ListGroupDetails'
import Header                  from '../../../Header/Header'
import Loading                 from '../../../Loading/Loading'

import { appointmentDisplay }  from '../../../../utils/initializeForm'
import { en }                  from '../../../../utils/languages'
import { loadAppointmentById } from '../../../../actions/appointmentActions'
import { switchView,
         viewTypes }           from '../../../../actions/viewActions'
import { toggleCheckErrors }   from '../../../../utils/checkErrors'
import { toggleError }         from '../../../../actions/toastActions'


import './AppointmentDetails.css'
import { Col, Row } from 'react-bootstrap'
 
const AppointmentDetails = props => {
  const {
    appointment,
    loadAppointmentById,
    loading,
    match: { params },
    switchView,
    toggleError,
    view,
  } = props

  const [ data, setData ]  = useState({})
  const history = useHistory()

  useEffect(() => {
    if (view !== viewTypes.logs) {
      switchView(viewTypes.logs, false, true)
    }
  }, [view])

  useEffect(() => {
    if (!appointment || appointment.id !== params.id) {

      loadAppointmentById(params.id)  
        .then((resp) => {
          toggleCheckErrors(resp, toggleError, () => {
            const apptData = appointmentDisplay(resp.payload.data)
            setData((state) => ({ ...state, ...apptData }))
          })
        })
    }
    else {
      const apptData = appointmentDisplay(appointment)
      setData((state) => ({ ...state, ...apptData}))
    }
    
  }, [loadAppointmentById, appointment, params])

  return (
    <>
      { loading ? <Loading /> : (
        <>
          <Header
            title={appointment?.name ? appointment.name : en.appointments.header}
            cta={en.appointments.cta}
            action={() => history.goBack()}
          />
          <Row>
              <Col md={6}>
                <ListGroupDetails data={data[0]} variant='flush'/>
              </Col>
              <Col md={6}>
                <ListGroupDetails data={data[1]} />
                
              </Col>
          </Row>
          
        </>  
      )}
    </>
  )
}

const mapStateToProps = (state) => ({
  appointment: state.appointment.appointment,
  loading:     state.appointment.isLoading,
  view:        state.view.view
})

export default  connect(
  mapStateToProps,
  { loadAppointmentById,
    switchView,
    toggleError }
)(AppointmentDetails)




