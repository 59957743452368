import React, 
       { useEffect, 
         useState }    from 'react'
import { reduxForm }   from 'redux-form'
import { connect, 
         useSelector } from 'react-redux'
import { InputGroup, 
         FormControl, 
         Button, 
         Row, 
         Col }         from 'react-bootstrap'

import CustomField       from './CustomField/CustomField'
import BottomOverlay     from '../../BottomOverlay/BottomOverlay'
import { useUnsaved }    from '../../../hooks/useUnsaved'
import submit            from '../submit'

import './CustomFieldsTab.css'

const CustomFieldsTab = ({ resource, change, pristine, initialize, handleSubmit }) => {
  const [ customFields, setCustomFields ] = useState({})
  const [ customFieldValue, setCustomFieldValue ] = useState('')

  const { unsaved } = useSelector(state => state.unsaved)

  useUnsaved(pristine)

  useEffect(() => {
    if (resource) {   
      setCustomFields(resource.customFields)
      initialize({ resourceId: resource.id, customFields: resource.customFields })
    }
  }, [ resource, initialize ])

  const handleClick = () => {
    
    if (customFieldValue && customFieldValue.length > 0) {
      // Find the first null property
      const key = Object.keys(customFields).find(key => customFields[key] === null || customFields[key] === "")

      // Add value to this key without mutating array
      if (key) { // there is a key available
        change('customFields', { ...customFields, [key] : customFieldValue})
        setCustomFields((prev) => ({ ...prev, [key] : customFieldValue })) 
      }
    }
  }

  const handleRemove = keyProp => {
    change(`customFields.${keyProp}`, '')
    setCustomFields((prevCustomFields) => ({ ...prevCustomFields, [keyProp]:  '' }))
  }
  
  return (
    <>
      <Row>
        <Col xl={8}>
          <InputGroup >
            <FormControl 
              name="Custom Hook"
              label={`Custom hook`}
              onChange={(e) => setCustomFieldValue(e.target.value)}
              
            /> 
            <InputGroup.Append>
              <Button className='append' onClick={handleClick}>Add</Button>
            </InputGroup.Append>
          </InputGroup>
        </Col>
      </Row>

      <Row>
        <Col xl={8}>
          <table className='list'>
            <tbody>

              <tr>
                <th></th>
                <th className='cells'>Current Custom Fields</th>
                <th></th>
              </tr>

              { customFields &&
                Object.keys(customFields).map((key) => {
                  if (customFields[key] !== null && customFields[key] !== "") {
                    return (
                      <CustomField 
                        customFields={customFields} 
                        setCustomFields={setCustomFields} 
                        key={key} 
                        keyProp={key}  
                        handleRemove={handleRemove}
                        customField={customFields[key]}
                        change={change}
                      />
                    )
                  }
                })
              }
              
            </tbody>
          </table>
        </Col>
      </Row>
      <form onSubmit={handleSubmit}>
        <BottomOverlay active={unsaved} />
      </form>
    </>
  )
}

const mapStateToProps = (state, props) => ({
  resource: state.selectedResource.selectedResource,
})

export default reduxForm({form: 'resource-fields', onSubmit: submit })(connect(mapStateToProps, {})(CustomFieldsTab))
