import axios       from 'axios'
import querystring from 'querystring'


export const LOAD_PROVINCES = 'LOAD_PROVINCES'

export const loadProvinces = ( country ) => {

  const config = {}

                   
  const resourceData = {
    country
  }
                 
  const queryString = querystring.stringify( resourceData )
  
  const provincesRoute = `/api/onsched/consumer/customers/states` +
                                          `?${queryString}`

  return {
    type:    LOAD_PROVINCES,
    promise: axios.get( provincesRoute, config )
  }

}
