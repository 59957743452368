import React           from 'react'
import { useSelector } from 'react-redux'
import { reduxForm }   from 'redux-form'

import useInitialize             from '../../../../hooks/useInitialize'
import { useUnsaved }            from '../../../../hooks/useUnsaved'
import { editLocationAvailForm } from '../../../../utils/initializeForm'

import AvailabilityTab from '../../../AvailabilityTab/AvailabilityTab'
import BottomOverlay   from '../../../BottomOverlay/BottomOverlay'
import Loading         from '../../../Loading/Loading'
import submit          from './submit'

const LocationAvailability = ({ form, initialize, handleSubmit, pristine, ...rest }) => {
  const { selectedLocation, isLoading } = useSelector(state => state.selectedLocation)
  const { unsaved } = useSelector(state => state.unsaved)

  useInitialize(selectedLocation, editLocationAvailForm, initialize)
  useUnsaved(pristine)

  return (
    isLoading ? <Loading /> : 
    <form onSubmit={handleSubmit} >
      <AvailabilityTab form={form} />
      <BottomOverlay active={unsaved} />
    </form>
  )
}

export default reduxForm({
  form: 'location-availability',
  onSubmit: submit
})(LocationAvailability)