import React, { Component } from 'react'
import { connect }          from 'react-redux'
import { Tab, Tabs }        from 'react-bootstrap'

import Header        from '../Header/Header'
import Appointments  from './Appointments/Appointments'
import Customers     from './Customers/Customers'
import { en }        from '../../utils/languages'
import { switchView, 
         viewTypes } from '../../actions/viewActions'

import './DataLogs.css'


class DataLogs extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tab: 'appointments'
    }
  }

  componentDidMount() {
    const { switchView, location } = this.props

    switchView(viewTypes.logs)

    if (location.search) {
      const urlSeachParams = new URLSearchParams(location.search)
      const paramsObject = Object.fromEntries(urlSeachParams.entries())
      
      this.setState({ tab: paramsObject.tab })
    }
  }

  render() {
    return (
      <div className="Logo">
        <Header hide title={en.sidenav.logs} />
        <Tabs
          id='datalogs-tabs'
          activeKey={ this.state.tab }
          onSelect={ key => this.setState({ tab: key }) }
        >
          <Tab
            eventKey="appointments"
            title="Appointments"
          >
            <Appointments history={this.props.history} />
          </Tab>
          <Tab
            eventKey="customers"
            title="Customers"
          >
            <Customers />
          </Tab>
        </Tabs>
      </div>
    )
  }
}

export default connect(null, { switchView })(DataLogs)
