import React, { Component }  from 'react'
import { connect }           from 'react-redux'
import { reduxForm, 
         formValueSelector } from 'redux-form'

import history                   from '../../../utils/history'
import Header                    from '../../Header/Header'
import Loading                   from '../../Loading/Loading'
import FormFields                from '../../FormFields/FormFields'
import { businessUserForm }      from '../../../utils/formFields'
import { validateBusinessUser }  from '../../../utils/validate'
import { toggleUserModal }       from '../../../actions/modalActions'
import { toggleUnsaved }         from '../../../actions/exitIntentActions'
import { switchView, 
         viewTypes, 
         modTypes }              from '../../../actions/viewActions'
import { loadUserById,  
         updateUser,
         addUser }            from '../../../actions/userActions'
import { toggleToast, 
         toastMessages }         from '../../../actions/toastActions'

import './BusinessUser.css'


class BusinessUser extends Component {
  componentDidMount() {
    const { switchView, 
            sendModal, 
            initialize, 
            modType,
            toggleUnsaved,
            loadUserById,
            match: { params }
          } = this.props
    
    switchView(viewTypes.business_users)
    
    if (modType === modTypes.create) {
      initialize(sendModal.modalData)
      toggleUnsaved(true)
    }
    else {
      loadUserById(params.userId)
          .then(resp => initialize(resp.payload.data))
    }
  }

  componentDidUpdate(prevProps) {
    const { dirty, toggleUnsaved } = this.props

    if (prevProps.dirty !== dirty)
      toggleUnsaved(dirty)
  }

  submitBusinessUser = values => {
    const { selected, 
            match: { params }, 
            modType,
            updateUser,
            addUser,
            toggleToast,
            toggleUnsaved
          } = this.props
    
    let businessUserName = values.email.split('@')[0]
    if (values.name) {
      businessUserName = values.name
    }

    let data = {
      name:  businessUserName,
      email: values.email,
      role:  'bizadmin',
      sendRegistrationInvite: false
    }

    // Invite user identity data
    // const names = splitFullName(values.name)

    // let inviteUserData = {
    //   firstName: names.firstName,
    //   lastName:  names.lastName,
    //   email:     values.email
    // }
    
    if (selected) {
      data.locationId = selected.id
    }

    const afterSubmit = resp => {
      toggleUnsaved(false)

      const isCreate = modType === modTypes.create 
      const toastMessage = isCreate ? toastMessages.add_user : toastMessages.update_user 
      
      if (resp.payload.status === 200) {
        // inviteUser(inviteUserData).then((resp) => console.log(resp))
        toggleToast(toastMessage)
        history.push('/BusinessUsers')
      }
      else toggleToast(toastMessages.error, resp.payload)
    }

    if (modType === modTypes.create) {
      addUser(data).then(afterSubmit)
    }
    else {
      updateUser(params.userId, data).then(afterSubmit)
    }
    
  }

  render() {
    const { loading, 
            name, 
            invalid, 
            pristine, 
            modType,
            handleSubmit,
            selected, 
            primary
          } = this.props

    if (loading) return <Loading />
    
    let disabled = invalid || pristine
    let cta = 'Save'
  
    if (modType === modTypes.create) {
      disabled = invalid
    }

    const title = name ? name + ' - ' + selected?.name : 'New user'

    return (
      <form 
        className="BusinessUser editCard" 
        onSubmit={handleSubmit(this.submitBusinessUser)}
      >
        <Header title={title} cta={cta} disabled={disabled} />
        <p className='mt-3'>All team members will have access to Company and API Settings. 
           Add members of your team here to allow access to the Dashboard.</p>
        <FormFields form="business-user" fields={businessUserForm} />
      </form>
    )
  }
}

const mapStateToProps = state => ({
  name:      formValueSelector('business-user')(state, 'name'),
  modType:   state.modType.modType,
  sendModal: state.sendModalData,
  selected:  state.selectedLocation.selectedLocation,
  primary:   state.primary.primary,
  loading:   state.businessUser.isLoading || 
             state.userUpdated.isLoading  ||
             state.newUser.isLoading ||
             state.primary.isLoading ||
             state.selectedLocation.isLoading
})

const connectedBusinessUser = connect(
  mapStateToProps, 
  { loadUserById, 
    updateUser,
    toggleUserModal,
    switchView,
    toggleUnsaved,
    toggleToast,
    addUser
  })(BusinessUser)

export default reduxForm({form: 'business-user', validate: validateBusinessUser, forceUnregisterOnUnmount: false })(connectedBusinessUser)