import React                from 'react'
import { connect }          from 'react-redux'
import { Field, reduxForm } from  'redux-form'

import { renderField }            from '../../../../utils/renderField'
import { addDomain, loadDomains } from '../../../../actions/domainActions'
import { validateDomain }         from '../../../../utils/validate'
import HelpTip                    from '../../../HelpTip/HelpTip'

import './NewDomainList.css'


const NewDomainList = props => {
  const { fields, 
          change,
          existing, 
          addDomain,
          loadDomains,
          meta: { touched, 
                  error, 
                  submitFailed 
                }
        } = props

  const domainAction = e => {
    if(/(?:^|[ \t])((https?:\/\/)?(?:localhost|[\w-]+(?:\.[\w-]+)+)(:\d+)?(\/\S*)?)/gm.test(e.target.value)) {
      addDomain({domain: e.target.value})
        .then(() => {
          loadDomains().then(
            resp => {
              const { clientDomains } = resp.payload.data
              change('clientDomains', clientDomains)
            }
          )
        })
    }
  }
  
  return (
    <ul className="NewDomainList">
      {fields.map((domain, index) => (
        <li className="DomainItem" key={index}>
          <Field
            name={`${domain}.domain`}
            placeholder={`OnSchedJs Domain ${existing.length + index + 1}`}
            label={`OnSchedJs Domain ${index + 1}`}
            type="text"
            component={renderField}
            onBlur={e => domainAction(e)}
          />
        </li>
      ))}
      <li>
        <button
          onClick={() => fields.push({})}
          type="button"
          className="btn add-domain"
        >
            <i className="fad fa-plus" /> Add domain
        </button>
        {(touched || submitFailed) && error && <span>{error}</span>}
      </li>
    </ul>
  )
}

const mapStateToProps = state => ({
  existing: state.domains.domains
})

const connectedNewDomainList = connect(
  mapStateToProps, { addDomain, loadDomains })(NewDomainList)

export default reduxForm({form: 'domain-list', validate: validateDomain })(connectedNewDomainList)
