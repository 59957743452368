import React, { useEffect } from 'react'
import { connect }          from 'react-redux'
import { reduxForm }        from 'redux-form'
import { useHistory }       from 'react-router'

import FormFields from '../../../FormFields/FormFields'
import Header     from '../../../Header/Header'
import Loading    from '../../../Loading/Loading'

import { loadCustomerById, 
         updateCustomer }    from '../../../../actions/customerActions'
import { switchView, 
         viewTypes  }        from '../../../../actions/viewActions'
import { toggleCheckErrors } from '../../../../utils/checkErrors'
import { customerForm }      from '../../../../utils/formFields'
import { editCustomerForm }  from '../../../../utils/initializeForm'
import { en }                from '../../../../utils/languages'
import { toggleToast, 
         toggleError,
         toastMessages }     from '../../../../actions/toastActions'
import { vallidateCustomer } from '../../../../utils/validate'

const CustomerDetails = props => {
  const {
    customer,
    form,
    handleSubmit,
    initialize,
    invalid,
    loadCustomerById,
    loading,
    match: { params },
    pristine,
    switchView,
    toggleError,
    updateCustomer,
    view
  } = props

  const history = useHistory()

  // Switch view 
  useEffect(() => {
    if (view !== viewTypes.logs) {
      switchView(viewTypes.logs, false, true)
    }
  }, [view])
 
  // Load customer details
  useEffect(() => {
    if (!customer || customer.id !== params.id) {
      loadCustomerById(params.id)
        .then((res) => {
          toggleCheckErrors(res, toggleError, () => {
            initialize(editCustomerForm(res.payload.data))
          })
        })
    }
    else {
      initialize(editCustomerForm(customer))
    }
  }, [customer, params, loadCustomerById])

  const submitForm = values => {
    updateCustomer(customer.id, values).
      then((res) => {
        console.log(res);
        toggleCheckErrors(res, toggleError, () => {
          toggleToast(toastMessages.save_customer, res.payload)
          loadCustomerById(customer.id)
          history.push('/DataLogs?tab=customers')
        })
      })
  }

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      { loading ? <Loading /> : (
        <>
          <Header
            title={customer?.name ? customer.name : 'Customer'}
            cta={en.ctas.save}
            btntype="Submit"
            disabled={invalid || pristine}
          />
          <FormFields form={form} fields={customerForm}/>
        </>  
      )}

    </form>
  )
}

const mapStateToProps = state => ({
  customer:    state.customer.customer,
  loading:     state.customer.isLoading,
  view:        state.view.view
})

const connectedCustomerDetails = connect(mapStateToProps, {
  loadCustomerById,
  switchView,
  toggleError,
  toggleToast,
  updateCustomer
})(CustomerDetails)

export default reduxForm({ validate: vallidateCustomer })(connectedCustomerDetails)
