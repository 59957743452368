import React, { Component}   from 'react'
import { connect }           from 'react-redux'
import { reduxForm, Field,
         formValueSelector } from 'redux-form'

import HelpTip           from '../../HelpTip/HelpTip'
import { loadResources } from '../../../actions/resourceActions'
import { renderField }   from '../../../utils/renderField'

import './ResourcesField.css'


class ResourcesField extends Component {
  constructor(props) {
    super(props)
    this.state = {
      searching: false
    }
  }

  componentDidMount() {
    const { loadResources, selected } = this.props

    if (selected)
      loadResources({locationId: selected.id, offset: 0})
  }
    
  componentDidUpdate(prevProps) {
    const { loadResources, selected } = this.props
  
    if (prevProps.selected !== selected && selected)
      loadResources({ locationId: selected.id })
  }

  handleKeyPress = e => {
    const { selected, loadResources } = this.props
    if (e.key === 'Enter') {
      e.preventDefault()   
      loadResources({
        name: e.target.value,
        locationId: selected.id, 
        limit: 10
      })
    }
  }

  selectResource = resource => {
    this.props.change('resourceId', resource.id)
    this.props.change('resourceName', resource.name)
  }

  render() {
    const { resources, resourceName } = this.props
    const { searching } = this.state

    let resourcesList = null
    if (resources) {
      resourcesList = resources.data.map(resource => 
        <li 
          key={resource.id} 
          value={resource.id} 
          onClick={() => this.selectResource(resource)}
        >
          {resource.name}
        </li>
      )
    }
    
    return (
      <div className={`ResourcesField ${searching ? 'searching' : ''}`}>
        <div className="renderField fullWidth">
          <label>
            Resource
            <HelpTip tip="If no resource is set all resources will be able to be booked for this event" />
          </label>
          
          <div>
            <i className="fad fa-search" />
            <Field 
              type="text" 
              name="resourceName"
              component={renderField}
              value={resourceName}
              keyPress={this.handleKeyPress} 
              onFocus={() => this.setState({ searching: true })}
              onBlur={() => this.setState({ searching: false })}
              onChange={e => this.props.change('resourceName', e.target.value )}
              autoComplete="off"
            />
          </div>
          
        </div>

        <ul className="results">
          { resourcesList }
        </ul>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => ({
  resources:    state.resources.resources,
  selected:     state.selectedLocation.selectedLocation,
  resourceName: formValueSelector(props.form)(state, 'resourceName'),
})

export default reduxForm({})(
  connect(
    mapStateToProps, 
    { loadResources }
  )
  (ResourcesField)
)