import React              from 'react'
import ReactDOM           from 'react-dom'
import { Provider }       from 'react-redux'
import * as Sentry        from '@sentry/browser'

import * as serviceWorker from './serviceWorker'
import store              from './reduxStore'

import App                from './components/App/App'

import './index.css'

if (process.env.NODE_ENV !== "development") {
  // Initialize Sentry for error handling
  Sentry.init({
    dsn: "https://b239e4b2a78f45148c6cee061ff1f917@o77015.ingest.sentry.io/5245207"
  })
}

// pass App component as a child to the Provider
ReactDOM.render(
  <Provider store={ store }>
      <App />
  </Provider>,
  document.getElementById('root')
)


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
