import { handle } from 'redux-pack'

import { LOAD_RESOURCE_BY_ID } from '../actions/resourceActions'


const INITIAL_STATE = { success: false }


export default function( state = INITIAL_STATE, action ) {
  const { type, payload } = action
  switch ( type ) {
    
    case LOAD_RESOURCE_BY_ID:
      return handle( state, action, {
        start:   previousState => ( { ...previousState, isLoading: true, selectedResourceError: null, selectedResource: null } ),
        finish:  previousState => ( { ...previousState, isLoading: false } ),
        failure: previousState => ( { ...previousState, selectedResourceError: payload } ),
        success: previousState => ( { ...previousState, success: true, selectedResource: payload.data } )
      })

    default:
      return state

  }

}