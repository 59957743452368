export const localRememberUser = user => {
  // Remove email if rememberMe is unchecked
  if (!user.rememberMe) {
    window.localStorage.removeItem('OnSchedEmail')
    window.localStorage.removeItem('OnSchedRemember')
  }
  // Set email and rememberMe setting on checked
  else {
    window.localStorage.setItem('OnSchedEmail', user.email)
    window.localStorage.setItem('OnSchedRemember', user.rememberMe)
  }
}

export const localJwt = token => {
  window.localStorage.setItem('jwt', token)
}

export const localSetUser = user => {
  window.localStorage.setItem('userEmail', user.email)
  window.localStorage.setItem('userFirstname', user.given_name)
  window.localStorage.setItem('userLastname', user.family_name)
}

export const localSetCompany = user => {
  window.localStorage.setItem('onschedCompanyId', user.id)
  window.localStorage.setItem('onschedCompanyName', user.name)
}

export const localSetLocation = id => {
  window.localStorage.setItem('onschedLocationId', id)
}
