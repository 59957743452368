import axios       from 'axios'
import querystring from 'querystring'


export const BOOKING_FIELDS = 'BOOKING_FIELDS'

export const loadBookingFields = ( queryParams = {} ) => {

  const config = {}
                 
  const queryString = querystring.stringify( queryParams )
  
    const bookingFieldRoute = `/api/onsched/consumer/appointments/bookingfields` +
                                          `?${queryString}`

  return {
    type:    BOOKING_FIELDS,
    promise: axios.get( bookingFieldRoute, config )
  }

}

export const CUSTOMER_FIELDS = 'CUSTOMER_FIELDS'

export const loadCustomerFields = ( queryParams = {} ) => {

  const config = {}
                 
  const queryString = querystring.stringify( queryParams )
  
    const customFieldsRoute = `/api/onsched/consumer/appointments/customfields` +
                                          `?${queryString}`

  return {
    type:    CUSTOMER_FIELDS,
    promise: axios.get( customFieldsRoute, config )
  }

}

export const LOAD_APPOINTMENTS = 'LOAD_APPOINTMENTS'

export const loadAppointments = ( queryParams = {} ) => {

  const config = {}
                 
  const queryString = querystring.stringify( queryParams )
  
  const AppointmentsRoute = `/api/onsched/consumer/appointments` +
                                              `?${queryString}`

  return {
    type:    LOAD_APPOINTMENTS,
    promise: axios.get( AppointmentsRoute, config )
  }

}

export const LOAD_APPOINTMENT_BY_ID = 'LOAD_APPOINTMENT_BY_ID'

export const loadAppointmentById = (appointmentId,  params = {} ) => {
  const config = {}
                 
  const queryString = querystring.stringify( params )
  
  const appointmentsRoute = `/api/onsched/setup/appointments/${appointmentId}` +
                                    `?${queryString}`

  return {
    type:    LOAD_APPOINTMENT_BY_ID,
    promise: axios.get( appointmentsRoute, config )
  }
}
