import { handle } from 'redux-pack'

import { LOAD_SERVICE_RESOURCES } from '../actions/serviceActions'


const INITIAL_STATE = { success: false }


export default function loadServiceResources( state = INITIAL_STATE, action ) {
  const { type, payload } = action

  switch ( type ) {
    
  case LOAD_SERVICE_RESOURCES:
    return handle( state, action, {
      start:   previousState => ( { ...previousState, isLoading: true, serviceResError: null, success: false } ),
      finish:  previousState => ( { ...previousState, isLoading: false } ),
      failure: previousState => ( { ...previousState, serviceResError: payload } ),
      success: previousState => ( { ...previousState, success: true, serviceRes: payload.data } )
    })

  default:
    return state
  }
}
