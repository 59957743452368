import React from 'react'

import './UserModalSettings.css'


const UserModalSettings = ({signOut}) => {
  return (
    <div className="UserModalSettings">
      <a href={window.origin + '/ChangePassword'}><h5>Change Password</h5></a>
      
      <button 
        className="btn btn-primary signout" 
        onClick={() => signOut()}
      >
        Sign out
      </button>
    </div>
  )
}

export default UserModalSettings
