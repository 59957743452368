const trackHistory = () => {
  const path = window.location.pathname.toLowerCase()
  if (path !== '/selectcompany' &&
      path !== '/signin') {
    window.localStorage.setItem(
      'osDashRecentPage', 
      window.location.pathname
    )
  }
}

export default trackHistory