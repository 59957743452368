import React, { useEffect }     from 'react'
import { connect, useDispatch } from 'react-redux'
import moment                   from 'moment'

import { en }                       from '../../../utils/languages'
import { times5Min }                from '../../../utils/businessHours'
import { EmptyList }                from '../../../utils/emptyList'
import { toggleCheckErrors }        from '../../../utils/checkErrors'
import { modTypes, viewTypes }      from '../../../actions/viewActions'
import { toggleToast,
         toastMessages, 
         toggleError }              from '../../../actions/toastActions'
import { loadAllocations,
         deleteAllocation }         from '../../../actions/serviceActions'
import { deleteResourceAllocation,
         loadResourceAllocations }  from '../../../actions/resourceActions'
import { RESET_CONFIRMATION_MODAL } from '../../../actions/modalActions'
import { formatRepeatStr }          from '../../../utils/timeUtils'
import Loading                      from '../../Loading/Loading'
import DataList                     from '../../DataList/DataList'
import DataListItem                 from '../../DataList/DataListItem/DataListItem'

import './AddedList.css'


const AddedList = props => {
  const { allocations,
          loading,
          loadAllocations,
          deleteAllocation,
          deleteResourceAllocation,
          toggleToast,
          toggleError,
          confirmed,
          modalData,
          loadResourceAllocations,
          resAllocations,
          selected,
          view } = props
  
  const dispatch = useDispatch()

  useEffect(() => {
    if (confirmed && modalData) {
      if (modalData.object === 'serviceAllocation')  {
        deleteAllocation(modalData.id, { locationId: selected.id })
        .then(res => {
          toggleCheckErrors(res, toggleError, () => {
            toggleToast(toastMessages.delete_allocation, res.payload)
            loadAllocations(res.payload.data.serviceId)
          })
        })
      }
      else if (modalData.object === 'resourceAllocation') {
        deleteResourceAllocation(modalData.id)
        .then(res => {
          toggleCheckErrors(res, toggleError, () => {
            toggleToast(toastMessages.delete_resource_allocation, res.payload)
            loadResourceAllocations(res.payload.data.resourceId)
          })
        })
      }
        
        dispatch({ type: RESET_CONFIRMATION_MODAL })
    }

  }, [confirmed, modalData.id])

   const buildList = build => {
     if (build.length === 0) return [] 

     return build.map((allocation, i) => {

      const findTime = find => times5Min().filter(time => time.value === parseInt(find))[0]

      let start      = findTime(allocation.startTime).time
      let startMerid = findTime(allocation.startTime).meridiem
      let end        = findTime(allocation.endTime).time
      let endMerid   = findTime(allocation.endTime).meridiem

      const startDate = moment(allocation.startDate).format('MM/DD/YY')
      const endDate =  moment(allocation.endDate).format('MM/DD/YY')

      const repeat = allocation.repeat && allocation.repeats ? formatRepeatStr(allocation) : ''

      let data = {
        img: '',
        name: allocation.reason,
        id: allocation.id,
        cells: [
          {
            title : 'Starting',
            cell  : startDate
          },
          {
            title : 'Ending',
            cell  : endDate
          },
          {
            title : 'From',
            cell  : `${start}${startMerid}`
          },
          {
            title : 'To',
            cell  : `${end}${endMerid}`
          },
          // conditionally add object to array
          ...(view === viewTypes.services ? [{
              title : 'Resource',
              cell  : allocation.resourceName,
          }] : []),
          {
            title : 'Repeats',
            cell  : repeat,
          },
        ]
      }
      return (
        <DataListItem 
          key={i} 
          resource={allocation} 
          data={data}
        />
      )
    })
   }

  let dataList = <EmptyList resource="event allocation" />

  let itemTitles
  if (view === viewTypes.services) {
    itemTitles = en.services.allocations.itemTitles
  }
  if (view === viewTypes.resources) {
    itemTitles = en.resources.allocations.itemTitles
  }

  if (view === viewTypes.services && allocations && allocations.length){
      dataList = (
        <DataList 
          list={buildList(allocations)} 
          itemTitles={itemTitles} 
        />
      )
  }
  else if (view === viewTypes.resources && resAllocations && resAllocations.length) {
    dataList = (
      <DataList 
        list={buildList(resAllocations)} 
        itemTitles={itemTitles} 
      />
    )
  }
  
  return (
    <div className="AddedList">
      { loading ? <Loading /> : dataList }
    </div>
  )
}

const mapStateToProps = state => ({
  selected:       state.selectedLocation.selectedLocation,
  allocations:    state.allocations.allocations,
  loading:        state.allocations.isLoading ||
                  state.resourceAllocations.isLoading,
  resAllocations: state.resourceAllocations.resourceAllocations,
  confirmed:      state.confirmModalReducer.confirmed,
  modalData:      state.confirmModalReducer.modalData,
  view:           state.view.view
  
})

export default connect(mapStateToProps, {
  toggleToast,
  toggleError,
  deleteAllocation,
  deleteResourceAllocation,
  loadResourceAllocations,
  loadAllocations
})(AddedList)
