import React               from 'react'
import { connect }         from 'react-redux'
import querystring         from 'querystring'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { OverlayTrigger,
         Tooltip }         from 'react-bootstrap'

import history                from '../../../utils/history'
import InitialsIcon           from '../../InitialsIcon/InitialsIcon'
import { toggleConfirmationModal,
         toggleUserModal }    from '../../../actions/modalActions'
import { switchView }         from '../../../actions/viewActions'
import { loadLocationById }   from '../../../actions/locationActions'

import './DataListItem.css'
import { toggleExitIntent } from '../../../actions/exitIntentActions'

const DataListItem = props => {
  const { resource, 
          dataItemSelect, 
          selectedLocation,
          toggleConfirmationModal,
          toggleExitIntent,
          queryParams,
          noDelete,
          noAction,
          primaryLocation,
          unsaved,
          data : {id, name, img, cells}
        } = props

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props} show={props.show.toString()}>
      You can't delete a company scope service from a secondary location
    </Tooltip>
  )

  let cellsAdded = <td className="cells">Please add</td>
  let queryString = ''
  if (queryParams) {
    queryString = '?' + querystring.stringify( queryParams )
  }

  let deleteAction = (
    <td onClick={() => toggleConfirmationModal(true, resource)}>
      <i className="fad fa-trash" />
    </td>
  )

  let disabled = ''

  if (noDelete) {
    deleteAction = (
      <td>
        <i className="fad fa-trash disabled" />
      </td>
    )
  }

  // Disable delete when its not a company scope service
  if ( selectedLocation?.id !== primaryLocation?.id && 
       resource?.object === 'service'              && 
       !resource?.locationId ) {

    deleteAction = (
      <OverlayTrigger
        placement="left"
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip}
      >
        <td>
          <i className="fad fa-trash disabled" />
        </td> 
      </OverlayTrigger>  
    )
  }

  if (noAction)
    disabled = 'disabled'

  const handleClick = () => {
    if (!noAction) {
      if (dataItemSelect) {
        dataItemSelect()
      }
      else if (resource.object === 'location') {
        if (!selectedLocation ||
            selectedLocation.id !== resource.id) {
          loadLocationById(resource.id)
        }
      }
      else if (resource.object === 'businessUser') {
        props.toggleUserModal(null, false)
        props.switchView(null)
        history.push(`/${resource.object}s/edit/${resource.id}${queryString}`)
      }
      else if (resource.object === 'customer' || resource.object === 'appointment') {
        history.push(`/DataLogs/${resource.object}s/edit/${resource.id}${queryString}`)
      }
      else if ((resource.object === 'serviceAllocation' || resource.object === 'resourceAllocation') && unsaved) { 
        toggleExitIntent(`/${resource.object}s/edit/${resource.id}${queryString}`, true)
      }
      else if (resource.object === 'resource') { 
        history.push(`/${resource.object}s/edit/${resource.id}#resource-profile`)
      }
      else {
        history.push(`/${resource.object}s/edit/${resource.id}${queryString}`)
      }
    }
  }

  if ( cells ) {
    cellsAdded = cells.map((cell, i) => 
      <td onClick={handleClick} className="cells" key={i}>
        {cell.cell}
      </td>
    )
  }

  let trimmedID = id

  if (id.length > 6)
    trimmedID = id.slice(0, 4) + '...'

  return (
    <tr className={`DataListItem ${disabled}`}>
      <CopyToClipboard text={id}>
        <td>
            <span>{trimmedID}</span>
            <i className="far fa-copy" />
        </td>
      </CopyToClipboard>

      <td onClick={handleClick}>
        <InitialsIcon name={name} img={img} />
      </td>
      
      <td onClick={handleClick}>
        {name}
      </td>
      
      {cellsAdded}
      
      {deleteAction}
    </tr>
  )
}

const mapStateToProps = state => ({
  selectedLocation: state.selectedLocation.selectedLocation,
  primaryLocation:  state.primary.primary,
  unsaved:          state.unsaved.unsaved
})

export default connect(
  mapStateToProps, 
  { toggleConfirmationModal, 
    toggleUserModal,
    loadLocationById,
    toggleExitIntent,
    switchView
  })(DataListItem)
