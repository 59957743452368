import React, { Component }             from 'react'
import { connect }                      from 'react-redux'
import moment                           from 'moment'
import { reduxForm, formValueSelector } from 'redux-form'

import { loadCalendarBlocks,
         addCalendarBlock,
         deleteCalendarBlock }     from '../../../../actions/calendarActions'
import { loadResourceBlocks,
         addResourceBlock,
         deleteResourceBlock }     from '../../../../actions/resourceActions'
import { loadServiceBlocks,
         deleteServiceBlocks }     from '../../../../actions/serviceActions'
import { toggleConfirmationModal } from '../../../../actions/modalActions'
import { viewTypes }               from '../../../../actions/viewActions'
import { checkErrors }             from '../../../../utils/checkErrors'
import { EmptyList }               from '../../../../utils/emptyList'
import { formatRepeatStr }         from '../../../../utils/timeUtils'
import { times5Min }               from '../../../../utils/businessHours'
import Loading                     from '../../../Loading/Loading'
import DataList                    from '../../../DataList/DataList'
import DataListItem                from '../../../DataList/DataListItem/DataListItem'

import './AddedTimeBlocks.css'


class AddedTimeBlocks extends Component {
  constructor(props) {
    super(props)
    this.state = {
      addedBlocks: []
    }
  }
  
  componentDidUpdate(prevProps) {
    const { selectedResource, 
            selectedLocation,
            selectedService,
            loadResourceBlocks, 
            resourceBlocks, 
            loadCalendarBlocks, 
            calendarBlocks,
            view,
            confirmed,
            modalData,
            deleteCalendarBlock,
            toggleConfirmationModal,
            deleteResourceBlock,
            loadServiceBlocks,
            serviceBlocks,
            deleteServiceBlocks
          } = this.props

    if (view === viewTypes.resources) {
      if (selectedResource && prevProps.selectedResource !== selectedResource) {
        loadResourceBlocks(selectedResource.id).then(resp => {
          checkErrors(resp, () => {
            this.setState({ addedBlocks: resp.payload.data.data })
          })
        })
      }
      if (prevProps.resourceBlocks !== resourceBlocks) {
        if (resourceBlocks && resourceBlocks.resourceBlocks) {
          this.setState({ addedBlocks: resourceBlocks.resourceBlocks.data })
        }
      }

      if (prevProps.confirmed !== confirmed) {
        if (confirmed && modalData) {
          deleteResourceBlock(modalData.id).then(() => loadResourceBlocks(modalData.resourceId))
          toggleConfirmationModal(false)
        }
      }
    }
    else if (view === viewTypes.locations && selectedLocation) {
      if (prevProps.selectedLocation.id !== selectedLocation.id) {
        loadCalendarBlocks(selectedLocation.primaryCalendarId).then(resp => {
          checkErrors(resp, () => {
            this.setState({ addedBlocks: resp.payload.data.data })
          })
        })
      }
      
      if (prevProps.calendarBlocks !== calendarBlocks) {
        if (calendarBlocks && calendarBlocks.calendarBlocks) {
          this.setState({ addedBlocks: calendarBlocks.calendarBlocks.data })
        }
      }

      if (prevProps.confirmed !== confirmed) {
        if (confirmed && modalData) {
          deleteCalendarBlock(modalData.id).then(() => loadCalendarBlocks(modalData.calendarId))
          toggleConfirmationModal(false)
        }
      }

    }
    else if (view === viewTypes.services) {
      if (prevProps.selectedService !== selectedService) {
        loadServiceBlocks(selectedService?.id).then(resp => {
          checkErrors(resp, () => {
            this.setState({ addedBlocks: resp.payload.data.data })
          })
        })
      }

      if (prevProps.serviceBlocks !== serviceBlocks) {
        if (serviceBlocks && serviceBlocks.serviceBlocks) {
          this.setState({ addedBlocks: serviceBlocks.serviceBlocks.data })
        }
      }
  
      if (prevProps.confirmed !== confirmed) {
        if (confirmed && modalData && modalData.object === 'serviceBlock') {
          deleteServiceBlocks(modalData.id).then(() => loadServiceBlocks(selectedService.id))
          toggleConfirmationModal(false)
        }
      }

    }
  }

  render() {
    const { resourceBlocks, 
            resourceBlock, 
            resourceBlockDeleted,
            calendarBlocks, 
            calendarBlock,
            calendarBlockDeleted,
            serviceBlock,
            serviceBlocks,
            serviceBlockDeleted
          } = this.props

    
    let list = this.state.addedBlocks.map((block, i) => {
      const findTime = find => times5Min().filter(time => time.value === parseInt(find))[0]

      let start      = findTime(block.startTime).time
      let startMerid = findTime(block.startTime).meridiem
      let end        = findTime(block.endTime).time
      let endMerid   = findTime(block.endTime).meridiem

      const startDate = moment(block.startDate).format('MM/DD/YY')
      const endDate =  moment(block.endDate).format('MM/DD/YY')

      const repeat = block.repeat && block.repeats ? formatRepeatStr(block) : ''

      const blockData = {
        name  : block.reason,
        id    : block.id,
        cells : [
          {
            title : 'Starting',
            cell  : startDate
          },
          {
            title : 'Ending',
            cell  : endDate
          },
          {
            title : 'From',
            cell  : `${start}${startMerid}`
          },
          {
            title : 'To',
            cell  : `${end}${endMerid}`
          },
          {
            title : 'Repeats',
            cell  : repeat,
          },
        ]
      }

      return (
        <DataListItem 
          key={i} 
          resource={block} 
          data={blockData}
        />
      )
    })

    if (!this.state.addedBlocks.length) {
      list = <EmptyList type="time block" />
    }

    let dataList = (
      <DataList
        itemTitles={['Starting', 'Ending', 'From', 'To', 'Repeats']}
        list={list}
      />
    )

    if (resourceBlocks.isLoading || 
        resourceBlock.isLoading || 
        resourceBlockDeleted.isLoading ||
        calendarBlocks.isLoading ||
        calendarBlock.isLoading ||
        calendarBlockDeleted.isLoading ||
        serviceBlocks.isLoading ||
        serviceBlock.isLoading ||
        serviceBlockDeleted.isLoading  
      ) {
      return <Loading />
    }

    return (
      <div className="AddedTimeBlocks">
        { dataList }
      </div>
    )
  }
}

const mapStateToProps = (state, props) => ({
  repeats:              formValueSelector(props.form)(state, 'repeats'),
  is24Hours:            formValueSelector(props.form)(state, 'is24Hours'),
  startDate:            formValueSelector(props.form)(state, 'startDate'),
  endDate:              formValueSelector(props.form)(state, 'endDate'),
  startTime:            formValueSelector(props.form)(state, 'startTime'),
  endTime:              formValueSelector(props.form)(state, 'endTime'),
  frequency:            formValueSelector(props.form)(state, 'repeat.frequency'),
  interval:             formValueSelector(props.form)(state, 'repeat.interval'),
  reason:               formValueSelector(props.form)(state, 'reason'),
  selectedLocation:     state.selectedLocation.selectedLocation,
  selectedResource:     state.selectedResource.selectedResource,
  selectedService:      state.service.service,
  view:                 state.view.view,
  resourceBlock:        state.resourceBlock,
  resourceBlocks:       state.resourceBlocks,
  resourceBlockDeleted: state.resourceBlockDeleted,
  calendarBlock:        state.calendarBlock,
  calendarBlocks:       state.calendarBlocks,
  calendarBlockDeleted: state.calendarBlockDeleted,
  serviceBlock:         state.serviceBlock,
  serviceBlocks:        state.serviceBlocks,
  serviceBlockDeleted:  state.serviceBlockDeleted,
  confirmed:            state.confirmModalReducer.confirmed,
  modalData:            state.confirmModalReducer.modalData,
})

export default reduxForm({ destroyOnUnmount: false })(
  connect(
    mapStateToProps, 
    { 
      addResourceBlock, 
      addCalendarBlock,
      loadResourceBlocks, 
      loadCalendarBlocks,
      toggleConfirmationModal,
      deleteCalendarBlock,
      deleteResourceBlock,
      loadServiceBlocks,
      deleteServiceBlocks
    }
  )(AddedTimeBlocks))
