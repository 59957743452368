import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

import './HelpTip.css'

const HelpTip = ({ tip, error }) => {
  let icon = 'fad fa-question-circle'

  if (error) {
    icon = 'fad fa-exclamation-triangle'
  }

  return (
    <div className="HelpTip">
      <OverlayTrigger
        key="right"
        placement="right"
        overlay={
          <Tooltip className="help" id="tooltip-right">
            {tip}
          </Tooltip>
        }
      >
        <i className={icon} />
      </OverlayTrigger>
    </div>
  )
}

export default HelpTip
