import React          from 'react'
import { useHistory } from 'react-router'

import './HeaderLogin.css'

const HeaderLogin = () => {
  const logo = 'https://cdn.public.onsched.com/content/assets/img/transpurple.png'
  const history = useHistory()

  return (
    <div className="SignUpNav">
      <img src={logo} className="Logo" alt="OnSched" onClick={() => history.push('/')}/>
    </div>
  )
}

export default HeaderLogin