import { handle } from 'redux-pack'

import { LOAD_MASTER_TEMPLATE } from '../actions/notificationActions'


const INITIAL_STATE = { 
  success: false,
  masterTemplate: {
    headerLogoHeight: "30px",
    footerLogoHeight: "20px",
    headerLogoPadding: "10px",
    footerLogoPadding: "10px",
    emailBackgroundColor: "#f9f9fb",
    emailColor: "#1b2553",
    emailLinkColor: "#2196f3",
    panelBackgroundColor: "#1b2553",
    panelColor: "#fff",
    panelLinkColor: "#fff",
    contentBackgroundColor: "#fff",
    contentColor: "#1b2553",
    contentLinkColor: "#2169f3",
    showHeaderLogo: true,
    showFooterLogo: true,
    footerFontSize: "14px",
    privacyPolicyLink: "#000",
    showHeaderPanel: false,
    showFooterPanel: false,
    showContentPanel: false,
    centerEmailContent: true,
    centerEmailContentPanel: true,
    centerEmailFooter: true,
    footerPanelEmailContact: true,
    footerPanelPhoneContact: true,
    footerPanelWebsiteContact: true
  }
}


export default function( state = INITIAL_STATE, action ) {
  const { type, payload } = action
  switch ( type ) {
    
    case LOAD_MASTER_TEMPLATE:
      return handle( state, action, {
        start:   previousState => ( { ...previousState, isLoading: true, masterTemplateError: null, masterTemplate: null } ),
        finish:  previousState => ( { ...previousState, isLoading: false } ),
        failure: previousState => ( { ...previousState, masterTemplateError: payload } ),
        success: previousState => ( { ...previousState, success: true, masterTemplate: payload.data } )
      })

    default:
      return state

  }

}