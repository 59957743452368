import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { setValidForm } from "../actions/exitIntentActions"

// Changes the redux value of exit.valid
const useValidForm = (valid) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setValidForm(valid))
  }, [valid])
}

export default useValidForm