import React, { useState }     from 'react'
import { useDispatch, 
        useSelector }          from 'react-redux'
import { CopyToClipboard }     from 'react-copy-to-clipboard'
import { Modal, Button, Form } from 'react-bootstrap'

import { toggleCalendarModal } from '../../actions/modalActions'
import { en }                  from '../../utils/languages'

import './ConfirmationModal.css'


const CalendarModal = () => {
    
    const [field, setField]   = useState(null)
    const [copied, setCopied] = useState(false)

    const updatedResource = useSelector(state => state.updatedResource.updatedResource)
    const resourceCreated = useSelector(state => state.resourceCreated.resourceCreated)
    const { showModal, isGoogle } = useSelector(state => state.calendarModal)

    const dispatch = useDispatch()

    let url           =  ''
    let calendartype  =  'calendar'
    let resourceName  =  ''
    let resourceEmail =  ''

    if (updatedResource) {
        resourceName  =  updatedResource.name
        resourceEmail =  updatedResource.email
        
        if (isGoogle) {
            url          =  updatedResource.googleCalendarAuthUrl
            calendartype = 'Google calendar'
        }
        else {
            url          = updatedResource.outlookCalendarAuthUrl
            calendartype = 'Outlook calendar'
        }

    } else if (resourceCreated) {
        resourceName  = resourceCreated.name
        resourceEmail = resourceCreated.email

        if (isGoogle) {
            url          = resourceCreated.googleCalendarAuthUrl
            calendartype = 'Google calendar'
        }
        else {
            url          = resourceCreated.outlookCalendarAuthUrl
            calendartype = 'Outlook calendar'
        }
    } 

    const copy = field => {
        setField(field)
        setCopied(true)
        
        if (!copied) {
          setTimeout(() => {
            setField(field)
            setCopied(false)
          }, 3000);
        }
    }

    return (
        <Modal
            className="ConfirmModal"
            show={ showModal }
            onHide={ () => dispatch(toggleCalendarModal(false)) }
            size='lg'
        >  
            <Modal.Header>
                <Modal.Title>{en.calendarModal.title}</Modal.Title>
            </Modal.Header>

            <Modal.Body>    
                <Form.Group>
                    <Form.Label>{en.calendarModal.urlTitle}</Form.Label>
                    <Form.Control type="text" value={url} disabled/>
                </Form.Group>
                <p>
                    Would you like to authorize the <b>{calendartype}</b> now for <b>{resourceName}</b>?
                
                    If you are not <b>{resourceName}</b>, you can copy the link and send it to his/her email:  
                    <a href={`mailto:${resourceEmail}`}> {resourceEmail}</a>
                </p>            
            </Modal.Body>
            
            <Modal.Footer>
                <Button 
                    variant="flat"
                    href={url}
                >
                    {en.calendarModal.authNow}
                </Button>

                <CopyToClipboard 
                    onCopy={(e) => copy(e)}
                    text={url} >
                    {
                        copied && field === url
                        ?
                        <Button variant="flat">
                            {en.calendarModal.copied} <i className="far fa-check" />
                        </Button>
                        :
                        <Button >
                            {en.calendarModal.copy}
                        </Button>
                    }
                </CopyToClipboard>
                
            </Modal.Footer>
        </Modal>
    )
}

export default CalendarModal
