import React       from 'react'
import { Link }    from 'react-router-dom'
import useRedirect from '../../../hooks/useRedirect'
import HeaderLogin from '../../HeaderLogin/HeaderLogin'

import './Verified.css'

const Verified = () => {
  useRedirect('/SignIn')

  return (
    <>
      <HeaderLogin />
      <div className="Verified">
        <h2>Verification complete!</h2>
        <h3>You will be redirected to the login page... or click <Link to={'/Signin'}>here</Link> to redirect now</h3>
      </div>
    </>
  )
}

export default Verified