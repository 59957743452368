import axios            from 'axios'
import { generateGuid } from '../utils/generateGuid'

export const toastMessages = {
  save_company      : 'Company updated',
  add_location      : 'Location created',
  save_location     : 'Location updated',
  delete_location   : 'Location deleted',
  add_user          : 'User added',
  update_user       : 'User updated',
  delete_user       : 'User deleted',
  add_account       : 'Account created',
  create_resource   : 'Resource created',
  save_resource     : 'Resource saved',
  delete_resource   : 'Resource deleted',
  create_service    : 'Service created',
  update_service    : 'Service updated',
  delete_service    : 'Service deleted',
  save_customer     : 'Customer updated',
  default_exists    : 'A default service is already set',
  serviceLinked     : 'Service linked',
  save_email        : 'Email template saved',
  delete_email      : 'Email template reset',
  delete_allocation : 'Event allocation deleted',
  update_allocation : 'Event allocation updated',
  create_allocation : 'Event allocation added',
  resize_image      : 'Image resized',
  add_time_block    : 'Time blocked',
  report_error      : 'Error reported',
  delete_resource_allocation : 'Resource allocation deleted',
  create_resource_allocation: 'Resource allocation added',
  updated_resource_allocation: 'Resource allocation updated',
  update_res_services: 'Resource Services Updated',
  update_res_custom_fields: 'Custom Fields updated',
}

export const LOAD_TOAST = 'LOAD_TOAST'

export const toggleToast = (message, payload) => ({
  type: LOAD_TOAST,
  message: message,
  data: payload
})

export const TOGGLE_ERROR = 'TOGGLE_ERROR'

export const toggleError = error => ({
  type: TOGGLE_ERROR,
  error,
  id: generateGuid()
})

export const REPORT_ERROR = 'REPORT_ERROR'

export const reportError = report => {
  const config = {}
  
  const reportErrorRoute = `/api/zendesk/tickets`

  return {
    type:    REPORT_ERROR,
    promise: axios.post( reportErrorRoute, report, config )
  }

}
