import React                 from 'react'
import { connect }           from 'react-redux'
import { reduxForm, 
         formValueSelector } from 'redux-form'

import './WeekDayItem.css'


const WeekDayItem = ({letter, number, repeat, change}) => {
  let weekdays = ''
  if (repeat)
    weekdays = repeat.weekdays

  const addWeekDay = day => {
    if (weekdays && weekdays.includes(day))
      weekdays = weekdays.replace(day, '')
    
    else
      weekdays = weekdays + day
    
    // Sort string of days
    weekdays = weekdays.split('')
                       .sort()
                       .join()
                       .replace(/[\s,]+/g,'')
                       .trim()

    change('repeat.weekdays', weekdays)
  }

  let active = ''
  if (weekdays && weekdays.includes(number))
    active = 'active'
  
  return (
    <li 
      className={`WeekDayItem ${active}`} 
      onClick={() => addWeekDay(number)}
    >
      {letter}
    </li>
  )
}

const mapStateToProps = (state, props) => ({
  repeat: formValueSelector(props.form)(state, 'repeat')
})

const connectWeekDayItem =  connect(mapStateToProps, {})(WeekDayItem)
export default reduxForm({})(connectWeekDayItem)