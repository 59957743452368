import React, { Component } from 'react'
import { reduxForm, Field } from 'redux-form'

import { renderCheckbox }      from '../../../../utils/renderField'
import { renderDropdownField } from '../../../../utils/renderDropdownField'
import { times }               from '../../../../utils/businessHours'

import './UpdateWeek.css'


class UpdateWeek extends Component {
  componentDidMount() {
    this.props.initialize({
      dayOfTheWeek: 'mon',
      startTime: 900,
      endTime: 1700,
      is24Hours: false,
      isClosed: false
    })
  }

  render() {
    const { updateDay } = this.props

    let weekdays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']

    let timesList = (
      times.map(time => 
        <option key={time.value} value={time.value}>
          {time.time} {time.meridiem}
          </option>
      )
    )
  
    return (
      <div className="UpdateWeek">
        <div className="optionsRow">
          <Field 
            name="dayOfTheWeek"
            component={renderDropdownField}
            label="Day"
          >
            {weekdays.map((day, i) => 
              <option key={i} value={day.toLowerCase()}>{day}</option>
            )}
          </Field>
    
          <Field
            name="startTime"
            component={renderDropdownField}
            label="Start"
          >
            {timesList}
          </Field>
    
          <Field
            name="endTime"
            component={renderDropdownField}
            label="End"
          >
            {timesList}
          </Field>
        </div>
  
        <button
          type="button"
          className="btn btn-primary"
          onClick={updateDay}
        >
          Update
        </button>

        <Field 
          name="isClosed" 
          id="isClosed" 
          type="checkbox" 
          label="Closed" 
          defaultValue="false"
          component={renderCheckbox}
          customClass="update-week-checkbox"
        />
  
        <Field 
          name="is24Hours" 
          id="is24Hours" 
          type="checkbox" 
          label="24hours" 
          component={renderCheckbox}
          customClass="update-week-checkbox"
        />
      </div>
    )
  }
}

export default reduxForm({form: 'update-availability', destroyOnUnmount: false})(UpdateWeek)
