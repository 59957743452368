import { handle } from 'redux-pack'

import { LOAD_COMPANY_SERVICES } from '../actions/serviceActions'


const INITIAL_STATE = { success: false }


export default function( state = INITIAL_STATE, action ) {
  const { type, payload } = action
  switch ( type ) {
    
    case LOAD_COMPANY_SERVICES:
      return handle( state, action, {
        start:   previousState => ( { ...previousState, isLoading: true, companyServicesError: null, companyServices: null } ),
        finish:  previousState => ( { ...previousState, isLoading: false } ),
        failure: previousState => ( { ...previousState, companyServicesError: payload } ),
        success: previousState => ( { ...previousState, success: true, companyServices: payload.data } )
      })

    default:
      return state

  }

}