import axios       from 'axios'


export const LOAD_DOMAINS = 'LOAD_DOMAINS'

export const loadDomains = () => {
  const config = {}  
  const loadDomainsRoute = `/api/identity/client_domains`

  return {
    type:    LOAD_DOMAINS,
    promise: axios.get( loadDomainsRoute, config )
  }

}

export const ADD_DOMAINS = 'ADD_DOMAINS'

export const addDomain = ( domain ) => {

  const config = {}  
  const addDomainRoute = `/api/identity/client_domains`

  return {
    type:    ADD_DOMAINS,
    promise: axios.post( addDomainRoute, domain, config )
  }

}

export const UPDATE_DOMAIN = 'UPDATE_DOMAIN'

export const updateDomain = ( domainId, domains ) => {

  const config = {}  
  const updateDomainRoute = `/api/identity/client_domains/${domainId}`

  return {
    type:    UPDATE_DOMAIN,
    promise: axios.put( updateDomainRoute, domains, config )
  }

}

export const DELETE_DOMAIN = 'DELETE_DOMAIN'

export const deleteDomain = ( domainId ) => {

  const config = {}  
  const updateDomainsRoute = `/api/identity/client_domains/${domainId}`

  return {
    type:    DELETE_DOMAIN,
    promise: axios.delete( updateDomainsRoute, config )
  }

}