import React, { Component } from 'react'
import { connect }          from 'react-redux'
import moment               from 'moment'

import { loadCustomers }    from '../../../actions/customerActions'
import { en }               from '../../../utils/languages'
import { EmptyList }        from '../../../utils/emptyList'
import Loading              from '../../Loading/Loading'
import DataList             from '../../DataList/DataList'
import DataListItem         from '../../DataList/DataListItem/DataListItem'
import Pages                from '../../Pages/Pages'
import OnSchedSearch        from '../../OnSchedSearch/OnSchedSearch'

import './Customers.css'


class Customers extends Component {
  componentDidMount() {
    const { loadCustomers, selected } = this.props
    
    if (selected) {
      const params = {
        locationId: selected.id,
        limit: 10
      }

      loadCustomers(params)
    }
  }
    
  componentDidUpdate(prevProps) {
    const { loadCustomers, selected } = this.props

    if (prevProps.selected !== selected && selected) {
      const params = {
        locationId: selected.id,
        limit: 10
      }

      loadCustomers(params)
    }
  }

  listAction = offset => {
    const { loadCustomers, selected } = this.props

    loadCustomers({
      locationId: selected.id,
      limit: 10,
      offset
    })
  }

  render() {
    const { customers, loading, success, loadCustomers } = this.props
    const { itemTitles } = en.customers

    let total = 0

    let list = <EmptyList type={en.customers.customers} />
    if (customers && customers.data) {
      total = customers.total
      list = customers.data.map(customer => {
        const data = {
          img   : customer.resourceImageUrl,
          name  : customer.name,
          id    : customer.id,
          cells : [
            {
              title : itemTitles[0],
              cell  : customer.email
            },
            {
              title : itemTitles[1],
              cell  : customer.contact.skypeUsername
            },
          ]}

        return (
          <DataListItem 
            key={customer.id} 
            resource={customer} 
            data={data}
            noDelete
          />
        )
      })
    }

    let dataList = (
      <DataList
        itemTitles={itemTitles}
        list={list}
      />
    )

    if (loading)
      return <Loading />

    return (
      <div className="Customers">
        <OnSchedSearch 
          object='customers' 
          action={loadCustomers}
          fields={[]}
          placeholder='Search by lastname or email'
        />
        { dataList }
        <Pages 
          total={total}
          isLoading={loading}
          success={success}
          listAction={this.listAction}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  customers: state.customers.customers,
  loading:      state.customers.isLoading,
  success:      state.customers.success,
  selected:     state.selectedLocation.selectedLocation
})

export default connect(mapStateToProps, { loadCustomers })(Customers)
