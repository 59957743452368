import React, { useEffect, useState } from 'react'
import { connect }                    from 'react-redux'
import { formValueSelector }          from 'redux-form'

import { loadProvinces } from '../actions/provinceActions'

const RenderProvincesField = props => {
  const { name, 
          input,
          label, 
          customClass, 
          country,
          meta: { touched, error }, 
          loadProvinces,
          provinces,
        } = props
  
  const [ isLoading, setIsLoading ] = useState(false)

  useEffect(() => {
    if (country) {
      setIsLoading(true)
      loadProvinces(country)
    }

    // After 3 seconds if no provinces have been found clear the loading
    const timer = setTimeout(() => {
      setIsLoading(false)
    }, 3000)
  
    return () => clearTimeout(timer) 
  }, [ country ])

  let errorClass = ''
  if ( touched && error ) errorClass = 'error'

  return (
    <div className={`renderField renderDropdownField ${customClass} ${errorClass}`}>
      <label htmlFor={name}>{label}</label>
      <select  {...input} >
        {
          provinces.provinces && provinces.provinces.length > 0 ? (
            <>
              <option value='' key='9992'>Choose one</option>
              {provinces.provinces.map( province  => <option value={province.code} key={province.code} >{province.name}</option> )}
            </>
          ) : (
            <option value='' key='9991'>{isLoading ? 'Loading ...' : 'None Found'}</option>
          )
         
        }
      </select>
    </div>
  )
}

const mapStateToProps = (state, props) => {
  let countryField = 'address.country'
  if(props.formName === 'company') {
    countryField = 'country'
  }

  return {
    form:      props.formName,
    country:   formValueSelector(props.formName)(state, countryField) ,
    provinces: state.provinces,
  }
}

export default connect(mapStateToProps, { loadProvinces })(RenderProvincesField)
