import React                  from 'react'
import { Link }               from 'react-router-dom'
import { connect }            from 'react-redux'
import { Button }             from 'react-bootstrap'

import { addUser, loadUsers } from '../../../actions/userActions'

import './CreateModalAction.css'

const CreateModalAction = props => {
  let disabled = ''

  if ( props.invalid ) disabled = 'disabled'

  if ( props.selectedLocation.isLoading ) return null

  return (
    <Button
      type="submit"
      disabled={props.submitting}
    >
      Create
    </Button>
  )
}

function mapStateToProps(state) {
  return {
    selectedLocation : state.selectedLocation,
    newUser          : state.newUser,
    users            : state.users,
  }
}
export default connect(mapStateToProps, { addUser, loadUsers })(CreateModalAction)