export const modType = () => {
  const { pathname } = document.location

  let modification = null
  
  if (pathname.includes('/edit/')) {
    modification = 'edit'
  }
  else if (pathname.includes('/create')) {
    modification = 'create'
  }
  
  return modification
}