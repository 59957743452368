import React, { Component } from 'react'
import { connect }          from 'react-redux'

import './InitialsIcon.css'


class InitialsIcon extends Component {
  constructor(props) {
    super(props)
    this.state = {
      initials: '',
      bg: 'var(--onschedBlue)'
    }
  }

  componentDidMount() {
    let colors = [
      "var(--onschedBlue)", 
      "var(--onschedDanger)", 
      "var(--onschedSuccess)", 
      "var(--onschedPurple)", 
      "#f3bc21", "#2196f3"]
    const randomColor = colors[Math.floor(Math.random() * colors.length)]
    this.setState({ bg: randomColor })

    if ( this.props.name ) {
      const names   = this.props.name.split(" ")
      const initials = names.map(name => name.slice(0,1)).slice(0,2)

      this.setState({ initials })
    }
  }

  componentDidUpdate(prevProps) {
    const { name } = this.props
    if ( prevProps.name !== name ) {
      const names   = this.props.name.split(" ")
      const initials = names.map(name => name.slice(0,1)).slice(0,2)

      this.setState({ initials })
    }
  }

  render() {
    if (this.props.img) {
      return (
        <div className="InitialsIcon">
          <img src={this.props.img} alt="" />
        </div>
      )
    }
    
    return (
      <div onClick={this.props.onClick} style={{backgroundColor:this.state.bg}} className="InitialsIcon">
        {this.state.initials}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    selectedLocation: state.selectedLocation
  }
}

export default connect(mapStateToProps, {})(InitialsIcon)