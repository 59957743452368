import React from 'react'

import './RequestLog.css'

const RequestLog = props => {
  let showView = ''

  if ( props.hide ) showView = 'hideMobile'

  return (
    <div className={`RequestLog ${showView}`}>
      Request Log
    </div>
  )
}

export default RequestLog