import axios       from 'axios'


export const AUTHORIZE_TOKEN = 'AUTHORIZE_TOKEN'

export const authorizeToken = ( client, env ) => {

  const config = {}
                 
  let authorizeTokenRoute = `https://sandbox-js.onsched.com/auth/mobile`
  
  if (env === 'PROD')
    authorizeTokenRoute = `https://js.onsched.com/auth/mobile`

  return {
    type:    AUTHORIZE_TOKEN,
    promise: axios.post( authorizeTokenRoute, client, config )
  }

}