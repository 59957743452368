import axios       from 'axios'
import querystring from 'querystring'


export const LOAD_SETTINGS = 'LOAD_SETTINGS'

export const loadSettings = ( params = {} ) => {

  const config = {}

  const queryString = querystring.stringify( params )
  
  const settingsRoute = `/api/onsched/consumer/settings` +
                                        `?${queryString}`

  return {
    type:    LOAD_SETTINGS,
    promise: axios.get( settingsRoute, config )
  }

}