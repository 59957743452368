import React                    from 'react'
import { useSelector }          from 'react-redux'
import { reduxForm }            from 'redux-form'

import useInitialize            from '../../../../hooks/useInitialize'
import { useUnsaved }           from '../../../../hooks/useUnsaved'
import { editServiceAvailForm } from '../../../../utils/initializeForm'
import AvailabilityTab          from '../../../AvailabilityTab/AvailabilityTab'
import BottomOverlay            from '../../../BottomOverlay/BottomOverlay'
import Loading                  from '../../../Loading/Loading'
import submit                   from './submit'

const ServiceAvailability = ({ form, pristine, initialize, handleSubmit }) => {
  const { service, isLoading } = useSelector(state => state.service)
  const { unsaved } = useSelector(state => state.unsaved)

  useUnsaved(pristine)
  useInitialize(service, editServiceAvailForm, initialize)

  return (
    isLoading ? <Loading /> :
    <form onSubmit={handleSubmit}>
      <AvailabilityTab form={form} />
      <BottomOverlay active={unsaved}/>
    </form>
  )
}

export default reduxForm({
  form: 'service-availability',
  onSubmit: submit
})(ServiceAvailability)