import React from 'react'

const SaveButton = ({ action, customClass = 'btn-primary' }) => { 
  return (
    <button className={`btn ${customClass}`} onClick={action}>
      <i className="fad fa-floppy-disk"></i>Save
    </button>
  )
}

export default SaveButton