import React                from 'react'
import { connect }          from 'react-redux'
import { reduxForm, Field } from 'redux-form'

import { updateEmailPreview } from '../../../../../actions/notificationActions'
import { emailFields,
         panelFields,
         bodyFields,
         headerLogoFields,
         footerLogoFields }   from '../../../../../utils/masterTemplateOptions'
import MasterTemplateHeader   from './MasterTemplateHeader/MasterTemplateHeader'

import './MasterTemplateOptions.css'


const MasterTemplateOptions = props => {
  const handleChange = (e, field) => {
    props.updateEmailPreview({
      ...props.emailPreview,
      [field.name]: e.target.value
    })
  }

  const renderFields = fields => (
    fields.map((field, i) => 
    <div key={i} className="renderField">
      <label htmlFor={field.name}>{field.label}</label>
      <Field 
        name={field.name}
        component="input"
        type={field.type}
        onChange={e => handleChange(e, field)}
      />
    </div>
    )
  )

  return (
    <div className="MasterTemplateOptions">
      <span className="template-warning">
        <i className="fad fa-exclamation-circle" />
        These settings will affect all templates
      </span>
      
      <h5>Email</h5>
      { renderFields(emailFields) }

      <MasterTemplateHeader title="Panel" switches={['Header', 'Footer']} />
      { renderFields(panelFields) }

      <h5>Body</h5>
      { renderFields(bodyFields) }
      
      <MasterTemplateHeader title="Logo" switches={['Header']} />
      { renderFields(headerLogoFields) }
      
      <MasterTemplateHeader title="Logo" switches={['Footer']} />
      { renderFields(footerLogoFields) }
      
      <MasterTemplateHeader title="Panel" switches={['Content']} />
    </div>
  )
}

const mapStateToProps = state => ({
  emailPreview: state.emailPreview.options,
  eTemplate:    state.eTemplate.eTemplate
})

export default connect(mapStateToProps, { updateEmailPreview })
(reduxForm({form: 'master-email-template'})(MasterTemplateOptions))