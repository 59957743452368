import React from 'react'

import './FailedMessage.css'


const FailedMessage = ({noToken, wrongPw}) => {
  // const signup = <a href="https://signup.onsched.com">Sign Up</a>
  const resetPw = <a href={window.location.origin + '/ForgotPassword'}>Reset Password</a>

  let msg = <p>Hmm seems we couldn't find you</p> 

  if (noToken)
    msg = <p>You are not authorized to change this password, please follow the link in your email to re-try</p>

  if (wrongPw)
    msg = <p>Incorrect password, {resetPw} now if you've forgotten it</p>

  return (
    <div className="FailedMessage">
      { msg }
    </div>
  )
}

export default FailedMessage
