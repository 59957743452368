import { SELECT_ITEM } from '../actions/searchActions'

const INITIAL_STATE = { index: 0 }

const selectItemReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SELECT_ITEM:
      return {
          id:        action.id,
          index:  action.index
        }
    default:
      return state
  }
}

export default selectItemReducer