import { PREVIEW_SIZE, previewSizes } from '../actions/notificationActions'

const INITIAL_STATE = {
  screenSize: previewSizes.desktop,
}

const togglePreviewSizeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PREVIEW_SIZE:
      return {
          screenSize: action.screenSize
        }
    default:
      return state
  }
}

export default togglePreviewSizeReducer