export const en = {
  "appointments" : {
    "header": "Appointments",
    "appointments" : "appointments",
    "cta" : "Go Back",
    "itemTitles" : ["Resource", "Service", "Date", "Time", "Status"],
    "total": 'Total: '
  },
  "customers" : {
    "customers" : "customers",
    "itemTitles" : ["Email", "Skype"]
  },
  "ctas" : {
    "new"    : "New",
    "save"   : "Save",
    "create" : "Create",
    "edit"   : "Edit",
    "delete"   : "Delete"
  },
  "please_add" : "Please add your first ",
  "exitIntent" : {
    "body" : "You are about to leave without saving, are you sure you want to leave?",
    "decline" : "Stay",
    "accept" : "Leave",
    "save"   : "Save & Continue",
    "loading": "Loading..."
  },
  "confirmModal" : {
    "header" : "You are about to ",
    "body"   : "Are you sure you want to ",
    "yes"    : "Yes",
    "no"     : "No",
    "delete" : "Delete",
    "cancel" : "Cancel"
  },
  "calendarModal": {
    "authNow" : "Auth Now",
    "urlTitle": "Authorization URL:",
    "title"   : "Authorize Calendar",
    "copy"    : "Copy to Clipboard",
    "copied"  : "Copied to clipboard!"
  },
  "contact" : {
    "id"               : "Id",
    "name"             : "Name",
    "address"          : "Address",
    "email"            : "Email",
    "phone"            : "Phone",
  },
  "topnav" : {
    "edit_location"    : "Edit location",
    "switch_locations" : "Switch locations",
  },
  "sidenav" : {
    "get_started"      : "Get started",
    "go_live"          : "Go live",
    "locations"        : "Locations",
    "resources"        : "Resources",
    "services"         : "Services",
    "calendars"        : "Calendars",
    "notifications"    : "Notifications",
    "request_log"      : "Request log",
    "api_settings"     : "API settings",
    "preferences"      : "Preferences",
    "company_settings" : "Company",
    "logs"             : "Data logs",
    "business_users"   : "Team members",
    "custom_fields"    : "Custom Fields",
    "users" : {
      "Name" : "Name",
      "Email" : "Email",
      "Role" : "Role",
      "add_user" : "Add user"
    } 
  },
  "get_started" : {
    "for_managers"      : "For managers",
    "for_managers_desc" : "Learn how to setup OnSched and manage your bookings on a daily basis.",
    "locations"         : "Add your first location",
    "resources"         : "Create your first resource",
    "services"          : "Create your first service",
    "calendars"         : "Setup your first calendar",
    "test_booking"      : "Make a test booking"
  },
  "locations" : {
    "searchPlaceholder" : "Search location name",
    "locations_header"  : "Locations",
    "locations_default" : "Default Settings",
    "locations_general" : "General Settings",
    "company_header"    : "Company info",
    "primaryHelpTip"    : "Settings and services created at the primary location will be set as the defaults for all other locations.",
    "primary"           : "Primary",
    "company"           : "company",
    "please_add"        : "Add secondary locations",
    "placeholders"      : {
      "name"    : "Hill Valley",
      "email"   : "name@yourwebsite.com",
      "address" : "1600 Azusa Ave, Hill Valley, CA, USA",
      "website" : "https://www.yourwebsite.com",
      "phone"   : "(518) 555-0187"
    },
    "itemTitles"       : [ "Email", "Address" ],
    "modalData"        : {
      "headerTitle" : "Add location",
      "bodyTitle"   : "How should this location be onboarded?",
      "forOption"   : "location"
    },
    "availability" : {
      "days" : {
        "mon" : "Monday",
        "tue" : "Tuesday",
        "wed" : "Wednesday",
        "thu" : "Thursday",
        "fri" : "Friday",
        "sat" : "Saturday",
        "sun" : "Sunday"
      },
      "workweek" : {
        "every" : "Every",
        "from"  : "from",
        "to"    : "to"
      }
    }
  },
  "users" : {
    "permissions" : {
      "owner"    : "Owner has full access to all of OnSched’s settings and data, and can add or remove other owners and locations.",
      "admin"    : "Admin has access to all of OnSched’s settings and data but cannot add or remove other owners and locations.",
      "booker"   : "Booker can manage calendar appointments for all resources but cannot update services, resources, or settings",
      "resource" : "Resource can manage only their own calendar appointments",
    },
    "errors" : {
      "notPermitted" : "Sorry, you don't have the permissions to view OnSched Dashboard. Please contact your administrator for assistance.",
      "noUser"       : "Sorry, we can't find your user at this company, please contact your administrator for assistance."
    }
  },
  "companies" : {
    "itemTitles" : ["Company id"],
    "type"   : "company",
    "select" : "Select your company"
  },
  "resources" : {
    "header"     : "Resources",
    "resource"   : "resource",
    "itemTitles" : [ "Email", "Cal sync", "Linked Services", "Availability"],
    "sync"   : {
      "title"   : "Cal sync",
      "none"    : "Not synced",
      "google"  : "Google",
      "outlook" : "Outlook"
    },
    "resourceCards" : {
      "person": {
        "title"       : "Person",
        "description" : "e.g. Sales rep, employee",
      },
      "asset" : {
        "title"       : "Item/Asset",
        "description" : "e.g. Meeting room, desk"
      },
    },
    "modalData"        : {
      "headerTitle" : "Add resource",
      "bodyTitle"   : "How should this resource be onboarded?"
    },
    "allocations": {
      "itemTitles": ["Starting", "Ending", "From", "To", "Repeats"],
      "title"      : "Allocation dates & times",
      "subtitle"   : "Define a single or recurring allocation that is available for booking appointments. This allocation will allow bookings to be made for these explicit dates and times only.",
      "cta"        : "Add Allocation"
    }
  },
  "services" : {
    "header" : "Services",
    "service" : "service",
    "services" : "services",
    "itemTitles" : [ "Duration", "Type", "Scope" ],
    "please_add" : "Please add your first service",
    "tabs" : {
      "company" : "Company",
      "local"   : "Local"
    },
    "placeholders" : {
      "name" : "Time travel"
    },
    "modalData" : {
      "headerTitle" : "Add service"
    },
    "allocations" : {
      "itemTitles" : ["Starting", "Ending", "From", "To", "Resource", "Repeats"],
      "title"      : "Event dates & times",
      "subtitle"   : "Define a single or recurring allocation that is available for booking appointments. This allocation will allow bookings to be made for these explicit dates and times only.",
      "cta"        : "Add event"
    }
  },
  "calendars" : {
    "calendar" : "calendar",
    "itemTitles" : ["Linked", "", "Name", "Scope", "Duration", "Type", "ID"],
    "emptyList" : "You have no services, click here to create a service"
  },
  "company": {
    "header": "Company",
    "save": "Save"
  },
  "emailTemplates" : {
    "header"        : "Edit Template",
    "codePreview"   : "Preview Email",
    "parameters"    : "Parameters",
    "emailTemplate" : "emailTemplate",
    "itemTitles"    : ['Scope', 'Customized']
  },
  "notifications" : {
    "header" : "Email Templates"
  },
  "businessUsers" : {
    "header"        : "Business Users - ",
    "businessUsers" : "business user",
    "itemTitles"    : ['Email']
  }
}
