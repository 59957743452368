import React, { Component }      from 'react'
import { connect }               from 'react-redux'
import { Router, Switch, Route } from 'react-router-dom'

import { routes }              from '../../utils/routes'
import history                 from '../../utils/history'
import trackHistory            from '../../utils/trackHistory'
import { modType }             from '../../utils/modType'
import Shortcuts               from '../Shortcuts/Shortcuts'
import Modals                  from '../Modals/Modals'
import TopNav                  from '../TopNav/TopNav'
import SideNav                 from '../SideNav/SideNav'
import Loading                 from '../Loading/Loading'
import { setActiveCompany, 
         loadCompany }         from '../../actions/companyActions'
import { stopSearch }          from '../../actions/searchActions'
import { compressSideNav }     from '../../actions/sideNavActions'
import { updateModification }  from '../../actions/viewActions'
import { loadLocations,
         loadLocationById,
         loadPrimaryLocation } from '../../actions/locationActions'

import './Dashboard.css'


class Dashboard extends Component {
  componentDidMount() {
    const { company, 
            setActiveCompany, 
            compressSideNav,
            updateModification
          } = this.props
    
    updateModification(modType())
    
    if (window.localStorage.onschedSideNavCompressed)
      compressSideNav(window.localStorage.onschedSideNavCompressed)

    if (company) {
      this.loadInitialLocations()
    } else if (window.localStorage.onschedCompanyId) {
      setActiveCompany(window.localStorage.onschedCompanyId)
        .then(a => {
          if (a.payload.status === 200) {
            this.loadInitialLocations()
            // this.renderAppcues()
          }
        })
    }
  }

  componentDidUpdate(prevProps) {
    const { company } = this.props

    if ( prevProps.company !== company && company ) {
      this.loadInitialLocations()
      // this.renderAppcues()
    }
  }

  loadInitialLocations = () => {
    const { loadLocations, 
            loadLocationById, 
            loadPrimaryLocation,
            loadCompany
          } = this.props

    loadCompany()
    
    loadLocations({ limit: 10 })
      .then(locations => {
        if ( locations.payload.status === 200 ) {
          const { data } = locations.payload.data
          
          //Filter for primary location
          const primary = data.filter(location => 
            location.primaryBusiness 
          )[0]

          loadPrimaryLocation(primary)

          if (window.localStorage.onschedLocationId)
            loadLocationById(window.localStorage.onschedLocationId)
              .then(location => {
                if (location.error) {
                  loadLocationById(primary.id)
                }
              })
          else
            loadLocationById(primary.id)
        }
      })
  }

  // renderAppcues = () => {
  //   const { user, company } = this.props
  //   const { localStorage, Appcues } = window

  //   var userId
  //   if (user)
  //     userId = user.email
  //   else
  //     userId = localStorage.userEmail

  //   var companyId
  //   if (company) 
  //     companyId = company.companyExternalId
  //   else
  //     companyId = localStorage.onschedCompanyId

  //   if (userId && companyId) {
  //     Appcues.page()
  //     Appcues.identify(
  //       userId,
  //       { companyId }
  //     )
  //   }
  // }

  render() {
    const { searching, stopSearch, company, signoutLoad, updateModification } = this.props
    
    if (!company || signoutLoad) return <Loading />

    const mappedRoutes = routes.map(({path, component}, i) => (
      <Route key={i} exact path={path} component={component} />
    ))
    
    history.listen(() => {
      trackHistory()
      updateModification(modType())
      stopSearch(false)
      // this.renderAppcues()
    })

    return (
      <div className="Dashboard">
        <Router history={history}>
          <Shortcuts />
          {/* <ToastList /> */}
          <Modals history={history} />

          <TopNav />

          <div className="Main" onClick={() => {if (searching) stopSearch(false)}}>
            <SideNav />
            
            <div 
              className="ContentContainer"
            >
              <Switch>
                { mappedRoutes }
              </Switch>
            </div>
          </div>
        </Router>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    selectLoad:  state.selectedLocation.isLoading,
    selected:    state.selectedLocation.selectedLocation,
    primary:     state.primary.primary,
    searching:   state.searching.searching,
    company:     state.activeCompany.company,
    signoutLoad: state.signedOut.isLoading,
    user:        state.user.user
  }
}

export default connect( 
  mapStateToProps, 
  { loadLocations, 
    loadLocationById, 
    loadPrimaryLocation, 
    stopSearch, 
    setActiveCompany,
    compressSideNav,
    loadCompany,
    updateModification
  } 
)(Dashboard)