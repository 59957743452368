import axios       from 'axios'
import querystring from 'querystring'
import { toggleUnsaved } from './exitIntentActions'
import { toastMessages, toggleError, toggleToast } from './toastActions'


export const LOAD_LOCATIONS = 'LOAD_LOCATIONS'

export const loadLocations = ( queryParams = {} ) => {

  const config = {}
                 
  const queryString = querystring.stringify( queryParams )
  
    const locationsRoute = `/api/onsched/setup/locations` +
                                          `?${queryString}`

  return {
    type:    LOAD_LOCATIONS,
    promise: axios.get( locationsRoute, config )
  }

}

export const LOAD_LOCATION_BY_ID = 'LOAD_LOCATION_BY_ID'
export const RESET_SELECTED_LOCATION = 'RESET_SELECTED_LOCATION'

export const loadLocationById = ( locationId,
                                  queryParams = {}, reset ) => {

  const config = {}
                 
  const queryString = querystring.stringify( queryParams )
  
    const locationsRoute = `/api/onsched/setup/locations/${locationId}` +
                                          `?${queryString}`

  if (reset) return {
    type : RESET_SELECTED_LOCATION
  }

  return {
    type:    LOAD_LOCATION_BY_ID,
    promise: axios.get( locationsRoute, config )
  }

}

export const LOAD_PRIMARY_LOCATION = 'LOAD_PRIMARY_LOCATION'

export const loadPrimaryLocation = ( location ) => {
  let id = 0

  return {
    type: LOAD_PRIMARY_LOCATION,
    id: id++,
    primary: location
  }

}

export const SAVE_LOCATION = 'SAVE_LOCATION'

export const saveLocation = ( locationId, locationData = {} ) => {

  const config = {}
  
  const locationsRoute = `/api/onsched/setup/locations/${locationId}`

  return {
    type:    SAVE_LOCATION,
    promise: axios.put( locationsRoute, locationData, config )
  }

}

export const CREATE_LOCATION = 'CREATE_LOCATION'

export const createLocation = ( locationData = {} ) => {

  const config = {}
  
  const locationsRoute = `/api/onsched/setup/locations`

  return {
    type:    CREATE_LOCATION,
    promise: axios.post( locationsRoute, locationData, config )
  }

}

export const DELETE_LOCATION = 'DELETE_LOCATION'

export const deleteLocation = ( locationId,
                                  queryParams = {} ) => {

  const config = {}
                 
  const queryString = querystring.stringify( queryParams )
  
    const locationsRoute = `/api/onsched/setup/locations/${locationId}` +
                                          `?${queryString}`

  return {
    type:    DELETE_LOCATION,
    promise: axios.delete( locationsRoute, config )
  }

}

export const SET_RECENT_LOCATIONS = 'SET_RECENT_LOCATIONS'

export const setRecentLocations = (locations, reset) => ({
  type: SET_RECENT_LOCATIONS,
  locations,
  reset
})

export const UPLOAD_LOCATION_IMAGE = 'UPLOAD_LOCATION_IMAGE'

export const uploadLocationImage = ( locationId, imgData ) => {

  const config = {}
  
  const locationsRoute = `/api/onsched/setup/locations/${locationId}/uploadimage`

  return {
    type:    UPLOAD_LOCATION_IMAGE,
    promise: axios.post( locationsRoute, imgData, config )
  }

}

export const DELETE_LOCATION_IMAGE = 'DELETE_LOCATION_IMAGE'

export const deleteLocationImage = ( locationId ) => {

  const config = {}
  
  const locationsRoute = `/api/onsched/setup/locations/${locationId}/deleteimage`

  return {
    type:    DELETE_LOCATION_IMAGE,
    promise: axios.delete( locationsRoute, config )
  }

}

export const TOGGLE_LOCATION_SCOPE = 'TOGGLE_LOCATION_SCOPE'

export const toggleLocationScope = (locationId, scopeValue) => {
  const config = {}
  const locationsRoute = `/api/onsched/setup/locations/${locationId}/settings/scope/${Number(scopeValue)}`

  return {
    type:    TOGGLE_LOCATION_SCOPE,
    promise: axios.put( locationsRoute, config )
  }

}


//-------------------------------------------------------------------//
//                         ASYNC THUNKS
//-------------------------------------------------------------------//

export const modifyAndLoadLocation = (id, location, isImage) => async (dispatch) => {
  try {
    const { payload } = await dispatch(saveLocation(id, location))

    if (payload.status === 200) {
      dispatch(toggleToast(toastMessages.save_location, payload))
      dispatch(toggleUnsaved(true))

      if (isImage) { 
        await dispatch(uploadLocationImage(location.id, {
          imageFileName: location.name + new Date().toISOString().slice(-4) + ' logo', 
          imageFileData: location.base64Image
        }))
      }
      
      await dispatch(loadLocationById(id))
      
      if (location.primaryBusiness === true) {
        await loadPrimaryLocation(payload.data)
      }
    } 
    else {
      dispatch(toggleError(payload))
    }
  }
  catch (error) {
    console.error({ error })
  }
}

export const modifyAndLoadLocationSettings = (id, settings) => async (dispatch) => {
  try {
    const { payload } = await dispatch(saveLocation(id, settings))

    if (payload.status === 200 ) {
      dispatch(toggleToast(toastMessages.save_location))
      await dispatch(loadLocationById(id))
    }
    else {
      dispatch(toggleError(payload))
    }
  }
  catch (error) {
    console.error({ error })
  }
}

export const toggleLocationScopeAndLoad = (id, scopeValue) => async (dispatch) => {
  try {
    const { payload } = await dispatch(toggleLocationScope(id, scopeValue))
    if (payload.status === 200) {
      await dispatch(loadLocationById(id))
    }
    else {
      dispatch(toggleError(payload))
    }
  }
  catch (error) {
    console.error({ error })
  }
}
