import React                 from 'react'
import { useDispatch,
         useSelector }       from 'react-redux'
import { OverlayTrigger, 
         ToggleButton, 
         Tooltip, 
         ToggleButtonGroup } from 'react-bootstrap'

import { toggleRecurringAvailandLoad } from '../../../actions/resourceActions'

const RecurringSwitch = () => {
  const { selectedResource } = useSelector(state => state.selectedResource)

  const dispatch =  useDispatch()
  
  const handleChange = (e) => {
    dispatch(toggleRecurringAvailandLoad(selectedResource?.id, !!Number(e.currentTarget.value)))
  }

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props} show={props.show.toString()}>
      Resource availability is either defined by selecting Weekly Hours or Allocations. 
      You cannot use both simultaneously. Select the one you wish to use for this resource.
    </Tooltip>
  )
  
  const radios = [
    { name: 'Weekly Hours', value: '1' },
    { name: 'Allocations', value: '0' }
  ]

  return (
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip} >

        <ToggleButtonGroup name='radio' className='my-3'>
          {radios.map((radio, idx) => (
            <ToggleButton
              key={idx}
              id={`radio-${idx}`}
              type="radio"
              // Purposely leaving == to get type coercion
              className={radio.value == selectedResource?.recurringAvailability ? 'outlined active' : 'outlined'}
              name="radio"
              value={radio.value}
              onChange={handleChange}            
            >
              {radio.name}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </OverlayTrigger>
  )
}

export default RecurringSwitch
