import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { toggleUnsaved } from "../actions/exitIntentActions"

export const useUnsaved = (pristine) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(toggleUnsaved(!pristine))
  }, [pristine, dispatch])
}

// Unsaved === true -> toggle exit intent