export const LOAD_EXIT_INTENT = 'LOAD_EXIT_INTENT'

export const toggleExitIntent = (destination, action, formName, callback = false, valid) => ({
  type: LOAD_EXIT_INTENT,
  destination: destination,
  actionType: action,
  form: formName, // form to submit
  valid,
  callback // flag to execute remote action
})

export const RESET_EXIT_INTENT = 'RESET_EXIT_INTENT'

export const resetExitIntent = () => ({
  type: RESET_EXIT_INTENT
}) 

export const TOGGLE_UNSAVED = 'TOGGLE_UNSAVED'

export const toggleUnsaved = unsaved => ({
  type: TOGGLE_UNSAVED,
  unsaved:    unsaved
})

export const SET_VALID_FORM = 'SET_VALID_FORM'

export const setValidForm = valid => ({
  type: SET_VALID_FORM,
  valid:    valid
})