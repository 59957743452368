import React, { useEffect }  from 'react'
import { connect }           from 'react-redux'
import { reduxForm,
         formValueSelector } from 'redux-form'
import moment                from 'moment'

import FormFields              from '../../../FormFields/FormFields'
import Header                  from '../../../Header/Header'
import Loading                 from '../../../Loading/Loading'
import WeekDays                from '../WeekDays/WeekDays'
import { toggleCheckErrors }   from '../../../../utils/checkErrors'
import { usePrevious }         from '../../../../hooks/usePrevious'
import { toggleError }         from '../../../../actions/toastActions'
import { en }                  from '../../../../utils/languages'
import { editBlock }           from '../../../../utils/initializeForm'
import { loadCalendarBlock,
         updateCalendarBlock } from '../../../../actions/calendarActions'
import { loadServiceBlock, 
         updateServiceBlock }  from '../../../../actions/serviceActions'
import { timeOffCardForm,
         timeOffRepeatSection }from '../../../../utils/formFields'
import { structureBlockData }  from '../../../../utils/structureBlockData'
import { switchView }          from '../../../../actions/viewActions'
import { loadResourceBlock,
         updateResourceBlock } from'../../../../actions/resourceActions'

const EditTimeOff = props => {
  const { match: { params },
          calendarBlock,
          change,
          form,
          handleSubmit,
          history,
          initialize,
          loading,
          noEndDate,
          reason,
          repeats,
          repeat,
          resourceBlock,
          serviceBlock,
          switchView,
          toggleError,
          view
        } = props

  const { pathname } = window.location
  const blockId = params.id

  useEffect(() => {
    const block   = structureBlockData(props)
      
    if (pathname.includes(block.path)) {
        switchView(block.viewType, false, true)
    }

    if (view === block.viewType) {
      if (!block.blockData || Number(block.blockData.id) !== Number(blockId)) {
        if (block.action) {          
            
          block.action(blockId)
               .then(res => {
                  toggleCheckErrors(res, toggleError, () => {
                      initialize(editBlock(res.payload.data))
                  })
               })
        }
      } else { 
          initialize(editBlock(block.blockData))
      }
    }

  },[ calendarBlock,
      blockId,
      initialize,
      serviceBlock, 
      resourceBlock,
      view ])
  
  const prevNoEndDate = usePrevious(noEndDate)

  useEffect(() => {
    if (prevNoEndDate && prevNoEndDate !== noEndDate) {
      change('endDate', noEndDate ? '9999-12-31' : moment().format('YYYY-MM-DD'))
    }
  }, [ noEndDate ])

  const submitForm = values => {
    let block   = structureBlockData(props)
    let viewId  = block.blockData[`${view.toLowerCase().slice(0, -1)}Id`]
    let url     = `/${view}/edit/${viewId}?tab=availability`

    const timeBlock = {
      startDate : values.startDateBlock,
      endDate : values.endDateBlock,
      startTime : values.startTimeBlock,
      endTime : values.endTimeBlock,
      reason : values.reasonBlock,
      repeats : values.repeatsBlock,
      repeat : values.repeatBlock,
    }

    block.update(blockId, timeBlock)
         .then(res => {
             toggleCheckErrors(res, toggleError, () => {
                block.action(blockId)
                history.push(url)
            })
         })
  }

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      { loading ? <Loading /> : (
        <>
          <Header
            title={reason ? reason : 'Edit Time Block'}
            cta={en.ctas.save}
            btntype="Submit"
          />
          <FormFields form={form} fields={timeOffCardForm} />
          <FormFields disabled={!repeats} form={form} fields={timeOffRepeatSection} />
          
          <WeekDays form={form} repeats={repeats} repeat={repeat} isEvent={false} />
        </>  
      )}
    </form>
  )
}

const mapStateToProps = (state, props) => ({
  calendarBlock:    state.calendarBlockLoaded.calendarBlock,
  serviceBlock:     state.serviceBlockLoaded.serviceBlock,
  resourceBlock:    state.resourceBlockLoaded.resourceBlock,
  loading:          state.calendarBlockLoaded.isLoading ||
                    state.serviceBlockLoaded.isLoading ||
                    state.resourceBlockLoaded.isLoading,
  noEndDate:        formValueSelector(props.form)(state, 'noEndDate'),
  reason:           formValueSelector(props.form)(state, 'reasonBlock'),
  repeats:          formValueSelector(props.form)(state, 'repeatsBlock'),
  repeat:           formValueSelector(props.form)(state, 'repeatBlock'),
  view:             state.view.view,
})

const connectedEditTimeOff = connect(
  mapStateToProps, 
  { loadCalendarBlock,
    updateCalendarBlock,
    loadServiceBlock,
    updateServiceBlock,
    switchView,
    loadResourceBlock,
    toggleError,
    updateResourceBlock }
)(EditTimeOff)

export default reduxForm({})(connectedEditTimeOff)
