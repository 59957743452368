import React, { Component } from 'react'
import axios                from 'axios'
import { Redirect }         from 'react-router-dom'

import Loading              from '../components/Loading/Loading'


export default function isAuthenticated(AuthComponent) {
  return class extends Component {
    constructor(props) {
      super(props)
      this.state = {
        loading: true,
        redirect: false
      }
    }

    componentDidMount() {
      axios.get('/auth/check_token')
        .then(token => {
          if (token.status === 200) {
            // update user/company
            this.setState({ loading: false })
          } 
          else{
            this.setState({ loading: false, redirect: true })
            throw new Error(token.error)
          } 
        })
        .catch(err => {
          console.error(err)
          this.setState({ loading: false, redirect: true })
        })
    }

    render() {
      const { loading, redirect } = this.state

      if ( loading ) return <Loading />
      if ( redirect ) return <Redirect to="/SignIn" />

      return <AuthComponent {...this.props} />
    }
  }
}