import React, { Component } from 'react'
import { Toast, Button }    from 'react-bootstrap'

import './ToastItem.css'

class ToastItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      active: true,
      details: false
    }
  }

  componentDidMount() {
    setTimeout(() => {
      if ( !this.state.details ) this.handleClose()
    }, 3000);
  }

  handleDetails = () => {
    this.setState({ details: !this.state.details })
  }

  handleClose = () => {
    this.setState({ active: false })
  }

  render() {
    const { toast } = this.props
    const { details, active } = this.state

    let detailsBtn = <div className="success">
                       <i className="far fa-check" />
                     </div>

    let errorMsg   = null
    let openClass  = ''

    if (toast.data && 
        toast.data.response && 
        toast.data.response.status !== 200 ) {
      detailsBtn = <Button className="fail" onClick={this.handleDetails}>Details</Button>

      if ( details ) {
        openClass = 'details'

      let errors = [{detail: 'unknown'}]

      if (toast.data.response)
        errors = toast.data.response.data.errors
        
        if (errors) {
          errorMsg = errors.map((error, i) => (
          <Toast.Body key={i}>{error.detail}</Toast.Body>
          ))
        }
      } 
    }

    return (
      <Toast
        show={active}
        className={`ToastItem ${openClass}`}
        onClose={this.handleClose}
      >
        <Toast.Header>
          { detailsBtn }

          <h5>{ toast.message }</h5>
        </Toast.Header>
        
        { errorMsg }
      </Toast>
    )
  }
}

export default ToastItem