import React, { Component } from 'react'

class renderPasswordField extends Component {
  constructor(props) {
    super(props) 
    this.state = {
      viewPw: false
    }
  }

  handleViewPw = () => {
    this.setState({ viewPw: !this.state.viewPw })
  }

  render() {
    const { showError, input, placeholder,
          customClass, label, meta: { touched, error } } = this.props
    
    let viewPw = (
      <i 
        onClick={() => this.handleViewPw()} 
        className="viewPw fad fa-eye" 
      />
    )
    if (this.state.viewPw) {
      viewPw = (
        <i 
          onClick={() => this.handleViewPw()} 
          className="viewPw fad fa-eye-slash" 
        />
      )
    }
    
    let renderError = null
    if (showError && touched && error) {
      renderError = <span className="error">{error}</span>
    }
  
    return (
      <div className={`renderField renderPasswordField ${customClass ? customClass : ''} ${touched && error ? 'error' : ''}`}>
        <label>{ label }</label>
        <input 
          {...input} 
          placeholder={placeholder}
          type={this.state.viewPw ? "text" : "password"} 
        />
        { viewPw }
        { renderError }
      </div>
    )
  }
}

export default renderPasswordField
