import { handle } from 'redux-pack'

import { LOAD_EMAIL_TEMPLATES } from '../actions/notificationActions'


const INITIAL_STATE = { success: false }


export default function( state = INITIAL_STATE, action ) {
  const { type, payload } = action
  switch ( type ) {
    
    case LOAD_EMAIL_TEMPLATES:
      return handle( state, action, {
        start:   previousState => ( { ...previousState, isLoading: true, eTemplatesError: null, eTemplates: null } ),
        finish:  previousState => ( { ...previousState, isLoading: false } ),
        failure: previousState => ( { ...previousState, eTemplatesError: payload } ),
        success: previousState => ( { ...previousState, success: true, eTemplates: payload.data.data } )
      })

    default:
      return state

  }

}