import { SET_LOCATIONS_LIST_SEARCH_TEXT, 
         RESET_LIST_SEARCH_TEXT,  
         SET_RESOURCES_LIST_SEARCH_TEXT,
         SET_SERVICE_LIST_SEARCH_TEXT} from '../actions/searchListActions'

const INITIAL_STATE = {
  searchLocationText: '',
  searchResourceText: '',
  searchServiceText: '',
}

export const searchTextReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_LOCATIONS_LIST_SEARCH_TEXT:
      return {
          ...state,
          searchLocationText: action.searchText
        }
    case SET_RESOURCES_LIST_SEARCH_TEXT:  
      return {
          ...state,
          searchResourceText: action.searchText
      }
    case SET_SERVICE_LIST_SEARCH_TEXT:  
      return {
          ...state,
          searchServiceText: action.searchText
      }
    case RESET_LIST_SEARCH_TEXT: 
      return{
         ...state,
         searchLocationText: '',
         searchResourceText: '',
         searchServiceText: ''
      }
    default:
      return state
  }
}
