import { handle } from 'redux-pack'

import { DELETE_USER } from '../actions/userActions'


const INITIAL_STATE = { success: false }


export default function deleteUserReducer( state = INITIAL_STATE, action ) {
  const { type, payload } = action

  switch ( type ) {
    
  case DELETE_USER:
    return handle( state, action, {
      start:   previousState => ( { ...previousState, isLoading: true, userDeletedError: null, success: false } ),
      finish:  previousState => ( { ...previousState, isLoading: false } ),
      failure: previousState => ( { ...previousState, userDeletedError: payload } ),
      success: previousState => ( { ...previousState, success: true, userDeleted: payload.data } )
    })

  default:
    return state

  }

}
