import React                      from 'react'
import { connect }                from 'react-redux'
import { OverlayTrigger, Tooltip} from 'react-bootstrap'
import { Link }                   from 'react-router-dom'

import { en }                     from '../../../../utils/languages'

import './EditSelected.css'


const EditSelected = props => {
  const { loading, success, selectingLocations, selectedLocation } = props

  if ( loading || !success || !selectedLocation || selectingLocations ) return null

  return (
    <div>
      <OverlayTrigger
        key={selectedLocation.id}
        placement="bottom"
        overlay={
          <Tooltip id="tooltip-editLocation">
            {en.topnav.edit_location}
          </Tooltip>
        }
      >
        <Link
          to={`/locations/edit/${selectedLocation.id}`}
        >
          { selectedLocation.name }
        </Link>
      </OverlayTrigger>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    selectedLocation: state.selectedLocation.selectedLocation,
    loading:          state.selectedLocation.isLoading,
    success:         state.selectedLocation.success
  }
}

export default connect(mapStateToProps, {})(EditSelected)