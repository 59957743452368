export const emailFields = [
  {
    name:"emailBackgroundColor",
    label:"Background",
  },
  {
    name:"emailColor",
    label:"Color",
  },
  {
    name:"emailLinkColor",
    label:"Link",
  },
]

export const panelFields = [
  {
    name:"panelBackgroundColor",
    label:"Background",
  },
  {
    name:"panelColor",
    label:"Color",
  },
  {
    name:"panelLinkColor",
    label:"Link",
  },
]

export const bodyFields = [
  {
    name:"contentBackgroundColor",
    label:"Background",
  },
  {
    name:"contentColor",
    label:"Color",
  },
  {
    name:"contentLinkColor",
    label:"Link",
  },
]

export const headerLogoFields = [
  {
    name:"headerLogoHeight",
    type:"number",
    label:"Logo Height",
  },
  {
    name:"headerLogoPadding",
    type:"number",
    label:"Padding",
  },
]

export const footerLogoFields = [
  {
    name:"footerLogoHeight",
    type:"number",
    label:"Logo Height",
  },
  {
    name:"footerLogoPadding",
    type:"number",
    label:"Padding",
  },
]

export const reqParams = [
  'ApptName',
  'ApptDate',
  'ApptTime',
]

export const optParams = [
  'CustomerName',
  'CustomerEmail',
  'CustomerGender',
  'CustomerBirthdate',
  'CustomerPhone',
  'CustomerGroup',
  'CustomerResource',
  'CustomerAddressLine1',
  'CustomerAddressLine2',
  'CustomerCity',
  'CustomerState',
  'CustomerPostalCode',
  'CustomerCountry',
  'CustomerCustomField1',
  'CustomerCustomField2',
  'CustomerCustomField3',
  'CustomerCustomField4',
  'CustomerCustomField5',
  'CustomerCustomField6',
  'CustomerCustomField7',
  'CustomerCustomField8',
  'CustomerCustomField9',
  'CustomerCustomField10',
  'BusinessName',
  'BusinessAddressLine1',
  'BusinessAddressLine2',
  'BusinessCity',
  'BusinessState',
  'BusinessPostalCode',
  'BusinessCountry',
  'BusinessPhone',
  'BusinessFax',
  'BusinessEmail',
  'BusinessWebsite',
  'BusinessScreenName',
  'BusnessConferenceInfo',
  'CompanyName',
  'CompanyAddressLine1',
  'CompanyAddressLine2',
  'CompanyCity',
  'CompanyState',
  'CompanyPostalCode',
  'CompanyCountry',
  'CompanyPhone',
  'CompanyFax',
  'CompanyEmail',
  'CompanyWebsite',
  'ShowReschedule',
  'NoReschedule',
  'ConfirmationNumber',
  'ApptDescription',
  'ApptNotes',
  'ApptDate',
  'ApptTime',
  'ApptTimeZone',
  'ApptName',
  'ApptEmail',
  'ApptPhone',
  'ApptResource',
  'ApptType',
  'ApptLocation',
  'RescheduledDate',
  'RescheduledTime',
  'RescheduledTimeZone',
  'CustomerMessage',
  'AppointmentCustomField1',
  'AppointmentCustomField2',
  'AppointmentCustomField3',
  'AppointmentCustomField4',
  'AppointmentCustomField5',
  'AppointmentCustomField6',
  'AppointmentCustomField7',
  'AppointmentCustomField8',
  'AppointmentCustomField9',
  'AppointmentCustomField10',
  'ResourceName',
  'ResourceEmail',
  'ResourceSkypeName',
  'ResourceBioLink',
  'ResourceConferenceInfo',
  'BookAppointmentLink',
  'CancelAppointmentLink',
  'ConfirmAppointmentLink',
  'CustomerRegistrationLink',
  'CustomerBookingLink',
  'BookAppointmentUrl',
  'CancelAppointmentUrl',
  'ConfirmAppointmentUrl',
  'CustomerRegistrationUrl',
  'CustomerBookingUrl',
  'AddToCalendarUrl',
  'AddToGoogleCalendarUrl',
  'ResourceRescheduleUrl',
  'ResourceCancelUrl',
  'BusinessUserInviteUrl',
]