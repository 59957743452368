import React, { Component } from 'react'

import { loadOnSchedJS } from '../../../utils/loadOnSchedJS'
import Loading           from '../../Loading/Loading'

import './OnSchedJS.css'

class OnSchedJS extends Component {
  constructor(props) {
    super(props)
    this.state = { onschedReady: false }
  }

  componentWillMount() {
    loadOnSchedJS(() => {
      // Work to do after the library loads.
      this.setState({ onschedReady: true })
    })
  }

  render() {
    const { onschedReady } = this.state
    const { client, show } = this.props
    
    var elHeading = document.getElementById("heading")

    if (!onschedReady || !client ) return <Loading />

    if (show) {
      var onsched = window.OnSched(client.clientId, "sbox")
      var elements = onsched.elements()


      // // Customer element
      // var customerObject = {
      //   locationId: '', firstname: 'Jim', lastname: 'Beem', email: 'jim@onsched.com', type: 0, contact: { mobilePhone: '9053998404' }
      // }
      // var customerParams = { locationId: '', customerId: '', email: 'jim@onsched.com', customerIM: customerObject }
      // var customerOptions = {}
      // var customer = elements.create("customer", customerParams, customerOptions)
      // customer.mount("customer")
      // var elCustomer = document.getElementById("customer")
      // var elCustomerButton = document.getElementById("customerButton")
      // elCustomerButton.onclick = function (e) {
      //   customer.mount("customer")
      // }


      var locationsParams = { units: 'imperial', limit: 8, offset: 0 }
      
      // elCustomer.addEventListener("postCustomer", function (e) {
      //   console.log("customerPost", e.detail)
      // })

      // elCustomer.addEventListener("getCustomer", function (e) {
      //   availabilityParams.customerId = e.detail.id
      //   console.log("customerGet", e.detail)
      //   availability.update(availabilityParams)
      // })
      // var customerParams = { locationId: '', customerId: '', email: 'john@onsched.com', customerIM: customerObject }
      // var customerOptions = {}
      // var customerObject = {
      //   locationId: '', firstname: 'Jim', lastname: 'Beem', email: 'jim@onsched.com', 
      //   type: 0, contact: { mobilePhone: '2896890659' }
      // }
      // var customer = elements.create("customer", customerParams, customerOptions)
      // var elCustomer = document.getElementById("customer")
      // elCustomer.addEventListener("postCustomer", function (e) {
      //   console.log(e.detail)
      // })

      // Resources element
      var resourcesParams = { locationId: '' }
      var resourcesOptions = {}
      var resources = elements.create("resources", resourcesParams, resourcesOptions)
      var elResources = document.getElementById("resources")
      elResources.addEventListener("getResources", function (e) {
        elLocations.innerHTML = ""
        elSearch.innerHTML = ""
        elServices.innerHTML = ""
        if (e)
          hideLoading()
      })
      elResources.addEventListener("clickResource", function(e) {
          // returns the resourceId in e.detail
          elHeading.innerHTML = ''
          elResources.innerHTML = ""
          availabilityParams.resourceId = e.detail.resourceId
          availability.mount("availability")
          console.log("avail params", availabilityParams)
      })

      // Services element
      var servicesParams = { locationId: '' }
      var servicesOptions = {}
      var services = elements.create("services", servicesParams, servicesOptions)
      var elServices = document.getElementById("services")
      elServices.addEventListener("getServices", function (e) {
        elLocations.innerHTML = ""
        elSearch.innerHTML = ""
        if (e)
          hideLoading()
      })
      elServices.addEventListener("clickService", function(e) {
        showLoading()
        elHeading.innerHTML = 'Resources'
        elServices.innerHTML = ""
        availabilityParams.serviceId = e.detail.serviceId
        resources.mount("resources")
      })

      // Search element
      var searchParams = { placeholder: 'Enter Zipcode to find nearest location', message: '', searchText: '' }
      var searchOptions = {}
      var search = elements.create("search", searchParams, searchOptions)

      var elSearch = document.getElementById("search")
      elSearch.addEventListener("clicked", function (e) {
        locationsParams.nearestTo = e.detail.searchText
        locations.mount("locations")
      })
      elSearch.addEventListener("notFound", function (e) {
          console.log(e.detail)
      })

      // Locations element
      var locationsParams = { units: 'imperial', limit: 8, offset: 0 }
      var locationsOptions = {}
      var locations = elements.create("locations", locationsParams, locationsOptions)
      var elLocations = document.getElementById("locations")
      elLocations.addEventListener("clickLocation", function(e) {
        showLoading()
        elHeading.innerHTML = 'Services'
        servicesParams.locationId = e.detail.locationId
        availabilityParams.locationId = e.detail.locationId
        // customerObject.locationId = e.detail.locationId
        // customerParams.locationId = e.detail.locationId
        
        services.update(servicesParams)
        services.mount("services")
      })

      // Availability element
      var now = new Date()
      var tzOffset = -now.getTimezoneOffset()
      var availabilityParams = {
        locationId: '', 
        serviceId: '', 
        resourceId: '', 
        tzOffset
      }
      var availabilityOptions = {}
      var availability = elements.create("availability", availabilityParams, availabilityOptions)
      var elAvailability = document.getElementById("availability")
      elAvailability.addEventListener("getAvailability", function(e) {
        elResources.innerHTML = ""
      })

      elAvailability.addEventListener("bookingConfirmation", function (e) {
        elAvailability.innerHTML = ""
        confirmationParams = { appointment: e.detail }
        confirmation.update(confirmationParams)
        confirmation.mount("confirmation")
      })

      // Confirmation element
      var confirmationParams = {}
      var confirmationOptions = {}
      var confirmation = elements.create("confirmation", confirmationParams, confirmationOptions)

      // Loading
      var elLoading = document.getElementById("OnSchedJS")
      function showLoading() {
        elHeading.classList.add("hide")
        elLoading.classList.add("loading")
        elLocations.innerHTML = ""
        elServices.innerHTML = ""
        elResources.innerHTML = ""
        elAvailability.innerHTML = ""
      }
      function hideLoading() {
        elLoading.classList.remove("loading")
        elHeading.classList.remove("hide")
      }

      elHeading.innerHTML = "Locations"
      elServices.innerHTML = ""
      elResources.innerHTML = ""
      elAvailability.innerHTML = ""
      search.mount("search")
      locations.mount("locations")
    }
    return (
      <div className="OnSchedJS" id="OnSchedJS">
        <h5 id="heading" />
        {/* <div id="loading">
          <Loading />
        </div> */}
        {/* <div id="customer" /> */}
        <div id="search" />
        <div id="locations" />
        <div id="services" />
        <div id="resources" />
        <div id="availability" />
        <div id="confirmation" />
        {/* <button id="customerButton">customer</button> */}
      </div>
    )
  }
}

export default OnSchedJS