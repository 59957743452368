import React, 
       { useState, 
         useEffect }        from 'react'
import { Modal, Button }    from 'react-bootstrap'
import { connect }          from 'react-redux'
import { Link }             from 'react-router-dom'
import { useHistory }       from 'react-router'
import { submit }           from 'redux-form'
import { toggleExitIntent,
         toggleUnsaved }    from '../../actions/exitIntentActions'
import { deleteService }    from '../../actions/serviceActions'
import { viewTypes }        from '../../actions/viewActions'
import { customErrors }     from '../../utils/customErrors'
import { toggleError }      from '../../actions/toastActions'
import { en }               from '../../utils/languages'

import './ExitIntentModal.css'


const ExitIntentModal = (props) => {
  const { exit,
          toggleExitIntent,
          toggleUnsaved,
          toggleError,
          submit,
          view
        } = props

  const [ show, setShow ] = useState(false)
  const history = useHistory()

  useEffect(() => {
    setShow(exit.actionType)
  }, [exit])

  const hideModal = () => {
    toggleExitIntent(exit?.destination, false)
  }

  const acceptAction = () => {
    hideModal()
    toggleUnsaved(false)
  }

  const saveAction = () => {
    const { destination, form, valid } = exit

    const submitAsync = async () => {
      if (valid) {
        await submit(form)
        toggleExitIntent(destination, false)
        history.push(destination)
      }
      else {
        const error = customErrors.formValidationError
        toggleError(error) 
        hideModal()
      }
    }

    if(form.includes('services')) {
      // set last parameter to TRUE to set the 'exit.callback' value to true 
      //                             and call a function based on this value
      toggleExitIntent(destination, false, '', true)
      toggleUnsaved(false)
    }
    else {
      submitAsync()
    }
  }

  if (!exit.destination) return null
  
  const displaySaveButton = view === viewTypes.resources || view === viewTypes.locations || view === viewTypes.services
  return (
    <Modal
      show={show}
      onHide={hideModal}
      className="ExitIntentModal"
    >
      <Modal.Body>{en.exitIntent.body}</Modal.Body>
      
      <Modal.Footer>
        <Button 
          onClick={() => hideModal()}
          variant="flat"
        >
          {en.exitIntent.decline}
        </Button>

        <Link 
          className={`btn ${displaySaveButton  ? 'btn-flat' : 'btn-primary'}`} 
          to={exit.destination} 
          onClick={() => acceptAction()}
        >
          {en.exitIntent.accept}
        </Link>

        { displaySaveButton && 
          <button 
            className="btn btn-primary"
            onClick={() => saveAction()}
          >
            {en.exitIntent.save}
          </button>    
        }
      </Modal.Footer>
    </Modal>
  )

}

function mapStateToProps(state) {
  return {
    exit:    state.exit,
    unsaved: state.unsaved,
    view:    state.view.view
  }
}

export default connect(mapStateToProps, { toggleExitIntent, toggleUnsaved, deleteService, submit, toggleError })(ExitIntentModal)