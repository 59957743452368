import { TOGGLE_CALENDAR_MODAL } from '../actions/modalActions'

const INITIAL_STATE = {
  showModal: false,
  isGoogle: false,
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TOGGLE_CALENDAR_MODAL:
      return {
        showModal: action.show,
        isGoogle:  action.isGoogle,
      }
    default:
      return state
  }
}
//CALENDAR NOT SHOWING