import { handle } from 'redux-pack'

import { DELETE_CALENDAR_BLOCK } from '../actions/calendarActions'


const INITIAL_STATE = { success: false }


export default function( state = INITIAL_STATE, action ) {
  const { type, payload } = action
  switch ( type ) {
    
    case DELETE_CALENDAR_BLOCK:
      return handle( state, action, {
        start:   previousState => ( { ...previousState, isLoading: true, calendarBlockDeletedError: null, calendarBlockDeleted: null } ),
        finish:  previousState => ( { ...previousState, isLoading: false } ),
        failure: previousState => ( { ...previousState, calendarBlockDeletedError: payload } ),
        success: previousState => ( { ...previousState, success: true, calendarBlockDeleted: payload.data } )
      })

    default:
      return state

  }

}