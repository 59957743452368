import { handle } from 'redux-pack'

import { LOAD_RESOURCES } from '../actions/resourceActions'


const INITIAL_STATE = { success: false }


export default function( state = INITIAL_STATE, action ) {
  const { type, payload } = action
  switch ( type ) {
    
    case LOAD_RESOURCES:
      return handle( state, action, {
        start:   previousState => ( { ...previousState, isLoading: true, resourcesError: null, resources: null } ),
        finish:  previousState => ( { ...previousState, isLoading: false } ),
        failure: previousState => ( { ...previousState, resourcesError: payload } ),
        success: previousState => ( { ...previousState, success: true, resources: payload.data } )
      })

    default:
      return state

  }

}