import React         from 'react'
import { reduxForm } from 'redux-form'

import WeekDayItem from './WeekDayItem/WeekDayItem'

import './WeekDays.css'


const WeekDays = ({repeat, form, repeats, isEvent}) => {
  let display = ''
  if (!repeats ||
      !repeat || 
      repeat.frequency !== 'W'
  ) {
    display = 'hide'
  }

  let days = ['S', 'M', 'T', 'W', 'T', 'F', 'S']

  return (
    <div className={`WeekDays ${display}`}>
      <label>On</label>
      <ul>
        {days.map((day, i) => 
          <WeekDayItem 
            key={i}
            letter={day}
            number={i.toString()}
            form={form}
            weekdays={repeat?.weekdays}
            isEvent={isEvent}
          />
        )}
      </ul>
    </div>
  )
}

export default reduxForm({})(WeekDays)