import { TOGGLE_CREATE_MODAL } from '../actions/modalActions'

const INITIAL_STATE = {
  showModal: false
}

const createModalReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TOGGLE_CREATE_MODAL:
      return {
        modalData: action.modalData,
        showModal: action.showModal
      }
    default:
      return state
  }
}

export default createModalReducer