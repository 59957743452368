import { handle } from 'redux-pack'

import { REVOKE_CLIENT_SECRET } from '../actions/credentialsActions'


const INITIAL_STATE = { success: false }


export default function( state = INITIAL_STATE, action ) {
  const { type, payload } = action
  switch ( type ) {
    
    case REVOKE_CLIENT_SECRET:
      return handle( state, action, {
        start:   previousState => ( { ...previousState, isLoading: true, clientSecretError: null, clientSecret: null } ),
        finish:  previousState => ( { ...previousState, isLoading: false } ),
        failure: previousState => ( { ...previousState, clientSecretError: payload } ),
        success: previousState => ( { ...previousState, success: true, clientSecret: payload.data } )
      })

    default:
      return state

  }

}