import { handle } from 'redux-pack'

import { CLIENT_CREDENTIALS } from '../actions/userActions'


const INITIAL_STATE = { success: false }


export default function( state = INITIAL_STATE, action ) {
  const { type, payload } = action
  switch ( type ) {
    
    case CLIENT_CREDENTIALS:
      return handle( state, action, {
        start:   previousState => ( { ...previousState, isLoading: true, clientCredentialsError: null, clientCredentials: null } ),
        finish:  previousState => ( { ...previousState, isLoading: false } ),
        failure: previousState => ( { ...previousState, clientCredentialsError: payload } ),
        success: previousState => ( { ...previousState, success: true, clientCredentials: payload.data } )
      })

    default:
      return state

  }

}