import React, { Component } from 'react'
import { connect }          from 'react-redux'

import MenuCard                  from '../MenuCard/MenuCard'
import TestBooking               from '../TestBooking/TestBooking'
import { en }                    from '../../../utils/languages'
import { detectEnv }             from '../../../utils/detectEnv'
import { clientCredentials }     from '../../../actions/userActions'
import { loadInitialResources }  from '../../../actions/resourceActions'
import { loadInitialServices }   from '../../../actions/serviceActions'
import { loadCalendars }         from '../../../actions/calendarActions'
import { switchView, viewTypes } from '../../../actions/viewActions'
import { authorizeToken }        from '../../../actions/onschedJsActions'

import './ForManagers.css'


class ForManagers extends Component {
  constructor(props) {
    super(props)
    this.state = {
      locationsStatus: false,
      resourcesStatus: false,
      servicesStatus:  false,
      calendarsStatus: false,
      client_id: null
    }
  }
  
  componentDidMount() {
    const { resources, 
            selectedLocation, 
            services, 
            switchView, 
            clientCredentials
          } = this.props
    
    switchView(viewTypes.get_started)
    clientCredentials()
    .then(creds => {
      if (creds.payload.status === 200) {
        const { clientId } = creds.payload.data

        this.setState({client_id: clientId})

        //OnSchedJs token
        this.props.authorizeToken({
          client_id: clientId,
        }, detectEnv(), {})
      }
    })

    if ( selectedLocation ) {
      this.props.loadInitialResources(selectedLocation.id)
      this.props.loadInitialServices(selectedLocation.id)
    }

    if ( resources && resources.data.length )
      this.setState({ resourcesStatus: true })

    else this.setState({ resourcesStatus: false })

    if ( services && services.data.length ) 
      this.setState({ servicesStatus: true })
    
    else this.setState({ servicesStatus: false })
  }
  
  componentDidUpdate(prevProps) {
    const { resources, services, selectedLocation } = this.props
    
    if ( prevProps.resources !== resources ) {
        if ( resources && resources.data.length ) {
          this.setState({ resourcesStatus: true })
        }
        else this.setState({ resourcesStatus: false })
      }
      
      if ( prevProps.services !== services ) {
        if (services && services.data.length) {
          this.setState({ servicesStatus: true })
        }
        else this.setState({ servicesStatus: false })

        // Check primary and secondary
        // if ( ( primary.id === selectedLocation.id && services && services.data.length > 0 ) ||
        //       ( services.data.filter(i => i.locationId.length > 0).length > 0 && 
        //       services && services.data.length > 0 ) ) {
        //   this.setState({ servicesStatus: true })
        // }
    }
        
    if ( prevProps.selectedLocation !== selectedLocation && selectedLocation ) {
      this.props.loadInitialResources(selectedLocation.id)
      this.props.loadInitialServices(selectedLocation.id)
    }
  }
  
  render() {
    const { resources, services } = en.get_started
    const { resourcesStatus, servicesStatus, } = this.state

    let data = [
      { "icon" : "fad fa-check-circle",  "body" : services,  "view" : viewTypes.services,  "onboardingStatus" : servicesStatus },
      { "icon" : "fad fa-check-circle", "body" : resources, "view" : viewTypes.resources, "onboardingStatus" : resourcesStatus },
    ]

    const cards = data.map((card, i) => (
      <MenuCard
        key={i}
        action="skip"
        icon={card.icon}
        body={card.body}
        view={card.view}
        status={card.onboardingStatus}
        switchView={this.props.switchView}
      />
    ))

    return (
      <div className="ForManagers">
        <h4>{en.get_started.for_managers}</h4>
        <p>{en.get_started.for_managers_desc}</p>
        
        { cards }
        
        <TestBooking
          icon="fad fa-arrow-right"
          body={en.get_started.test_booking}
          enabled={resourcesStatus && servicesStatus}
          client_id={this.state.client_id}
        />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    selectedLocation : state.selectedLocation.selectedLocation,
    primary          : state.primary.primary,
    resources        : state.initialResources.initialResources,
    services         : state.initialServices.initialServices
  }
}

export default connect( 
  mapStateToProps, 
  { 
    loadInitialResources, 
    loadInitialServices, 
    switchView,
    loadCalendars,
    clientCredentials,
    authorizeToken
  } )( ForManagers )
