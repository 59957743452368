import { TOGGLE_UNSAVED } from '../actions/exitIntentActions'

const INITIAL_STATE = {
  unsaved: false
}

const unsaved = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TOGGLE_UNSAVED:
      return {
          unsaved:    action.unsaved
        }
    default:
      return state
  }
}

export default unsaved