import { en } from '../utils/languages'

export const resourceModalData = {
  forOption: 'resource',
  action: 'radio',
  headerTitle: en.resources.modalData.headerTitle,
  bodyTitle: en.resources.modalData.bodyTitle
}

export const serviceModalData = {
  forOption : 'service',
  headerTitle : 'Add service'
}

export const userModalData = {
  forOption : 'businessuser',
  headerTitle : 'Add team member'
}

export const TOGGLE_CREATE_MODAL = 'TOGGLE_CREATE_MODAL'

export const toggleCreateModal = (data, action) => ({
  type: TOGGLE_CREATE_MODAL,
  modalData: data,
  showModal: action
})

export const SEND_MODAL_DATA = 'SEND_MODAL_DATA'

export const sendModalData = (modalData, redirect) => ({
  type: SEND_MODAL_DATA,
  modalData,
  redirect
})

export const TOGGLE_BOOK_MODAL = 'TOGGLE_BOOK_MODAL'

export const toggleBookModal = (location, action) => ({
  type: TOGGLE_BOOK_MODAL,
  modalData: location,
  showModal: action
})

export const TOGGLE_USER_MODAL = 'TOGGLE_USER_MODAL'

export const toggleUserModal = (user, action) => ({
    type: TOGGLE_USER_MODAL,
    modalData: user,
    showModal: action
})

export const TOGGLE_CONFIRMATION_MODAL = 'TOGGLE_CONFIRMATION_MODAL'

export const toggleConfirmationModal = (action, modalData = {} ) => ({
  type: TOGGLE_CONFIRMATION_MODAL,
  showModal: action,
  modalData 
})

export const ACCEPT_CONFIRMATION_MODAL = 'ACCEPT_CONFIRMATION_MODAL'
export const RESET_CONFIRMATION_MODAL =  'RESET_CONFIRMATION_MODAL'

export const TOGGLE_CALENDAR_MODAL = 'TOGGLE_CALENDAR_MODAL'

export const toggleCalendarModal = (show, isGoogle = null) => ({
  type: TOGGLE_CALENDAR_MODAL,
  show,
  isGoogle
})