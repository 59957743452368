import { LOAD_TOAST } from '../actions/toastActions'

const toasts = (state = [], action) => {
  switch (action.type) {
    case LOAD_TOAST:
      return {
        message:    action.message,
        data:       action.data,
        showToast:  true
      }
    default:
      return state
  }
}

export default toasts