import React from 'react'

import InitialsIcon from '../../../InitialsIcon/InitialsIcon'

import './BookableServiceItem.css'


const BookableServiceItem = props => {
  const { service, linked, handleLinked, selectedID } = props
  
  let itemLinked = ''
  if (linked) {
    itemLinked = 'linked'
  }
  
  const scope = service.locationId === selectedID ? 'LOC' :'COM'
  
  return (
    <tr 
      className={`BookableServiceItem ${itemLinked}`}
      onClick={() => handleLinked(service)}
    >
      <td className="bookable">
        <i className="fad fa-check-circle" />
      </td>
      <td>
        <InitialsIcon name={service.name} img={service.imageUrl} />
      </td>
      <td>
        <span>{service.name}</span>
      </td>
      <td>
        <span>{scope}</span>
      </td>
      <td>
        <span>{service.duration} min</span>
      </td>
      <td>
        <span>{service.type - 1 ? 'Event' : 'Appointment'}</span>
      </td>
      <td>
        <span>{service.id}</span>
      </td>
    </tr>
  )
}

export default BookableServiceItem
