import { handle } from 'redux-pack'

import { CHANGE_PASSWORD } from '../actions/authActions'


const INITIAL_STATE = { isLoading: false }


export default function( state = INITIAL_STATE, action ) {
  const { type, payload } = action

  switch ( type ) {

    case CHANGE_PASSWORD:
      return handle( state, action, {
        start:   previousState => ( { ...previousState, isLoading: true, changePasswordError: null, changePassword: null } ),
        finish:  previousState => ( { ...previousState, isLoading: false } ),
        failure: previousState => ( { ...previousState, changePasswordError: payload.data } ),
        success: previousState => ( { ...previousState, changePassword: payload.data } )
      })

    default:
      return state

  }

}
