import React         from 'react'

export const handleDesc = () => {
  var textarea = document.querySelector('textarea');
  textarea.addEventListener('keydown', autosize);
              
  function autosize(){
    setTimeout(() => {
      this.style.cssText = 'height:auto; padding:0'
      this.style.cssText = `height: ${(this.scrollHeight + 8)}px`
    },0);
  }
}

export const renderDescField = props => {
  const {name, label, id, input, defaultValue, customClass,
          placeholder, meta: {touched, error}} = props
          
  let errorClass = ''
  if ( touched && error ) errorClass = 'error'

  return (
    <div className={`renderField ${errorClass} ${customClass}`}>
      <label htmlFor={name}>{label}</label>
      <textarea 
        {...input}
        name={name} 
        placeholder={placeholder}
        defaultValue={defaultValue}
        id={id}
        cols="30" 
        rows="4"
        className='textArea'
        onInput={handleDesc}
      />
    </div>
  )
}