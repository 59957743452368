import React                            from 'react'
import { reduxForm, formValueSelector } from 'redux-form'
import { connect }                      from 'react-redux'

import WeekDay     from './WeekDay/WeekDay'
import UpdateWeek  from './UpdateWeek/UpdateWeek'
import Loading     from '../../Loading/Loading'

import './WorkWeekCard.css'


const WorkWeekCard = props => {
  const { change, 
          form, 
          availability, 
          serviceType 
        } = props

  const updateDay = () => {
    const { day, updateWeekValues } = props

    let values = updateWeekValues

    let availabilityTerm = "availability"
    
    if (props.form.split('-')[0] === "location") {
      availabilityTerm = "businessHours"
    }

    values.startTime = parseInt(values.startTime)
    values.endTime = parseInt(values.endTime)

    if (updateWeekValues.is24Hours) {
      values.startTime = 0
      values.endTime = 2400
    } 
    
    if (updateWeekValues.isClosed) {
      values.startTime = 0
      values.endTime = 0
    }

      change(`${availabilityTerm}.${day}`, values)
  }
  
  let weekdays = []

  if (availability) {
    for (const [day, value] of Object.entries(
      availability
    )) {
      weekdays.push({day, value})
    }
  }

  let weekdaysBoard = weekdays.map((weekday, i) =>
    <WeekDay key={i} weekday={weekday} />
  )

  let eventWarning = (
    <p>
      <i className="fad fa-exclamation-circle" />
      This service has a type of "Event" and will 
      only show availability during the Event dates 
      and times added in the Events tab
    </p>
  )

  if (serviceType !== "2")
    eventWarning = null 

  if (!availability)
    weekdaysBoard = <Loading />

  return (
    <div className="WorkWeekCard">
      <div className="HoursOfOperation">
        <h5>Hours of operation</h5>
        { eventWarning }
        <p style={{ marginBottom: '1rem' }}>
            Define the weekly days/hours 
            that are available for 
            booking appointments.
        </p>
        
        <div className="displayed">
          <table>
            <tbody>
              { weekdaysBoard }
            </tbody>
          </table>
        </div>
              
        <UpdateWeek 
          updateDay={updateDay} 
          weekdays={weekdays} 
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state, props) => {
  let availabilityTerm = 'availability'
  let updateWeekForm = 'update-availability'

  if (props.form.split('-')[0] === 'location') {
    availabilityTerm = 'businessHours'
  }

  return {
    availability: formValueSelector(props.form)(state, availabilityTerm),
    serviceType:  formValueSelector(props.form)(state, 'type'),
    day:          formValueSelector(updateWeekForm)(state, 'dayOfTheWeek'),
    updateWeekValues: {
      startTime:  formValueSelector(updateWeekForm)(state, 'startTime'),
      endTime:    formValueSelector(updateWeekForm)(state, 'endTime'),
      is24Hours:  formValueSelector(updateWeekForm)(state, 'is24Hours'),
      isClosed:   formValueSelector(updateWeekForm)(state, 'isClosed')
    }
  }
}

const connectedWorkWeekCard = connect(mapStateToProps, {})(WorkWeekCard)

export default reduxForm({ destroyOnUnmount: false })(connectedWorkWeekCard)
