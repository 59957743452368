import isAuthenticated    from '../utils/isAuthenticated'
import formWrapper        from '../utils/formWrapper'
import GetStarted      from '../components/GetStarted/GetStarted'
import ActivateAccount from '../components/ActivateAccount/ActivateAccount'
import Resources       from '../components/Resources/Resources'
import Services        from '../components/Services/Services'
import Notifications   from '../components/Notifications/Notifications'
import RequestLog      from '../components/RequestLog/RequestLog'
import APISettings     from '../components/APISettings/APISettings'
import ModifyCompany   from '../components/Company/ModifyCompany'
import EmailTemplate   from '../components/Notifications/EmailTemplate/EmailTemplate'
import DataLogs        from '../components/DataLogs/DataLogs'
import EditEvent       from '../components/EventsTab/EditEvent/EditEvent'
import BusinessUser    from '../components/BusinessUsers/BusinessUser/BusinessUser'
import BusinessUsers   from '../components/BusinessUsers/BusinessUsers'
import Locations       from '../components/Locations/Locations'
import ModifyLocation  from '../components/Locations/ModifyLocation/ModifyLocation'
import ModifyService   from '../components/Services/ModifyService/ModifyService'
import ModifyResource  from '../components/Resources/ModifyResource/ModifyResource'
import ChangePassword  from '../components/ChangePassword/ChangePassword'
import EditTimeOff     from '../components/AvailabilityTab/TimeOffCard/EditTimeOff/EditTimeOff'
import AppointmentDetails from '../components/DataLogs/Appointments/AppointmentsDetails/AppointmentDetails'
import CustomerDetails    from '../components/DataLogs/Customers/CustomerDetails/CustomerDetails'
// import CustomFields from '../components/CustomFields/CustomFields'

export const routes = [
  {
    "path"      : "/",
    "component" : isAuthenticated(GetStarted)
  },

  {
    "path"      : "/ActivateAccount",
    "component" : isAuthenticated(ActivateAccount)
  },
  
  {
    "path"      : "/Locations",
    "component" : isAuthenticated(Locations)
  },
  
  // {
  //   "path"      : "/Locations/create",
  //   "component" : isAuthenticated(formWrapper(ModifyLocation, 'location'))
  // },
  
  {
    "path"      : "/locations/edit/:id",
    "component" : isAuthenticated(ModifyLocation)
  },

  {
    "path"      : "/calendarBlocks/edit/:id",
    "component" : isAuthenticated(formWrapper(EditTimeOff, 'calendar-block'))
  },

  {
    "path"      : "/Resources",
    "component" : isAuthenticated(Resources)
  },
  
  // {
  //   "path"      : "/Resources/create",
  //   "component" : isAuthenticated(ModifyResource)
  // },
  
  {
    "path"      : "/Resources/edit/:id",
    "component" : isAuthenticated(ModifyResource)
  },

  {
    "path"      : "/resourceBlocks/edit/:id",
    "component" : isAuthenticated(formWrapper(EditTimeOff, 'resource-block'))
  },
  
  {
    "path"      : "/Services",
    "component" : isAuthenticated(Services)
  },
  
  // {
  //   "path"      : "/Services/create",
  //   "component" : isAuthenticated(formWrapper(ModifyService, 'service'))
  // },
  
  {
    "path"      : "/Services/edit/:id",
    "component" : isAuthenticated(ModifyService)
  },

  {
    "path"      : "/serviceBlocks/edit/:id",
    "component" : isAuthenticated(formWrapper(EditTimeOff, 'service-block'))
  },
  
  {
    "path"      : "/Notifications",
    "component" : isAuthenticated(Notifications)
  },
  
  {
    "path"      : "/emailTemplates/edit/:id",
    "component" : isAuthenticated(EmailTemplate)
  },
  
  {
    "path"      : "RequestLog",
    "component" : isAuthenticated(RequestLog)
  },
  
  {
    "path"      : "/APISettings",
    "component" : isAuthenticated(APISettings)
  },
  
  {
    "path"      : "/Company",
    "component" : isAuthenticated(ModifyCompany)
  },
  
  {
    "path"      : "/DataLogs",
    "component" : isAuthenticated(DataLogs)
  },

  {
    "path"      : "/DataLogs/appointments/edit/:id",
    "component" : isAuthenticated(AppointmentDetails)
  },

  {
    "path"      : "/DataLogs/customers/edit/:id",
    "component" : isAuthenticated(formWrapper(CustomerDetails, 'customer'))
  },
  
  {
    "path"      : "/serviceAllocations/edit/:allocationId",
    "component" : isAuthenticated(formWrapper(EditEvent, 'service-allocation'))
  },
  
  {
    "path"      : "/resourceAllocations/edit/:allocationId",
    "component" : isAuthenticated(formWrapper(EditEvent, 'resource-allocation'))
  },

  {
    "path"      : "/BusinessUsers/edit/:userId",
    "component" : isAuthenticated(BusinessUser)
  },
  
  {
    "path"      : "/BusinessUsers/create",
    "component" : isAuthenticated(BusinessUser)
  },
  
  {
    "path"      : "/BusinessUsers",
    "component" : isAuthenticated(BusinessUsers)
  },
  
  {
    "path"      : "/ChangePassword",
    "component" : isAuthenticated(ChangePassword)
  },
  // {
  //   "path"      : "/CustomFields",
  //   "component" : isAuthenticated(CustomFields)
  // }
]
