import React, { Component } from 'react'
import { connect }         from 'react-redux'

import ToastErrorDetails from './ToastErrorDetails/ToastErrorDetails'
import ToastErrorActions from './ToastErrorActions/ToastErrorActions'

import './ToastError.css'


class ToastError extends Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false
    }
  }

  componentDidUpdate(prevProps) {
    const { error, toasts } = this.props
    const { active } = this.state
    
    if (prevProps.error !== error) {
      this.setState({ active: true })
    }
    
    if (prevProps.toasts !== toasts) {
      if (active) {
        if (toasts) {
          if (toasts.status === 200) {
            if (error?.error?.response) {
              // If same endpoint successful, ignore error
              if (error?.error?.response?.config?.url === toasts?.config?.url) {
                this.setState({ active: false })
              }
            }
          }
        }
      }
    }
  }
  
  render() {
    const { error } = this.props
    const { active } = this.state
    
    let errors = []

    if (error && error.error && error.error.response) {
      if (error.error.response.data && error.error.response.data.errors) {
        errors = error.error.response.data.errors
      }
      else {
        errors.push('Unknown Error')
      }
    }
    
    if (errors.length) {
      return (
        <div className={`ToastError ${active ? 'active' : ''}`}>
          <h1>
            <span>Oops, something went wrong!</span>
          </h1>
          
          <ToastErrorDetails errors={errors} />
          
          <ToastErrorActions 
            setActive={e => this.setState({ active: e })} 
          />
        </div>
      )
    }
  
    return null
  }
}

const mapStateToProps = state => ({
  error: state.error,
  toasts: state.toasts.data
})

export default connect(mapStateToProps, {})(ToastError)
