import { handle } from 'redux-pack'

import { LOAD_INITIAL_SERVICES } from '../actions/serviceActions'


const INITIAL_STATE = { success: false }


export default function initialServicesReducer( state = INITIAL_STATE, action ) {
  const { type, payload } = action

  switch ( type ) {
    
  case LOAD_INITIAL_SERVICES:
    return handle( state, action, {
      start:   previousState => ( { ...previousState, isLoading: true, initialServicesError: null, success: false } ),
      finish:  previousState => ( { ...previousState, isLoading: false } ),
      failure: previousState => ( { ...previousState, initialServicesError: payload } ),
      success: previousState => ( { ...previousState, success: true, initialServices: payload.data } )
    })

  default:
    return state

  }

}