export const TOGGLE_SIDE_NAV  = 'TOGGLE_SIDE_NAV'

let nextNav = 0
export const toggleSideNav = action => {
  return {
    type: TOGGLE_SIDE_NAV,
    id: nextNav++,
    action: action
  }
}
export const COMPRESS_SIDE_NAV  = 'COMPRESS_SIDE_NAV'

export const compressSideNav = compressed => ({
  type: COMPRESS_SIDE_NAV,
  compressed
})