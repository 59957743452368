import React, { Component } from 'react'
import { connect }          from 'react-redux'

import { switchView,
          viewTypes }        from '../../actions/viewActions'
import { clientCredentials } from '../../actions/userActions'
import { authorizeApiDocs, loadCompany }  from '../../actions/companyActions'
import { toggleError }       from '../../actions/toastActions'

import CredentialsRow        from './CredentialsRow/CredentialsRow'
import Loading               from '../Loading/Loading'

import './APISettings.css'

class APISettings extends Component {
  constructor(props) {
    super(props)
    this.state = {
      copied: false,
      copyText: 'copied it'
    }
  }

  componentDidMount() {
    const { switchView, company, loadCompany } = this.props
    
    switchView(viewTypes.api_settings)
    
    // Check if company is loaded
    if (!company) {
      loadCompany()
        .then(companyResp => {
          this.loadClientCredentials(companyResp.payload.data)
        })
    }
    else {
      this.loadClientCredentials(company)
    }
  }

  // Load Client Credentials once we have company
  loadClientCredentials = (currentCompany) => {
    this.props.clientCredentials()
    .then(client => {
      if (client.payload.status !== 200) {
        this.props.toggleError(client.payload)
      }
      else {
        client.companyName = currentCompany.name
        client.companyExternalId = currentCompany.id

        this.props.authorizeApiDocs(client)
      }
    })
  }

  render() {
    const { client, loadCreds } = this.props

    if ( !client || loadCreds ) return <Loading />

    return (
      <div className="APISettings">
        <CredentialsRow />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  client:    state.clientCredentials.clientCredentials,
  company:   state.company.company,
  loadCreds: state.clientCredentials.isLoading ||
             state.revokeApiSecret.isLoading
})

export default connect(mapStateToProps, 
  { clientCredentials, 
    authorizeApiDocs,
    switchView,
    loadCompany,
    toggleError })(APISettings)
