import React from 'react'
import { connect } from 'react-redux'

import MasterTemlpate from './MasterTemplate/MasterTemplate'
import PreviewSizes   from './PreviewSizes/PreviewSizes'
import { en }         from '../../../../utils/languages'

import './CodePreview.css'


const CodePreview = ({HTML, options, screenSize}) => {
  const createMarkup = () => ({
     __html: HTML 
  })

  return (
    <div 
      className="CodePreview"
    >
      <h5>{en.emailTemplates.codePreview}</h5>
      <PreviewSizes />
      
      <style dangerouslySetInnerHTML={{__html: `
        .CenterEmailContent a { color: ${options.emailLinkColor} }
      `}} />
      
      <div 
        className="preview"
        style={{
          margin:          '0 auto',
          maxWidth:        screenSize,
          backgroundColor: options.emailBackgroundColor
        }}
      >
      <MasterTemlpate>
        <div 
          className="CenterEmailContent"
          dangerouslySetInnerHTML={createMarkup(HTML)}  
        />                  
      </MasterTemlpate>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  options:    state.emailPreview.options,
  screenSize: state.screenSize.screenSize
})

export default connect(mapStateToProps, {})(CodePreview)