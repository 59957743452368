import { useEffect } from "react"
import { usePrevious } from "./usePrevious"

// Resets the State when the Country has changed.
const useCountryChange = (country, change, isCompany = false) => {
  const prevCountry = usePrevious(country)
  useEffect(() => {
    if (prevCountry && prevCountry !== country ) {
      if (!isCompany) {
        change('address.state', '')
      }
      else {
        change('state', '')
      }
    }
  }, [country, change, prevCountry])
}

export default useCountryChange