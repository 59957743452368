import axios           from 'axios'
import querystring     from 'querystring'

import { toastMessages, 
         toggleError, 
         toggleToast } from './toastActions'


export const LOAD_INITIAL_SERVICES = 'LOAD_INITIAL_SERVICES'

export const loadInitialServices = ( locationId ) => {

  const config = {}

                   
  const resourceData = {
    "locationId" : locationId,
    "offset"     : 0,
    "limit"      : 1
  }
                 
  const queryString = querystring.stringify( resourceData )
  
  const servicesRoute = `/api/onsched/setup/services` +
                                          `?${queryString}`

  return {
    type:    LOAD_INITIAL_SERVICES,
    promise: axios.get( servicesRoute, config )
  }

}

export const LOAD_SERVICES = 'LOAD_SERVICES'

export const loadServices = ( queryParams = {} ) => {

  const config = {}
  
  const queryString = querystring.stringify( queryParams )
  
    const servicesRoute = `/api/onsched/consumer/services` +
                                          `?${queryString}`

  return {
    type:    LOAD_SERVICES,
    promise: axios.get( servicesRoute, config )
  }

}

export const LOAD_COMPANY_SERVICES = 'LOAD_COMPANY_SERVICES'

export const loadCompanyServices = ( location, offset ) => {

  const config = {}

                   
  const serviceData = {
    "locationId" : location.id,
    "offset"     : offset,
    "limit"      : 10
  }
                 
  const queryString = querystring.stringify( serviceData )
  
    const servicesRoute = `/api/onsched/setup/services` +
                                          `?${queryString}`

  return {
    type:    LOAD_COMPANY_SERVICES,
    promise: axios.get( servicesRoute, config )
  }

}

export const LOAD_SERVICE_BY_ID = 'LOAD_SERVICE_BY_ID'

export const loadServiceById = ( serviceId,
                                  queryParams = {} ) => {

  const config = {}
                 
  const queryString = querystring.stringify( queryParams )
  
  const servicesRoute = `/api/onsched/setup/services/${serviceId}` +
                                          `?${queryString}`

  return {
    type:    LOAD_SERVICE_BY_ID,
    promise: axios.get( servicesRoute, config )
  }

}

export const LOAD_SERVICE_AVAILABILITY = 'LOAD_SERVICE_AVAILABILITY'

export const loadServiceAvailability = ( serviceId,
                                  queryParams = {} ) => {

  const config = {}
                 
  const queryString = querystring.stringify( queryParams )
  
  const serviceAvailRoute = `/api/onsched/setup/services/${serviceId}/availability` +
                                                                `?${queryString}`

  return {
    type:    LOAD_SERVICE_AVAILABILITY,
    promise: axios.get( serviceAvailRoute, config )
  }

}

export const DELETE_SERVICE = 'DELETE_SERVICE'

export const deleteService = ( serviceId,
                                  queryParams = {} ) => {

  const config = {}
                 
  const queryString = querystring.stringify( queryParams )
  
  const deleteServicesRoute = `/api/onsched/setup/services/${serviceId}` +
                                          `?${queryString}`

  return {
    type:    DELETE_SERVICE,
    promise: axios.delete( deleteServicesRoute, config )
  }

}

export const CREATE_SERVICE = 'CREATE_SERVICE'

export const createService = ( serviceData = {} ) => {

  const config = {}
  
  const createServicesRoute = `/api/onsched/setup/services`

  return {
    type:    CREATE_SERVICE,
    promise: axios.post( createServicesRoute, serviceData, config )
  }

}

export const UPDATE_SERVICE = 'UPDATE_SERVICE'

export const updateService = ( id, service, params = {} ) => {

  const config = {}

  const queryString = querystring.stringify( params )

  const updateServiceRoute = `/api/onsched/setup/services/${id}` +
                                              `?${queryString}`

  return {
    type:    UPDATE_SERVICE,
    promise: axios.put( updateServiceRoute, service, config )
  }

}

export const UPDATE_SERVICE_AVAILABILITY = 'UPDATE_SERVICE_AVAILABILITY'

export const updateServiceAvailability = ( id, availability ) => {

  const config = {}
  
  const updateServiceAvailRoute = `/api/onsched/setup/services/${id}/availability`

  return {
    type:    UPDATE_SERVICE_AVAILABILITY,
    promise: axios.put( updateServiceAvailRoute, availability, config )
  }

}

export const LOAD_SERVICE_GROUPS = 'LOAD_SERVICE_GROUPS'

export const loadServiceGroups = ( params = {} ) => {

  const config = {}

  const queryString = querystring.stringify( params )
  
  const loadServiceGroupsRoute = `/api/onsched/consumer/serviceGroups` +
                                                  `?${queryString}`

  return {
    type:    LOAD_SERVICE_GROUPS,
    promise: axios.get( loadServiceGroupsRoute, config )
  }

}

export const LOAD_SERVICE_RESOURCES = 'LOAD_SERVICE_RESOURCES'

export const loadServiceResources = ( serviceId,
                                  queryParams = {} ) => {

  const config = {}
                 
  const queryString = querystring.stringify( queryParams )
  
  const servicesRoute = `/api/onsched/setup/services/${serviceId}/resources` +
                                          `?${queryString}`

  return {
    type:    LOAD_SERVICE_RESOURCES,
    promise: axios.get( servicesRoute, config )
  }

}

export const LOAD_LINKED_SERVICE = 'LOAD_LINKED_SERVICE'

export const loadLinkedService = ( id,
                                  queryParams = {} ) => {

  const config = {}
                 
  const queryString = querystring.stringify( queryParams )
  
  const loadLinkedRoute = `/api/onsched/setup/services/${id}/calendar` +
                                          `?${queryString}`

  return {
    type:    LOAD_LINKED_SERVICE,
    promise: axios.get( loadLinkedRoute, config )
  }

}

export const LINK_SERVICE = 'LINK_SERVICE'

export const linkService = ( calData,
                                  queryParams = {} ) => {

  const config = {}
                 
  const queryString = querystring.stringify( queryParams )
  
  const servicesRoute = `/api/onsched/setup/services/calendar` +
                                          `?${queryString}`

  return {
    type:    LINK_SERVICE,
    promise: axios.post( servicesRoute, calData, config )
  }

}

export const UNLINK_SERVICE = 'UNLINK_SERVICE'

export const unlinkService = ( id,
                                  queryParams = {} ) => {

  const config = {}
                 
  const queryString = querystring.stringify( queryParams )
  
  const servicesRoute = `/api/onsched/setup/services/calendar/${id}` +
                                          `?${queryString}`

  return {
    type:    UNLINK_SERVICE,
    promise: axios.delete( servicesRoute, config )
  }

}

export const ADD_SERVICE_ALLOCATIONS = 'ADD_SERVICE_ALLOCATIONS'

export const addServiceAllocations = ( id, allocations ) => {

  const config = {}
  
  const allocationsRoute = `/api/onsched/setup/services/${id}/allocations` 

  return {
    type:    ADD_SERVICE_ALLOCATIONS,
    promise: axios.post( allocationsRoute, allocations, config )
  }
}

export const DELETE_ALLOCATION = 'DELETE_ALLOCATION'

export const deleteAllocation = ( id,
                                  queryParams = {} ) => {

  const config = {}
                 
  const queryString = querystring.stringify( queryParams )
  
  const allocationRoute = `/api/onsched/setup/services/allocations/${id}`

  return {
    type:    DELETE_ALLOCATION,
    promise: axios.delete( allocationRoute, config )
  }

}

export const LOAD_ALLOCATION = 'LOAD_ALLOCATION'

export const loadAllocation = ( id,
                                  queryParams = {} ) => {

  const config = {}
                 
  const queryString = querystring.stringify( queryParams )
  
  const allocationRoute = `/api/onsched/setup/services/allocations/${id}` + 
                                                        `?${queryString}`

  return {
    type:    LOAD_ALLOCATION,
    promise: axios.get( allocationRoute, config )
  }

}

export const UPDATE_ALLOCATION = 'UPDATE_ALLOCATION'

export const updateAllocation = ( id, allocation,
                                  queryParams = {} ) => {

  const config = {}
                 
  const queryString = querystring.stringify( queryParams )
  
  const allocationRoute = `/api/onsched/setup/services/allocations/${id}` + 
                                                        `?${queryString}`

  return {
    type:    UPDATE_ALLOCATION,
    promise: axios.put( allocationRoute, allocation, config )
  }

}

export const LOAD_ALLOCATIONS = 'LOAD_ALLOCATIONS'

export const loadAllocations = ( id,
                                  queryParams = {} ) => {

  const config = {}
                 
  const queryString = querystring.stringify( queryParams )
  
  const allocationsRoute = `/api/onsched/setup/services/${id}` +
                                          `/allocations?${queryString}`

  return {
    type:    LOAD_ALLOCATIONS,
    promise: axios.get( allocationsRoute, config )
  }

}

export const UPLOAD_SERVICE_IMAGE = 'UPLOAD_SERVICE_IMAGE'

export const uploadServiceImage = ( serviceId, imgData ) => {

  const config = {}
  
  const serviceImgRoute = `/api/onsched/setup/services/${serviceId}/uploadimage`

  return {
    type:    UPLOAD_SERVICE_IMAGE,
    promise: axios.post( serviceImgRoute, imgData, config )
  }

}

export const DELETE_SERVICE_IMAGE = 'DELETE_SERVICE_IMAGE'

export const deleteServiceImage = ( serviceId ) => {

  const config = {}
  
  const serviceImgRoute = `/api/onsched/setup/services/${serviceId}/deleteimage`

  return {
    type:    DELETE_SERVICE_IMAGE,
    promise: axios.delete( serviceImgRoute, config )
  }

}

export const LOAD_SERVICE_BLOCKS = 'LOAD_SERVICE_BLOCKS'

export const loadServiceBlocks = ( id ) => {
  const config = {}
  
  const serviceBlockRoute = `/api/onsched/setup/services/${id}/blocks`

  return {
    type:    LOAD_SERVICE_BLOCKS,
    promise: axios.get( serviceBlockRoute, config )
  }
}

export const DELETE_SERVICE_BLOCKS = 'DELETE_SERVICE_BLOCKS'

export const deleteServiceBlocks = ( id ) => {
  const config = {}
  
  const serviceBlockRoute = `/api/onsched/setup/services/block/${id}/`

  return {
    type:    DELETE_SERVICE_BLOCKS,
    promise: axios.delete( serviceBlockRoute, config )
  }
}


export const ADD_SERVICE_BLOCK = 'ADD_SERVICE_BLOCK'

export const addServiceBlock = ( id, timeBlock = {} ) => {
  const config = {}
  
  const serviceBlockRoute = `/api/onsched/setup/services/${id}/block`

  return {
    type:    ADD_SERVICE_BLOCK,
    promise: axios.post( serviceBlockRoute, timeBlock, config )
  }
}

export const LOAD_SERVICE_BLOCK = 'LOAD_SERVICE_BLOCK'

export const loadServiceBlock = ( id ) => {
  const config = {}
  
  const serviceBlockRoute = `/api/onsched/setup/services/blocks/${id}`

  return {
    type:    LOAD_SERVICE_BLOCK,
    promise: axios.get( serviceBlockRoute, config )
  }
}

export const UPDATE_SERVICE_BLOCK = 'UPDATE_SERVICE_BLOCK'

export const updateServiceBlock = ( id, data ) => {
  const config = {}
  
  const serviceBlockRoute = `/api/onsched/setup/services/block/${id}`

  return {
    type:    UPDATE_SERVICE_BLOCK,
    promise: axios.put( serviceBlockRoute, data, config )
  }
}

//-------------------------------------------------------------------//
//                         ASYNC THUNKS
//-------------------------------------------------------------------//

export const modifyAndLoadService = (id, service) => async (dispatch, getState) => {
  try {
    const { payload } = await dispatch(updateService(id, service))
    if (payload.status === 200) {
      dispatch(toggleToast(toastMessages.update_service))
      await dispatch(loadServiceById(id))
    }
    else {
      dispatch(toggleError(payload))
    }
  }
  catch (error) {
    console.error({ error })
  }
}

export const modifyAndLoadServiceAvail = (id, availability) => async (dispatch, getState) => {
  try {
    const { payload } = await dispatch(updateServiceAvailability(id, availability))
    if (payload.status === 200) {
      dispatch(toggleToast(toastMessages.update_service))
      await dispatch(loadServiceById(id))
    }
    else {
      dispatch(toggleError(payload))
    }
  }
  catch (error) {
    console.error({ error })
  }
}
