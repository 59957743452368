import { TOGGLE_BOOK_MODAL } from '../actions/modalActions'

const INITIAL_STATE = {
  modalData: {},
  showModal: false
}

const bookingModalReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TOGGLE_BOOK_MODAL:
      return {
          id: action.id,
          modalData: action.modalData,
          showModal: action.showModal
        }
    default:
      return state
  }
}

export default bookingModalReducer