export const viewTypes = {
  get_started      : 'GetStarted',
  activate_account : 'ActivateAccount',
  locations        : 'Locations',
  resources        : 'Resources',
  services         : 'Services',
  calendars        : 'Calendars',
  notifications    : 'Notifications',
  request_log      : 'RequestLog',
  api_settings     : 'APISettings',
  preferences      : 'Preferences',
  company_settings : 'Company',
  logs             : 'DataLogs',
  business_users   : 'BusinessUsers',
  custom_fields    : 'CustomFields',
}

export const SWITCH_VIEW = 'SWITCH_VIEW'

export const switchView = (view, action, edit) => {
  let id = 0

  return {
    type: SWITCH_VIEW,
    id: id++,
    view,
    sideNav: action,
    edit: edit
  }
}

export const modTypes = {
  none:   null,
  edit:   'edit',
  create: 'create',
}

export const MOD_TYPE = 'MOD_TYPE'

export const updateModification = (modType) => ({
  type: MOD_TYPE,
  modType
})
