import { handle } from 'redux-pack'

import { LOAD_CUSTOMERS,
        LOAD_CUSTOMER_BY_ID } from '../actions/customerActions'


const INITIAL_STATE = { success: false }


export const loadCustomersReducer = ( state = INITIAL_STATE, action ) => {
  const { type, payload } = action
  switch ( type ) {
    
    case LOAD_CUSTOMERS:
      return handle( state, action, {
        start:   previousState => ( { ...previousState, isLoading: true, customersError: null, customers: null } ),
        finish:  previousState => ( { ...previousState, isLoading: false } ),
        failure: previousState => ( { ...previousState, customersError: payload } ),
        success: previousState => ( { ...previousState, success: true, customers: payload.data } )
      })

    default:
      return state

  }

}

export const loadCustomerByIdReducer = ( state = INITIAL_STATE, action ) => {
  const { type, payload } = action

  switch ( type ) {
    
  case LOAD_CUSTOMER_BY_ID:
    return handle( state, action, {
      start:   previousState => ( { ...previousState, isLoading: true, customerError: null, success: false } ),
      finish:  previousState => ( { ...previousState, isLoading: false } ),
      failure: previousState => ( { ...previousState, customerError: payload } ),
      success: previousState => ( { ...previousState, success: true, customer: payload.data } )
    })

  default:
    return state

  }

}
