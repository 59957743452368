import axios       from 'axios'
import querystring from 'querystring'
import { toggleError } from './toastActions'


export const LOAD_CALENDARS = 'LOAD_CALENDARS'

export const loadCalendars = ( params = {} ) => {

  const config = {}
                 
  const queryString = querystring.stringify( params )
  
  const calendarsRoute = `/api/onsched/setup/calendars` +
                                    `?${queryString}`

  return {
    type:    LOAD_CALENDARS,
    promise: axios.get( calendarsRoute, config )
  }

}

export const LOAD_CALENDAR_BY_ID = 'LOAD_CALENDAR_BY_ID'

export const loadCalendarById = ( id, params = {} ) => {

  const config = {}
                 
  const queryString = querystring.stringify( params )
  
  const calendarsRoute = `/api/onsched/setup/calendars/${id}` +
                                    `?${queryString}`

  return {
    type:    LOAD_CALENDAR_BY_ID,
    promise: axios.get( calendarsRoute, config )
  }

}

export const LOAD_CALENDAR_SERVICES = 'LOAD_CALENDAR_SERVICES'

export const loadCalendarServices = ( id, params = {} ) => {

  const config = {}
                 
  const queryString = querystring.stringify( params )
  
  const calServicesRoute = `/api/onsched/setup/calendars/${id}/services` +
                                                        `?${queryString}`

  return {
    type:    LOAD_CALENDAR_SERVICES,
    promise: axios.get( calServicesRoute, config )
  }

}

export const ADD_CALENDAR_BLOCK = 'ADD_CALENDAR_BLOCK'

export const addCalendarBlock = ( id, calendarBlock = {} ) => {
  const config = {}
  
  const calendarBlockRoute = `/api/onsched/setup/calendars/${id}/block`

  return {
    type:    ADD_CALENDAR_BLOCK,
    promise: axios.post( calendarBlockRoute, calendarBlock, config )
  }

}

export const LOAD_CALENDAR_BLOCKS = 'LOAD_CALENDAR_BLOCKS'

export const loadCalendarBlocks = ( id ) => {
  const config = {}
  
  const calendarBlockRoute = `/api/onsched/setup/calendars/${id}/blocks`

  return {
    type:    LOAD_CALENDAR_BLOCKS,
    promise: axios.get( calendarBlockRoute, config )
  }

}

export const DELETE_CALENDAR_BLOCK = 'DELETE_CALENDAR_BLOCK'

export const deleteCalendarBlock = ( id ) => {
  const config = {}
  
  const calendarBlockRoute = `/api/onsched/setup/calendars/block/${id}`

  return {
    type:    DELETE_CALENDAR_BLOCK,
    promise: axios.delete( calendarBlockRoute, config )
  }

}

export const LOAD_CALENDAR_BLOCK = 'LOAD_CALENDAR_BLOCK'

export const loadCalendarBlock = ( id ) => {
  const config = {}
  
  const calendarBlockRoute = `/api/onsched/setup/calendars/blocks/${id}`

  return {
    type:    LOAD_CALENDAR_BLOCK,
    promise: axios.get( calendarBlockRoute, config )
  }
}

export const UPDATE_CALENDAR_BLOCK = 'UPDATE_CALENDAR_BLOCK'

export const updateCalendarBlock = ( id, data ) => {
  const config = {}
  
  const calendarBlockRoute = `/api/onsched/setup/calendars/block/${id}`

  return {
    type:    UPDATE_CALENDAR_BLOCK,
    promise: axios.put( calendarBlockRoute, data, config )
  }
}

export const UPDATE_CALENDAR = 'UPDATE_CALENDAR'

export const updateCalendar = ( id, data ) => {
  const config = {}
  
  const calendarRoute = `/api/onsched/setup/calendars/${id}`

  return {
    type:    UPDATE_CALENDAR,
    promise: axios.put( calendarRoute, data, config )
  }
}

//-------------------------------------------------------------------//
//                         ASYNC THUNKS
//-------------------------------------------------------------------//

export const modifyAndLoadCalendar = (id, { bookingsPerSlot }) => async (dispatch) => {
  try {
    const { payload: calendarPayload } = 
        await dispatch(updateCalendar(id, { bookingsPerSlot }))
        
    if (calendarPayload.status === 200) {
      await dispatch(loadCalendarById(id))
    }
    else {
      dispatch(toggleError(calendarPayload))
    }
  }
  catch (error) {
    console.error({ error })
  }
}
