import React from 'react'

import { Col, ListGroup, Row } from 'react-bootstrap'

import './ListGroupDetails.css'

const ListGroupDetails = ({ data, variant }) => {
  
  return (
    <ListGroup variant={variant}>
      { 
        data && 
        Object.keys(data).map((k, index) => {
          return  (
            <ListGroup.Item key={index} >
              <Row>
                <Col>
                  <p className='label'>{ k }</p>
                </Col>
                <Col>
                    { typeof data[k] !== 'boolean' ? (
                      <p>{ data[k] }</p>
                    ) : (
                      data[k] ? <i class="fad fa-check green"></i> : <i class="fad fa-times red"></i>
                    )}
                    
                </Col>
              </Row>
            </ListGroup.Item>
          )
        })
      }
    </ListGroup>
  )
}

export default ListGroupDetails
