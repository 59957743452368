import React from 'react'

import HelpTip from '../components/HelpTip/HelpTip'

export const renderDropdownField = props => {
  const { input, 
          placeholder, 
          customClass, 
          children, 
          customOnChange, 
          label, 
          name, 
          disabled,
          tip,
          meta : { touched, error } 
        } = props

  let tooltip = null
  if (tip) tooltip = <HelpTip tip={tip} />

  let errorClass = ''
  if ( touched && error ) errorClass = 'error'

  return (
    <div className={`renderField renderDropdownField ${customClass} ${errorClass}`}>
      <label htmlFor={name}>{label}</label> {tooltip}
      { error && touched && <HelpTip tip={error} error /> }
      <select disabled={disabled} onChange={e => customOnChange(e)} {...input}>
        <option disabled>{placeholder}</option>
        {children}
      </select>
    </div>
  )
}
