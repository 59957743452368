import React           from 'react'
import { Col, Row }    from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { formValueSelector, 
         reduxForm }   from 'redux-form'

import useCountryChange from '../../../../hooks/useCountryChange'
import useInitialize    from '../../../../hooks/useInitialize'
import { useUnsaved }   from '../../../../hooks/useUnsaved'
import useValidForm     from '../../../../hooks/useValidForm'

import { editLocationForm } from '../../../../utils/initializeForm'
import { locationForm }     from '../../../../utils/formFields'
import { validateLocation } from '../../../../utils/validate'

import BottomOverlay from '../../../BottomOverlay/BottomOverlay'
import FormFields    from '../../../FormFields/FormFields'
import Loading       from '../../../Loading/Loading'
import UploadImage   from '../../../UploadImage/UploadImage'

import submit from './submit'

const LocationProfile = ({ handleSubmit, form, initialize, pristine, change, valid }) => {
  const { selectedLocation, isLoading } = useSelector(state => state.selectedLocation)
  const { unsaved } = useSelector(state => state.unsaved)

  const selector = formValueSelector(form)
  const country = useSelector(state => selector(state, 'address.country'))

  useUnsaved(pristine)
  useCountryChange(country, change)
  useInitialize(selectedLocation, editLocationForm, initialize)
  useValidForm(valid)

  return (
    isLoading ? <Loading /> : 
    <form onSubmit={handleSubmit}>
      <Row>
        <Col md={7}>
          <FormFields form={form} fields={locationForm} />
        </Col>
        <Col md={5}>
          <UploadImage form={form} img={selectedLocation.imageUrl} />
        </Col>
      </Row>
      <BottomOverlay active={unsaved}/>
    </form>   
  )
}

export default reduxForm({
  form: 'location-profile', 
  validate: validateLocation, 
  onSubmit: submit 
})(LocationProfile)