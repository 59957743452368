import React                 from 'react'
import { connect }           from 'react-redux'
import { reduxForm,
         formValueSelector } from 'redux-form'

import { renderField, renderCheckbox } from '../../../utils/renderField'
import FormFields                      from '../../FormFields/FormFields'

import './SearchOptions.css'


const SearchOptions = props => {
  const { filtered, filters, change, object, placeholder, icon } = props
  const fields = [
    {
      name:        'searchText',
      component:   renderField,
      customClass: 'fullWidth appointmentSearch',
      placeholder
    },
  ]

  if (filtered) {
    fields.unshift({
      name:        'filters',
      component:   renderCheckbox,
      type:        'checkbox',
      label:       'Filters',
    })
  }

  return (
    <div className="SearchOptions">
      <FormFields form='onsched-search' fields={fields} />
      <button className="search" type='submit'><i className={`fad fa-${icon}`} /></button>
  </div>
  )
}

const mapStateToProps = state => ({
  filters: formValueSelector('onsched-search')(state, 'filters'),
})

const connectedSearchOptions = connect(mapStateToProps, {})(SearchOptions)

export default reduxForm({form: 'onsched-search'})(connectedSearchOptions)
