import React, { Component } from 'react'
import { connect }          from 'react-redux'
import { reduxForm, Field } from 'redux-form'
import { CopyToClipboard }  from 'react-copy-to-clipboard'

import { Button, Row, Col }        from 'react-bootstrap'
import { checkErrors }             from '../../../utils/checkErrors'
import { renderField }             from '../../../utils/renderField'
import { revokeAPIKeys }           from '../../../actions/credentialsActions'
import { toggleConfirmationModal } from '../../../actions/modalActions'
import { clientCredentials }       from '../../../actions/userActions'
import renderClientSecretField     from '../../../utils/renderClientSecretField'

import './SettingsFields.css'


class SettingsFields extends Component {
  constructor(props) {
    super(props)
    this.state = {
      copied: false,
      field: null
    }
  }

  componentDidUpdate(prevProps){
    const { revokeAPIKeys, 
            clientCredentials,
            confirmed,
            modalData, 
            toggleConfirmationModal 
          } = this.props

    if (prevProps.confirmed !== confirmed) {
      if (confirmed) {
        revokeAPIKeys(modalData.id)
          .then((res) => {
            checkErrors(res, clientCredentials)
            toggleConfirmationModal(false)
          })
      }
    }
  }

  copy = field => {
    this.setState({ field, copied: true })
    
    if (!this.state.copied) {
      setTimeout(() => {
        this.setState({ field, copied: false })
      }, 3000);
    }
  }

  handleClick = (event) => {
    const { toggleConfirmationModal } = this.props

    const explanation = ' This will delete the selected client secret and return a new client secret'
    const name        = event.target.name
    toggleConfirmationModal(true, { explanation, id: event.target.id, name, action: 'Revoke' })
  }

  render() {
    const { client } = this.props
    const { copied, field } = this.state

    return (
      <div className="SettingsFields">
        <h4>API Credentials</h4>
        <Field 
          type='text'
          name="clientId"
          label="ClientID"
          customClass="fullWidth"
          component={renderField}
          placeholder="ClientID"
          fieldValue={client.clientId}
          disabled={true}
        />
        <CopyToClipboard 
          onCopy={(e) => this.copy(e)}
          text={client.clientId}
        >
          {
            copied && field === client.clientId
              ?
            <i className="far fa-check" />
              :
            <i className="fad fa-copy" />
          }
        </CopyToClipboard>
        
        <Row>
          <Col md={9} className='pr-md-0 ' >
            <Field 
            type='text'
            name="clientSecret"
            label="Client Secret 1"
            customClass="ClientSecret fullWidth"
            component={renderClientSecretField}
            placeholder="ClientSecret"
            fieldValue={client.clientSecret1.secret}
            disabled={true}
          />
          <CopyToClipboard 
            onCopy={(e) => this.copy(e)}
            text={client.clientSecret1.secret}
          >
            {
              copied && field === client.clientSecret1.secret
                ?
              <i className="far fa-check" />
                :
              <i className="fad fa-copy" />
            }
          </CopyToClipboard>
          </Col>
          <Col md={3} className='pl-md-0'>
            <Button 
              onClick={this.handleClick}
              className='btn btn-danger reset-button'
              id={client.clientSecret1.id}
              name='Client Secret 1'
            >
              Revoke
            </Button>
          </Col>
        </Row>
        
        <Row>
          <Col md={9} className='pr-md-0'>
            <Field 
              type='text'
              name="clientSecret2"
              label="Client Secret 2"
              customClass="ClientSecret fullWidth"
              component={renderClientSecretField}
              placeholder="ClientSecret 2"
              fieldValue={client.clientSecret2.secret}
              disabled={true}
            />
            <CopyToClipboard 
              onCopy={(e) => this.copy(e)}
              text={client.clientSecret2.secret}
            >
              {
                copied && field === client.clientSecret2.secret
                  ?
                <i className="far fa-check" />
                  :
                <i className="fad fa-copy" />
              }
            </CopyToClipboard>
          </Col>
          <Col md={3} className='pl-md-0'>
            <Button 
              onClick={this.handleClick}
              className='btn btn-danger reset-button'
              id={client.clientSecret2.id}
              name='Client Secret 2'
            > 
              Revoke
            </Button>
          </Col>
        </Row>   
      </div>
    )
  }
}

const mapStateToProps = state => ({
  client:    state.clientCredentials.clientCredentials,
  confirmed: state.confirmModalReducer.confirmed,
  modalData: state.confirmModalReducer.modalData
})

export default reduxForm({ form: 'APISettings' })
(connect(mapStateToProps, { revokeAPIKeys, clientCredentials, toggleConfirmationModal })(SettingsFields))