import { TOGGLE_ERROR } from '../actions/toastActions'

const toasts = (state = [], action) => {
  switch (action.type) {
    case TOGGLE_ERROR:
      return action

    default:
      return state
  }
}

export default toasts