import { handle } from 'redux-pack'

import { DELETE_RESOURCE_BLOCK } from '../actions/resourceActions'


const INITIAL_STATE = { success: false }


export default function( state = INITIAL_STATE, action ) {
  const { type, payload } = action
  switch ( type ) {
    
    case DELETE_RESOURCE_BLOCK:
      return handle( state, action, {
        start:   previousState => ( { ...previousState, isLoading: true, resourceBlockDeletedError: null, resourceBlockDeleted: null } ),
        finish:  previousState => ( { ...previousState, isLoading: false } ),
        failure: previousState => ( { ...previousState, resourceBlockDeletedError: payload } ),
        success: previousState => ( { ...previousState, success: true, resourceBlockDeleted: payload.data } )
      })

    default:
      return state

  }

}