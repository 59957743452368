import { UPDATE_SEARCH_TEXT } from '../actions/searchActions'

const INITIAL_STATE = { searchText : "" }

const updateSearchTextReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_SEARCH_TEXT:
      return {
          searchText: action.searchText
        }
    default:
      return state
  }
}

export default updateSearchTextReducer