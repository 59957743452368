import React          from 'react'

import DataListHeader from './DataListHeader/DataListHeader'

import './DataList.css'

const DataList = ({ itemTitles, list }) => {
  let header =  null
  if (list.length > 0) header = <DataListHeader itemTitles={itemTitles} />

  return (
    <table className="DataList">
      <tbody>
        { header }

        { list }
      </tbody>
    </table>
  )
}

export default DataList