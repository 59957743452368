import React, 
       { useEffect, 
         useReducer } from 'react'
import { useDispatch, 
         useSelector } from 'react-redux'
import { useHistory }  from 'react-router'
import { Row, Col }    from 'react-bootstrap'

import Pages                from '../../Pages/Pages'
import Loading              from '../../Loading/Loading'
import BookableServiceItem  from './BookableServiceItem/BookableServiceItem'
import BookableServicesList from './BookableServicesList/BookableServicesList'
import BottomOverlay        from '../../BottomOverlay/BottomOverlay'

import { loadServices }         from '../../../actions/serviceActions'
import { updateResource,
         loadResourceById, 
         enableLinkedServices } from '../../../actions/resourceActions'
import { toastMessages, 
         toggleError,
         toggleToast }          from '../../../actions/toastActions'
import { en }                   from '../../../utils/languages'
import { EmptyList }            from '../../../utils/emptyList'
import { resetExitIntent, 
        toggleUnsaved }         from '../../../actions/exitIntentActions'


import './BookableServices.css'

const initialState = []

const resServicesReducer = (state, action) => {
  switch (action.type) {
    case 'add':
      return [ ...state, action.serviceId ]
    case 'remove':
      return state.filter( id => id !== action.serviceId)
    case 'set':
      return action.servicesIds
    default:
      return state
  }
}

const BookableServices = () => {
  
  const { page } = useSelector(state => state.page)
  const services = useSelector(state => state.services)
  const { isLoading: loadingService } = services

  const { selectedResource: resource, 
          isLoading: loadingResource } = useSelector(state => state.selectedResource)
  const { selectedLocation, 
          isLoading: loadingLocation } = useSelector(state => state.selectedLocation)
  const { callback, destination }      = useSelector(state => state.exit)
  const { unsaved }                    = useSelector(state => state.unsaved)

  const [ resServices, dispatchReducer ] = useReducer(resServicesReducer, initialState)

  const dispatch =  useDispatch()
  const history = useHistory()
  
  // Load linked Services in reducer
  useEffect(() => {
    if (resource) {
      dispatchReducer({type: 'set', servicesIds: resource.services.map(service => service.serviceId.toString())})
    }
  }, [resource])

  // Load services when selected location changes
  useEffect(() => {
    if (selectedLocation) {
      dispatch(loadServices({ limit: 25, locationId: selectedLocation.id }))
    }
  }, [ selectedLocation, loadServices ])

  const linkedListAction = offset => {
    if (selectedLocation) {
      dispatch(loadServices({ locationId: selectedLocation.id, offset, limit: 25 }))
    }
  }

  const handleLinked = service => {
    // Remove linked service
    if (resServices.some(linked => linked === service.id)) {
      dispatchReducer({type: 'remove', serviceId: service.id})
    }
    // Add linked service
    else {
      dispatchReducer({type: 'add', serviceId: service.id})
    }
    dispatch(toggleUnsaved(true))
  }

  // Action to be run after modal selection is save and continue
  useEffect(() => {
    if (callback === true) {
      submitEvent()
      history.push(destination)
      dispatch(resetExitIntent())
    }
  }, [callback, destination, history])

  const handleEnableServices = () => {
    dispatch(enableLinkedServices(resource?.id))
  }

  const submitEvent = async () => {
    const { payload } = await dispatch(updateResource(resource.id, { serviceIds: resServices }))

    if (payload.status !== 200) {
      dispatch(toggleError(payload))
    }
    else {
      dispatch(toggleUnsaved(false))
      dispatch(loadResourceById(resource.id))
      dispatch(toggleToast(toastMessages.update_res_services, payload))
    }
  }

  let servicesTotal = 0
  if (services.services) {    
    servicesTotal = services.services.total
  }
  
  let servicesListItems = null

  if (services && services.services) {
    servicesListItems = services.services.data.map((service, i) => {
      let selectedBool = false
      
      if (resServices && resServices.includes(service.id)){
        selectedBool = true
      }

      return (
        <BookableServiceItem linked={selectedBool} handleLinked={handleLinked} key={i} service={service} selectedID={selectedLocation?.id} />
      )
    })
  }

  let plural = 'services are bookable'
  let linkedTotal = 'all'

  if (resServices) {
    linkedTotal = resServices.length
    
    if (resServices.length === 0) {
      linkedTotal = 'all'
    }
    else if (resServices.length === 1) {
      plural = 'service is bookable'
    }
  }

  const loading = loadingLocation || loadingResource || loadingService

  return (
    <Row className="BookableServices">
      <BottomOverlay action={submitEvent} active={unsaved}/>
      <Col lg={4}>
        <h5>Services</h5> 
        <p>
          Resources must be linked to at least one service. By default all services are assigned to the resource.
        </p>
        <br></br>
        <p>
          Linking a service here will limit and allow only those linked services to be booked for this resource.
        </p>
        <br></br>
        <p>
          Currently 
          <span className="total">{linkedTotal}</span> 
          {plural}.
        </p>
        {
          resource?.services?.length > 0 &&
          <button className='btn btn-flat my-3' onClick={handleEnableServices}>
            Enable all services
          </button> 
        }

      </Col>

      <Col lg={8}>
        {loading ? <Loading /> : (
          <>
            {
              page === 1 && !servicesTotal ? 
              <EmptyList type={en.services.services} /> : 
              <BookableServicesList items={servicesListItems} />
            }
            
            <Pages 
              total={servicesTotal}
              listAction={linkedListAction}
              divisible={25}
            />
          </>
        )}
      </Col>
    </Row>
  )
}

export default BookableServices
