import { handle } from 'redux-pack'

import { LOAD_CALENDAR_BLOCKS } from '../actions/calendarActions'


const INITIAL_STATE = { success: false }


export default function( state = INITIAL_STATE, action ) {
  const { type, payload } = action
  switch ( type ) {
    
    case LOAD_CALENDAR_BLOCKS:
      return handle( state, action, {
        start:   previousState => ( { ...previousState, isLoading: true, calendarBlocksError: null, calendarBlocks: null } ),
        finish:  previousState => ( { ...previousState, isLoading: false } ),
        failure: previousState => ( { ...previousState, calendarBlocksError: payload } ),
        success: previousState => ( { ...previousState, success: true, calendarBlocks: payload.data } )
      })

    default:
      return state

  }

}