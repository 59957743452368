import React, { useEffect } from 'react'
import { connect, 
         useDispatch, 
         useSelector }      from 'react-redux'

import HelpTip                        from '../../HelpTip/HelpTip'
import LocationCard                   from './LocationCard/LocationCard'
import { en }                         from '../../../utils/languages'
import { deleteLocation,
         loadLocations }              from '../../../actions/locationActions'

import { RESET_CONFIRMATION_MODAL }   from '../../../actions/modalActions'
import { toggleToast, toastMessages } from '../../../actions/toastActions'

import './LocationList.css'


const LocationList = ({ locations, history, primaryId }) => {

  const { confirmed, modalData } = useSelector(state => state.confirmModalReducer)
  const { page }                 = useSelector(state => state.page)

  const dispatch = useDispatch()

  const limit = 10
  const offset = page === 1 ? 0 : (page - 1) * 10

  useEffect(() => {
   
    if( confirmed && modalData.id !== primaryId ) {
      dispatch(deleteLocation(modalData.id))

          .then(locationDeleted => {  
            dispatch(loadLocations({ offset, limit }))

            if ( locationDeleted.payload.status === 200 ) {
              // Success
              dispatch(toggleToast(toastMessages.delete_location, locationDeleted.payload))
            }
            // Failure
            else dispatch(toggleToast(toastMessages.error, locationDeleted.payload))
          })

        dispatch({type: RESET_CONFIRMATION_MODAL})
    }

  }, [confirmed, dispatch, modalData.id, offset, limit])

  return (
    <table className="LocationList">
      <tbody>
        <tr>
          <th>ID</th>
          <th colSpan="2">{en.contact.name}</th>
          <th className="cells">
            {en.locations.primary}
            <HelpTip tip={en.locations.primaryHelpTip} />
          </th>
        </tr>

        { locations.data.map(location => (
          <LocationCard
            key={location.id}
            history={history}
            location={location}
          />
        )) }

      </tbody>
    </table>
  )
}

function mapStateToProps(state) {
  return {
    locations:        state.locations.locations,
    locationsLoading: state.locations.isLoading,
    locationsSuccess: state.locations.success,
    primaryId:        state.primary.primary,
  }
}

export default connect(mapStateToProps, {})(LocationList)
