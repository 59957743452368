import React                from 'react'
import { connect }          from 'react-redux'

import DataListItem                from '../../DataList/DataListItem/DataListItem'
import { customErrors }            from '../../../utils/customErrors'
import { en }                      from '../../../utils/languages'
import { localSetCompany }         from '../../../utils/localStorage'
import { loadUsers }               from '../../../actions/userActions'
import { setActiveCompany }        from '../../../actions/companyActions'
import { toggleError }             from '../../../actions/toastActions'
import { redirectToDocumentation } from '../../../utils/redirect'

import './CompanySelectItem.css'


const CompanySelectItem = props => {
  const { companies, users : { errors } } = en
  const { user, 
          index, 
          company, 
          loadUsers,
          toggleError, 
          setActiveCompany, 
        } = props
    
  let data = {
    "img"   : null,
    "id"    : company.id,
    "name"  : company.name,
    "cells" : []
  }

  var email
  if (user)
    email = user.email
  else
    email = localStorage.userEmail

  const err = msg => ({
    status: 400,
    response: {
      data: { errors: [{ detail: msg }] }
    }
  })

  const selectCompany = () => {
    setActiveCompany(company.id, company.name)
    .then(c => {
      if (c.payload.status === 200) {
        // Check if redirecting to documentation
        redirectToDocumentation(c.payload.data.readme)
        
        localSetCompany(company)
        // Check if user is onsched support email
        if (email.split('@')[1] !== 'onsched.com') {
          loadUsers({ email })
          .then(businessUser => {
            if (businessUser.payload.status === 200) {
                // Find user role
                if (businessUser.payload.data.data.length) {
                  const { role } = businessUser.payload.data.data[0]
                  if (role === 'bizowner' ||
                      role === 'bizadmin'
                    ) {
                      props.history.push('/')
                    }
                  else
                    toggleError(err(errors.notPermitted))
                }
                else
                  toggleError(err(errors.noUser))
              }
            }) 
          }
          else {
            // Skip user check if onsched support
            props.history.push('/')
          }
        }
      else {
        let error = customErrors.companySelectError
        toggleError(error)
      }
    })
  }
  
  return <DataListItem 
    key={index} 
    data={data} 
    noDelete
    company={company} 
    dataItemSelect={selectCompany} 
  />
}

const mapStateToProps = state => ({
  user: state.user.user
})

export default connect(
  mapStateToProps, 
  { toggleError, 
    setActiveCompany, 
    loadUsers
  }
)(CompanySelectItem)
