import { modifyAndLoadService, uploadServiceImage } from "../../../../actions/serviceActions"

const submit = async (values, dispatch) => {
  //---------------------------
  // Options 
  //---------------------------
  values.options = {
    defaultService: values.defaultService,
    durationSelect: values.durationSelect,
    durationInterval: values.durationInterval,
    durationMin: values.durationMin,
    durationMax: values.durationMax,
    padding: values.padding
  }
  //---------------------------
  // Others
  //---------------------------
  var description
  if (values.description?.length > 2) {
    description = values.description
  }
  else {
    description = values.name
  }

  values.description = description

  try {
    await dispatch(modifyAndLoadService(values.id, values))
    //Update service image
    if (values.base64Image) {
      await uploadServiceImage(values.id, {
        imageFileName: values.name + new Date().toISOString().slice(-4) + ' image',
        imageFileData: values.base64Image
      })
    }
  }
  catch (error) {
    console.error({ error })
  }

}

export default submit