import React             from 'react'
import { connect }       from 'react-redux'
import { Link }          from 'react-router-dom'
import { OverlayTrigger, 
               Tooltip } from 'react-bootstrap'

import InitialsIcon      from '../../../InitialsIcon/InitialsIcon'

import './LocationIcons.css'


const LocationIcons = props => {
  const { selectingLocations, selectedLocation, locations, loadLocation, view } = props

  if ( !selectingLocations ) return null

  const filtered = locations.filter(l => l !== selectedLocation)
                                  .slice(0,4)

  const mapped = filtered.map((l, i) => {

    let logo         = <InitialsIcon name={l.name} img={l.img} />
    if (l.logo) logo = <img src={l.logo} alt={l.name} />

    let iconAction = <div
                       key={i}
                       onClick={() => loadLocation(l.id)}
                       className={`selectedLogo selecting`}
                     >
                       {logo}
                     </div>

    if ( view.edit ) iconAction = <Link
                                    to={`/Locations/edit/${l.id}`} 
                                    key={i}
                                    onClick={() => loadLocation(l.id)}
                                    className={`selectedLogo selecting`}
                                  >
                                    {logo}
                                  </Link>

    return (
      <OverlayTrigger
        key={`${l.id}-${i}`}
        placement="bottom"
        overlay={
          <Tooltip id={`tooltip-bottom`}>
            {l.name}
          </Tooltip>
        }
      >
        {iconAction}
      </OverlayTrigger>
    )
  })

  if ( filtered.length === 0 ) {
    return <h5>No recent locations</h5>
  }
  
  return (
    <div className="LocationIcons">
      { mapped }
    </div>
  )
}

function mapStateToProps(state) {
  return {
    selectedLocation: state.selectedLocation.selectedLocation,
    view: state.view
  }
}

export default connect(mapStateToProps, {})(LocationIcons)