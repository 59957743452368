export const timeFromMilitaryTime = (time, locale) => {
    const militaryTime = parseInt(time)

    const hours    = militaryTime / 100
    const mins     = militaryTime % 100
    const timeDate = new Date(1960, 10-1, 30, hours, mins, 0)

    return timeDate.toLocaleTimeString(locale, { hour: '2-digit', minute: '2-digit'})
}


export const formatRepeatStr = (block) => {
  var frequency
  switch (block.repeat.frequency) {
    case 'D':
      frequency =  'day'
      break;
    case 'W':
      frequency =  'week'
      break;
    case 'M':
      frequency =  'month'
      break;
  }

  let multiple = block.repeat.interval > 1
  let intervalAmount = multiple ? `${block.repeat.interval} ` : ''

  let effective = `Every ${intervalAmount}${frequency}${multiple ? 's' : ''}`
  
  if (block.repeat.weekdays.length) {
    effective += ` on ${formatWeekDaysStr(block.repeat.weekdays)}`
  }

  return effective
}

export const formatWeekDaysStr = str => {
  let newStr = str.replace('0', 'Sun, ')
                  .replace('1', 'Mon, ')
                  .replace('2', 'Tue, ')
                  .replace('3', 'Wed, ')
                  .replace('4', 'Thu, ')
                  .replace('5', 'Fri, ')
                  .replace('6', 'Sat, ')
                  .slice(0, -2)
  return newStr
}