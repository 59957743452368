import { useEffect } from "react";
import { useHistory } from "react-router";

const useRedirect = (url, miliseconds = 3000) => {
  const history = useHistory()

  useEffect(() => {
    let interval = null
    interval = setInterval(() => {
      history.push(url)
    }, miliseconds)

    return () => interval ? clearInterval(interval) : null;
  }, [])
}

export default useRedirect