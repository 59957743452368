import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from  'redux-form'

import { renderField } from '../../../../utils/renderField'
import { updateDomain, 
         deleteDomain,
         loadDomains } from '../../../../actions/domainActions'
import { validateDomain } from '../../../../utils/validate'


const ExistingDomainList = props => {
  const { change,
          fields,
          loadDomains,
          updateDomain,
          deleteDomain
        } = props

  const setDomains = () => {
    loadDomains().then(resp => {
      const { clientDomains } = resp.payload.data
      change('clientDomains', clientDomains)
    })
  }

  const updateAction = (index, e) => {
    if (/(?:^|[ \t])((https?:\/\/)?(?:localhost|[\w-]+(?:\.[\w-]+)+)(:\d+)?(\/\S*)?)/gm.test(e.target.value)) {
      let id = fields.get(index).id
      updateDomain(id, {domain: e.target.value})
        .then(() =>  setDomains())
    }
  }

  const deleteAction = (index) => {
    let id = fields.get(index).id
    deleteDomain(id).then(() => setDomains())
  }

  return (
    <ul className="ExistingDomainList">
      {fields.map((domain, index) => (
        <li className="ExistingDomainItem" key={index}>
          <Field
            name={`${domain}.domain`}
            component={renderField}
            type="text"
            label={`OnSchedJS Domain ${index + 1}`}
            placeholder={`OnSchedJS Domain ${index + 1}`}
            onBlur={e => updateAction(index, e)}
          />
          <i
            onClick={() => deleteAction(index)}
            className="fad fa-trash-alt"
          />
        </li>
      ))}
    </ul>
  )
}

const connectedExistingDomainList = connect(
  null, 
  { updateDomain, 
    deleteDomain,
    loadDomains
  })(ExistingDomainList)

export default reduxForm({form: 'domain-list', validate: validateDomain})(connectedExistingDomainList)
