import React from 'react'

import './ToastErrorDetails.css'


const ToastErrorDetails = ({ errors }) => (
  <div className="ToastErrorDetails">
    { errors.map((err, i) => 
      <p key={i}><h5>{err.code}</h5><p>{err.detail}</p></p>
    )}
  </div>
)

export default ToastErrorDetails
