import { handle } from 'redux-pack'

import { BOOKING_FIELDS } from '../actions/appointmentActions'


const INITIAL_STATE = { success: false }


export default function( state = INITIAL_STATE, action ) {
  const { type, payload } = action
  switch ( type ) {
    
    case BOOKING_FIELDS:
      return handle( state, action, {
        start:   previousState => ( { ...previousState, isLoading: true, bFieldsError: null, bFields: null } ),
        finish:  previousState => ( { ...previousState, isLoading: false } ),
        failure: previousState => ( { ...previousState, bFieldsError: payload } ),
        success: previousState => ( { ...previousState, success: true, bFields: payload.data } )
      })

    default:
      return state

  }

}