import { defaultServiceAvailability } from "./businessHours";

export const serviceDefaults = {
  duration:    30,
  type:        "1",
  allocations: [],
  availability: defaultServiceAvailability,
  fees: {
    cancellationFeeAmount: 0.00,
    feeAmount:             0.00
  },
  defaultService:   false,
  durationSelect:   false,
  durationInterval: 10,
  durationMin:      10,
  durationMax:      30,
  padding:          0,
}
