import React                 from 'react'
import { connect }           from 'react-redux'
import { reduxForm, 
         formValueSelector } from 'redux-form'

import { eventFields, 
        eventFieldsResource }      from '../../../../utils/eventUtils'
import { eventRepeatSection }      from '../../../../utils/formFields'
import WeekDays                    from '../../WeekDays/WeekDays'
import ResourcesField              from '../../ResourcesField/ResourcesField'
import FormFields                  from '../../../FormFields/FormFields'
import { viewTypes }               from '../../../../actions/viewActions'

import './EditEventForm.css'

const EditEventForm = props => {
  const { repeat, repeats, change, startDate, form, view } = props

  const handleRepeat = () => {
    if (repeat) {
      change('repeat.frequency', 'D')
      change('repeat.interval', 1)
      change('repeat.weekdays', '')
      change('repeat.monthDay', new Date(startDate).toISOString().slice(8,10))
      change('repeat.monthType', 'D')
    }
  }

  return (
    <div className="EditEventForm">


      <FormFields form={form} fields={view === viewTypes.services ? eventFields : eventFieldsResource} /> 

      <FormFields disabled={!repeats} form={form} fields={eventRepeatSection} />
      <WeekDays 
        form={form} 
        repeat={repeat}
        repeats={repeats}
        isEvent={true}
      />
      
      {view === viewTypes.services && <ResourcesField form={form} />}
    
    </div>
  )
}

const mapStateToProps = (state, props) => ({
  repeat:     formValueSelector(props.form)(state, 'repeat'),
  repeats:    formValueSelector(props.form)(state, 'repeats'),
  startDate:  formValueSelector(props.form)(state, 'startDate')
})


const connectedEditEventForm = connect(mapStateToProps, {})(EditEventForm)

export default reduxForm({destroyOnUnmount: false})(connectedEditEventForm)