import { ModifyAndLoadResource, uploadResourceImage } from '../../../../actions/resourceActions';

const submit = async (values, dispatch, props) => {
  //---------------------------
  // Notifications Logic
  //---------------------------
  values.options = {}
  values.options.notificationType = 0
  const { notificationType1, notificationType2 } = values.notifications

  if (notificationType1 && notificationType2) {
    values.options.notificationType = 3
  }
  else if (notificationType1) {
    values.options.notificationType = 1
  }
  else if (notificationType2) {
    values.options.notificationType = 2
  }

  //---------------------------
  // Calendar Sync Logic
  //---------------------------
  let calendarOptionsObject = {}
  let returnUrlParameters   = {}

  if (values.calendarSync !== 'none') {

    calendarOptionsObject = { 
      options: { ...values.options }
     }

    if (values.calendarSync === 'google') {
      returnUrlParameters.googleAuthReturnUrl = values.returnUrl
      calendarOptionsObject.options.googleCalendarId = values.calendarId
    }
    else if (values.calendarSync === 'outlook') {
      returnUrlParameters.outlookAuthReturnUrl = values.returnUrl
      calendarOptionsObject.options.outlookCalendarId = values.calendarId
    }

     // Calendar two way sync 
     if(values.calendarAvailability){

       if (values.calendarSync === 'google') {
        calendarOptionsObject.options.calendarAvailability = 1
       } 
       else if (values.calendarSync === 'outlook'){
        calendarOptionsObject.options.calendarAvailability = 2
       }
      } else {
        calendarOptionsObject.options.calendarAvailability = 0
     }
  }

  // Description is not deleted unless we pass an empty space
  const description =  values.description === '' ? ' ' : values.description

  //---------------------------
  // Submission
  //---------------------------
  const resourceDataReady = {
    ...values,
    ...calendarOptionsObject,
    description
  }

  try {
    await dispatch(ModifyAndLoadResource(values.id, resourceDataReady, returnUrlParameters))
  }
  catch (err){
    console.error({ err })
  }
}

export default submit
