import { SET_PAGE, SET_LAST_PAGE } from '../actions/pageActions'

const INITIAL_STATE = { page : 1, lastPageOffset: 0, lastPage: 1 }

const toasts = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_PAGE:
      return {
          ...state,
          id: action.id,
          page: action.page
        }
    case SET_LAST_PAGE:
      return {
        ...state,
        lastPageOffset: action.lastPageOffset,
        lastPage: action.lastPage
      }
    default:
      return state
  }
}

export default toasts
