export const formatFullDate = (date, locale ='en-us', options = detailedDateTimeOptions) => {
    return new Date(date).toLocaleString(locale, options)
}

/**
 * Function to verify if a date is just an empty string. Returns date when the date is not an empty string.
 * @param  {[string]} date Date in a string format
 * @return  Bool or String   
 */
export const isDateEmpty = (date) => {
    if (date === ''){
        return false 
    }
    else {
        return date
    }
}

const detailedDateTimeOptions = {  
    day:   'numeric',
    month: 'short',
    year:  'numeric',
    hour: '2-digit',
    minute: '2-digit',
    timeZoneName: 'short'
}