import React, { Component } from 'react'
import { connect }          from 'react-redux'
import { reduxForm }        from 'redux-form'

import { updateEmailPreview } from '../../../../../../../actions/notificationActions'

import './TemplateHeaderSwitch.css'


class TemplateHeaderSwitch extends Component {
  constructor(props) {
    super(props)
    this.state = {
      active: true
    }
  }

  componentDidMount() {
    const { options,
            displaySwitch, 
            title, 
          } = this.props

          
    if (options) {
      const name = 'show' + displaySwitch + title

      let i = Object.keys(options).indexOf(name)
      let option = Object.values(options)[i]

      this.handleSwitch(option)
    }
  }

  componentDidUpdate(prevProps) {
    const { options,
            displaySwitch,
            title
          } = this.props

    if (prevProps.options !== options && options) {
      const name = 'show' + displaySwitch + title

      let i = Object.keys(options).indexOf(name)
      let option = Object.values(options)[i]

      this.handleSwitch(option)
    }
  }

  handleSwitch = active => {
    const { emailPreview, 
            displaySwitch, 
            title 
          } = this.props

    const name = 'show' + displaySwitch + title

    this.props.change(name, active)
    this.setState({ active })
    this.props.updateEmailPreview({
      ...emailPreview,
      [name]: active
    })
  }

  render() {
    const { active } = this.state
    const { displaySwitch, title } = this.props
    const name = 'show' + displaySwitch + title

    let onOff = ''
    if (active)
      onOff = 'active'

    return (
      <div 
        onClick={() => this.handleSwitch(!active)}
        className={`TemplateHeaderSwitch ${onOff}`}
      >
        <div className="toggle" />
        {displaySwitch}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  options:      state.masterTemplate.masterTemplate,
  emailPreview: state.emailPreview.options,
})

export default reduxForm({form: 'master-email-template'})(
  connect(mapStateToProps, { updateEmailPreview })(TemplateHeaderSwitch)
)