import React from 'react'

import { timeFromMilitaryTime } from '../../../../utils/timeUtils'

import './WeekDay.css'


const WeekDay = ({ weekday }) => {
  const { day, value: { startTime, endTime, isOpen, is24Hours } } = weekday
  const days = [ 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday' ]
  
  const start = timeFromMilitaryTime(startTime)
  const end   = timeFromMilitaryTime(endTime)

  let open = false
  // If isOpen is undefined or null use the endTime to determine availability
  if (isOpen == null) {
    
    if(endTime > 0){
      open = true
    }
  }
  else {
    open = isOpen
  }

  const is24 = is24Hours || 
             (startTime === 0 && endTime === 2400)

  const isOpen24 = open && is24

  const shortDay = day
  const longDay  = days.filter(e => e.slice(0, 3)
                       .toLowerCase() === day)[0]

  if (isOpen24) {
    return (
      <tr key={day} className="WeekDay">
        <td>
          <span className="long">{longDay}</span>
          <span className="short">{shortDay}</span>
        </td>
        <td>is open</td>
        <td colSpan="3">24 Hours</td>
      </tr>
    )
  }

  return (
    <tr key={day} className="WeekDay">
      <td>
        <span className="long">{longDay}</span>
        <span className="short">{shortDay}</span>
      </td>
      <td>{open ? 'is open' : 'is closed'}</td>
      <td className="time">{open ? start : ''}</td>
      <td>{open ? 'to' : ''}</td>
      <td className="time">{open ? end : ''}</td>
    </tr>
  )
}

export default WeekDay