import React           from 'react'
import { useDispatch, 
         useSelector } from 'react-redux'
import { Tabs, Tab }   from 'react-bootstrap'
import { useHistory }  from 'react-router'

import { loadLocationById } from '../../../actions/locationActions'
import { toggleExitIntent } from '../../../actions/exitIntentActions'
import { viewTypes }        from '../../../actions/viewActions'
import { useTabParams }     from '../../../hooks/useTabParams'
import { useView }          from '../../../hooks/useView'
import Header               from '../../Header/Header'
import LocationAvailability from './LocationAvailability/LocationAvailability'
import LocationProfile      from './LocationProfile/LocationProfile'
import LocationSettings     from './LocationSettings/LocationSettings'
import useLoadData          from '../../../hooks/useLoadData'

import './ModifyLocation.css'

const ModifyLocation = () => {
  const history  = useHistory()
  const dispatch = useDispatch()
  
  const { view }             = useSelector(state => state.view)
  const { unsaved }          = useSelector(state => state.unsaved)
  const { isLoading, 
          selectedLocation } = useSelector(state => state.selectedLocation)
 
  const [ tab, setTab ]      = useTabParams('location-profile')

  useView(view, viewTypes.locations)
  useLoadData(selectedLocation, loadLocationById, isLoading)
  
  const selectTabHandle = (key) => {
    if (unsaved) {
      dispatch(toggleExitIntent(history.location.pathname + `#${key}`, true, tab))
    }
    else {
      setTab(key)
      history.replace({ hash: `${key}` });
    }
  }

    return (
      <div className="ModifyLocation">
          {/* {saveShortcut} */}
          <Header 
            title={selectedLocation?.name ?? 'Loading...'} 
            hide 
          />

          <Tabs 
            activeKey={ tab }
            onSelect={ selectTabHandle }
            id={`location-tabs`}
            unmountOnExit={true}
          >
            <Tab eventKey="location-profile" title="Profile" >
              <LocationProfile />
            </Tab>

            <Tab eventKey="location-availability" title="Business Hours" >
              <LocationAvailability />
            </Tab>

            <Tab eventKey="location-settings" title="Settings" >
              <LocationSettings />
            </Tab>
          </Tabs>
      </div>
    )  
}

export default ModifyLocation
