import { handle } from 'redux-pack'

import { LOAD_SERVICES } from '../actions/serviceActions'


const INITIAL_STATE = { success: false }


export default function( state = INITIAL_STATE, action ) {
  const { type, payload } = action
  switch ( type ) {
    
    case LOAD_SERVICES:
      return handle( state, action, {
        start:   previousState => ( { ...previousState, isLoading: true, servicesError: null, services: null } ),
        finish:  previousState => ( { ...previousState, isLoading: false } ),
        failure: previousState => ( { ...previousState, servicesError: payload } ),
        success: previousState => ( { ...previousState, success: true, services: payload.data } )
      })

    default:
      return state

  }

}