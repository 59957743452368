import React          from 'react'
import { connect }    from 'react-redux'
import { Pagination } from 'react-bootstrap'

import { setPage } from '../../actions/pageActions'

import './Pages.css'


const Pages = ({ total, page, setPage, listAction, divisible }) => {
  if(!divisible){
    divisible = 10
  }

  const handleClick = i => {
    if (i > 0) {
      setPage(i)
      listAction(
        Math.ceil((i-1) * divisible)
      )
    }
  }

  const pages = Math.ceil(total/divisible)
  let items = []
  
  let min = 1
  let max = pages

  
  if (pages > 4) {
    max = page + 2
    if (page > 3)
      min = page - 2
    }
    
  // Second last page & more than 3 pages
  if (page === pages -1) {
    max = page + 1
    if (pages > 4)
    min = page - 3
  }
  
  // Last page & more than 3 pages
  if (page === pages) {
    max = page
    if (pages > 4)
      min = page - 4  
  }

  while (min <= max) {
    let push = min

    if (page > 2)
      push = min

    items.push(push)
    min++
  }

  if (pages === 1)
    return null

  return (
    <div className="Pages">
      <Pagination>
        { page > 3 && <Pagination.First onClick={() => handleClick(1)} /> }
        { page > 1 && <Pagination.Prev onClick={() => handleClick(--page)} /> }
        { 
          items.map(item => 
            <Pagination.Item 
              key={item} 
              onClick={() => handleClick(item)}
              active={item === page} 
            >
              {item}
            </Pagination.Item>  
        )}
        { page < pages && <Pagination.Next onClick={() => handleClick(++page)} /> }
        { page < pages - 2 && <Pagination.Last onClick={() => handleClick(pages)} /> }
      </Pagination>
    </div>
  )
}

const mapStateToProps = state => ({
  page: state.page.page
})

export default connect(mapStateToProps, { setPage })(Pages)
