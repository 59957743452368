export const validateLocation = (values) => {
  const errors = { address: {}, settings: {} }

  if (!values.name) {
    errors.name = 'Valid name required';
  } else if (values.name.length < 3) {
    errors.name = 'Must be at least 3 characters'
  } 

  if (values.phone) {
    if (values.phone.length > 15) {
      errors.phone = 'Must be 15 digits or less'
    } else if (!(/[0-9]/i.test(values.phone))) {
      errors.phone = 'Valid number required'
    } else if(values.phone.length < 10){
      errors.phone = 'Must be 10 digits minimum'
    }
  }

  if (values.email) {
    if (!(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(values.email))) {
      errors.email = 'Invalid email address'
    } 
  } 

  if (values.website) {
    if (!(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/gm.test(values.website))) {
      errors.website = 'Please enter valid url'
    }
  }

  if (!values.timezoneName) {
    errors.timezoneName = 'Required'
  }
  
  // if (values.settings) {
  //   if (!(/\d{1,}/g.test(values.settings.bookAheadValue))) {
  //     errors.settings.bookAheadValue = 'Please enter a numeric value'
  //   }
    
  //   if (!(/[^a-z ]\ *([.0-9])*\d/g.test(values.settings.bookInAdvance))) {
  //     errors.settings.bookInAdvance = 'Please enter a numeric value'
  //   }
  // }

  // Google address validation
  // if (!/(.*[,)]){3}/i.test(values.streetaddress)) {
  //   errors.streetaddress = 'Street, city, state, and country required';
  // } if (/(.*[,)]){4}/i.test(values.streetddress)) {
  //   errors.streetaddress = null;
  // } 

  return errors;
};

export const validateForgotPw = (values) => {
  const errors = {}
  
  if (!values.email) {
    errors.email = 'Required'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(values.email)) {
    errors.email = 'Invalid email address'
  }
  return errors
}

export const validateNewPw = (values) => {
  const errors = {}
  
  if (!values.email) {
    errors.email = 'Required'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(values.email)) {
    errors.email = 'Invalid email address'
  }
  if (!(/.{8,}$/.test(values.password))) {
    errors.password = 'Miminum 8 characters'
  }
  if (!(/(?=.*\d).{0,}$/.test(values.password))) {
    errors.password = 'One number required'
  }
  if (!(/(?=.*?[a-z]).{0,}$/.test(values.password))) {
    errors.password = 'One lowercase letter required'
  }
  if (!(/(?=.*?[A-Z]).{0,}$/.test(values.password))) {
    errors.password = 'One uppercase letter required'
  }
  if (!values.password) {
    errors.password = 'Required'
  }
  if (values.confirmPassword !== values.password) {
    errors.confirmPassword = 'Must match password'
  }
  return errors
}

export const validateResource = (values) => {
  const errors = { address: {} }
  if (!values.name) {
    errors.name = 'Valid name required';
  } 
  if (values.phone) {
    if (values.phone.length > 15) {
    errors.phone = 'Must 15 digits or less'
    } else if (!(/[0-9]/i.test(values.phone))) {
    errors.phone = 'Valid number required'
    }
  }
  
  if (values.email) {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(values.email)) {
      errors.email = 'Invalid email address'
    } 
  }                 

  if (values.calendarSync !== 'none') {
    if (!values.email) {
      errors.email        = 'Email required for calendar sync'
      errors.calendarSync = 'Email required for calendar sync'
    } 
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(values.email)) {
      errors.email = 'Valid email required for calendar sync'
    }
  }

    if (values.address && (values.address.addressLine1 || values.address.addressLine2)) {

      if (!values.address.country) {
        errors.address.addressLine1 = 'State and Country required when address is present'
        errors.address.addressLine2 = 'State and Country required when address is present'
      }
    }
  
  return errors
}

export const vallidateCustomer = (values) => {
  const errors = { }
  if (!values.name) {
    errors.name = 'Valid name required';
  } 
  
  if (values.phone) {
    if (values.phone.length > 15) {
      errors.phone = 'Must 15 digits or less'
    } else if (!(/[0-9]/i.test(values.phone))) {
      errors.phone = 'Valid number required'
    }
  }
  
  if (values.email) {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(values.email)) {
      errors.email = 'Invalid email address'
    } 
  }                 

  
  return errors
}


export const validateService = values => {
  const errors = { }
  if (!values.name) {
    errors.name = 'Required'
  }
  if (!values.duration) {
    errors.duration = 'Required'
  }
  if (values.durationSelect) {
    if (values.durationMax % values.durationInterval !== 0) {
      errors.durationInterval = 'Interval needs to be divisible by the max'
      errors.durationMax =      'Interval needs to be divisible by the max'
    }
  }
  if (values.maxCapacity) {
    if(!/^[0-9]*$/.test(values.maxCapacity)) {
      errors.maxCapacity = 'Max Capacity can only be a whole number'
    }
  }
  if (values.maxGroupSize) {
    if(!/^[0-9]*$/.test(values.maxGroupSize)) {
      errors.maxGroupSize = 'Max Capacity can only be a whole number'
    }
  }

  return errors
}

export const validateSignIn = (values) => {
  const errors = {}
  
  if (!values.email) {
    errors.email = 'Required'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(values.email)) {
    errors.email = 'Invalid email address'
  }
  if (!values.password) {
    errors.password = 'Required'
  } else if (values.password.length < 8) {
    errors.password = 'Must be at least 8 characters'
  }
  return errors
}

export const validateUsers = (values) => {
  const errors = {};

  if (!values.userName) errors.userName = 'Name is required'
  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(values.userEmail)) {
    errors.userEmail = 'Invalid email address'
  }
  return errors
}

export const validateResourceModal = values => {
  const errors = {};
  
  if (values.type === 'businessuser') {

    if (!values.email) {
      errors.email = 'Required'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(values.email)) {
      errors.email = 'Invalid email address'
    }
    if (values.name && values.name.length < 3) {
      errors.name = 'Name must be at least 3 characters'
    }
  } 
  else {
    
    if (!values.name) {
      errors.name = 'Valid name required';
    } else if (values.name.length < 3) {
      errors.name = 'Name must be at least 3 characters'
    } if (!values.email) {
      errors.email = 'Required'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(values.email)) {
      errors.email = 'Invalid email address'
    } 
  }
  
  return errors
}

export const validateBusinessUser = values => {
  const errors = {};
  
  if (!values.email) {
    errors.email = 'Required'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(values.email)) {
    errors.email = 'Invalid email address'
  }
  
  return errors
}

export const validateChangePassword = values => {
  const errors = {};
  if (!(/.{8,}$/.test(values.oldPassword))) {
    errors.oldPassword = 'Miminum 8 characters'
  }
  if (!(/(?=.*\d).{0,}$/.test(values.oldPassword))) {
    errors.oldPassword = 'One number required'
  }
  if (!(/(?=.*?[a-z]).{0,}$/.test(values.oldPassword))) {
    errors.oldPassword = 'One lowercase letter required'
  }
  if (!(/(?=.*?[A-Z]).{0,}$/.test(values.oldPassword))) {
    errors.oldPassword = 'One uppercase letter required'
  }
  if (!values.oldPassword) {
    errors.oldPassword = 'Required'
  }

  if (values.newPassword === values.oldPassword) {
    errors.newPassword = 'Must be different than old password'
  }

  if (!(/.{8,}$/.test(values.newPassword))) {
    errors.newPassword = 'Miminum 8 characters'
  }
  if (!(/(?=.*\d).{0,}$/.test(values.newPassword))) {
    errors.newPassword = 'One number required'
  }
  if (!(/(?=.*?[a-z]).{0,}$/.test(values.newPassword))) {
    errors.newPassword = 'One lowercase letter required'
  }
  if (!(/(?=.*?[A-Z]).{0,}$/.test(values.newPassword))) {
    errors.newPassword = 'One uppercase letter required'
  }
  if (!values.newPassword) {
    errors.newPassword = 'Required'
  }

  if (values.newPassword !== values.confirmPassword) {
    errors.confirmPassword = 'Passwords must match'
  }
  
  return errors
}

export const validateDomain = values => {
  
  const errors = { clientDomains: [], domains: [] }
  
  if (values.clientDomains) {
    for (let i = 0; i < values.clientDomains.length; i++) {
      //create errors array with domain property
      errors.clientDomains.push({ domain: "" })
      
      if(!(/(?:^|[ \t])((https?:\/\/)?(?:localhost|[\w-]+(?:\.[\w-]+)+)(:\d+)?(\/\S*)?)/gm.test(values.clientDomains[i].domain))){
        errors.clientDomains[i].domain = 'Please enter a valid URL'
      }
    }
  }

  if (values.domains) {
    for (let i = 0; i < values.domains.length; i++) {
      //create errors array with domain property
      errors.domains.push({ domain: "" })
  
      if(!(/(?:^|[ \t])((https?:\/\/)?(?:localhost|[\w-]+(?:\.[\w-]+)+)(:\d+)?(\/\S*)?)/gm.test(values.domains[i].domain) )){
        errors.domains[i].domain = 'Please enter a valid URL'
      }
    }
  }
  
  return errors
  
}

export const validateCompany = values => {
  const errors = {}

  if (!values.name) {
    errors.name = 'Valid name required';
  } else if (values.name.length < 3) {
    errors.name = 'Must be at least 3 characters'
  } 

  return errors
}


export const validateAllocation = values => {
  const errors = {}

  if (values.repeats) {
    if (!values.repeats.frequency) {
      errors.frequency = 'Frequency is required when repeat is true'
    }
    if (!values.repeats.interval) {
      errors.interval = 'Interval is required when repeat is true'
    }
  }

  return errors
}

export const validateUser = values => {
  const errors = {}
  
  if (!values.firstName) {
    errors.firstName = 'Required'
  }
  if (!values.lastName) {
    errors.lastName = 'Required'
  } 
  if (!values.email) {
    errors.email = 'Required'
  } 
  else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(values.email)) {
    errors.email = 'Invalid email address'
  }
  if (!(/.{8,}$/.test(values.password))) {
    errors.password = 'Miminum 8 characters'
  }
  if (!(/(?=.*\d).{0,}$/.test(values.password))) {
    errors.password = 'One number required'
  }
  if (!(/(?=.*?[a-z]).{0,}$/.test(values.password))) {
    errors.password = 'One lowercase letter required'
  }
  if (!(/(?=.*?[A-Z]).{0,}$/.test(values.password))) {
    errors.password = 'One uppercase letter required'
  }
  if (!values.password) {
    errors.password = 'Required'
  }
  if (values.confirmPassword !== values.password) {
    errors.confirmPassword = 'Must match password'
  }

  return errors
}
