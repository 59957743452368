import { SET_RECENT_LOCATIONS } from '../actions/locationActions'

const INITIAL_STATE = {
  locations: []
}

const setRecentsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_RECENT_LOCATIONS:
      return {
          locations: action.locations,
          reset:     action.reset
        }
    default:
      return state
  }
}

export default setRecentsReducer