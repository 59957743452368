import React                    from 'react'
import { useSelector }          from 'react-redux'
import { reduxForm }            from 'redux-form'

import useInitialize            from '../../../hooks/useInitialize'
import { editServiceAvailForm } from '../../../utils/initializeForm'
import EventsTab                from '../../EventsTab/EventsTab'
import Loading                  from '../../Loading/Loading'

const ServiceEvents = ({ form, initialize }) => {
  const { service, isLoading } = useSelector(state => state.service)
  useInitialize(service, editServiceAvailForm, initialize)

  return (
    isLoading ? <Loading /> :
    <form>
      <EventsTab form={form} />
    </form>
  )
}

export default reduxForm({
  form: 'service-events'
})(ServiceEvents)