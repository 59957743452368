import React                from 'react'
import { reduxForm, Field } from 'redux-form'

import { validateNewPw }    from '../../../utils/validate'
import { renderField }      from '../../../utils/renderField'
import renderPasswordField  from '../../../utils/renderPasswordField'
import FormFailedMessage    from '../../FormFailedMessage/FailedMessage'

import './NewPasswordForm.css'

const NewPasswordForm = ({ handleSubmit, failed, noToken }) => {
  return (
    <form onSubmit={handleSubmit} className="NewPasswordForm">
      <h3>Reset password</h3>
      { failed && <FormFailedMessage noToken={noToken} /> }

      <Field 
        name="email"
        label="Email"
        component={renderField}
        placeholder="doc@brown.bsu"
        customClass='fullWidth'
      />
      <Field 
        name="password"
        label="Password"
        component={renderPasswordField}
        placeholder="**********"
        showError={true}
        customClass='fullWidth'
      />
      <Field 
        name="confirmPassword"
        label="Confirm password"
        component={renderPasswordField}
        placeholder="**********"
        showError={true}
        customClass='fullWidth'
      />

      <button type="submit" className="btn btn-primary">Reset password</button>
    </form>
  )
}

export default reduxForm({ 
  form: 'reset-password', 
  validate: validateNewPw 
})(NewPasswordForm)