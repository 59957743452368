import { modifyAndLoadCalendar } from "../../../../actions/calendarActions"
import { toggleUnsaved } from "../../../../actions/exitIntentActions"
import { modifyAndLoadLocationSettings } from "../../../../actions/locationActions"

const submit = async (values, dispatch) => {
  if (true === values.isCompanyScope && !values.primaryBusiness) {
    delete values.settings
  }
  
  // Fix - #473
  if (values.settings.bookInAdvance.length === 0) {
    values.settings.bookInAdvance = 0
  }

  Promise.all([
    dispatch(modifyAndLoadLocationSettings(values.id, values)),
    dispatch(modifyAndLoadCalendar(values.calendarId, values))
  ]).then(() => dispatch(toggleUnsaved(false)))
}

export default submit