import React from 'react'
import { connect } from 'react-redux'

import { togglePreviewSize, previewSizes } from '../../../../../actions/notificationActions'

import './PreviewSizes.css'


const PreviewSizes = ({ screenSize, togglePreviewSize }) => {
  const sizes = [
    {
      size: 'laptop',
      preview: previewSizes.desktop
    }, 
    {
      size: 'tablet-alt',
      preview: previewSizes.tablet
    }, 
    {
      size: 'mobile',
      preview: previewSizes.mobile
    }
  ]

  const icons = sizes.map((size, i) => {
    let selected = ''
    if (size.preview === screenSize)
      selected = 'selected'

    return (
      <i 
        key={i}
        className={`fad fa-${size.size} ${selected}`} 
        onClick={() => togglePreviewSize(size.preview)}
      />
    )
  }
  )

  return (
    <div className="PreviewSizes">
      { icons }
    </div>
  )
}

const mapStateToProps = state => ({
  screenSize: state.screenSize.screenSize
})

export default connect(mapStateToProps, { togglePreviewSize })(PreviewSizes)
