import { handle } from 'redux-pack'
import { TOGGLE_RECURRING_AVAIL } from '../actions/resourceActions'

const INITIAL_STATE = { success: false }

export const toggleRecurringAvaillReducer = ( state = INITIAL_STATE, action ) => {
    const { type, payload } = action
    switch ( type ) {
      
      case TOGGLE_RECURRING_AVAIL:
        return handle( state, action, {
          start:   previousState => ( { ...previousState, isLoading: true, resourceRecurrinAvailError: null, resourceRecurrinAvail: null } ),
          finish:  previousState => ( { ...previousState, isLoading: false } ),
          failure: previousState => ( { ...previousState, resourceRecurrinAvailError: payload } ),
          success: previousState => ( { ...previousState, success: true, resourceRecurrinAvail: payload.data } )
        })
  
      default:
        return state
    }
  }