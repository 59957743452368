import React, { Component } from 'react'
import { connect }          from 'react-redux'
import querystring          from 'querystring'

import { en }                  from '../../../utils/languages'
import { EmptyList }           from '../../../utils/emptyList'
import { renderDropdownField } from '../../../utils/renderDropdownField'
import { renderField }         from '../../../utils/renderField'
import { loadAppointments }    from '../../../actions/appointmentActions'
import { loadServices }        from '../../../actions/serviceActions'
import { loadCustomers }       from '../../../actions/customerActions'
import { loadResources }       from '../../../actions/resourceActions'
import { convertTime }         from '../../../utils/businessHours'
import Loading                 from '../../Loading/Loading'
import DataList                from '../../DataList/DataList'
import DataListItem            from '../../DataList/DataListItem/DataListItem'
import Pages                   from '../../Pages/Pages'
import OnSchedSearch           from '../../OnSchedSearch/OnSchedSearch'

import './Appointments.css'


class Appointments extends Component {
  componentDidMount() {
    const { loadAppointments, 
            selected, 
            history,
            loadCustomers,
            loadServices,
            loadResources } = this.props
    if (selected) {
      const params = {
        locationId: selected.id,
        limit: 10
      }
      loadAppointments(params)
      loadCustomers(params)
      loadServices(params)
      loadResources(params)
    }
  }
    
  componentDidUpdate(prevProps) {
    const { loadAppointments, 
            loadCustomers,
            loadServices,
            loadResources,
            selected 
          } = this.props

    if (prevProps.selected !== selected && selected) {
      const params = {
        locationId: selected.id,
        limit: 10
      }

      loadAppointments(params)
      loadCustomers(params)
      loadServices(params)
      loadResources(params)
    }
  }

  listAction = offset => {
    const { loadAppointments, selected, history } = this.props
    const parsedSearchOptions = querystring.parse(history.location.search.substring(1))
    
    loadAppointments({
      locationId: selected.id,
      limit: 10,
      offset,
      ...parsedSearchOptions
    })
  }

  loadFilterList = list => {
    let filterList = []
    
    if (list) {
      filterList = list.data.map(item => {
        let label = item.name
        if (!item.name?.length) {
          label = item.email
        }
        
        return { label, value: item.id }
      })
    }
    
    return filterList
  }

  render() {
    const { itemTitles } = en.appointments
    const { appointments, 
            loading, 
            success, 
            loadAppointments, 
            customers, 
            services, 
            resources,
            history
          } = this.props
    
    const appointmentsSearchFiltersForm = [
      {
        name:        'serviceId',
        label:       'Service',
        component:   renderDropdownField,
        options:     this.loadFilterList(services),
        customClass: 'hide-disabled',
        dynamicVisibility: [{trigger: 'filters', match: true}]
      },
      {
        name:        'resourceId',
        label:       'Resource',
        component:   renderDropdownField,
        options:     this.loadFilterList(resources),
        customClass: 'hide-disabled',
        dynamicVisibility: [{trigger: 'filters', match: true}]
      },
      {
        name:        'customerId',
        label:       'Name',
        component:   renderDropdownField,
        options:     this.loadFilterList(customers),
        customClass: 'hide-disabled',
        dynamicVisibility: [{trigger: 'filters', match: true}]
      },
      {
        name:        'status',
        label:       'Status',
        component:   renderDropdownField,
        customClass: 'hide-disabled',
        dynamicVisibility: [{trigger: 'filters', match: true}],
        options: [
          {
            label: 'Initial',
            value: 'IN'
          },
          {
            label: 'Booked',
            value: 'BK'
          },
          {
            label: 'Cancelled',
            value: 'CN'
          },
          {
            label: 'Rescheduled',
            value: 'RE'
          },
          {
            label: 'Reserved',
            value: 'RS'
          },
          {
            label: 'No Show',
            value: 'NS'
          },
        ]
      },
      {
        name:        'startDate',
        label:       'Start Date',
        component:   renderField,
        type:        'date',
        customClass: 'hide-disabled',
        dynamicVisibility: [{trigger: 'filters', match: true}]
      },
      {
        name:        'endDate',
        label:       'End Date',
        component:   renderField,
        type:        'date',
        customClass: 'hide-disabled',
        dynamicVisibility: [{trigger: 'filters', match: true}]
      },
    ]

    const bookingLabel = status => {
      switch(status){
        case 'BK':
          return 'Booked'
        case 'IN':
          return 'Initial'
        case 'CN':
          return 'Cancelled'
        case 'RE':
          return 'Rescheduled'
        case 'RS':
          return 'Reserved'
        case 'NS':
          return 'No Show'
        default:
          return ''
      }
    }

    let total = 0

    let list = <EmptyList type={en.appointments.appointments} />

    if (appointments && appointments.data && appointments.data.length) {
      total = appointments.total
      
      list = appointments.data.map(appt => {
        const data = {
          img   : appt.resourceImageUrl,
          name  : appt.name,
          id    : appt.id,
          cells : [
            {
              title: itemTitles[0],
              cell: appt.resourceName
            },
            {
              title: itemTitles[1],
              cell: appt.serviceName
            },
            {
              title : itemTitles[1],
              cell  : appt.date
            },
            {
              title : itemTitles[2],
              cell  : convertTime(appt)
            },
            {
              title: itemTitles[3],
              cell: bookingLabel(appt.status)
            },
          ]}

        return (
          <DataListItem 
            key={appt.id} 
            resource={appt} 
            data={data}
            noDelete
          />
        )
      })
    }

    let dataList = (
      <DataList
        itemTitles={itemTitles}
        list={list}
      />
    )

    if (loading)
      return <Loading />

    return (
      <div className="Appointments">
        <OnSchedSearch 
          object='appointments' 
          action={loadAppointments} 
          fields={appointmentsSearchFiltersForm}
          placeholder='Search by lastname or email'
          history={history}
        />
        { dataList }
        <Pages 
          total={total}
          isLoading={loading}
          success={success}
          listAction={this.listAction}
        />
        <p className='total'>{en.appointments.total}{appointments && appointments.total}</p>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  appointments: state.appointments.appointments,
  loading:      state.appointments.isLoading,
  success:      state.appointments.success,
  selected:     state.selectedLocation.selectedLocation,
  customers:    state.customers.customers,
  services:     state.services.services,
  resources:    state.resources.resources
})

export default connect(mapStateToProps, { loadAppointments, loadCustomers, loadServices, loadResources })(Appointments)
