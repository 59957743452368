import React           from 'react'

import CreateModal       from '../CreateModal/CreateModal'
import ExitIntentModal   from '../ExitIntentModal/ExitIntentModal'
import BookingModal      from '../BookingModal/BookingModal'
import UserModal         from '../UserModal/UserModal'
import ConfirmationModal from './ConfirmationModal'
import CalendarModal     from './CalendarModal'

const Modals = ({history}) => (
  <>
    <CreateModal history={history} />
    <ExitIntentModal />
    <BookingModal />
    <UserModal />
    <ConfirmationModal />
    <CalendarModal />
  </>
)

export default Modals