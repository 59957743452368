import React, { Component } from 'react'
import moment               from 'moment'
import { connect }          from 'react-redux'

import { loadCompanyTimezones } from '../actions/companyActions'


class renderTimezonesField extends Component {
  componentDidMount() {
    const { loadCompanyTimezones, timezones } = this.props
    
    if (!timezones)
      loadCompanyTimezones(moment().format('YYYY-MM-DD'))
  }

  render() {
    const { name, 
            input,
            label, 
            timezones,
            placeholder, 
            customClass, 
            meta: { touched, error }, 
          } = this.props

    let errorClass = ''
    if ( touched && error ) errorClass = 'error'

    let timezoneOptions = <option disabled>{placeholder}</option>
    if (timezones) {
      let newTz = timezones;
      delete newTz.timezones[258];
      delete newTz.timezones[259];

      timezoneOptions = newTz.regions.map((tzRegion, i) => 
        timezoneOptions = <optgroup key={i} label={tzRegion}>
          {
            newTz.timezones
              .filter(tz => tz.region === tzRegion)
              .map((groupedTz, idx) => 
                <option 
                  key={idx}
                  value={groupedTz.timezoneIanna}
                >
                  {groupedTz.name}
                </option>  
              )
          }
        </optgroup>
      )
    }

    return (
      <div className={`renderField renderDropdownField ${customClass} ${errorClass}`}>
        <label htmlFor={name}>{label}</label>
        <select {...input}>
          <option />
          {timezoneOptions}
        </select>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  timezones: state.timezones.timezones
})

export default connect(mapStateToProps, { loadCompanyTimezones })(renderTimezonesField)
