import React from 'react'

import { en } from '../../../../utils/languages'

import './BookableServicesList.css'


const BookableServicesList = ({ items }) => {
  return (
    <table className="BookableServicesList">
      <tbody>
        <tr className="BookableServicesHeader">
          {en.calendars.itemTitles.map((title, i) => 
            <th key={i}>{title}</th>
            )}
        </tr>
        
        { items }
      </tbody>
    </table>
  )
}

export default BookableServicesList