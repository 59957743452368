import React       from 'react'

import history       from '../../../utils/history'
import Header from '../../Header/Header'
import CompanySearch from '../CompanySearch/CompanySearch'

import './CompanySelectHeader.css'


const CompanySelectHeader = props => {
  const { handleSearch } = props
  
  const logo = 'https://cdn.public.onsched.com/content/assets/img/transpurple.png'

  return (
    <>
      <div className="CompanySelectHeader">
        <div className="CompaniesNav">
          <img
            src={logo}
            className="Logo"
            alt="OnSched" 
            onClick={() => history.push('/')}
          />
        </div>
        <div className='header'>
          <Header title='Companies'  hide/>
        </div>
        <CompanySearch onSubmit={handleSearch} />
      </div>
    </>
  )
}

export default CompanySelectHeader
