import React, { Component } from 'react'
import { connect }          from 'react-redux'
import { Modal }            from 'react-bootstrap'

import { toggleUserModal }   from '../../actions/modalActions'
import { loadUsers }         from '../../actions/userActions'
import { switchView }        from '../../actions/viewActions'
import UserInfo              from './UserInfo/UserInfo'
import UserModalSettings     from './UserModalSettings/UserModalSettings'
import { setToken, signOut } from '../../actions/authActions'

import './UserModal.css'


class UserModal extends Component {
  signOut = () => {
    const { toggleUserModal, setToken, signOut } = this.props
    toggleUserModal(null, false)
    window.localStorage.clear()
    setToken(null)
    signOut()
  }

  render() {
    const { toggleUserModal, modal, user, loading } = this.props
    const { localStorage } = window

    if ( loading ) return null

    let name = ''
    let email = ''
    
    if (user) {
      name = `${user.given_name} ${user.family_name}`
      email = user.email
    }
    
    else if ( localStorage.userFirstname &&
              localStorage.userLastname &&
              localStorage.userEmail
            ) {
      name = `${localStorage.userFirstname} ${localStorage.userLastname}`
      email = localStorage.userEmail
    } 
    
    return (
      <Modal 
        show={modal.modalAction}
        onHide={() => toggleUserModal(null, false)}
        className="UserModal"
      >
        <Modal.Body>
              <UserInfo name={name} email={email} />

              <UserModalSettings signOut={this.signOut} />
        </Modal.Body>
      </Modal>
    )
  }
}

const mapStateToProps = state => ({
  modal: state.userModal,
  users: state.users.users,
  user:  state.user.user,
  loading: state.users.isLoading,
  selected: state.selectedLocation.selectedLocation
})

export default connect(mapStateToProps, { 
  toggleUserModal, 
  loadUsers, 
  setToken,
  signOut,
  switchView
})(UserModal)