import React         from 'react'
import { connect }   from 'react-redux'
import { reduxForm } from 'redux-form'

import { en }                     from '../../utils/languages'
import { changePassword }         from '../../actions/authActions'
import { changePasswordForm }     from '../../utils/formFields'
import { validateChangePassword } from '../../utils/validate'
import Loading                    from '../Loading/Loading'
import Header                     from '../Header/Header'
import FormFields                 from '../FormFields/FormFields'

import './ChangePassword.css'

const ChangePassword = props => {
  const { loading, 
          handleSubmit, 
          changePassword, 
          user, 
          invalid, 
          pristine,
          history
        } = props

  const handleChangePassword = values => {
    if (user && user.email) {
      values.email = user.email
    }
    else if (localStorage && localStorage.userEmail) {
      values.email = localStorage.userEmail
    }

    changePassword(values)
      .then(resp => {
        if (resp.payload.status === 200) {
          if (localStorage.osDashRecentPage) {
            history.push(localStorage.osDashRecentPage)
          }
          else {
            history.push('/')
          }
        }
      })
  }

  if (loading) return <Loading />

  return (
    <form onSubmit={handleSubmit(handleChangePassword)} className="ChangePassword">
      <Header title="Change password" disabled={invalid || pristine} cta={en.ctas.save} />

      <FormFields form='change-password' fields={changePasswordForm} />
    </form>
  )
}

const mapStateToProps = state => ({
  loading: state.changePassword.isLoading,
  user:    state.user.user
})

const connectedChangePassword = connect(mapStateToProps, { changePassword })(ChangePassword)

export default reduxForm({ form: 'change-password', validate: validateChangePassword })(connectedChangePassword)
