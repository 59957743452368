import React from 'react'

import './VerifyPassword.css'


const VerifyPassword = () => {
  const logo = 'https://cdn.public.onsched.com/content/assets/img/transpurple.png'
  return (
    <div className="VerifyPassword">
      <div className="VerifyPasswordNav">
        <img src={logo} className="Logo" alt="OnSched" />
      </div>
  
      <h3>That's it!</h3>
      <p>Please check your email and follow the link to reset your password...</p>
    </div>
  )
}

export default VerifyPassword
