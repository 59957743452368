import { LOAD_PRIMARY_LOCATION } from '../actions/locationActions'

const INITIAL_STATE = {
  success: false
}

const loadPrimaryLocationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_PRIMARY_LOCATION:
      return {
          id:      action.id,
          primary: action.primary
        }
    default:
      return state
  }
}

export default loadPrimaryLocationReducer