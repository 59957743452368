import { SELECT_SEARCH_CATEGORY } from '../actions/searchActions'

const INITIAL_STATE = { index: 0 }

const selectSearchCategoryReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SELECT_SEARCH_CATEGORY:
      return {
          id:        action.id,
          category:  action.category
        }
    default:
      return state
  }
}

export default selectSearchCategoryReducer