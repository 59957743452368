import React, { Component } from 'react'
import { Navbar }           from 'react-bootstrap'
import { connect }          from 'react-redux'
import Hotkeys              from 'react-hot-keys'

import { localSetLocation } from '../../utils/localStorage'
import { loadLocationById,
       setRecentLocations } from '../../actions/locationActions'
import { toggleSideNav }  from '../../actions/sideNavActions'
import history              from '../../utils/history'
import { stopSearch }       from '../../actions/searchActions'
//import OnSchedGlobalSearch        from '../OnSchedGlobalSearch/OnSchedGlobalSearch'
import SelectedLocation     from './SelectedLocation/SelectedLocation'

import './TopNav.css'


class TopNav extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sideNavActive      : false,
      selectingLocations : false
    }
  }

  componentDidMount() {
    const { selectedLocation, locationLoading, 
            locationSuccess, sideNav } = this.props

    if ( !locationLoading && locationSuccess ) {
      this.props.setRecentLocations([selectedLocation])
    }

    if ( sideNav ) {
      this.setState({ 
        sideNavActive: sideNav.action 
      })
    }
  }

  componentDidUpdate(prevProps) {
    const { sideNav, selectedLocation, locationLoading, recents } = this.props
    
    if ( prevProps.selectedLocation !== selectedLocation && !locationLoading ) {
      if (recents.filter(recent => recent.id !== selectedLocation.id)) {
        this.props.setRecentLocations([
          selectedLocation,
          ...recents.filter(recent => recent.id !== selectedLocation.id)
        ])
      }
      else {
        this.props.setRecentLocations([selectedLocation])
      }
    }
    
    if ( prevProps.sideNav !== sideNav && sideNav ) {
      this.setState({ 
        sideNavActive: sideNav.action 
      })
    }
  }

  handleSelectingLocations = () => {
    this.setState({ 
      selectingLocations: !this.state.selectingLocations 
    })
  }

  loadLocation = locationId => {
    localSetLocation(locationId)
    this.props.loadLocationById(locationId)
    this.handleSelectingLocations()
  }

  toggleSideNav = () => {
    this.props.toggleSideNav(!this.state.sideNavActive)
  }

  render() {
    const { recents, 
      selectedLocation, 
      locationLoading, 
      locationSuccess, 
      searching, 
      stopSearch,
      compressed
    } = this.props

    const { selectingLocations, sideNavActive } = this.state

    let logo = 'https://cdn.public.onsched.com/content/assets/img/transpurple.png'
    if (compressed)
      logo = '/icons/transpurple-icon.png'
      
    if ( !locationLoading && locationSuccess && selectedLocation && selectedLocation.logo ) {
      logo = selectedLocation.logo
    }

    return (
      <Navbar 
        variant="light" 
        expand="lg" 
        className={`TopNav ${compressed ? 'compressed' : ''}`}  
        onClick={() => {if (searching) stopSearch(false)}}
      >
        <Hotkeys
          keyName="alt+shift+l"
          onKeyDown={this.handleSelectingLocations.bind(this)}
        />

        <Navbar.Brand>
          <img
            className="Logo"
            onClick={() => history.push('/')}
            src={logo}
            alt="OnSched"
          />
        </Navbar.Brand>
  
        <Navbar.Toggle
          onClick={this.toggleSideNav}
          aria-controls="basic-navbar-nav"
          className={sideNavActive ? 'active' : ''}
        >
          <img src="/icons/ellipsis.svg" alt="" />
        </Navbar.Toggle>

        <div className="MainNav">
          {/* <OnSchedGlobalSearch selectingLocations={selectingLocations} /> */}

          <SelectedLocation
            locations={recents}
            loadLocation={this.loadLocation}
            selectingLocations={selectingLocations}
            handleSelectingLocations={this.handleSelectingLocations}
          />
        </div>
      </Navbar>
    )
  }
}

function mapStateToProps(state) {
  return {
    recents:          state.recentLocations.locations,
    selectedLocation: state.selectedLocation.selectedLocation,
    locationLoading:  state.selectedLocation.isLoading,
    locationSuccess:  state.selectedLocation.success,
    locations:        state.locations,
    sideNav:          state.sideNav,
    searching:        state.searching.searching,
    compressed:       state.compressed.compressed
  }
}

export default connect(mapStateToProps, { 
  loadLocationById, 
  toggleSideNav, 
  stopSearch, 
  setRecentLocations
})(TopNav)
