import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { switchView } from "../actions/viewActions";

export const useView = (view, targetView) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (view !== targetView) {
      dispatch(switchView(targetView))
    }
  }, [view, targetView, dispatch])

}