import React                 from 'react'
import { useSelector }       from 'react-redux'
import { reduxForm } from 'redux-form'

import { editResourceAvailabilityForm } from '../../../../utils/initializeForm'
import { useUnsaved }                   from '../../../../hooks/useUnsaved'

import AvailabilityTab from '../../../AvailabilityTab/AvailabilityTab'
import EventsTab       from '../../../EventsTab/EventsTab'
import BottomOverlay   from '../../../BottomOverlay/BottomOverlay'
import useInitialize   from '../../../../hooks/useInitialize'
import Loading         from '../../../Loading/Loading'

import submit          from './submit'
import RecurringSwitch from '../../RecurringSwitch/RecurringSwitch'
import TimeOffCard from '../../../AvailabilityTab/TimeOffCard/TimeOffCard'

const ResourceAvailability = ({ initialize, form, handleSubmit, pristine }) => {

  const { selectedResource, isLoading } = useSelector(state => state.selectedResource)
  const { unsaved } = useSelector(state => state.unsaved)

  useInitialize(selectedResource, editResourceAvailabilityForm, initialize)
  useUnsaved(pristine)
  
  return (
    isLoading ? <Loading /> :
    <form onSubmit={handleSubmit}>
      <BottomOverlay active={unsaved}/>
      <RecurringSwitch />
      <EventsTab form={form} display={!selectedResource?.recurringAvailability}/> 
      <AvailabilityTab form={form} display={selectedResource?.recurringAvailability} isResource />
      <TimeOffCard form={form} />
    </form>
  )
}

export default reduxForm({
  form: 'resource-availability',
  onSubmit: submit
})(ResourceAvailability)
