import React, { Component }      from 'react'
import { connect }               from 'react-redux'
import { reduxForm, FieldArray } from 'redux-form'

import Header             from '../../Header/Header'
import NewDomainList      from './NewDomainList/NewDomainList'
import ExistingDomainList from './ExistingDomainList/ExistingDomainList'
import Loading            from '../../Loading/Loading'
import { toggleUnsaved }  from '../../../actions/exitIntentActions'
import { loadDomains }    from '../../../actions/domainActions'

import './DomainChecking.css'


class DomainChecking extends Component {
  componentDidMount() {
    const { client, loadDomains, initialize } = this.props

    if (client && client.clientId) {
      loadDomains().then(domains => {
        const { clientDomains } = domains.payload.data
        initialize({clientDomains})
      })
    }
  }
  
  componentDidUpdate(prevProps) {
    const { client, loadDomains, initialize } = this.props
    
    if (prevProps.client !== client && 
      client && client.clientId
      ) {
      loadDomains().then(domains => {
        const { clientDomains } = domains.payload.data
        initialize({clientDomains})
      })
    }
  }

  handleSubmit = () => {}
  
  render() {
    const { handleSubmit, existing } = this.props

    if (!existing)
      return <Loading />

    return (
      <form className="DomainChecking" onSubmit={handleSubmit(this.handleSubmit)}>
        <Header title="OnSchedJS Domains" hide />
        <p>The OnSchedJS booking widget is secured by domains so that it can not be duplicated on other sites. In order to share the OnSchedJS booking widget on your website or in your application you must add the domain to the list below.</p>
      
        <FieldArray name="clientDomains" component={ExistingDomainList} />
        <FieldArray name="domains" component={NewDomainList} />
      </form>
    );
  }
}

const mapStateToProps = state => ({
  client:   state.clientCredentials.clientCredentials,
  existing: state.domains.domains
})

const connectedDomainChecking = connect(
  mapStateToProps, 
  { toggleUnsaved,
    loadDomains
  })(DomainChecking)

export default reduxForm({form: 'domain-list'})(connectedDomainChecking)
