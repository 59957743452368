import React                from 'react'
import { useSelector }      from 'react-redux'
import { Col, Row }         from 'react-bootstrap'
import { reduxForm }        from 'redux-form'

import { resourceForm }     from '../../../../utils/formFields'
import { editResourceForm } from '../../../../utils/initializeForm'
import { validateResource } from '../../../../utils/validate'
import { useUnsaved }       from '../../../../hooks/useUnsaved'
import useInitialize        from '../../../../hooks/useInitialize'
import useValidForm         from '../../../../hooks/useValidForm'
import FormFields           from '../../../FormFields/FormFields'
import UploadImage          from '../../../UploadImage/UploadImage'
import Loading              from '../../../Loading/Loading'
import BottomOverlay        from '../../../BottomOverlay/BottomOverlay'
import CalendarSync         from './CalendarSync/CalendarSync'
import submit               from './submit'

import './ResourceProfile.css'

const ResourceProfile = ({ initialize, form, handleSubmit, pristine, valid }) => {
  const { selectedResource, isLoading  } = useSelector(state => state.selectedResource)
  const { unsaved } =  useSelector(state => state.unsaved)

  useInitialize(selectedResource, editResourceForm, initialize)
  useUnsaved(pristine)
  useValidForm(valid)

  return (
    isLoading ? <Loading /> :
      <form onSubmit={handleSubmit}>
        <Row className='form-image-container'>
          <Col md={7}>
            <FormFields form={form} fields={resourceForm[0]} />
            <FormFields form={form} fields={resourceForm[1]} />
          </Col>
          <Col md={5}>
            <UploadImage form={form} img={selectedResource?.imageUrl} />
            <FormFields form={form} fields={resourceForm[2]} />
          </Col>
        </Row>
        
        <BottomOverlay active={unsaved}/>
  
        <CalendarSync form={form} />
         
      </form>
  )
}

export default reduxForm({
  form: 'resource-profile', 
  validate: validateResource, 
  onSubmit: submit 
})(ResourceProfile)
