import { handle } from 'redux-pack'
import { TOGGLE_LOCATION_SCOPE } from '../actions/locationActions'

const INITIAL_STATE = { success: false }

export const toggleLocationScopeReducer = ( state = INITIAL_STATE, action ) => {
    const { type, payload } = action
    switch ( type ) {
      
      case TOGGLE_LOCATION_SCOPE:
        return handle( state, action, {
          start:   previousState => ( { ...previousState, isLoading: true, locationScopeError: null, locationScope: null } ),
          finish:  previousState => ( { ...previousState, isLoading: false } ),
          failure: previousState => ( { ...previousState, resourceRecurrinAvailError: payload } ),
          success: previousState => ( { ...previousState, success: true, locationScope: payload.data } )
        })
  
      default:
        return state
    }
  }