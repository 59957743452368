import React, { useEffect, useState } from 'react'
import { Alert, Row }                 from 'react-bootstrap'
import { useDispatch, useSelector }   from 'react-redux'

import { unlinkCalendarandLoadRes }   from '../../../../../actions/resourceActions'
import { resourceForm }               from '../../../../../utils/formFields'
import FormFields                     from '../../../../FormFields/FormFields'

import './CalendarSync.css'

const CalendarSync = ({ form }) => {
  const { selectedResource } = useSelector(state => state.selectedResource)

  const [ calendar, setCalendar ] = useState('none')

  const dispatch = useDispatch()

  useEffect(() => {
    if (selectedResource?.googleCalendarAuthorized) {
      setCalendar('google')
    }
    else if (selectedResource?.outlookCalendarAuthorized) {
      setCalendar('outlook')
    }
  }, [])

  const enumCalendar = {
    google:  <p> Your calendar is synced with the Google calendar of: <b>{selectedResource?.googleCalendarId}</b><i class="fad fa-check"></i></p>,
    outlook: <p> Your calendar is synced with the Outlook calendar of: <b>{selectedResource?.outlookCalendarId}</b><i class="fad fa-check"></i></p>,
    none:    <p> No calendar is synchronized            <i class="fad fa-times"></i></p>,
  }

  const handleUnlink = () => {
    dispatch(unlinkCalendarandLoadRes(selectedResource?.id))
  }

  return (
    <>
      <h5>Calendar Sync</h5>
      <Alert variant='light' className='sync-container'>
        
        { enumCalendar[calendar] }
        { calendar !== 'none' && 
            <button className='btn btn-logo-remove' onClick={handleUnlink} type='button'>Unlink</button>
        }

      </Alert>

      <Row className='form-sync-container' style={{ ...(calendar !== 'none' && { display: 'none' }) }}>
        <FormFields form={form} fields={resourceForm[3]} /> 
      </Row>
    </>
  )
}

export default CalendarSync
