import { SWITCH_VIEW, viewTypes } from '../actions/viewActions'

const INITIAL_STATE = {
  view:    viewTypes.get_started,
  sideNav: false
}

const switchView = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SWITCH_VIEW:
      return {
          id:      action.id,
          view:    action.view,
          sideNav: action.sideNav,
          edit:    action.edit
        }
    default:
      return state
  }
}

export default switchView