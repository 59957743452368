import React              from 'react'

import WorkWeekCard       from './WorkWeekCard/WorkWeekCard'
import TimeOffCard        from './TimeOffCard/TimeOffCard'

import './AvailabilityTab.css'

const AvailabilityTab = ({ form, display, isResource }) => {
  let none = ""
  if(display === false) {
    none = "none"
  }

  return (
    <div className="AvailabilityTab" style={{display: `${none}`}}>
      <WorkWeekCard form={form} />
      {!isResource && <TimeOffCard form={form} /> }
    </div>
  )
}

export default AvailabilityTab