import { handle } from 'redux-pack'

import { SET_ACTIVE_COMPANY } from '../actions/companyActions'


const INITIAL_STATE = { success: false }


export default function( state = INITIAL_STATE, action ) {
  const { type, payload } = action
  switch ( type ) {
    
    case SET_ACTIVE_COMPANY:
      return handle( state, action, {
        start:   previousState => ( { ...previousState, isLoading: true, companyError: null, company: null } ),
        finish:  previousState => ( { ...previousState, isLoading: false } ),
        failure: previousState => ( { ...previousState, companyError: payload } ),
        success: previousState => ( { ...previousState, success: true, company: payload.data } )
      })

    default:
      return state

  }

}