import React                from 'react'
import { reduxForm, Field } from 'redux-form'
import { connect }          from 'react-redux'

import { renderField }      from '../../../utils/renderField'
import { updateSearchText } from '../../../actions/searchActions'

import './CompanySearch.css'


const CompanySearch = ({handleSubmit, updateSearchText}) => (
  <form onSubmit={handleSubmit} className="CompanySearch">
    <button type="submit" className="searchIcon">
      <i className="fad fa-search" />
    </button>
    <Field 
      name="searchText" 
      component={renderField}
      customClass='fullWidth'
      placeholder='Search for complete or partial company name or GUID'
      onChange={e => updateSearchText(e.target.value)}
      autoFocus
    />
  </form>
)

export default connect(null, { updateSearchText })(reduxForm({form: 'company-search'})(CompanySearch))