import { handle } from 'redux-pack'
import { ADD_RESOURCE_BLOCK } from '../actions/resourceActions'

const INITIAL_STATE = { success: false }

export default function addResourceBlockReducer( state = INITIAL_STATE, action ) {
  const { type, payload } = action
  switch ( type ) {
  case ADD_RESOURCE_BLOCK:
    return handle( state, action, {
      start:   previousState => ( { ...previousState, isLoading: true, resourceBlockError: null, success: false } ),
      finish:  previousState => ( { ...previousState, isLoading: false } ),
      failure: previousState => ( { ...previousState, resourceBlockError: payload } ),
      success: previousState => ( { ...previousState, success: true, resourceBlock: payload.data } )
    })
  default:
    return state
  }
}
