export const handleAutosize = content => {
  setTimeout(() => {
    var codeArea = document.getElementById('codeArea')
    
    if (codeArea && content) {
      const rows = content.split(/\r\n|\r|\n/).length
      
      let charPerRow = codeArea.clientWidth/8.4
      let excessContent = content.length - (charPerRow * rows)
      let extraRows = Math.ceil(excessContent / charPerRow)
      if (extraRows < 0)
        extraRows = extraRows* -1
    
      codeArea.style.cssText = 'height:auto padding:0'
      codeArea.style.cssText = `min-height: ${(rows + extraRows) * 22 + 260}px`

      window.scrollTo(0, window.scrollY)
    }
  }, 1);
}