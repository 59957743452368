import React       from 'react'
import { connect } from 'react-redux'

import Loading from '../../../../Loading/Loading'

import './MasterTemplate.css'


const MasterTemplate = props => {
  const { selectedLocation, children, options } = props

  if (!selectedLocation)
    return <Loading />

  let contentRadius = '0px'
  if (!options.showHeaderPanel)
    contentRadius = '8px 8px 0 0'
  if (!options.showFooterPanel)
    contentRadius = '0 0 8px 8px'
  if (!options.showHeaderPanel && 
      !options.showFooterPanel)
    contentRadius = '8px'
    
  return (
    <table 
      border="0" 
      cellPadding="0" 
      cellSpacing="0" 
      className="MasterTemplate email-body"
      style={{
        color: options.emailColor
      }}
    >
      
      <style dangerouslySetInnerHTML={{__html: `
        .email-footer a { color: ${options.contentLinkColor} }
        .email-panel a { color: ${options.panelLinkColor} }
      `}} />

      <tbody>
        <tr>
          <td>
            <table 
              align="center" 
              cellPadding="0" 
              cellSpacing="HeaderLogoPadding" 
              className={`email-logo top ${options.showHeaderLogo ? '' : 'hide'}`}
            >
              <tbody>
                <tr>
                  <td>
                    <img 
                      style={{
                        height: options.headerLogoHeight + 'px',
                        margin: options.headerLogoPadding + 'px'
                      }} 
                      src="https://cdn.public.onsched.com/content/assets/img/transpurple.png" 
                      alt="Company Logo" className="logo" 
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <table 
              align="center" 
              cellPadding="0" 
              cellSpacing="0" 
              style={{
                backgroundColor: options.panelBackgroundColor,
                color: options.panelColor
              }} 
              className={`email-panel top ${options.showHeaderPanel ? '' : 'hide'}`}
            >
              <tbody>
                <tr>
                  <td>
                    <h2 className="header">{selectedLocation.name}</h2>
                    <p className="header">{options.title}</p>
                  </td>
                </tr>
              </tbody>
            </table>
            <table 
              align="center" 
              cellPadding="0" 
              cellSpacing="0" 
              className="email-content"
              style={{ 
                borderRadius:    contentRadius, 
                backgroundColor: options.contentBackgroundColor,
                color:           options.contentColor
              }}
            >
              <tbody>
                <tr>
                  <td align="CenterEmailContent">
                    {children}
                  </td>
                </tr>
                <tr className={options.showContentPanel ? '' : 'hide'}>
                  <td className="separator"><hr /></td>
                </tr>
                <tr className={options.showContentPanel ? '' : 'hide'}>
                  <td align="CenterEmailContentPanel">
                    <section>
                      IMPORTANT - do not respond to this email. It is an unattended mailbox.
                    </section>
                  </td>
                </tr>
              </tbody>
            </table>               
            <table 
              align="center" 
              cellPadding="0" 
              cellSpacing="0" 
              style={{
                backgroundColor: options.panelBackgroundColor,
                color: options.panelColor
              }} 
              className={`email-panel bottom ${options.showFooterPanel ? '' : 'hide'}`}
            >
              <tbody>
                <tr>
                  <td className="contactUs" align="center" >
                    <div className="icons">
                      <img src="https://cdn.public.onsched.com/content/img/emailicon.png" height="40" alt="Email" />
                    </div>
                    <div className="contactInfo">
                      <h5>Email</h5>
                      <a href="mailto:BusinessEmail">BusinessEmail</a>
                    </div>
                  </td>
                  <td className="contactUs" align="center">
                    <div className="icons">
                        <img src="https://cdn.public.onsched.com/content/img/phoneicon.png" height="40" alt="Phone" />
                    </div>
                    <div className="contactInfo">
                      <h5>Phone</h5>
                      <a href="tel:BusinessPhone">BusinessPhone</a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <table align="center" cellPadding="0" cellSpacing="0" className="email-footer">
              <tbody>
                <tr>
                  <td align="CenterEmailFooter">
                    <footer>
                      <p>
                          You are receiving this email because you opted in on our website, or during your visit. Please click <a className="email-link" href="UnsubscribeUrl">here</a> to unsubscribe.
                      </p>
                      <p>
                        BusinessName, BusinessAddressLine1 BusinessCity, BusinessState BusinessPostalCode
                      </p>
                    </footer>
                  </td>
                </tr>
              </tbody>
            </table>
            <table 
              align="center" 
              cellPadding="0" 
              cellSpacing="FooterLogoPadding"
              className={`email-logo bottom ${options.showFooterLogo ? '' : 'hide'}`}
              >
              <tbody>
                <tr>
                  <td align="center">
                      <img 
                        src="https://cdn.public.onsched.com/content/assets/img/transpurple.png" 
                        alt="Company Logo" 
                        className="logo" 
                        style={{
                          height: options.footerLogoHeight + 'px', 
                          margin: options.footerLogoPadding + 'px'
                        }} 
                      />
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

const mapStateToProps = state => ({
  selectedLocation: state.selectedLocation.selectedLocation,
  options:          state.emailPreview.options
})

export default connect(mapStateToProps, {})(MasterTemplate)
