import { LOAD_EXIT_INTENT, RESET_EXIT_INTENT, SET_VALID_FORM } from '../actions/exitIntentActions'

const defaultState = {
  actionType: false,
  destination: '',
  form: '',
  callback: false,
  valid: true
}

const exitIntentReducer = (state = defaultState, action) => {
  switch (action.type) {
    case LOAD_EXIT_INTENT:
      return {
          ...state,
          actionType: action.actionType,
          destination: action.destination,
          form: action.form,
          callback: action.callback,
        }
    case SET_VALID_FORM: 
      return {
        ...state, 
        valid: action.valid
      }
    case RESET_EXIT_INTENT:
      return {
        ...state,
        actionType: false,
        callback: false
      }
    default:
      return state
  }
}

export default exitIntentReducer