import React, 
       { useEffect, 
         useState }    from 'react'
import { FormCheck }   from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { reduxForm }   from 'redux-form'

import BottomOverlay from '../../../BottomOverlay/BottomOverlay'
import FormFields    from '../../../FormFields/FormFields'
import Loading       from '../../../Loading/Loading'

import { useUnsaved }                from '../../../../hooks/useUnsaved'
import { locationCapacityFieldsForm, 
         LocationSettingsForm }      from '../../../../utils/formFields'
import { editLocationSettingsForm }  from '../../../../utils/initializeForm'
import { loadCalendarById }          from '../../../../actions/calendarActions'
import { toggleError }               from '../../../../actions/toastActions'
import { toggleLocationScopeAndLoad } from '../../../../actions/locationActions'

import submit from './submit'


const LocationSettings = ({ handleSubmit, initialize, form, pristine, change }) => {
  const { selectedLocation, isLoading } = useSelector(state => state.selectedLocation)
  const { calendar, isLoading: calendarLoading } = useSelector(state => state.calendar)
  const { unsaved } = useSelector(state => state.unsaved)

  useUnsaved(pristine)
  const dispatch = useDispatch()

  // Disable when is not a primary location and is company scope
  const disableSettings =  false === selectedLocation?.primaryBusiness && 
                           true === !!selectedLocation?.settings.companyId


  useEffect(() => {
    const loadCalendar = async () => {
      try {
        const { payload } = await dispatch(loadCalendarById(selectedLocation?.primaryCalendarId))
        if (payload.status !== 200) {
          dispatch(toggleError(payload))
        }
      } catch (e) {
        console.error(e)
      }
    }

    if((selectedLocation && 
       (!calendar || calendar.id !== selectedLocation?.primaryCalendarId)) && 
        !calendarLoading) {
      loadCalendar()
    }
  }, [calendar, selectedLocation])

  useEffect(() => {
    if (calendar && selectedLocation) {
      initialize(editLocationSettingsForm(selectedLocation, calendar))
    }
  }, [calendar, selectedLocation, initialize, editLocationSettingsForm])

  const handleSettingsChange = () => {
    dispatch(toggleLocationScopeAndLoad(selectedLocation?.id, !!selectedLocation?.settings.companyId))
  }

  return (
    isLoading || calendarLoading ? <Loading /> :
    <form onSubmit={handleSubmit} className='mb-5'>
      <BottomOverlay active={unsaved} />
      <FormFields form={form} fields={locationCapacityFieldsForm} />
      <FormFields form={form} fields={LocationSettingsForm.slice(1, 2)} />


      <h5 className='subtitle'> Online Booking Settings</h5>
      { 
        selectedLocation?.primaryBusiness ? (
          // Primary company
          <div className='paragraph'>
            <p> These values will be used by any of your locations that currently use Company Scoped settings.</p>
            <p> Only the fields that are listed below are company scoped. </p>
          </div>
        ) : (

          // Secondary Location
          <>
            
            { disableSettings ?  (
                // Company Scope
                <div className='paragraph'>
                 <p>Some settings are managed by the Primary Location to instill a booking flow continuity throughout their locations/organization. The fields are listed below. </p>
                  <p>This location is currently using company scope settings defined by the primary location.</p>
                  <p>Toggling “Use Location Scope” will generate your own location based settings.</p>
                </div>

              ) : (

                // Location scope
                <div className='paragraph'>
                  <p>This location is using the location scoped settings that are defined below, thereby ignoring company settings.</p>
                </div>
              )
            }

            <FormCheck 
              className='text my-2' 
              type="switch" 
              id="enableSettingsCheckbox" 
              label="Use Location Scope Settings"
              checked={!disableSettings}
              onChange={handleSettingsChange}
            />
          </>
        )              
      }
      
      <FormFields disabled={disableSettings} form={form} fields={LocationSettingsForm.slice(0, 1)} />

    </form>
  )
}

export default reduxForm({
  form: 'location-settings',
  onSubmit: submit
})(LocationSettings)