import React  from 'react'

import './Header.css'


const Header = ({title, action, disabled, btntype, customClass, cta, hide}) => {
  return (
    <div className="Header">
      <h4>{ title }</h4>

        {
          !hide &&
          <button 
            className={`btn btn-primary ${customClass}`}
            disabled={disabled}
            btntype={btntype}
            onClick={action}
          >
            { cta }
          </button>
        }
    </div>
  )
}

export default Header