import React, { Component } from 'react'
import { connect }          from 'react-redux'
import { reduxForm }        from 'redux-form'
import { Base64 }           from 'js-base64'
import querystring          from 'querystring'

import { loadEmailTemplate,
         loadMasterTemplate,  
         updateEmailPreview,
         updateEmailTemplate,
         updateMasterTemplate }  from '../../../actions/notificationActions'
import { switchView, viewTypes } from '../../../actions/viewActions'
import { toggleUnsaved }         from '../../../actions/exitIntentActions'
import { toggleToast, 
         toastMessages,
         toggleError }           from '../../../actions/toastActions'
import { en }                    from '../../../utils/languages'
import Header                    from '../../Header/Header'
import Loading                   from '../../Loading/Loading'
import CodeEditor                from './CodeEditor/CodeEditor'
import CodePreview               from './CodePreview/CodePreview'

import './EmailTemplate.css'


class EmailTemplate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      HTML: "",
      master: {}
    }
  }

  componentDidMount() {
    const { switchView, selectedLocation } = this.props
    
    switchView(viewTypes.notifications)
   
    if (selectedLocation) {
      this.loadTemplateAndMaster()
    }
  }
    
  componentDidUpdate(prevProps) {
    const { selectedLocation, dirty, toggleUnsaved } = this.props

    if (prevProps.selectedLocation !== selectedLocation && selectedLocation) {
      this.loadTemplateAndMaster()
    }

    if (prevProps.dirty !== dirty) {
      toggleUnsaved(dirty)
    }
  }

  updateCode = HTML => {
    this.setState({ HTML })
  }
  
  loadTemplateAndMaster = () => {
    const { initialize,
            updateEmailPreview,
            loadEmailTemplate,
            loadMasterTemplate,
            selectedLocation,
            location: { search },
            match: { params },
            toggleError
          } = this.props

    let queryParams = {}

    if (search)
      queryParams = querystring.parse( search.replace(/\?/g, "") )
    
    loadEmailTemplate(
      selectedLocation.id, 
      params.id, 
      queryParams
    )
    .then(template => {
      if (template.payload.status === 200) {
        let init = {}
        
        loadMasterTemplate(selectedLocation.id)
          .then(master => {
            if (master.payload.status === 200) {
              init = master.payload.data
              let titleAndCode = template.payload.data
                                  .split("<title>")[1]
                                  .split("</title>")
              
              init.title = titleAndCode[0]
              init.HTML = titleAndCode[1]

              initialize(init)
              updateEmailPreview(init)
              
              this.setState({ 
                HTML: titleAndCode[1],
                master: master.payload.data
              })
            }
            else
              toggleError(master.payload)
            })
        }
        else
          toggleError(template.payload)
    })
  }

  handleSubmit = values => {
    const { match, 
            toggleToast,
            toggleError,
            updateEmailTemplate, 
            updateMasterTemplate,
            selectedLocation,
            history
          } = this.props

    let HTML = `<title>${values.title}</title> ${values.HTML}`
    let templateContent = Base64.encode(HTML)
    let templateName = match.params.id
    
    updateMasterTemplate(selectedLocation.id, values)
      .then(master => {
        if (master.payload.status === 200) {
          updateEmailTemplate(
            selectedLocation.id, 
            { templateName, templateContent }
          )
            .then(template => {
              if (template.payload.status === 200) {
                toggleToast(toastMessages.save_email, template.payload)
                history.push('/Notifications')
              }
              else
                toggleError(template.payload)
            })
        }
        else
          toggleError(master.payload)
      })
  }

  render() {
    const { HTML, title, master } = this.state
    const { loading1, 
            loading2, 
            loading3, 
            loading4 
          } = this.props

    const { match: { params },
            handleSubmit, 
            eTemplate,
            dirty
          } = this.props

    if (!eTemplate || loading1 || 
        loading2 || loading3 || loading4
    ) {
      return <Loading />
    }

    let templateName = params.id.match(/[A-Z][a-z]+/g).join(' ')

    return (
      <form onSubmit={handleSubmit(this.handleSubmit.bind(this))} className="EmailTemplate">
        <Header 
          title={en.emailTemplates.header}
          cta="Save" 
          btntype="submit" 
          disabled={!dirty}
        />
        <div className="template-editor">
          <CodeEditor 
            updateCode={this.updateCode} 
            templateName={templateName} 
            HTML={HTML}
            title={title}
          />
          <CodePreview HTML={HTML} master={master} />
        </div>
      </form>
    )
  }
}

const mapStateToProps = state => ({
  loading1:         state.updatedEmailTemplate.isLoading,
  loading2:         state.updatedMaster.isLoading,
  loading3:         state.eTemplate.isLoading,
  loading4:         state.masterTemplate.isLoading,
  eTemplate:        state.eTemplate.eTemplate,
  masterTemplate:   state.masterTemplate.masterTemplate,
  selectedLocation: state.selectedLocation.selectedLocation,
})

export default reduxForm({form: 'master-email-template'})(
  connect(
    mapStateToProps, 
    { loadEmailTemplate,
      loadMasterTemplate, 
      updateEmailPreview, 
      switchView,
      updateEmailTemplate,
      updateMasterTemplate,
      toggleToast,
      toggleError,
      toggleUnsaved
  })(EmailTemplate))
