import { handle } from 'redux-pack'

import { REPORT_ERROR } from '../actions/toastActions'


const INITIAL_STATE = { success: false }


export default function( state = INITIAL_STATE, action ) {
  const { type, payload } = action
  switch ( type ) {
    
    case REPORT_ERROR:
      return handle( state, action, {
        start:   previousState => ( { ...previousState, isLoading: true, errorError: null, error: null } ),
        finish:  previousState => ( { ...previousState, isLoading: false } ),
        failure: previousState => ( { ...previousState, errorError: payload } ),
        success: previousState => ( { ...previousState, success: true, error: payload.data.error } )
      })

    default:
      return state

  }

}
