import React, { Component } from 'react'
import TemplateHeaderSwitch from './TemplateHeaderSwitch/TemplateHeaderSwitch'

import './MasterTemplateHeader.css'


const MasterTemplateHeader = ({switches, title}) => {
  return (
    <div className="MasterTemplateHeader">
      <h5>{title}</h5>

      {switches.map((displaySwitch, i) => (
        <TemplateHeaderSwitch 
          key={i} 
          title={title}
          displaySwitch={displaySwitch}
        />
      ))}
    </div>
  )
}

export default MasterTemplateHeader