import React                from 'react'
import { reduxForm, Field } from 'redux-form'

import { validateForgotPw } from '../../../utils/validate'

import { renderField }      from '../../../utils/renderField'
import FormFailedMessage    from '../../FormFailedMessage/FailedMessage'

import './ForgotPasswordForm.css'

const ForgotPasswordForm = ({ handleSubmit, failed }) => {
  return (
    <form onSubmit={handleSubmit} className="ForgotPasswordForm">
      <h3>Forgot password</h3>
      { failed && <FormFailedMessage /> }

      <Field 
        name="email"
        label="Email"
        component={renderField}
        placeholder="doc@brown.bsu"
      />
      <button type="submit" className="btn btn-primary">Reset password</button>
    </form>
  )
}

export default reduxForm({ 
  form: 'forgot-password', 
  validate: validateForgotPw 
})(ForgotPasswordForm)