import { MOD_TYPE } from '../actions/viewActions'


const modTypeReducer = (state = { modType: null }, action) => {
  switch (action.type) {
    case MOD_TYPE:
      return {
          modType: action.modType
        }
    default:
      return state
  }
}

export default modTypeReducer
