import React                from 'react'
import { connect }          from 'react-redux'
import { Nav }              from 'react-bootstrap'
import { Link }             from 'react-router-dom'
import { useLocation }      from 'react-router'

import { toggleExitIntent } from '../../actions/exitIntentActions'
import { switchView, 
         viewTypes }        from '../../actions/viewActions'
import { toggleSideNav }    from '../../actions/sideNavActions'

import './SideNavItem.css'


const SideNavItem = props => {
  const location = useLocation()

  function exitIntent() {
    if (view === viewTypes.resources || 
        view === viewTypes.locations || 
        view === viewTypes.services) {
      props.toggleExitIntent(props.to, true, location.hash.substring(1))
    }
    else {
      props.toggleExitIntent(props.to, true)
    }
    props.toggleSideNav(false)
  }

  function switchView(eventKey) {
    props.switchView(eventKey, true)
    props.toggleSideNav(false)
  }

  const { view, unsaved, icon, eventKey, to, label, compressed } = props

  let showLabel = label
  if (compressed) {
    showLabel = null
  }

  if ( view === eventKey && !unsaved) {
    return (
      <Nav.Item onClick={() => props.toggleSideNav(false)} className="SideNavItem active">
        <Link
          to={to}
          >
          <i className={icon} /> {showLabel}
        </Link>
    </Nav.Item>
    )
  }

  if ( view === eventKey && unsaved ) {
    return (
      <Nav.Item className="SideNavItem active">
        <button
          onClick={() => exitIntent()}
        >
          <i className={icon} /> {showLabel}
        </button>
    </Nav.Item>
    )
  }

  if ( view !== eventKey && unsaved ) {
    return (
      <Nav.Item className="SideNavItem">
        <button
          onClick={() => exitIntent()}
        >
          <i className={icon} /> {showLabel}
        </button>
    </Nav.Item>
    )
  }
  
  return (
    <Nav.Item className="SideNavItem">
      <Link
        onClick={() => switchView(eventKey, true)}
        to={to}
      >
        <i className={icon} /> {showLabel}
      </Link>
    </Nav.Item>
  )
}

function mapStateToProps(state) {
  return {
    unsaved:    state.unsaved.unsaved,
    view:       state.view.view,
    compressed: state.compressed.compressed
  }
}

export default connect(mapStateToProps, { toggleExitIntent, switchView, toggleSideNav })(SideNavItem)