import React       from 'react'
import { connect } from 'react-redux'

import Loading  from '../../Loading/Loading'
// import Settings from './Settings/Settings'

import './CompanyCard.css'

const CompanyCard = ({company, primary, loading}) => {
  if (loading || !company)
    return <Loading />

  let logo = <div className="Logo"><i className="fad fa-city fa-2x" /></div>
  if (company.logo) {
    logo = <img src={company.logo} alt={company.name} />
  }
  else if (primary.imageUrl) {
    logo = <img src={primary.imageUrl} alt={company.name} />
  }

  return (
    <div className="CompanyCard">
      <div className="Card">
        <div className="LogoContainer">
          {logo}
        </div>
        
        <div className="CardContent">
          <h5>{company.name}</h5>
          <h5>{company.website}</h5>
          <p>
            {company.addressLine1}<br />
            {company.addressLine2}
            {company.addressLine2 && <br />}
            {company.city}, {company.state}, {company.country}
          </p>
        </div>
      </div>
      {/* <Settings /> */}
    </div>
  )
}

function mapStateToProps(state) {
  return {
    company: state.company.company,
    primary: state.primary.primary,
    loading: state.company.isLoading
  }
}

export default connect(mapStateToProps, {})(CompanyCard)
