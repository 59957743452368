import axios       from 'axios'
import querystring from 'querystring'

export const LOAD_USERS = 'LOAD_USERS'

export const loadUsers = ( queryParams = {} ) => {

  const config = {}

  const queryString = querystring.stringify( queryParams )

  const usersRoute = `/api/onsched/setup/businessusers` +
                                      `?${queryString}`

  return {
    type:    LOAD_USERS,
    promise: axios.get( usersRoute, config )
  }

}

export const LOAD_USER_BY_ID = 'LOAD_USER_BY_ID'

export const loadUserById = ( id, queryParams = {} ) => {

  const config = {}

  const queryString = querystring.stringify( queryParams )

  const userByIdRoute = `/api/onsched/setup/businessusers/${id}` +
                                                `?${queryString}`

  return {
    type:    LOAD_USER_BY_ID,
    promise: axios.get( userByIdRoute, config )
  }

}

export const ADD_USER = 'ADD_USER'

export const addUser = ( userData ) => {

  const config = {}
  
  const usersRoute = `/api/onsched/setup/businessusers`

  return {
    type:    ADD_USER,
    promise: axios.post( usersRoute, userData, config )
  }

}

export const UPDATE_USER = 'UPDATE_USER'

export const updateUser = ( id, userData ) => {

  const config = {}
  
  const usersRoute = `/api/onsched/setup/businessusers/${id}`

  return {
    type:    UPDATE_USER,
    promise: axios.put( usersRoute, userData, config )
  }

}

export const DELETE_USER = 'DELETE_USER'

export const deleteUser = ( id ) => {

  const config = {}
  
  const deleteUserRoute = `/api/onsched/setup/businessusers/${id}`

  return {
    type:    DELETE_USER,
    promise: axios.delete( deleteUserRoute, config )
  }

}

export const CLIENT_CREDENTIALS = 'CLIENT_CREDENTIALS'

export const clientCredentials = () => {

  const config = {}
  
  const clientCredentialsRoute = `/api/identity/client`

  return {
    type:    CLIENT_CREDENTIALS,
    promise: axios.get( clientCredentialsRoute, config )
  }

}

export const SET_ACTIVE_USER = 'SET_ACTIVE_USER'

export const setActiveUser = ( user ) => {
  let id = 0

  return {
    type: SET_ACTIVE_USER,
    id: id++,
    user: user
  }
}
