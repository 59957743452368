import React  from 'react'

import { en } from '../../../utils/languages'

import './DataListHeader.css'

const DataListHeader = ({itemTitles}) => {
  let items = null

  if ( itemTitles ) items = itemTitles.map((item, i) => (
    <th key={i} className="HeaderItem">{ item }</th>
  ))

  let nameTitle = null
  if (items)
    nameTitle = <th colSpan="2">{ en.contact.name }</th>
  
  if (!itemTitles)
    return null
    
  return (
    <tr className="DataListHeader">
      <th>ID</th>
      { nameTitle }
      { items }
    </tr>
  )
}

export default DataListHeader
