import React                       from 'react'
import { connect }                 from 'react-redux'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

import InitialsIcon                from '../../../InitialsIcon/InitialsIcon'
import { en }                      from '../../../../utils/languages'

import './SwitchLocations.css'

const SwitchLocations = props => {
  const { loading, success, selected } = props
  if ( loading || !success || !selected ) {
    return <div id="locationLoading" />
  }

  return (
    <div className="SwitchLocations">      
      <OverlayTrigger
        key={selected.id}
        placement="bottom"
        overlay={
          <Tooltip id={`tooltip-${selected.id}`}>
            {props.selectingLocations ? selected.name : en.topnav.switch_locations}
          </Tooltip>
        }
      >
        <div className={loading || !success ? 'hide' : ''}>
          <InitialsIcon onClick={() => props.handleSelectingLocations()} name={selected.name} img={selected.logo} />
        </div>
      </OverlayTrigger>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    selected: state.selectedLocation.selectedLocation,
    loading:  state.selectedLocation.isLoading,
    success:  state.selectedLocation.success
  }
}

export default connect( mapStateToProps, {} )(SwitchLocations)