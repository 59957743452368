import axios       from 'axios'
import querystring from 'querystring'


export const LOAD_COMPANIES = 'LOAD_COMPANIES'

export const loadCompanies = ( user, userParams = {} ) => {

  const config = {}
  const queryParams = querystring.stringify(userParams)
  
  const loadCompaniesRoute = `/api/users/${user}/companies?${queryParams}`

  return {
    type:    LOAD_COMPANIES,
    promise: axios.get( loadCompaniesRoute, config )
  }

}

export const SET_ACTIVE_COMPANY = 'SET_ACTIVE_COMPANY'

export const setActiveCompany = ( companyExternalId, companyName ) => {

  const config = {}
  const body = {
    companyExternalId,
    companyName
  }
  const setActiveCompanyRoute = `/api/companies/set_active_company${window.location.search}`

  return {
    type:    SET_ACTIVE_COMPANY,
    promise: axios.post( setActiveCompanyRoute, body, config )
  }

}

export const AUTHORIZE_API_DOCS = 'AUTHORIZE_API_DOCS'

export const authorizeApiDocs = client => {

  const config = {}
  const body = {
    client: {
      companyExternalId: client.companyExternalId,
      companyName: client.companyName,
      clientId: client.payload.data.clientId,
      clientSecrets: [{ description: client.payload.data.clientSecret1.secret }]
    }
  }
  
  const authorizeApiDocsRoute = `/api/companies/authorize_api_docs`

  return {
    type:    AUTHORIZE_API_DOCS,
    promise: axios.post( authorizeApiDocsRoute, body, config )
  }

}

export const LOAD_COMPANY = 'LOAD_COMPANY'

export const loadCompany = ( params = {} ) => {

  const config = {}
                 
  const queryString = querystring.stringify( params )
  
  const companyRoute = `/api/onsched/setup/companies` +
                                    `?${queryString}`

  return {
    type:    LOAD_COMPANY,
    promise: axios.get( companyRoute, config )
  }

}

export const UPDATE_COMPANY = 'UPDATE_COMPANY'

export const updateCompany = ( company ) => {
  const config = {}

  const companyRoute = `/api/onsched/setup/companies/`

  return {
    type:    UPDATE_COMPANY,
    promise: axios.put( companyRoute, company, config )
  } 
}


export const LOAD_COMPANY_TIMEZONES = 'LOAD_COMPANY_TIMEZONES'

export const loadCompanyTimezones = ( date = {} ) => {
  const config = {}
  
  const companyRoute = `/api/onsched/setup/companies/timezones/${date}`

  return {
    type:    LOAD_COMPANY_TIMEZONES,
    promise: axios.get( companyRoute, config )
  }
}
