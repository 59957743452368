import { handle } from 'redux-pack'

import { LOAD_ALLOCATIONS } from '../actions/serviceActions'


const INITIAL_STATE = { success: false }


export default function loadAllocationsReducer( state = INITIAL_STATE, action ) {
  const { type, payload } = action

  switch ( type ) {
    
  case LOAD_ALLOCATIONS:
    return handle( state, action, {
      start:   previousState => ( { ...previousState, isLoading: true, allocationsError: null, success: false } ),
      finish:  previousState => ( { ...previousState, isLoading: false } ),
      failure: previousState => ( { ...previousState, allocationsError: payload } ),
      success: previousState => ( { ...previousState, success: true, allocations: payload.data.data } )
    })

  default:
    return state

  }

}
