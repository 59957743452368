import { handle } from 'redux-pack'

import { LOAD_ALLOCATION } from '../actions/serviceActions'


const INITIAL_STATE = { success: false }


export default function loadAllocationReducer( state = INITIAL_STATE, action ) {
  const { type, payload } = action

  switch ( type ) {
    
  case LOAD_ALLOCATION:
    return handle( state, action, {
      start:   previousState => ( { ...previousState, isLoading: true, allocationError: null, success: false } ),
      finish:  previousState => ( { ...previousState, isLoading: false } ),
      failure: previousState => ( { ...previousState, allocationError: payload } ),
      success: previousState => ( { ...previousState, success: true, allocation: payload.data } )
    })

  default:
    return state

  }

}
