import { SET_TOKEN } from '../actions/authActions'

const INITIAL_STATE = {
  token: null
}

const setTokenReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_TOKEN:
      return {
          jwtToken: action.jwtToken
        }
    default:
      return state
  }
}

export default setTokenReducer