import React   from 'react'

import HelpTip from '../components/HelpTip/HelpTip'

export const renderField = (props) => {
  const { input, 
          label, 
          className, 
          type, 
          myId, 
          customClass,
          fieldValue, 
          defaultValue, 
          placeholder,
          iconId,
          maxLimit,
          tip, 
          disabled, 
          keyPress,
          autoComplete,
          autoFocus,
          meta : { touched, error }
        } = props

  let errorClass = ''
  let errorTip   = ''

  if ( touched && error ) {
    errorClass = 'error'
    errorTip = <HelpTip tip={error} error />
  }

  let labelDisplayed = null
  if (label) labelDisplayed = <label>{label}</label>

  let tooltip = null
  if (tip) tooltip = <HelpTip tip={tip} />

  let limit = null
  if (maxLimit) limit = maxLimit

  let checkbox = ''
  if (type === 'checkbox') checkbox = 'renderCheckbox'

  let icon = <i className={iconId}></i>
  if (iconId) icon = <i className="fad fa-dollar-sign"></i>
  
  if (fieldValue)
    return (
      <div className={`renderField ${customClass} ${className} ${errorClass} ${checkbox}`}>
        {labelDisplayed} {tooltip} {errorTip} {icon}
        
        <input 
          {...input} 
          type={type} 
          value={fieldValue}  
          maxLength={limit}
          autoFocus={autoFocus}
          id={myId} 
          placeholder={placeholder} 
          disabled={disabled}
          onKeyPress={keyPress}
        />
      </div>
    )
    
    return (
      <div className={`renderField ${customClass} ${className} ${errorClass} ${checkbox}`}>
      {labelDisplayed} {tooltip} {errorTip}{icon}
      
      <input 
        {...input} 
        type={type}  
        onKeyPress={keyPress}
        defaultValue={defaultValue} 
        maxLength={limit}
        id={myId} 
        placeholder={placeholder} 
        disabled={disabled}
        autoFocus={autoFocus}
        autoComplete={autoComplete}
      />
    </div>
  )
}

export const renderRadio = (props) => {
  const { input, label, options, customClass, meta: { touched, error } } = props

  let radios = options.map((option, i) => {
    let checked = false

    if (input.value === option.value) {
      checked = true
    }
  
    let errorTip   = ''
    let errorClass = ''

    if (checked && touched && error) {
      errorTip = <HelpTip tip={error} error />
      errorClass = 'error'
    }

    return (
      <label className={`input-radio ${errorClass}`} key={i}>
        <input 
          {...input} 
          name={input.name} 
          type="radio" 
          value={option.value} 
          checked={checked} 
          
        /> 
        {' '}
        {option.label}
        {errorTip}
      </label>
    )
  })  

  return (
    <div className={`renderField ${customClass}`}>
      <label>{label}</label>
      {radios}
    </div>
  )
}

export const renderCheckbox = (props) => {
  const { input, 
          label, 
          type, 
          value, 
          tip, 
          customClass, 
          meta : { touched,  error } 
        } = props

  let tooltip = null
  let errorClass = ''

  if (tip) {
    tooltip = <HelpTip tip={tip} />
  }

  if (error && touched) {
    tooltip = <HelpTip tip={error} error />
    errorClass = 'error'
  }

  return (
    <label className={`input-checkbox ${customClass} ${errorClass}`}>
      <input {...input} type={type} value={value} />
      {' '}
      {label} {tooltip}
    </label>
  )
}

export const renderSelect = (props) => {
  const { input, label, className, myId, tip, children, disabled } = props
  let tooltip = null
  if (tip) tooltip = <HelpTip tip={tip} />

  let invalid = false
  if (disabled) invalid = true

  return (
    <div className={`renderSelect ${className}`}>
      <label>{label}</label>{tooltip}
      <select {...input} disabled={invalid} id={myId}>{children}</select>
    </div>
  )
}

export const renderLabelField = (props) => {
  const { label, tip, customClass } = props
  let tooltip = null
  if (tip) tooltip = <HelpTip tip={tip} />

  return (
    <div className={`renderField ${customClass}`}>
      <label>{label}</label>{tooltip}
    </div>
  )
}
