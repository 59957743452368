import { useEffect } from 'react'

const useInitialize = (object, action, initialize) => {
  useEffect(() => {
    if (object) {
      initialize(action(object))
    }
  }, [object, initialize, action])
}

export default useInitialize