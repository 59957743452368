import { handle } from 'redux-pack'

import { LOAD_CALENDARS } from '../actions/calendarActions'


const INITIAL_STATE = { success: false }


export default function createServiceReducer( state = INITIAL_STATE, action ) {
  const { type, payload } = action

  switch ( type ) {
    
  case LOAD_CALENDARS:
    return handle( state, action, {
      start:   previousState => ( { ...previousState, isLoading: true, calendarsError: null, success: false } ),
      finish:  previousState => ( { ...previousState, isLoading: false } ),
      failure: previousState => ( { ...previousState, calendarsError: payload } ),
      success: previousState => ( { ...previousState, success: true, calendars: payload.data.data } )
    })

  default:
    return state

  }

}
