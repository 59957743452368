import React, { Component } from 'react'
import { connect }          from 'react-redux'

import { forgotPassword } from '../../actions/authActions'
import ForgotPasswordForm from './ForgotPasswordForm/ForgotPasswordForm'
import Loading            from '../Loading/Loading'

import './ForgotPassword.css'

class ForgotPassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      failed: false
    }
  }

  render() {
    const handleSubmit = values => {
      // Start loading
      this.setState({ loading: true })
      
      // Reset password
      this.props.forgotPassword(values.email)
      .then(resp => {
        // Stop loading
        this.setState({ loading: false })
        
        // Redirect to /VerifyPassword confirmation page
        if (resp.payload.status === 200) {
          this.setState({ loading: false })
          this.props.history.push('/VerifyPassword')
        }
        else if (resp.error) {
          if (resp.payload.response.status === 404) {
            this.setState({ failed: true })
          }
        }
      })
    }
  
    const { loading, failed } = this.state

    const signupLink = "https://signup.onsched.com/"
    const logo = 'https://cdn.public.onsched.com/content/assets/img/transpurple.png'

    if (loading) return <Loading />

    return (
      <div className="ForgotPassword">
        <div className="ForgotPasswordNav">
          <img src={logo} className="Logo" alt="OnSched" />
        </div>
  
        <ForgotPasswordForm 
          history={this.props.history} 
          onSubmit={handleSubmit} 
          failed={failed}
        />
        {/* <p>Don't have an account? <a href={signupLink}>Sign up</a> now</p> */}
      </div>
    )
  }
}

export default connect(null, { forgotPassword })(ForgotPassword)
