import { handle } from 'redux-pack'

import { LOAD_SERVICE_BLOCKS,
         DELETE_SERVICE_BLOCKS,
         ADD_SERVICE_BLOCK,
         LOAD_SERVICE_BLOCK,
         UPDATE_SERVICE_BLOCK } from '../actions/serviceActions'

const INITIAL_STATE = { success: false }

export const loadServiceBlocksReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action
  switch ( type ) {
    
    case LOAD_SERVICE_BLOCKS:
      return handle( state, action, {
        start:   previousState => ( { ...previousState, isLoading: true, serviceBlocksError: null, serviceBlocks: null } ),
        finish:  previousState => ( { ...previousState, isLoading: false } ),
        failure: previousState => ( { ...previousState, serviceBlocksError: payload } ),
        success: previousState => ( { ...previousState, success: true, serviceBlocks: payload.data } )
      })

    default:
      return state

  }
}

export const deleteServiceBlockReducer = ( state = INITIAL_STATE, action ) => {
    const { type, payload } = action
    switch ( type ) {
      
      case DELETE_SERVICE_BLOCKS:
        return handle( state, action, {
          start:   previousState => ( { ...previousState, isLoading: true, serviceBlockDeletedError: null, serviceBlockDeleted: null } ),
          finish:  previousState => ( { ...previousState, isLoading: false } ),
          failure: previousState => ( { ...previousState, serviceBlockDeletedError: payload } ),
          success: previousState => ( { ...previousState, success: true, serviceBlockDeleted: payload.data } )
        })
  
      default:
        return state
  
    }
  }

export const addServiceBlockReducer = ( state = INITIAL_STATE, action ) => {
    const { type, payload } = action
    switch ( type ) {
      
      case ADD_SERVICE_BLOCK:
        return handle( state, action, {
          start:   previousState => ( { ...previousState, isLoading: true, serviceBlockError: null, serviceBlock: null } ),
          finish:  previousState => ( { ...previousState, isLoading: false } ),
          failure: previousState => ( { ...previousState, serviceBlockError: payload } ),
          success: previousState => ( { ...previousState, success: true, serviceBlock: payload.data } )
        })
  
      default:
        return state
  
    }
  
  }

  export const loadServiceBlockReducer = ( state = INITIAL_STATE, action ) => {
    const { type, payload } = action
    switch ( type ) {
      
      case LOAD_SERVICE_BLOCK:
        return handle( state, action, {
          start:   previousState => ( { ...previousState, isLoading: true, serviceBlockError: null, serviceBlock: null } ),
          finish:  previousState => ( { ...previousState, isLoading: false } ),
          failure: previousState => ( { ...previousState, serviceBlockError: payload } ),
          success: previousState => ( { ...previousState, success: true, serviceBlock: payload.data } )
        })
  
      default:
        return state
  
    }
  
  }
  
  export const updateServiceBlockReducer = ( state = INITIAL_STATE, action ) => {
    const { type, payload } = action
    switch ( type ) {
      
      case UPDATE_SERVICE_BLOCK:
        return handle( state, action, {
          start:   previousState => ( { ...previousState, isLoading: true, updatedServiceBlockError: null, updatedServiceBlock: null } ),
          finish:  previousState => ( { ...previousState, isLoading: false } ),
          failure: previousState => ( { ...previousState, updatedServiceBlockError: payload } ),
          success: previousState => ( { ...previousState, success: true, updatedServiceBlock: payload.data } )
        })
  
      default:
        return state
  
    }
  
  }
  