import { SET_ACTIVE_USER, viewTypes } from '../actions/userActions'

const INITIAL_STATE = {
  user: null
}

const setActiveUserReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_ACTIVE_USER:
      return {
          id:   action.id,
          user: action.user
        }
    default:
      return state
  }
}

export default setActiveUserReducer