import { handle } from 'redux-pack'

import { LOAD_RESOURCE_BLOCK,
         UPDATE_RESOURCE_BLOCK } from '../actions/resourceActions'

const INITIAL_STATE = { success: false }

export const loadResourceBlockReducer = ( state = INITIAL_STATE, action ) => {
    const { type, payload } = action
    switch ( type ) {
      
      case LOAD_RESOURCE_BLOCK:
        return handle( state, action, {
          start:   previousState => ( { ...previousState, isLoading: true, resourceBlockError: null, resourceBlock: null } ),
          finish:  previousState => ( { ...previousState, isLoading: false } ),
          failure: previousState => ( { ...previousState, resourceBlockError: payload } ),
          success: previousState => ( { ...previousState, success: true, resourceBlock: payload.data } )
        })
  
      default:
        return state
    }
  }
  
  export const updateResourceBlockReducer = ( state = INITIAL_STATE, action ) => {
    const { type, payload } = action
    switch ( type ) {
      
      case UPDATE_RESOURCE_BLOCK:
        return handle( state, action, {
          start:   previousState => ( { ...previousState, isLoading: true, updatedResourceBlockError: null, updatedResourceBlock: null } ),
          finish:  previousState => ( { ...previousState, isLoading: false } ),
          failure: previousState => ( { ...previousState, updatedResourceBlockError: payload } ),
          success: previousState => ( { ...previousState, success: true, updatedResourceBlock: payload.data } )
        })
  
      default:
        return state
    }
  }
