import React               from 'react'
import { Col, Row }        from 'react-bootstrap'
import { useSelector }     from 'react-redux'
import { formValueSelector, reduxForm }       from 'redux-form'

import useInitialize       from '../../../../hooks/useInitialize'
import { useUnsaved }      from '../../../../hooks/useUnsaved'
import useValidForm        from '../../../../hooks/useValidForm'
import { serviceForm }     from '../../../../utils/formFields'
import { editServiceForm } from '../../../../utils/initializeForm'
import { validateService } from '../../../../utils/validate'
import FormFields          from '../../../FormFields/FormFields'
import UploadImage         from '../../../UploadImage/UploadImage'
import submit              from './submit'
import BottomOverlay       from '../../../BottomOverlay/BottomOverlay'
import Loading             from '../../../Loading/Loading'

import './ServiceProfile.css'
import { useEffect } from 'react'

const ServiceProfile = ({ initialize, form, pristine, handleSubmit, valid, change }) => {
  const { service, isLoading } = useSelector(state => state.service)
  const { unsaved } = useSelector(state => state.unsaved)

  const selector       = formValueSelector(form)
  const durationMin    = useSelector(state => selector(state, 'durationMin'))
  const durationMax    = useSelector(state => selector(state, 'durationMax'))
  const duration       = useSelector(state => selector(state, 'duration'))
  const durationSelect = useSelector(state => selector(state, 'durationSelect'))

  // change duration based on min or max
  useEffect(() => {
    if (durationSelect) {
      if(duration < durationMin) {
        change('duration', durationMin)
      }
      if(duration > durationMax) {
        change('duration', durationMax)
      }
    }
  }, [durationMin, durationMax, durationSelect])

  useInitialize(service, editServiceForm, initialize)
  useUnsaved(pristine)
  useValidForm(valid)

  return (
    isLoading ? <Loading /> :
    <form onSubmit={handleSubmit}> 
      <Row className='profile-content'>
        <Col md={6}>
          <FormFields form={form} fields={serviceForm.slice(0,3)} />
          <UploadImage form={form} img={service?.img} />
        </Col>
        <Col md={6}>
          <FormFields form={form} fields={serviceForm.slice(3)} />
        </Col>
      </Row>
      <BottomOverlay active={unsaved}/>
    </form>
  )
}

export default reduxForm({
  form: 'service-profile', 
  validate: validateService, 
  onSubmit: submit
})(ServiceProfile)