import React       from 'react'
import { connect } from 'react-redux'

import { zendeskReport } from '../../../../utils/zendesk'
import { detectEnv }     from '../../../../utils/detectEnv'
import { reportError, 
         toggleToast, 
         toastMessages } from '../../../../actions/toastActions'

import './ToastErrorActions.css'


const ToastErrorActions = props => {
  const { user,
          view,
          error, 
          service,
          company,
          loading,
          setActive, 
          reportError,
          toggleToast,
          selectedLocation,
          resource,
        } = props

  if (
    !error || 
    !error.error || 
    !error.error.response ||
    !company || 
    !selectedLocation ||
    !(user || 
      Boolean(
        localStorage.userFirstname && 
        localStorage.userLastname && 
        localStorage.userEmail
      )
    )
  ) {
    return null
  }

  // Detect environment for ticket
  let environment = 'sandbox__testing_area_'
  if (detectEnv() === 'PROD') {
    environment = 'production'
  }

  // Set user name as Requester
  var userName
  if (user) userName = `${user.given_name} ${user.family_name}`
  else      userName = `${localStorage.userFirstname} ${localStorage.userLastname}`

  let values = {
    userName:     user ? `${user.given_name}` : localStorage.userFirstname,
    userEmail:    user ? user.email : localStorage.userEmail,
    companyId:    company.id,
    locationId:   selectedLocation.id,
    resourceId:   resource ? resource.id : 'Not found',
    serviceId:    service ? service.id : 'Not found',
    errorDetails: error.error.response.data.errors,
    requestUrl:   error.error.response.config?.url,
    method:       error.error.response.config?.method,
    requestBody:  error.error.response.config?.data,
    customFields: [
      // Company
      { id: 360048101433, value: company.name }, 
      // Product
      { id: 360047075134, value: 'dashboard' }, 
      // Environment
      { id: 360047135313, value: environment }
    ]
  }
  
  // Hide Report button if error status is not 500
  var hideError = ''
  if (values.errorDetails?.filter(err => err.status !== 500).length) {
    hideError = 'hide'
  }

  // Disable and add spinner to Report button on load
  var loadingSubmit = ''
  if (loading) loadingSubmit = 'loading'
  
  // Report error actions
  const reportToZendesk = e => {
    e.currentTarget.className = 'loading'

    let report = zendeskReport(values, view)

    reportError(report).then(resp => {
      if (resp.payload.status === 200) {
        e.currentTarget.className = ''
        setActive(false)
        toggleToast(toastMessages.report_error)
      }
    })
  }

  return (
    <div className="ToastErrorActions">
      <button onClick={() => setActive(false)}>
        Ignore
      </button>

      <button 
        className={`${hideError} ${loadingSubmit}`} 
        disabled={loading}
        onClick={reportToZendesk}
      >
        Report
      </button>
    </div>
  )
}

const mapStateToProps = state => ({
  error:            state.error,
  user:             state.user.user,
  company:          state.company.company,
  selectedLocation: state.selectedLocation.selectedLocation,
  resource:         state.selectedResource.selectedResource,
  service:          state.service.service,
  view:             state.view.view,
  loading:          state.errorReported.isLoading
})

export default connect(mapStateToProps, { reportError, toggleToast })(ToastErrorActions)
