import { TOGGLE_CONFIRMATION_MODAL,
         ACCEPT_CONFIRMATION_MODAL,
         RESET_CONFIRMATION_MODAL } from '../actions/modalActions'

const INITIAL_STATE = {
    showModal: false,
    confirmed: false,
    modalData: {},
  }
  
  export const confirmModalReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case TOGGLE_CONFIRMATION_MODAL:
        return {
            id: action.id,
            showModal: action.showModal,
            modalData: action.modalData
          }
      case ACCEPT_CONFIRMATION_MODAL:
          return {
              ...state,
              confirmed: true
          }
      case RESET_CONFIRMATION_MODAL:
          return {
              showModal: false,
              confirmed: false,
              modalData: {}
          }
      default:
        return state
    }
  }
  
