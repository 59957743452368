import { handle } from 'redux-pack'

import { LOAD_COMPANY,
         UPDATE_COMPANY } from '../actions/companyActions'


const INITIAL_STATE = { success: false, company: {} }


export const loadCompanyReducer = ( state = INITIAL_STATE, action ) => {
  const { type, payload } = action
  switch ( type ) {

    case LOAD_COMPANY:
      return handle( state, action, {
        start:   previousState => ( { ...previousState, isLoading: true, companyError: null, company: null } ),
        finish:  previousState => ( { ...previousState, isLoading: false } ),
        failure: previousState => ( { ...previousState, companyError: payload } ),
        success: previousState => ( { ...previousState, success: true, company: payload.data } )
      })

    default:
      return state

  }

}

export const updateCompanyReducer = ( state = INITIAL_STATE, action ) => {
  const { type, payload } = action
  switch ( type ) {

    case UPDATE_COMPANY:
      return handle( state, action, {
        start:   previousState => ( { ...previousState, isLoading: true, updatedCompanyError: null, updatedCompany: null } ),
        finish:  previousState => ( { ...previousState, isLoading: false } ),
        failure: previousState => ( { ...previousState, updatedCompanyError: payload } ),
        success: previousState => ( { ...previousState, success: true, updatedCompany: payload.data } )
      })

    default:
      return state

  }

}