import React from 'react'

import InitialsIcon from '../../InitialsIcon/InitialsIcon'

import './UserInfo.css'


const UserInfo = ({name, email}) => {
  return (
    <div className="UserInfo">
      <InitialsIcon name={name} img="" />
      <h3>{ name }</h3>
      <h3>{ email }</h3>
    </div>
  )
}

export default UserInfo