import React from 'react'
import { connect } from 'react-redux'

import { Field, 
         FormSection, 
         formValueSelector, 
         reduxForm }        from 'redux-form'

import './FormFields.css'

const FormFields = props => {
  const { fields, dynamicValues, disabled } = props

  const dynamicVisibility = Object.keys(dynamicValues)
                                  .map(i => ({
                                      trigger: i, 
                                      match: dynamicValues[i]
                                  }))

  let formData = fields.map((data, idx) => {
    let renderFields = (fieldInput, index) => {
      let dynamicDisable = false
      if (fieldInput.dynamicVisibility) {
        fieldInput.dynamicVisibility.forEach(visibleCase => {
          let match = {trigger: `${fieldInput.name}_${visibleCase.trigger}`, match: visibleCase.match}
            dynamicDisable = !Boolean(dynamicVisibility.filter(a => a.trigger === match.trigger).length)
        })
      }

      let customClass = fieldInput.customClass
      if (dynamicDisable) {
        customClass += ' dynamically-disabled'
      }
      
      return (
        <Field 
          key={index}
          type={fieldInput.type}
          name={fieldInput.name}
          label={fieldInput.label}
          iconId={fieldInput.iconId}
          placeholder={fieldInput.placeholder}
          component={fieldInput.component}
          customClass={customClass}
          showError={fieldInput.showError}
          formName={props.form}
          tip={fieldInput.tip}
          options={fieldInput.options}
          defaultChecked={fieldInput.defaultChecked}
          autoFocus={fieldInput.autoFocus}
          disabled={dynamicDisable || disabled}
        >
          {fieldInput.options && fieldInput.options.map((option, optIdx) =>
            <option key={optIdx} value={option.value}>{option.label}</option>
          )}
        </Field>
      )
    }

    if (data.component === FormSection) {
      return (
        <FormSection component="span" className={data.customClass} key={idx} name={data.name}>
          {data.fields.map((sectionField, i) => 
            renderFields(sectionField, i)
          )}
        </FormSection>
      )
    }
    else return renderFields(data, idx)
  })

  return (
    <div className="FormFields">
      { formData }
    </div>
  )
}

const mapStateToProps = (state, props) => {
  let dynamicFields = props.fields.filter(field => field.dynamicVisibility)

  let dynamicSectionFields = props.fields.filter(sectField => sectField.component.displayName === 'FormSection')
  dynamicSectionFields = dynamicSectionFields.map(dSF => dSF.fields).flat().filter(field => field.dynamicVisibility)
  dynamicFields = dynamicFields.concat(dynamicSectionFields)

  let dynamicValues = {}
  
  dynamicFields.map(field => {
    field.dynamicVisibility.map(dynamic => {
      if (formValueSelector(props.form)(state, dynamic.trigger) === dynamic.match) {
        Object.assign(
          dynamicValues, 
          {[field.name + '_' + dynamic.trigger]: formValueSelector(props.form)(state, dynamic.trigger)}
        )
      }
    })
  })

  return { dynamicValues }
}

export default reduxForm()(connect(mapStateToProps, {})(FormFields))
