import React, { useEffect } from 'react'
import { connect, useDispatch }       from 'react-redux'
import { formValueSelector }          from 'redux-form'

import { en }                       from '../../utils/languages'
import { EmptyList }                from '../../utils/emptyList'
import { toggleCheckErrors }        from '../../utils/checkErrors'
import { loadResources,
         deleteResource }           from '../../actions/resourceActions'
import { switchView, 
        viewTypes }                 from '../../actions/viewActions'
import { setPage }                  from '../../actions/pageActions'
import { toggleCreateModal,
         resourceModalData,
         RESET_CONFIRMATION_MODAL } from '../../actions/modalActions'
import { resetSearchText }          from '../../actions/searchListActions'
import { toggleToast,
         toastMessages,
         toggleError }              from '../../actions/toastActions'  
import { useView }                  from '../../hooks/useView'
import { initialResourceSearchValues,
         resourceSearchFields }     from './resourcesSearchFields'
import Loading                      from '../Loading/Loading'
import Pages                        from '../Pages/Pages'
import Header                       from '../Header/Header'
import DataList                     from '../DataList/DataList'
import DataListItem                 from '../DataList/DataListItem/DataListItem'
import OnSchedSearch                from '../OnSchedSearch/OnSchedSearch'

import './Resources.css'

const Resources = props => {
    const {
        loadResources,
        selected,
        setPage,
        resources, 
        loading, 
        success, 
        toggleCreateModal,
        page,
        searchText,
        deleteResource,
        modalData,
        confirmed,
        toggleToast,
        toggleError,
        resetSearchText,
        view,
    } = props

    //constants
    const limit = 10
    const offset = page === 1 ? 0 : (page - 1) * 10

    const dispatch = useDispatch()
    useView(view, viewTypes.resources)

    useEffect(() => {
      resetSearchText()
      // eslint-disable-next-line
    }, [])

    useEffect(() => {
        setPage(1) 
      
        if (selected) {
          loadResources({ locationId: selected.id, limit })
            .then((response) => toggleCheckErrors(response, toggleError))
        }
    }, [ selected, setPage, loadResources, toggleError, limit ])


    useEffect(() => {

      if( confirmed && modalData ) {
        deleteResource(modalData.id)

          .then(resourceDeleted => {
            loadResources({ locationId: modalData.locationId, limit, offset})

            setPage(page)
            
            if ( resourceDeleted.payload.status === 200 ) {
              // Success
              toggleToast(toastMessages.delete_resource, resourceDeleted.payload)
            }
            // Failure
            else {
              toggleToast(toastMessages.error, resourceDeleted.payload)
            }
          })
  
          dispatch({ type: RESET_CONFIRMATION_MODAL })
      }
  
    }, [confirmed, modalData.id])

    const handleListAction = offset => {

        loadResources({locationId: selected.id, limit, offset, name: searchText})
    }
    
    let itemTitles = en.resources.itemTitles

    let list = []

    if (resources) list = resources.data.map((resource, i) => {
      let authorized = '--'
      let email      = '--'
      let timezone   = '--'
      let linkedServices = 'All'
      let Availability = resource.recurringAvailability ? 'Weekly' : 'Allocations'
      if (resource.googleCalendarAuthorized) authorized  = 'Google'
      if (resource.outlookCalendarAuthorized) authorized = 'Outlook'
      if (resource.email) email = resource.email
      if (resource.timezoneOffset) timezone = resource.timezoneOffset
      if (resource.services.length > 0) linkedServices = resource.services.length

      let data = {
        "img"   : resource.imageUrl,
        "name"  : resource.name,
        "id"    : resource.id,
        "cells" : [
          {
            "title" : itemTitles[0],
            "cell"  : email
          },
          {
            "title" : itemTitles[1],
            "cell"  : authorized
          },
          {
            "title" : itemTitles[2],
            "cell"  : linkedServices
          },
          {
            "title" : itemTitles[3],
            "cell"  : Availability
          },
        ]
      }
      
      return <DataListItem key={i} resource={resource} data={data} />
    })

    let dataList = <EmptyList type={en.resources.resource} />
    if ( resources && resources.total > 0 ) dataList = <DataList itemTitles={itemTitles} list={list} />
    
    return (
        <div className="Resources">
          <Header 
            title={en.resources.header} 
            cta={en.ctas.create}
            action={() => toggleCreateModal(resourceModalData, true)} 
          />
          <OnSchedSearch 
            object="resources" 
            action={loadResources}
            fields={resourceSearchFields}
            initialValues={initialResourceSearchValues}
            placeholder='Search by name or email'
          />
          
          {loading ? <Loading /> : (
              <>
                { dataList }
                <Pages 
                    total={resources.total}
                    isLoading={loading}
                    success={success}
                    listAction={handleListAction}
                />
              </>
          )}
        </div>
      )
}

const mapStateToProps = state => ({
    selected:   state.selectedLocation.selectedLocation,
    modal:      state.createModal,
    resources:  state.resources.resources,
    success:    state.resources.success,
    confirmed:  state.confirmModalReducer.confirmed,
    modalData:  state.confirmModalReducer.modalData,
    page:       state.page.page,
    view:       state.view.view,
    loading:    state.resources.isLoading ||
                !state.resources.resources ||
                state.resourceDeleted.isLoading ||
                !state.selectedLocation.selectedLocation,
    searchText: formValueSelector('onsched-search')(state, 'searchText')
  })
  
  export default connect( 
    mapStateToProps, 
    { loadResources, 
      switchView, 
      toggleCreateModal,
      setPage,
      deleteResource,
      toggleToast,
      toggleError,
      resetSearchText
    })(Resources)
  