import { UPDATE_EMAIL_PREVIEW } from '../actions/notificationActions'

const INITIAL_STATE = {
  options: {},
}

const updateEmailPreviewReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_EMAIL_PREVIEW:
      return {
          options: action.options
        }
    default:
      return state
  }
}

export default updateEmailPreviewReducer