import React, { useState }  from 'react'
import { Field, reduxForm } from 'redux-form'

import { signUpFields }     from '../../../utils/formFields'
import { validateUser }     from '../../../utils/validate'
import FormFields           from '../../FormFields/FormFields'

const SignUpForm = ({ handleSubmit, form, invalid, submitting }) => {
  const legal = 'https://onsched.com/support/index.html#legal'
  const [ terms, setTerms ] = useState(false)

  return (
    <form onSubmit={handleSubmit}>
        <h3 className='mb-3'> Sign Up </h3>
        <FormFields  fields={signUpFields} form={form} />
        <label className="terms">
            <Field 
              name="termsAndConditions"
              onChange={() => setTerms(!terms)}
              component="input"
              type="Checkbox"
              className='mt-3'
            />
            {' '} Click here to agree to <a target="_blank" href={legal}>OnSched's Terms &amp; Conditions</a>
          </label>
          <button 
            className="btn btn-primary next"
            type="submit"
            disabled={!terms || invalid || submitting}
          >
            Next
          </button>
      </form>
  )
}

export default reduxForm({
  form: 'signup', 
  validate: validateUser,
  forceUnregisterOnUnmount: true,
})(SignUpForm)