import React                 from 'react'
import { connect }           from 'react-redux'
import { formValueSelector } from 'redux-form'
import HelpTip from '../components/HelpTip/HelpTip'

//import { numbersByRegion }  from './renderPhoneMask'

// const renderPhoneField = country_code => {
//   let phoneMask = null
//   let placeholder = 'Phone number'

//   numbersByRegion.map(reg => {
//       if (reg.countries.includes(country_code)) {
//         phoneMask = reg.mask
//         placeholder = reg.placeholder
//       }
//     }
//   )

//     class renderCountryPhone extends Component {
//       constructor(props) {
//         super(props)
//         this.state = {
//           phoneHasNoValue : true,
//           formatting:       false,
//           number:           ''
//         }
//       }
    
//       handlePhoneValue = value => {
//         const phone = value.replace(/[^a-zA-Z0-9]/g, '')
        
//         if ( phone.length === 0 ) this.setState({ phoneHasNoValue: true })
//         if ( phone.length === 1 ) this.setState({ phoneHasNoValue : false })
//       }

//       handleFormatting = () => {
//         this.setState({ formatting: !this.state.formatting })
//       }

//       componentDidMount() {
//         if (this.props.fieldValue)
//           this.setState({ phoneHasNoValue: false })
//       }
    
//       componentDidUpdate(prevProps) {
//         if ( prevProps.input.value !== this.props.input.value && this.props.input.value.length === 10 ) this.setState({ phoneHasNoValue: false })
//       }
    
//       render() {
//         const { meta : { touched, error }, showError, country } = this.props
//         const { phoneHasNoValue, formatting } = this.state
//         console.log(country)
//         if (!formatting) {
//           phoneMask = null
//           placeholder = 'Phone number'
//         }

//         let field = <Field
//                       name="phone"
//                       component="input"
//                       placeholder={this.props.placeholder}
//                       type="tel"
//                       className={`renderPhoneMask ${this.state.phoneHasNoValue ? 'noValue' : ''}`}
//                       onInput={e => this.handlePhoneValue(e.target.value)}
//                       {...phoneMask}
//                     />
        
//         let empty = <Field
//                       name="phone"
//                       component="input"
//                       placeholder={placeholder}
//                       type="tel"
//                       className={`renderPhoneMask ${this.state.phoneHasNoValue ? 'noValue' : ''}`}
//                       onInput={e => this.handlePhoneValue(e.target.value)}
//                     />
    
//         let errorClass = ''
//         if ( touched && error ) errorClass = 'error'
        
//         return (
//           <div className={`renderField phoneField ${errorClass}`}>
//             <label>{this.props.label}</label>
//             { field }

//             {/* <div 
//               className="clear-formatting-icon"
//               onClick={() => this.handleFormatting()} 
//             >
//               <i className="fad fa-eraser" >
//                 <span>Clear formatting</span>
//               </i>  
//             </div> */}
//             {
//               touched && error && showError && 
//               <span className="error">{error}</span>
//             }
//           </div>
//         )
//       }
//     }
    
//     return renderCountryPhone
// }

const renderPhoneField = props => {
  const { input, placeholder, customClass, label, name, meta : { touched, error } } = props

  let errorClass = ''
  let errorTip = ''
  if ( touched && error ) {
    errorTip = <HelpTip tip={error} error /> 
    errorClass = 'error'
  }

  return (
    <div className={`renderField ${errorClass} ${customClass}`}>
      <label htmlFor={name}>{label}</label>
      {errorTip}
      <input {...input} placeholder={placeholder} />
    </div>
  )
}

const mapStateToProps = (state, props) => ({
  form:    props.formName,
  country: formValueSelector(props.formName)(state, 'country'),
})

export default connect(mapStateToProps, {})(renderPhoneField)