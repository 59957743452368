import React, { useEffect }     from 'react'
import { connect, useDispatch } from 'react-redux'

import { en }                         from '../../utils/languages'
import { toggleCheckErrors }          from '../../utils/checkErrors'
import { EmptyList }                  from '../../utils/emptyList'
import { toggleCreateModal,
         serviceModalData,
         RESET_CONFIRMATION_MODAL }   from '../../actions/modalActions'
import { loadServices,
         loadCompanyServices,
         deleteService,
         loadLinkedService,
         unlinkService, }             from '../../actions/serviceActions'
import { setPage }                    from '../../actions/pageActions'
import { viewTypes, 
         switchView }                 from '../../actions/viewActions'
import { toggleToast, 
         toastMessages, 
         toggleError }                from '../../actions/toastActions'         
import { initialServiceSearchValues, 
         servicesSearchFields }       from './servicesSearchFields'
import Header                         from '../Header/Header'
import Pages                          from '../Pages/Pages'
import Loading                        from '../Loading/Loading'
import DataList                       from '../DataList/DataList'
import DataListItem                   from '../DataList/DataListItem/DataListItem'
import OnSchedSearch                  from '../OnSchedSearch/OnSchedSearch'

import './Services.css'


const Services = props => {
  const {
    selected,
    loadServices,
    switchView,
    setPage,
    loading,
    success, 
    services,
    toggleCreateModal,
    primary,
    page,
    confirmed,
    modalData,
    deleteService,
    toggleToast,
    toggleError,
    history
  } = props
  
  const limit = 10
  const offset = page === 1 ? 0 : (page - 1) * 10
  
  const dispatch = useDispatch()

  // On first render and if the selected location is changed
  useEffect(()=> {
    switchView(viewTypes.services)
    setPage(1)
    if(selected){
      loadServices({ locationId: selected.id, limit })
        .then((response) => toggleCheckErrors(response, toggleError))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ selected ])
  
  

  // Delete when confirmation is passed
  useEffect(() => {
    if(confirmed && modalData.id){

      deleteService(modalData.id)
        .then(serviceDeleted => {
          // Success
          if ( serviceDeleted.payload.status === 200 ) {
            toggleToast(toastMessages.delete_service, serviceDeleted.payload)
            loadServices({ locationId: selected.id, offset, limit })
            // loadCompanyServices(primary, 0)
            setPage(page)
          }
          // Failure
          else {
            toggleToast(toastMessages.error, serviceDeleted.payload)
          }
        })

      dispatch({ type: RESET_CONFIRMATION_MODAL })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmed, modalData.id, selected, primary])

  const { itemTitles } = en.services
  
  let list = []

  if (services && selected) list = services.data.map((service, i) => {
    let scope = 'Company'
    if (service.locationId === selected.id) scope = 'Location'
    
    let serviceGroup = '--'
    if (service.serviceGroupName)
      serviceGroup = service.serviceGroupName

    let duration = service.duration
    if (service.durationSelect)
      duration = `${service.durationMin}-${service.durationMax}`

    let type = 'Appointment'
    if (service.type === "2")
      type = 'Event'

    let data = {
      img   : service.imageUrl,
      name  : service.name,
      id    : service.id,
      cells : [
        {
          title : itemTitles[0],
          cell  : `${duration} min`
        },
        {
          title : itemTitles[1],
          cell  : type
        },
        {
          title : itemTitles[2],
          cell  : scope
        },
      ]
    }

    return (
      <DataListItem key={i} resource={service} data={data} />
    )
  })

  let dataList = <EmptyList type={en.services.service} />
  if ( services && services.total > 0 ) dataList = <DataList itemTitles={itemTitles} list={list} />

  let total = 0
  if (services) total = services.total

return (
    <div className="Services">
      <Header 
        title={en.services.header} 
        cta={en.ctas.create}
        action={() => toggleCreateModal(serviceModalData, true)} 
      />
      <OnSchedSearch
        object='services' 
        action={loadServices} 
        fields={servicesSearchFields}
        placeholder='Search service name'
        history={history}
        initialValues={initialServiceSearchValues}
      />
      
      {loading ? <Loading /> : ( 
        <>
          {dataList}  
          <Pages 
           total={total}
           isLoading={loading}
           success={success}
           listAction={offset => loadServices({ locationId: selected.id, offset, limit })}
          />
        </>
        )}   
    </div>
  )
}

function mapStateToProps(state) {
return {
  success:    state.services.success,
  selected:   state.selectedLocation.selectedLocation,
  services:   state.services.services,
  primary:    state.primary.primary,
  confirmed:  state.confirmModalReducer.confirmed,
  modalData:  state.confirmModalReducer.modalData,
  page:       state.page.page,
  loading:    state.services.isLoading ||
              state.unlinked.isLoading ||
              state.locations.isLoading ||
              state.linkedLoaded.isLoading || 
              state.serviceDeleted.isLoading ||
              state.selectedLocation.isLoading ||
              state.updatedServiceAvail.isLoading ||
              !state.selectedLocation.selectedLocation
}
}

export default connect(
mapStateToProps, 
{ 
  loadServices, 
  switchView, 
  toggleCreateModal,
  setPage,
  deleteService,
  toggleToast,
  toggleError,
  loadCompanyServices,
  loadLinkedService,
  unlinkService
}
)(Services)
