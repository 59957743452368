import { handle } from 'redux-pack'

import { LOAD_USER_BY_ID } from '../actions/userActions'


const INITIAL_STATE = { success: false }


export default function( state = INITIAL_STATE, action ) {
  const { type, payload } = action
  switch ( type ) {
    
    case LOAD_USER_BY_ID:
      return handle( state, action, {
        start:   previousState => ( { ...previousState, isLoading: true, businessUserError: null, businessUser: null } ),
        finish:  previousState => ( { ...previousState, isLoading: false } ),
        failure: previousState => ( { ...previousState, businessUserError: payload } ),
        success: previousState => ( { ...previousState, success: true, businessUser: payload.data } )
      })

    default:
      return state

  }

}