import { handle } from 'redux-pack'

import { AUTHORIZE_API_DOCS } from '../actions/companyActions'


const INITIAL_STATE = { success: false }


export default function( state = INITIAL_STATE, action ) {
  const { type, payload } = action
  switch ( type ) {
    
    case AUTHORIZE_API_DOCS:
      return handle( state, action, {
        start:   previousState => ( { ...previousState, isLoading: true, referenceApiUrlError: null, referenceApiUrl: null } ),
        finish:  previousState => ( { ...previousState, isLoading: false } ),
        failure: previousState => ( { ...previousState, referenceApiUrlError: payload } ),
        success: previousState => ( { ...previousState, success: true, referenceApiUrl: payload.data } )
      })

    default:
      return state

  }

}