import React from 'react'

import Upload  from './Upload/Upload'

import './UploadImage.css'


const UploadImage = ({ form, img }) => {
  const object = form.split('-')[1]
  
  let title = object + ' image'
  if (object === 'location')
    title = 'Logo'

  return (
    <div className="UploadImage">
      <h5>{title}</h5>
      
      <Upload img={img} form={form} />
    </div>
  )
}

export default UploadImage
