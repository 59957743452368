import React, { Component } from 'react'
import { connect }          from 'react-redux'
import { Redirect }         from 'react-router-dom'

import Loading              from '../Loading/Loading'
import CompanySelectHeader  from './CompanySelectHeader/CompanySelectHeader'
import CompanySelectItem    from './CompanySelectItem/CompanySelectItem'
import DataList             from '../DataList/DataList'
import Pages                from '../Pages/Pages'
import { EmptyList }        from '../../utils/emptyList'
import { localSetCompany }  from '../../utils/localStorage'
import { en }               from '../../utils/languages'
import { toggleError }      from '../../actions/toastActions'
import { loadCompanies,
         setActiveCompany } from '../../actions/companyActions'
import { loadUsers }        from '../../actions/userActions'
import { setPage }          from '../../actions/pageActions'
import { setRecentLocations,
         loadLocationById } from '../../actions/locationActions'
import { updateSearchText } from '../../actions/searchActions'

import './CompanySelect.css'
import { redirectToDocumentation } from '../../utils/redirect'


class CompanySelect extends Component {
  constructor(props) {
    super(props)
    this.state = {
      autoSelect: false,
      redirectUrl: ''
    }
  }

  componentDidMount() {
    const { selectedLocation,
            setRecentLocations,
            loadLocationById,
            setPage,
            updateSearchText
          } = this.props

    this.renderCompanies(0, '')
    setRecentLocations([])
    setPage(1)
    updateSearchText('')
    
    if (selectedLocation) {
      // Clear selectedLocation
      loadLocationById(undefined, {}, true)
    }
  }  

  handleSearch = values => {
    this.renderCompanies(0, values.searchText)
    this.props.setPage(1)
  }

  checkCompanyRole = (companyId, redirectUrl, companyName) => {
    const { user, loadUsers, toggleError, setActiveCompany } = this.props
    const { users: { errors }} = en

    var userEmail
    if (user)
      userEmail = user.email
    else if (window.localStorage.userEmail)
      userEmail = window.localStorage.userEmail

    const err = msg => ({
      status: 400,
      response: {
        data: { errors: [{ detail: msg }] }
      }
    })

    setActiveCompany(companyId, companyName)
    .then(activeCompany => {
      if (activeCompany.payload.status === 200) {
        // Check if redirecting to documentation
        redirectToDocumentation(activeCompany.payload.data.readme)

        // Check if user is onsched support email
        if (userEmail.split('@')[1] !== 'onsched.com') {
          loadUsers({ email: userEmail })
          .then(businessUser => {
            if (businessUser.payload.status === 200) {
                // Find user role
                if (businessUser.payload.data.data.length) {
                  const { role } = businessUser.payload.data.data[0]

                  if (role === 'bizowner' ||
                      role === 'bizadmin'
                    ) {
                    this.setState({ 
                      autoSelect: true, 
                      redirectUrl
                    })
                  }
                  else
                    toggleError(err(errors.notPermitted))
                }
                else
                  toggleError(err(errors.noUser))
              }
            })
        }
      }
    })
  }

  renderCompanies = offset => {
    const { loadCompanies, user, location, searchText } = this.props
    var userEmail
    if (user)
      userEmail = user.email
    else if (window.localStorage.userEmail)
      userEmail = window.localStorage.userEmail

    if (userEmail) {
      loadCompanies(userEmail, {offset, limit: 10, searchText})
        .then(companies => {
          if (companies.payload.status === 200) {
            const { data } = companies.payload.data
            const { onschedCompanyId, onschedCompanyName, osDashRecentPage } = window.localStorage

            // To avoid change the count on changing page we just set if it is different
            if (localStorage.getItem('onschedCompanyCount') !== data?.length) {
              // Save companyCount to localStorage
              window.localStorage.setItem(
                'onschedCompanyCount',
                data.length
              )
            }
            
            // Autoselect company if no forceSelect
            if (!location.forceSelect) {
              // Check if only 1 company exists
              if (data.length === 1) {
                // Set only company as active
                this.checkCompanyRole(data[0].id, '/', data[0].name)
                localSetCompany(data[0])
              }
              // Check if companyId is stored locally
              else if (onschedCompanyId && onschedCompanyId.length) {
                // Check if user owns company stored
                let ownsCompany = data.filter(
                  company => company.id.includes(onschedCompanyId)).length
                  
                if (ownsCompany && osDashRecentPage) {
                  // Set local company as active, then redirect
                  // to page stored from history
                  this.checkCompanyRole(
                    onschedCompanyId, 
                    osDashRecentPage,
                    onschedCompanyName
                  )
                }
              }
            }
          }
          else
            toggleError(companies.payload)
        })
    }
  }
  
  render() {
    const { autoSelect, redirectUrl } = this.state
    const { history, 
            companies, 
            success,
            page, 
            loading1, 
            loading2,
            loading3
          } = this.props

    if ( loading1 || 
         loading2 || 
         loading3 || 
         !success 
       ) return <Loading />

    if (autoSelect && redirectUrl) return <Redirect to={redirectUrl} />

    let list = companies.data.map((company, i) => (
      <CompanySelectItem 
        key={i} 
        index={i} 
        company={company} 
        history={history} 
      />
    ))

    let dataList = <EmptyList type={en.companies.type} />
    if ( companies && companies.data.length > 0 ) {
      dataList = (
        <DataList list={list} />
      )
    }

    return (
      <div className="CompanySelect">
        <CompanySelectHeader 
          handleSearch={this.handleSearch}
        />

        { dataList }

        <Pages 
          total={companies.total}
          isLoading={loading1}
          success={success}
          listAction={this.renderCompanies}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user:             state.user.user,
  recents:          state.recentLocations.locations,
  selectedLocation: state.selectedLocation.selectedLocation, 
  companies:        state.companies.companies,
  loading1:         state.companies.isLoading,
  success:          state.companies.success,
  company:          state.activeCompany,
  loading2:         state.activeCompany.isLoading,
  loading3:         state.users.isLoading,
  page:             state.page.page,
  searchText:       state.searchText.searchText
})

export default connect(
  mapStateToProps, 
  {toggleError,  
    setRecentLocations, 
    loadLocationById,
    setActiveCompany,
    loadUsers,
    setPage,
    updateSearchText,
    loadCompanies
  })(CompanySelect)
