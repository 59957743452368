import React, { Component } from 'react'
import { connect }          from 'react-redux'

import { switchView, 
         viewTypes }               from '../../actions/viewActions'
import { loadEmailTemplates,
         deleteEmailTemplate }     from '../../actions/notificationActions'
import { toggleToast,
         toastMessages }           from '../../actions/toastActions'
import { toggleConfirmationModal } from '../../actions/modalActions.js'
import Header                      from '../Header/Header'
import DataList                    from '../DataList/DataList'
import DataListItem                from '../DataList/DataListItem/DataListItem'
import Loading                     from '../Loading/Loading'
import { en }                      from '../../utils/languages'

import './Notifications.css'

class Notifications extends Component {
  componentDidMount() {
    const { switchView, 
            selectedLocation, 
            loadEmailTemplates
          } = this.props

    switchView(viewTypes.notifications)

    if (selectedLocation)
      loadEmailTemplates(selectedLocation.id)
  }

  componentDidUpdate(prevProps) {
    const { selectedLocation, 
            loadEmailTemplates,
            confirmed,
            modalData,
            toggleConfirmationModal,
            deleteEmailTemplate,
            toggleToast
          } = this.props

    if (prevProps.selectedLocation !== selectedLocation && selectedLocation) {
      loadEmailTemplates(selectedLocation.id)
    }

    if (prevProps.confirmed !== confirmed) {
      if(confirmed && modalData){
        deleteEmailTemplate(selectedLocation.id, modalData.name)
          .then(eTemplate => {
            if (eTemplate.payload.status === 200) {
              loadEmailTemplates(selectedLocation.id)
              toggleToast(toastMessages.delete_email, eTemplate.payload)
            }
            else
              toggleToast(toastMessages.error, eTemplate.payload)
          })

        toggleConfirmationModal(false)
      }
    }
  }

  render() {
    const { eTemplates, deletedLoading } = this.props

    if (!eTemplates || deletedLoading)
      return <Loading />

    let list = eTemplates.filter(eTemplate => eTemplate.name !== "OnScheduleEmail")
                         .map((eTemplate, i) => {
      let data = {
        img: '',
        id: ++i,
        name: eTemplate.description,
        cells: [
          {
            title : en.emailTemplates.itemTitles[0],
            cell  : eTemplate.scope
          },
          {
            title : en.emailTemplates.itemTitles[1],
            cell  : eTemplate.customized ? <i className="far fa-check" /> : ''
          }
        ]
      }

      eTemplate.id = eTemplate.name

      let noDelete = true
      if (eTemplate.customized)
        noDelete = false

      return (
        <DataListItem 
          key={i} 
          resource={eTemplate} 
          data={data}
          noDelete={noDelete} 
        />
      )
    })

    return (
      <div className="Notifications">
        <Header title={en.notifications.header} hide={true} />
        <DataList itemTitles={en.emailTemplates.itemTitles} list={list} />  
      </div>
    )
  }
}

const mapStateToProps = state => ({
  selectedLocation: state.selectedLocation.selectedLocation,
  eTemplates:       state.eTemplates.eTemplates,
  deletedLoading:   state.eTemplateDeleted.isLoading,
  confirmed:        state.confirmModalReducer.confirmed,
  modalData:        state.confirmModalReducer.modalData,
})

export default connect(
  mapStateToProps, 
  { switchView, 
    loadEmailTemplates,
    deleteEmailTemplate,
    toggleToast,
    toggleConfirmationModal
})(Notifications)
