import React           from 'react'

import LocationIcons   from './LocationIcons/LocationIcons'
import SwitchLocations from './SwitchLocations/SwitchLocations'
import EditSelected    from './EditSelected/EditSelected'

import './SelectedLocation.css'


const SelectedLocation = props => {
  const { selectingLocations } = props

  return (
    <div className="SelectedLocation">
      <EditSelected
        selectingLocations={selectingLocations}
      />

      <LocationIcons
        selectingLocations={selectingLocations}
        loadLocation={props.loadLocation}
        locations={props.locations}
      />

      <SwitchLocations 
        selectingLocations={selectingLocations}
        handleSelectingLocations={props.handleSelectingLocations}
      />
    </div>
  )
}

export default SelectedLocation