import { SEARCHING } from '../actions/searchActions'

const INITIAL_STATE = { searching: false }

const searchingReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SEARCHING:
      return {
          id:        action.id,
          searching: action.searching
        }
    default:
      return state
  }
}

export default searchingReducer