import React                from 'react'
import { useDispatch, 
         useSelector }      from 'react-redux'
import { useHistory }       from 'react-router'
import { Tabs, Tab }        from 'react-bootstrap'

import BookableServices     from '../BookableServices/BookableServices'
import CustomFieldsTab      from '../../CustomFields/CustomFieldsTab.jsx/CustomFieldsTab'
import Header               from '../../Header/Header'
import ResourceAvailability from './ResourceAvailability/ResourceAvailability'
import ResourceProfile      from './ResourceProfile/ResourceProfile'
import { loadResourceById } from '../../../actions/resourceActions'
import { toggleExitIntent } from '../../../actions/exitIntentActions'
import { viewTypes }        from '../../../actions/viewActions'
import { useView }          from '../../../hooks/useView'
import { useTabParams }     from '../../../hooks/useTabParams'
import useLoadData          from '../../../hooks/useLoadData'

import './ModifyResource.css'

const ModifyResource = () => {
  const dispatch = useDispatch()
  const history  = useHistory()

  const { selectedResource: resource, isLoading } = useSelector(state => state.selectedResource)
  const { view }      = useSelector(state => state.view)
  const { unsaved }   = useSelector(state => state.unsaved)
  
  const [ tab, setTab ] = useTabParams('resource-profile')

  useView(view, viewTypes.resources)
  useLoadData(resource, loadResourceById, isLoading)
  
  const selectTabHandle = (key) => {
    if (unsaved) {
      dispatch(toggleExitIntent(history.location.pathname + `#${key}`, true, tab))
    }
    else {
      setTab(key)
      history.replace({ hash: `${key}` });
    }
  }

  return (
    <>   
      <Header title={resource ? resource.name : ''} hide />
      <Tabs
        id="resource-tabs"
        activeKey={ tab }
        onSelect={ selectTabHandle }
        mountOnEnter={true}
        unmountOnExit={true}
      >
        <Tab eventKey="resource-profile" title="Profile" >
          <ResourceProfile history={history} />
        </Tab>

        <Tab eventKey="resource-availability" title="Availability" >
          <ResourceAvailability />
        </Tab>

        <Tab eventKey="resource-services" title="Services" >
          <BookableServices  />
        </Tab>

        <Tab eventKey="resource-fields" title="Custom Fields" >
          <CustomFieldsTab />
        </Tab>
        
      </Tabs>
            
    </>
  )
}

export default ModifyResource
