import { viewTypes } from '../actions/viewActions'

export const zendeskReport = (values, view) => ({
  ticket: {
    comment: {
      body: `
        Company ID: ${values.companyId}
        Location ID: ${values.locationId}
        Component: ${view}
        ${view === viewTypes.resources ? `\nResource ID: ${values.resourceId}` : ''}
        ${view === viewTypes.services ? `\nService ID: ${values.serviceId}` : ''}
        \nRequest URL: ${values.requestUrl}
        Method: ${values.method}
        Request Body: ${JSON.stringify(JSON.parse(values.requestBody), null, '\t')}
        \nErrors:
        ${JSON.stringify(values.errorDetails, null, '\t')}
      `
    },
    requester: { name: values.userName, email: values.userEmail },
    priority: 'urgent',
    subject: '500 error reported in Dashboard',
    custom_fields: values.customFields
  }
})
