export const customErrors = {
  companySelectError: { 
    response : { 
      data : {
        errors : [{ detail: "Company not found" }]
      }
    }
  },
  formValidationError: {
    response: {
      data : {
        errors: [{ detail: "Form Validation Error"}]
      }
    }
  }
}