import { SET_CARET_POSITION } from '../actions/notificationActions'

const INITIAL_STATE = {
  position: {start: 0, end: 0}
}

const setCaretPositionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CARET_POSITION:
      return {
          position: action.position
        }
    default:
      return state
  }
}

export default setCaretPositionReducer