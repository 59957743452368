import { handle } from 'redux-pack'

import { LOAD_EMAIL_TEMPLATE } from '../actions/notificationActions'


const INITIAL_STATE = { success: false }


export default function( state = INITIAL_STATE, action ) {
  const { type, payload } = action
  switch ( type ) {
    
    case LOAD_EMAIL_TEMPLATE:
      return handle( state, action, {
        start:   previousState => ( { ...previousState, isLoading: true, eTemplateError: null, eTemplate: null } ),
        finish:  previousState => ( { ...previousState, isLoading: false } ),
        failure: previousState => ( { ...previousState, eTemplateError: payload } ),
        success: previousState => ( { ...previousState, success: true, eTemplate: payload.data } )
      })

    default:
      return state

  }

}