import axios       from 'axios'
import querystring from 'querystring'

export const LOAD_MASTER_TEMPLATE = 'LOAD_MASTER_TEMPLATE'

export const loadMasterTemplate = locationId => {

  const config = {}
  
  const emailTemplatesRoute = `/api/onsched/setup/locations/${locationId}` + 
                                                `/email/templates/master`

  return {
    type:    LOAD_MASTER_TEMPLATE,
    promise: axios.get( emailTemplatesRoute, config )
  }

}

export const LOAD_EMAIL_TEMPLATES = 'LOAD_EMAIL_TEMPLATES'

export const loadEmailTemplates = ( locationId ) => {

  const config = {}
  
  const emailTemplatesRoute = `/api/onsched/setup/locations/${locationId}/email/templates`

  return {
    type:    LOAD_EMAIL_TEMPLATES,
    promise: axios.get( emailTemplatesRoute, config )
  }

}

export const UPDATE_EMAIL_TEMPLATE = 'UPDATE_EMAIL_TEMPLATE'

export const updateEmailTemplate = ( locationId, templateData ) => {

  const config = {}
  
  const emailTemplatesRoute = `/api/onsched/setup/locations/${locationId}/email/templates`

  return {
    type:    UPDATE_EMAIL_TEMPLATE,
    promise: axios.post( emailTemplatesRoute, templateData, config )
  }

}

export const DELETE_EMAIL_TEMPLATE = 'DELETE_EMAIL_TEMPLATE'

export const deleteEmailTemplate = ( locationId, templateName ) => {

  const config = {}
  
  const emailTemplatesRoute = `/api/onsched/setup/locations/${locationId}` + 
                                        `/email/templates/${templateName}`

  return {
    type:    DELETE_EMAIL_TEMPLATE,
    promise: axios.delete( emailTemplatesRoute, config )
  }

}

export const LOAD_EMAIL_TEMPLATE = 'LOAD_EMAIL_TEMPLATE'

export const loadEmailTemplate = ( locationId, templateName, queryParams = {} ) => {

  const config = {}

  const queryString = querystring.stringify( queryParams )
  
  const emailTemplateRoute = `/api/onsched/setup/locations/${locationId}` + 
                                        `/email/templates/${templateName}`+
                                        `?${queryString}`

  return {
    type:    LOAD_EMAIL_TEMPLATE,
    promise: axios.get( emailTemplateRoute, config )
  }

}

export const UPDATE_MASTER_TEMPLATE = 'UPDATE_MASTER_TEMPLATE'

export const updateMasterTemplate = (locationId, masterTemplate) => {

  const config = {}
  
  const emailTemplateRoute = `/api/onsched/setup/locations/${locationId}` + 
                                                `/email/templates/master`

  return {
    type:    UPDATE_MASTER_TEMPLATE,
    promise: axios.post( emailTemplateRoute, masterTemplate, config )
  }

}

export const UPDATE_EMAIL_PREVIEW = 'UPDATE_EMAIL_PREVIEW'

export const updateEmailPreview = options => ({
  type: UPDATE_EMAIL_PREVIEW,
  options
})

export const SET_CARET_POSITION = 'SET_CARET_POSITION'

export const setCaretPosition = (start, end) => ({
  type: SET_CARET_POSITION,
  position: {start, end}
})

export const ADD_PARAMETER = 'ADD_PARAMETER'

export const addParameter = (param, start, end) => ({
  type: ADD_PARAMETER,
  param,
  position: {start, end}
})

export const previewSizes = {
  mobile: '375px',
  tablet: '768px',
  desktop: '100%'
}

export const PREVIEW_SIZE = 'PREVIEW_SIZE'

export const togglePreviewSize = (screenSize) => ({
  type: PREVIEW_SIZE,
  screenSize
})
