import React from 'react'

export const weekday = {
  startTime : 900,
  endTime   : 1700,
  isOpen    : true,
  is24Hours : false,
}

export const weekend = {
  startTime : 0,
  endTime   : 0,
  isOpen    : false,
  is24Hours : false,
}

export const times = [
  {
    "i"        : 1,
    "time"     : "12:00",
    "value"    : 0,
    "meridiem" : "am",
  },
  {
    "i"        : 2,
    "time"     : "12:15",
    "value"    : 15,
    "meridiem" : "am",
  },
  {
    "i"        : 3,
    "time"     : "12:30",
    "value"    : 30,
    "meridiem" : "am",
  },
  {
    "i"        : 4,
    "time"     : "12:45",
    "value"    : 45,
    "meridiem" : "am",
  },
  {
    "i"        : 5,
    "time"     : "1:00",
    "value"    : 100,
    "meridiem" : "am",
  },
  {
    "i"        : 6,
    "time"     : "1:15",
    "value"    : 115,
    "meridiem" : "am",
  },
  {
    "i"        : 7,
    "time"     : "1:30",
    "value"    : 130,
    "meridiem" : "am",
  },
  {
    "i"        : 8,
    "time"     : "1:45",
    "value"    : 145,
    "meridiem" : "am",
  },
  {
    "i"        : 9,
    "time"     : "2:00",
    "value"    : 200,
    "meridiem" : "am",
  },
  {
    "i"        : 10,
    "time"     : "2:15",
    "value"    : 215,
    "meridiem" : "am",
  },
  {
    "i"        : 11,
    "time"     : "2:30",
    "value"    : 230,
    "meridiem" : "am",
  },
  {
    "i"        : 12,
    "time"     : "2:45",
    "value"    : 245,
    "meridiem" : "am",
  },
  {
    "i"        : 13,
    "time"     : "3:00",
    "value"    : 300,
    "meridiem" : "am",
  },
  {
    "i"        : 14,
    "time"     : "3:15",
    "value"    : 315,
    "meridiem" : "am",
  },
  {
    "i"        : 15,
    "time"     : "3:30",
    "value"    : 330,
    "meridiem" : "am",
  },
  {
    "i"        : 16,
    "time"     : "3:45",
    "value"    : 345,
    "meridiem" : "am",
  },
  {
    "i"        : 17,
    "time"     : "4:00",
    "value"    : 400,
    "meridiem" : "am",
  },
  {
    "i"        : 18,
    "time"     : "4:15",
    "value"    : 415,
    "meridiem" : "am",
  },
  {
    "i"        : 19,
    "time"     : "4:30",
    "value"    : 430,
    "meridiem" : "am",
  },
  {
    "i"        : 20,
    "time"     : "4:45",
    "value"    : 445,
    "meridiem" : "am",
  },
  {
    "i"        : 21,
    "time"     : "5:00",
    "value"    : 500,
    "meridiem" : "am",
  },
  {
    "i"        : 22,
    "time"     : "5:15",
    "value"    : 515,
    "meridiem" : "am",
  },
  {
    "i"        : 23,
    "time"     : "5:30",
    "value"    : 530,
    "meridiem" : "am",
  },
  {
    "i"        : 24,
    "time"     : "5:45",
    "value"    : 545,
    "meridiem" : "am",
  },
  {
    "i"        : 25,
    "time"     : "6:00",
    "value"    : 600,
    "meridiem" : "am",
  },
  {
    "i"        : 26,
    "time"     : "6:15",
    "value"    : 615,
    "meridiem" : "am",
  },
  {
    "i"        : 27,
    "time"     : "6:30",
    "value"    : 630,
    "meridiem" : "am",
  },
  {
    "i"        : 28,
    "time"     : "6:45",
    "value"    : 645,
    "meridiem" : "am",
  },
  {
    "i"        : 29,
    "time"     : "7:00",
    "value"    : 700,
    "meridiem" : "am",
  },
  {
    "i"        : 30,
    "time"     : "7:15",
    "value"    : 715,
    "meridiem" : "am",
  },
  {
    "i"        : 31,
    "time"     : "7:30",
    "value"    : 730,
    "meridiem" : "am",
  },
  {
    "i"        : 32,
    "time"     : "7:45",
    "value"    : 745,
    "meridiem" : "am",
  },
  {
    "i"        : 33,
    "time"     : "8:00",
    "value"    : 800,
    "meridiem" : "am",
  },
  {
    "i"        : 34,
    "time"     : "8:15",
    "value"    : 815,
    "meridiem" : "am",
  },
  {
    "i"        : 35,
    "time"     : "8:30",
    "value"    : 830,
    "meridiem" : "am",
  },
  {
    "i"        : 36,
    "time"     : "8:45",
    "value"    : 845,
    "meridiem" : "am",
  },
  {
    "i"        : 37,
    "time"     : "9:00",
    "value"    : 900,
    "meridiem" : "am",
  },
  {
    "i"        : 38,
    "time"     : "9:15",
    "value"    : 915,
    "meridiem" : "am",
  },
  {
    "i"        : 39,
    "time"     : "9:30",
    "value"    : 930,
    "meridiem" : "am",
  },
  {
    "i"        : 40,
    "time"     : "9:45",
    "value"    : 945,
    "meridiem" : "am",
  },
  {
    "i"        : 41,
    "time"     : "10:00",
    "value"    : 1000,
    "meridiem" : "am",
  },
  {
    "i"        : 42,
    "time"     : "10:15",
    "value"    : 1015,
    "meridiem" : "am",
  },
  {
    "i"        : 43,
    "time"     : "10:30",
    "value"    : 1030,
    "meridiem" : "am",
  },
  {
    "i"        : 44,
    "time"     : "10:45",
    "value"    : 1045,
    "meridiem" : "am",
  },
  {
    "i"        : 45,
    "time"     : "11:00",
    "value"    : 1100,
    "meridiem" : "am",
  },
  {
    "i"        : 46,
    "time"     : "11:15",
    "value"    : 1115,
    "meridiem" : "am",
  },
  {
    "i"        : 47,
    "time"     : "11:30",
    "value"    : 1130,
    "meridiem" : "am",
  },
  {
    "i"        : 48,
    "time"     : "11:45",
    "value"    : 1145,
    "meridiem" : "am",
  },
  {
    "i"        : 49,
    "time"     : "12:00",
    "value"    : 1200,
    "meridiem" : "pm",
  },
  {
    "i"        : 50,
    "time"     : "12:15",
    "value"    : 1215,
    "meridiem" : "pm",
  },
  {
    "i"        : 51,
    "time"     : "12:30",
    "value"    : 1230,
    "meridiem" : "pm",
  },
  {
    "i"        : 52,
    "time"     : "12:45",
    "value"    : 1245,
    "meridiem" : "pm",
  },
  {
    "i"        : 53,
    "time"     : "1:00",
    "value"    : 1300,
    "meridiem" : "pm",
  },
  {
    "i"        : 54,
    "time"     : "1:15",
    "value"    : 1315,
    "meridiem" : "pm",
  },
  {
    "i"        : 55,
    "time"     : "1:30",
    "value"    : 1330,
    "meridiem" : "pm",
  },
  {
    "i"        : 56,
    "time"     : "1:45",
    "value"    : 1345,
    "meridiem" : "pm",
  },
  {
    "i"        : 57,
    "time"     : "2:00",
    "value"    : 1400,
    "meridiem" : "pm",
  },
  {
    "i"        : 58,
    "time"     : "2:15",
    "value"    : 1415,
    "meridiem" : "pm",
  },
  {
    "i"        : 59,
    "time"     : "2:30",
    "value"    : 1430,
    "meridiem" : "pm",
  },
  {
    "i"        : 60,
    "time"     : "2:45",
    "value"    : 1445,
    "meridiem" : "pm",
  },
  {
    "i"        : 61,
    "time"     : "3:00",
    "value"    : 1500,
    "meridiem" : "pm",
  },
  {
    "i"        : 62,
    "time"     : "3:15",
    "value"    : 1515,
    "meridiem" : "pm",
  },
  {
    "i"        : 63,
    "time"     : "3:30",
    "value"    : 1530,
    "meridiem" : "pm",
  },
  {
    "i"        : 64,
    "time"     : "3:45",
    "value"    : 1545,
    "meridiem" : "pm",
  },
  {
    "i"        : 65,
    "time"     : "4:00",
    "value"    : 1600,
    "meridiem" : "pm",
  },
  {
    "i"        : 66,
    "time"     : "4:15",
    "value"    : 1615,
    "meridiem" : "pm",
  },
  {
    "i"        : 67,
    "time"     : "4:30",
    "value"    : 1630,
    "meridiem" : "pm",
  },
  {
    "i"        : 68,
    "time"     : "4:45",
    "value"    : 1645,
    "meridiem" : "pm",
  },
  {
    "i"        : 69,
    "time"     : "5:00",
    "value"    : 1700,
    "meridiem" : "pm",
  },
  {
    "i"        : 70,
    "time"     : "5:15",
    "value"    : 1715,
    "meridiem" : "pm",
  },
  {
    "i"        : 71,
    "time"     : "5:30",
    "value"    : 1730,
    "meridiem" : "pm",
  },
  {
    "i"        : 72,
    "time"     : "5:45",
    "value"    : 1745,
    "meridiem" : "pm",
  },
  {
    "i"        : 73,
    "time"     : "6:00",
    "value"    : 1800,
    "meridiem" : "pm",
  },
  {
    "i"        : 74,
    "time"     : "6:15",
    "value"    : 1815,
    "meridiem" : "pm",
  },
  {
    "i"        : 75,
    "time"     : "6:30",
    "value"    : 1830,
    "meridiem" : "pm",
  },
  {
    "i"        : 76,
    "time"     : "6:45",
    "value"    : 1845,
    "meridiem" : "pm",
  },
  {
    "i"        : 77,
    "time"     : "7:00",
    "value"    : 1900,
    "meridiem" : "pm",
  },
  {
    "i"        : 78,
    "time"     : "7:15",
    "value"    : 1915,
    "meridiem" : "pm",
  },
  {
    "i"        : 79,
    "time"     : "7:30",
    "value"    : 1930,
    "meridiem" : "pm",
  },
  {
    "i"        : 80,
    "time"     : "7:45",
    "value"    : 1945,
    "meridiem" : "pm",
  },
  {
    "i"        : 81,
    "time"     : "8:00",
    "value"    : 2000,
    "meridiem" : "pm",
  },
  {
    "i"        : 82,
    "time"     : "8:15",
    "value"    : 2015,
    "meridiem" : "pm",
  },
  {
    "i"        : 83,
    "time"     : "8:30",
    "value"    : 2030,
    "meridiem" : "pm",
  },
  {
    "i"        : 84,
    "time"     : "8:45",
    "value"    : 2045,
    "meridiem" : "pm",
  },
  {
    "i"        : 85,
    "time"     : "9:00",
    "value"    : 2100,
    "meridiem" : "pm",
  },
  {
    "i"        : 86,
    "time"     : "9:15",
    "value"    : 2115,
    "meridiem" : "pm",
  },
  {
    "i"        : 87,
    "time"     : "9:30",
    "value"    : 2130,
    "meridiem" : "pm",
  },
  {
    "i"        : 88,
    "time"     : "9:45",
    "value"    : 2145,
    "meridiem" : "pm",
  },
  {
    "i"        : 89,
    "time"     : "10:00",
    "value"    : 2200,
    "meridiem" : "pm",
  },
  {
    "i"        : 90,
    "time"     : "10:15",
    "value"    : 2215,
    "meridiem" : "pm",
  },
  {
    "i"        : 91,
    "time"     : "10:30",
    "value"    : 2230,
    "meridiem" : "pm",
  },
  {
    "i"        : 92,
    "time"     : "10:45",
    "value"    : 2245,
    "meridiem" : "pm",
  },
  {
    "i"        : 93,
    "time"     : "11:00",
    "value"    : 2300,
    "meridiem" : "pm",
  },
  {
    "i"        : 94,
    "time"     : "11:15",
    "value"    : 2315,
    "meridiem" : "pm",
  },
  {
    "i"        : 95,
    "time"     : "11:30",
    "value"    : 2330,
    "meridiem" : "pm",
  },
  {
    "i"        : 96,
    "time"     : "11:45",
    "value"    : 2345,
    "meridiem" : "pm",
  },
  {
    "i"        : 97,
    "time"     : "12:00",
    "value"    : 2400,
    "meridiem" : "am",
  },
]

export const times5Min = () => {
  var i, value
  let times = []

  for (i = 0;i <= 1440;i++) {
    let time = Math.floor((i)/60)*100 + ((i)%60)
    let hr = Math.floor((i)/60)
    let min = (i)%60
    
    let meridiem = 'pm'
    if (time < 1200 || time === 2400) {
      meridiem = 'am'
    }

    if (hr > 12)
      hr -= 12

    if (hr === 0)
      hr = 12

    if (min.toString().length < 2) {
      min = "".concat("0", min)
    }

    times.push({
      i,
      time: `${hr}:${min}`,
      value: time,
      meridiem
    })
  }

  return times
}

export const intervalDurations = [
  {
    "time"  : "5 min",
    "value" : 5
  },
  {
    "time"  : "10 min",
    "value" : 10
  },
  {
    "time"  : "15 min",
    "value" : 15
  },
  {
    "time"  : "20 min",
    "value" : 20
  },
  {
    "time"  : "25 min",
    "value" : 25
  },
  {
    "time"  : "30 min",
    "value" : 30
  },
  {
    "time"  : "35 min",
    "value" : 35
  },
  {
    "time"  : "40 min",
    "value" : 40
  },
  {
    "time"  : "45 min",
    "value" : 45
  },
  {
    "time"  : "50 min",
    "value" : 50
  },
  {
    "time"  : "55 min",
    "value" : 55
  },
  {
    "time"  : "1 hour",
    "value" : 60
  },
  {
    "time"  : "1 hr, 5 min",
    "value" : 65
  },
  {
    "time"  : "1 hr, 10 min",
    "value" : 70
  },
  {
    "time"  : "1 hr, 15 min",
    "value" : 75
  },
  {
    "time"  : "1 hr, 20 min",
    "value" : 80
  },
  {
    "time"  : "1 hr, 25 min",
    "value" : 85
  },
  {
    "time"  : "1 hr, 30 min",
    "value" : 90
  },
  {
    "time"  : "1 hr, 35 min",
    "value" : 95
  },
  {
    "time"  : "1 hr, 40 min",
    "value" : 100
  },
  {
    "time"  : "1 hr, 45 min",
    "value" : 105
  },
  {
    "time"  : "1 hr, 50 min",
    "value" : 110
  },
  {
    "time"  : "1 hr, 55 min",
    "value" : 115
  },
  {
    "time"  : "2 hrs",
    "value" : 120
  },
  {
    "time"  : "2 hrs, 5 min",
    "value" : 125
  },
  {
    "time"  : "2 hrs, 10 min",
    "value" : 130
  },
  {
    "time"  : "2 hrs, 15 min",
    "value" : 135
  },
  {
    "time"  : "2 hrs, 20 min",
    "value" : 140
  },
  {
    "time"  : "2 hrs, 25 min",
    "value" : 145
  },
  {
    "time"  : "2 hrs, 30 min",
    "value" : 150
  },
  {
    "time"  : "2 hrs, 35 min",
    "value" : 155
  },
  {
    "time"  : "2 hrs, 40 min",
    "value" : 160
  },
  {
    "time"  : "2 hrs, 45 min",
    "value" : 165
  },
  {
    "time"  : "2 hrs, 50 min",
    "value" : 170
  },
  {
    "time"  : "2 hrs, 55 min",
    "value" : 175
  },
  {
    "time"  : "3 hrs",
    "value" : 180
  },
  {
    "time"  : "3 hrs, 5 min",
    "value" : 185
  },
  {
    "time"  : "3 hrs, 10 min",
    "value" : 190
  },
  {
    "time"  : "3 hrs, 15 min",
    "value" : 195
  },
  {
    "time"  : "3 hrs, 20 min",
    "value" : 200
  },
  {
    "time"  : "3 hrs, 25 min",
    "value" : 205
  },
  {
    "time"  : "3 hrs, 30 min",
    "value" : 210
  },
  {
    "time"  : "3 hrs, 35 min",
    "value" : 215
  },
  {
    "time"  : "3 hrs, 40 min",
    "value" : 220
  },
  {
    "time"  : "3 hrs, 45 min",
    "value" : 225
  },
  {
    "time"  : "3 hrs, 50 min",
    "value" : 230
  },
  {
    "time"  : "3 hrs, 55 min",
    "value" : 235
  },
  {
    "time"  : "4 hrs",
    "value" : 240
  },
]

export const paddingDurations = [
  {
    "time"  : "None",
    "value" : 0
  },
  {
    "time"  : "5 min",
    "value" : 5
  },
  {
    "time"  : "10 min",
    "value" : 10
  },
  {
    "time"  : "15 min",
    "value" : 15
  },
  {
    "time"  : "20 min",
    "value" : 20
  },
  {
    "time"  : "25 min",
    "value" : 25
  },
  {
    "time"  : "30 min",
    "value" : 30
  },
  {
    "time"  : "35 min",
    "value" : 35
  },
  {
    "time"  : "40 min",
    "value" : 40
  },
  {
    "time"  : "45 min",
    "value" : 45
  },
  {
    "time"  : "50 min",
    "value" : 50
  },
  {
    "time"  : "55 min",
    "value" : 55
  },
  {
    "time"  : "1 hour",
    "value" : 60
  }
]

export const durations = [
  {
    "value" : 1,
    "time"  : "1 min",
  },
  {
    "value" : 2,
    "time"  : "2 min",
  },
  {
    "value" : 3,
    "time"  : "3 min",
  },
  {
    "value" : 4,
    "time"  : "4 min",
  },
  {
    "value" : 5,
    "time"  : "5 min",
  },
  {
    "value" : 6,
    "time"  : "6 min",
  },
  {
    "value" : 7,
    "time"  : "7 min",
  },
  {
    "value" : 8,
    "time"  : "8 min",
  },
  {
    "value" : 9,
    "time"  : "9 min",
  },
  {
    "value" : 10,
    "time"  : "10 min",
  },
  {
    "value" : 11,
    "time"  : "11 min",
  },
  {
    "value" : 12,
    "time"  : "12 min",
  },
  {
    "value" : 13,
    "time"  : "13 min",
  },
  {
    "value" : 14,
    "time"  : "14 min",
  },
  {
    "value" : 15,
    "time"  : "15 min",
  },
  {
    "value" : 16,
    "time"  : "16 min",
  },
  {
    "value" : 17,
    "time"  : "17 min",
  },
  {
    "value" : 18,
    "time"  : "18 min",
  },
  {
    "value" : 19,
    "time"  : "19 min",
  },
  {
    "value" : 20,
    "time"  : "20 min",
  },
  {
    "value" : 21,
    "time"  : "21 min",
  },
  {
    "value" : 22,
    "time"  : "22 min",
  },
  {
    "value" : 23,
    "time"  : "23 min",
  },
  {
    "value" : 24,
    "time"  : "24 min",
  },
  {
    "value" : 25,
    "time"  : "25 min",
  },
  {
    "value" : 26,
    "time"  : "26 min",
  },
  {
    "value" : 27,
    "time"  : "27 min",
  },
  {
    "value" : 28,
    "time"  : "28 min",
  },
  {
    "value" : 29,
    "time"  : "29 min",
  },
  {
    "value" : 30,
    "time"  : "30 min",
  },
  {
    "value" : 31,
    "time"  : "31 min",
  },
  {
    "value" : 32,
    "time"  : "32 min",
  },
  {
    "value" : 33,
    "time"  : "33 min",
  },
  {
    "value" : 34,
    "time"  : "34 min",
  },
  {
    "value" : 35,
    "time"  : "35 min",
  },
  {
    "value" : 36,
    "time"  : "36 min",
  },
  {
    "value" : 37,
    "time"  : "37 min",
  },
  {
    "value" : 38,
    "time"  : "38 min",
  },
  {
    "value" : 39,
    "time"  : "39 min",
  },
  {
    "value" : 40,
    "time"  : "40 min",
  },
  {
    "value" : 41,
    "time"  : "41 min",
  },
  {
    "value" : 42,
    "time"  : "42 min",
  },
  {
    "value" : 43,
    "time"  : "43 min",
  },
  {
    "value" : 44,
    "time"  : "44 min",
  },
  {
    "value" : 45,
    "time"  : "45 min",
  },
  {
    "value" : 46,
    "time"  : "46 min",
  },
  {
    "value" : 47,
    "time"  : "47 min",
  },
  {
    "value" : 48,
    "time"  : "48 min",
  },
  {
    "value" : 49,
    "time"  : "49 min",
  },
  {
    "value" : 50,
    "time"  : "50 min",
  },
  {
    "value" : 51,
    "time"  : "51 min",
  },
  {
    "value" : 52,
    "time"  : "52 min",
  },
  {
    "value" : 53,
    "time"  : "53 min",
  },
  {
    "value" : 54,
    "time"  : "54 min",
  },
  {
    "value" : 55,
    "time"  : "55 min",
  },
  {
    "value" : 56,
    "time"  : "56 min",
  },
  {
    "value" : 57,
    "time"  : "57 min",
  },
  {
    "value" : 58,
    "time"  : "58 min",
  },
  {
    "value" : 59,
    "time"  : "59 min",
  },
  {
    "value" : 60,
    "time"  : "1 hour",
  },
  {
    "value" : 61,
    "time"  : "1 hr, 01 min",
  },
  {
    "value" : 62,
    "time"  : "1 hr, 02 min",
  },
  {
    "value" : 63,
    "time"  : "1 hr, 03 min",
  },
  {
    "value" : 64,
    "time"  : "1 hr, 04 min",
  },
  {
    "value" : 65,
    "time"  : "1 hr, 05 min",
  },
  {
    "value" : 66,
    "time"  : "1 hr, 06 min",
  },
  {
    "value" : 67,
    "time"  : "1 hr, 07 min",
  },
  {
    "value" : 68,
    "time"  : "1 hr, 08 min",
  },
  {
    "value" : 69,
    "time"  : "1 hr, 09 min",
  },
  {
    "value" : 70,
    "time"  : "1 hr, 10 min",
  },
  {
    "value" : 71,
    "time"  : "1 hr, 11 min",
  },
  {
    "value" : 72,
    "time"  : "1 hr, 12 min",
  },
  {
    "value" : 73,
    "time"  : "1 hr, 13 min",
  },
  {
    "value" : 74,
    "time"  : "1 hr, 14 min",
  },
  {
    "value" : 75,
    "time"  : "1 hr, 15 min",
  },
  {
    "value" : 76,
    "time"  : "1 hr, 16 min",
  },
  {
    "value" : 77,
    "time"  : "1 hr, 17 min",
  },
  {
    "value" : 78,
    "time"  : "1 hr, 18 min",
  },
  {
    "value" : 79,
    "time"  : "1 hr, 19 min",
  },
  {
    "value" : 80,
    "time"  : "1 hr, 20 min" ,
  },
  {
    "value" : 81,
    "time"  : "1 hr, 21 min",
  },
  {
    "value" : 82,
    "time"  : "1 hr, 22 min",
  },
  {
    "value" : 83,
    "time"  : "1 hr, 23 min",
  },
  {
    "value" : 84,
    "time"  : "1 hr, 24 min",
  },
  {
    "value" : 85,
    "time"  : "1 hr, 25 min",
  },
  {
    "value" : 86,
    "time"  : "1 hr, 26 min",
  },
  {
    "value" : 87,
    "time"  : "1 hr, 27 min",
  },
  {
    "value" : 88,
    "time"  : "1 hr, 28 min",
  },
  {
    "value" : 89,
    "time"  : "1 hr, 29 min",
  },
  {
    "value" : 90,
    "time"  : "1 hr, 30 min",
  },
  {
    "value" : 91,
    "time"  : "1 hr, 31 min",
  },
  {
    "value" : 92,
    "time"  : "1 hr, 32 min",
  },
  {
    "value" : 93,
    "time"  : "1 hr, 33 min",
  },
  {
    "value" : 94,
    "time"  : "1 hr, 34 min",
  },
  {
    "value" : 95,
    "time"  : "1 hr, 35 min",
  },
  {
    "value" : 96,
    "time"  : "1 hr, 36 min",
  },
  {
    "value" : 97,
    "time"  : "1 hr, 37 min",
  },
  {
    "value" : 98,
    "time"  : "1 hr, 38 min",
  },
  {
    "value" : 99,
    "time"  : "1 hr, 39 min",
  },
  {
    "value" : 100,
    "time"  : "1 hr, 40 min",
  },
  {
    "value" : 101,
    "time"  : "1 hr, 41 min",
  },
  {
    "value" : 102,
    "time"  : "1 hr, 42 min",
  },
  {
    "value" : 103,
    "time"  : "1 hr, 43 min",
  },
  {
    "value" : 104,
    "time"  : "1 hr, 44 min",
  },
  {
    "value" : 105,
    "time"  : "1 hr, 45 min",
  },
  {
    "value" : 106,
    "time"  : "1 hr, 46 min",
  },
  {
    "value" : 107,
    "time"  : "1 hr, 47 min",
  },
  {
    "value" : 108,
    "time"  : "1 hr, 48 min",
  },
  {
    "value" : 109,
    "time"  : "1 hr, 49 min",
  },
  {
    "value" : 110,
    "time"  : "1 hr, 50 min",
  },
  {
    "value" : 111,
    "time"  : "1 hr, 51 min",
  },
  {
    "value" : 112,
    "time"  : "1 hr, 52 min",
  },
  {
    "value" : 113,
    "time"  : "1 hr, 53 min",
  },
  {
    "value" : 114,
    "time"  : "1 hr, 54 min",
  },
  {
    "value" : 115,
    "time"  : "1 hr, 55 min",
  },
  {
    "value" : 116,
    "time"  : "1 hr, 56 min",
  },
  {
    "value" : 117,
    "time"  : "1 hr, 57 min",
  },
  {
    "value" : 118,
    "time"  : "1 hr, 58 min",
  },
  {
    "value" : 119,
    "time"  : "1 hr, 59 min",
  },
  {
    "value" : 120,
    "time"  : "2 hours",
  },
  {
    "value" : 121,
    "time"  : "2 hr, 01 min",
  },
  {
    "value" : 122,
    "time"  : "2 hr, 02 min",
  },
  {
    "value" : 123,
    "time"  : "2 hr, 03 min",
  },
  {
    "value" : 124,
    "time"  : "2 hr, 04 min",
  },
  {
    "value" : 125,
    "time"  : "2 hr, 05 min",
  },
  {
    "value" : 126,
    "time"  : "2 hr, 06 min",
  },
  {
    "value" : 127,
    "time"  : "2 hr, 07 min",
  },
  {
    "value" : 128,
    "time"  : "2 hr, 08 min",
  },
  {
    "value" : 129,
    "time"  : "2 hr, 09 min",
  },
  {
    "value" : 130,
    "time"  : "2 hr, 10 min",
  },
  {
    "value" : 131,
    "time"  : "2 hr, 11 min",
  },
  {
    "value" : 132,
    "time"  : "2 hr, 12 min",
  },
  {
    "value" : 133,
    "time"  : "2 hr, 13 min",
  },
  {
    "value" : 134,
    "time"  : "2 hr, 14 min",
  },
  {
    "value" : 135,
    "time"  : "2 hr, 15 min",
  },
  {
    "value" : 136,
    "time"  : "2 hr, 16 min",
  },
  {
    "value" : 137,
    "time"  : "2 hr, 17 min",
  },
  {
    "value" : 138,
    "time"  : "2 hr, 18 min",
  },
  {
    "value" : 139,
    "time"  : "2 hr, 19 min",
  },
  {
    "value" : 140,
    "time"  : "2 hr, 20 min",
  },
  {
    "value" : 141,
    "time"  : "2 hr, 21 min",
  },
  {
    "value" : 142,
    "time"  : "2 hr, 22 min",
  },
  {
    "value" : 143,
    "time"  : "2 hr, 23 min",
  },
  {
    "value" : 144,
    "time"  : "2 hr, 24 min",
  },
  {
    "value" : 145,
    "time"  : "2 hr, 25 min",
  },
  {
    "value" : 146,
    "time"  : "2 hr, 26 min",
  },
  {
    "value" : 147,
    "time"  : "2 hr, 27 min",
  },
  {
    "value" : 148,
    "time"  : "2 hr, 28 min",
  },
  {
    "value" : 149,
    "time"  : "2 hr, 29 min",
  },
  {
    "value" : 150,
    "time"  : "2 hr, 30 min",
  },
  {
    "value" : 151,
    "time"  : "2 hr, 31 min",
  },
  {
    "value" : 152,
    "time"  : "2 hr, 32 min",
  },
  {
    "value" : 153,
    "time"  : "2 hr, 33 min",
  },
  {
    "value" : 154,
    "time"  : "2 hr, 34 min",
  },
  {
    "value" : 155,
    "time"  : "2 hr, 35 min",
  },
  {
    "value" : 156,
    "time"  : "2 hr, 36 min",
  },
  {
    "value" : 157,
    "time"  : "2 hr, 37 min",
  },
  {
    "value" : 158,
    "time"  : "2 hr, 38 min",
  },
  {
    "value" : 159,
    "time"  : "2 hr, 39 min",
  },
  {
    "value" : 160,
    "time"  : "2 hr, 40 min",
  },
  {
    "value" : 161,
    "time"  : "2 hr, 41 min",
  },
  {
    "value" : 162,
    "time"  : "2 hr, 42 min",
  },
  {
    "value" : 163,
    "time"  : "2 hr, 43 min",
  },
  {
    "value" : 164,
    "time"  : "2 hr, 44 min",
  },
  {
    "value" : 165,
    "time"  : "2 hr, 45 min",
  },
  {
    "value" : 166,
    "time"  : "2 hr, 46 min",
  },
  {
    "value" : 167,
    "time"  : "2 hr, 47 min",
  },
  {
    "value" : 168,
    "time"  : "2 hr, 48 min",
  },
  {
    "value" : 169,
    "time"  : "2 hr, 49 min",
  },
  {
    "value" : 170,
    "time"  : "2 hr, 50 min",
  },
  {
    "value" : 171,
    "time"  : "2 hr, 51 min",
  },
  {
    "value" : 172,
    "time"  : "2 hr, 52 min",
  },
  {
    "value" : 173,
    "time"  : "2 hr, 53 min",
  },
  {
    "value" : 174,
    "time"  : "2 hr, 54 min",
  },
  {
    "value" : 175,
    "time"  : "2 hr, 55 min",
  },
  {
    "value" : 176,
    "time"  : "2 hr, 56 min",
  },
  {
    "value" : 177,
    "time"  : "2 hr, 57 min",
  },
  {
    "value" : 178,
    "time"  : "2 hr, 58 min",
  },
  {
    "value" : 179,
    "time"  : "2 hr, 59 min",
  },
  {
    "value" : 180,
    "time"  : "3 hours",
  },
  {
    "value" : 181,
    "time"  : "3 hr, 01 min",
  },
  {
    "value" : 182,
    "time"  : "3 hr, 02 min",
  },
  {
    "value" : 183,
    "time"  : "3 hr, 03 min",
  },
  {
    "value" : 184,
    "time"  : "3 hr, 04 min",
  },
  {
    "value" : 185,
    "time"  : "3 hr, 05 min",
  },
  {
    "value" : 186,
    "time"  : "3 hr, 06 min",
  },
  {
    "value" : 187,
    "time"  : "3 hr, 07 min",
  },
  {
    "value" : 188,
    "time"  : "3 hr, 08 min",
  },
  {
    "value" : 189,
    "time"  : "3 hr, 09 min",
  },
  {
    "value" : 190,
    "time"  : "3 hr, 10 min",
  },
  {
    "value" : 191,
    "time"  : "3 hr, 11 min",
  },
  {
    "value" : 192,
    "time"  : "3 hr, 12 min",
  },
  {
    "value" : 193,
    "time"  : "3 hr, 13 min",
  },
  {
    "value" : 194,
    "time"  : "3 hr, 14 min",
  },
  {
    "value" : 195,
    "time"  : "3 hr, 15 min",
  },
  {
    "value" : 196,
    "time"  : "3 hr, 16 min",
  },
  {
    "value" : 197,
    "time"  : "3 hr, 17 min",
  },
  {
    "value" : 198,
    "time"  : "3 hr, 18 min",
  },
  {
    "value" : 199,
    "time"  : "3 hr, 19 min",
  },
  {
    "value" : 200,
    "time"  : "3 hr, 20 min",
  },
  {
    "value" : 201,
    "time"  : "3 hr, 21 min",
  },
  {
    "value" : 202,
    "time"  : "3 hr, 22 min",
  },
  {
    "value" : 203,
    "time"  : "3 hr, 23 min",
  },
  {
    "value" : 204,
    "time"  : "3 hr, 24 min",
  },
  {
    "value" : 205,
    "time"  : "3 hr, 25 min",
  },
  {
    "value" : 206,
    "time"  : "3 hr, 26 min",
  },
  {
    "value" : 207,
    "time"  : "3 hr, 27 min",
  },
  {
    "value" : 208,
    "time"  : "3 hr, 28 min",
  },
  {
    "value" : 209,
    "time"  : "3 hr, 29 min",
  },
  {
    "value" : 210,
    "time"  : "3 hr, 30 min",
  },
  {
    "value" : 211,
    "time"  : "3 hr, 31 min",
  },
  {
    "value" : 212,
    "time"  : "3 hr, 32 min",
  },
  {
    "value" : 213,
    "time"  : "3 hr, 33 min",
  },
  {
    "value" : 214,
    "time"  : "3 hr, 34 min",
  },
  {
    "value" : 215,
    "time"  : "3 hr, 35 min",
  },
  {
    "value" : 216,
    "time"  : "3 hr, 36 min",
  },
  {
    "value" : 217,
    "time"  : "3 hr, 37 min",
  },
  {
    "value" : 218,
    "time"  : "3 hr, 38 min",
  },
  {
    "value" : 219,
    "time"  : "3 hr, 39 min",
  },
  {
    "value" : 220,
    "time"  : "3 hr, 40 min",
  },
  {
    "value" : 221,
    "time"  : "3 hr, 41 min",
  },
  {
    "value" : 222,
    "time"  : "3 hr, 42 min",
  },
  {
    "value" : 223,
    "time"  : "3 hr, 43 min",
  },
  {
    "value" : 224,
    "time"  : "3 hr, 44 min",
  },
  {
    "value" : 225,
    "time"  : "3 hr, 45 min",
  },
  {
    "value" : 226,
    "time"  : "3 hr, 46 min",
  },
  {
    "value" : 227,
    "time"  : "3 hr, 47 min",
  },
  {
    "value" : 228,
    "time"  : "3 hr, 48 min",
  },
  {
    "value" : 229,
    "time"  : "3 hr, 49 min",
  },
  {
    "value" : 230,
    "time"  : "3 hr, 50 min",
  },
  {
    "value" : 231,
    "time"  : "3 hr, 51 min",
  },
  {
    "value" : 232,
    "time"  : "3 hr, 52 min",
  },
  {
    "value" : 233,
    "time"  : "3 hr, 53 min",
  },
  {
    "value" : 234,
    "time"  : "3 hr, 54 min",
  },
  {
    "value" : 235,
    "time"  : "3 hr, 55 min",
  },
  {
    "value" : 236,
    "time"  : "3 hr, 56 min",
  },
  {
    "value" : 237,
    "time"  : "3 hr, 57 min",
  },
  {
    "value" : 238,
    "time"  : "3 hr, 58 min",
  },
  {
    "value" : 239,
    "time"  : "3 hr, 59 min",
  },
  {
    "value" : 240,
    "time"  : "4 hours",
  },
  {
    "value" : 241,
    "time"  : "4 hr, 01 min",
  },
  {
    "value" : 242,
    "time"  : "4 hr, 02 min",
  },
  {
    "value" : 243,
    "time"  : "4 hr, 03 min",
  },
  {
    "value" : 244,
    "time"  : "4 hr, 04 min",
  },
  {
    "value" : 245,
    "time"  : "4 hr, 05 min",
  },
  {
    "value" : 246,
    "time"  : "4 hr, 06 min",
  },
  {
    "value" : 247,
    "time"  : "4 hr, 07 min",
  },
  {
    "value" : 248,
    "time"  : "4 hr, 08 min",
  },
  {
    "value" : 249,
    "time"  : "4 hr, 09 min",
  },
  {
    "value" : 250,
    "time"  : "4 hr, 10 min",
  },
  {
    "value" : 251,
    "time"  : "4 hr, 11 min",
  },
  {
    "value" : 252,
    "time"  : "4 hr, 12 min",
  },
  {
    "value" : 253,
    "time"  : "4 hr, 13 min",
  },
  {
    "value" : 254,
    "time"  : "4 hr, 14 min",
  },
  {
    "value" : 255,
    "time"  : "4 hr, 15 min",
  },
  {
    "value" : 256,
    "time"  : "4 hr, 16 min",
  },
  {
    "value" : 257,
    "time"  : "4 hr, 17 min",
  },
  {
    "value" : 258,
    "time"  : "4 hr, 18 min",
  },
  {
    "value" : 259,
    "time"  : "4 hr, 19 min",
  },
  {
    "value" : 260,
    "time"  : "4 hr, 20 min",
  },
  {
    "value" : 261,
    "time"  : "4 hr, 21 min",
  },
  {
    "value" : 262,
    "time"  : "4 hr, 22 min",
  },
  {
    "value" : 263,
    "time"  : "4 hr, 23 min",
  },
  {
    "value" : 264,
    "time"  : "4 hr, 24 min",
  },
  {
    "value" : 265,
    "time"  : "4 hr, 25 min",
  },
  {
    "value" : 266,
    "time"  : "4 hr, 26 min",
  },
  {
    "value" : 267,
    "time"  : "4 hr, 27 min",
  },
  {
    "value" : 268,
    "time"  : "4 hr, 28 min",
  },
  {
    "value" : 269,
    "time"  : "4 hr, 29 min",
  },
  {
    "value" : 270,
    "time"  : "4 hr, 30 min",
  },
  {
    "value" : 271,
    "time"  : "4 hr, 31 min",
  },
  {
    "value" : 272,
    "time"  : "4 hr, 32 min",
  },
  {
    "value" : 273,
    "time"  : "4 hr, 33 min",
  },
  {
    "value" : 274,
    "time"  : "4 hr, 34 min",
  },
  {
    "value" : 275,
    "time"  : "4 hr, 35 min",
  },
  {
    "value" : 276,
    "time"  : "4 hr, 36 min",
  },
  {
    "value" : 277,
    "time"  : "4 hr, 37 min",
  },
  {
    "value" : 278,
    "time"  : "4 hr, 38 min",
  },
  {
    "value" : 279,
    "time"  : "4 hr, 39 min",
  },
  {
    "value" : 280,
    "time"  : "4 hr, 40 min",
  },
  {
    "value" : 281,
    "time"  : "4 hr, 41 min",
  },
  {
    "value" : 282,
    "time"  : "4 hr, 42 min",
  },
  {
    "value" : 283,
    "time"  : "4 hr, 43 min",
  },
  {
    "value" : 284,
    "time"  : "4 hr, 44 min",
  },
  {
    "value" : 285,
    "time"  : "4 hr, 45 min",
  },
  {
    "value" : 286,
    "time"  : "4 hr, 46 min",
  },
  {
    "value" : 287,
    "time"  : "4 hr, 47 min",
  },
  {
    "value" : 288,
    "time"  : "4 hr, 48 min",
  },
  {
    "value" : 289,
    "time"  : "4 hr, 49 min",
  },
  {
    "value" : 290,
    "time"  : "4 hr, 50 min",
  },
  {
    "value" : 291,
    "time"  : "4 hr, 51 min",
  },
  {
    "value" : 292,
    "time"  : "4 hr, 52 min",
  },
  {
    "value" : 293,
    "time"  : "4 hr, 53 min",
  },
  {
    "value" : 294,
    "time"  : "4 hr, 54 min",
  },
  {
    "value" : 295,
    "time"  : "4 hr, 55 min",
  },
  {
    "value" : 296,
    "time"  : "4 hr, 56 min",
  },
  {
    "value" : 297,
    "time"  : "4 hr, 57 min",
  },
  {
    "value" : 298,
    "time"  : "4 hr, 58 min",
  },
  {
    "value" : 299,
    "time"  : "4 hr, 59 min",
  },
  {
    "value" : 300,
    "time"  : "5 hours",
  },
  {
    "value" : 301,
    "time"  : "5 hr, 01 min",
  },
  {
    "value" : 302,
    "time"  : "5 hr, 02 min",
  },
  {
    "value" : 303,
    "time"  : "5 hr, 03 min",
  },
  {
    "value" : 304,
    "time"  : "5 hr, 04 min",
  },
  {
    "value" : 305,
    "time"  : "5 hr, 05 min",
  },
  {
    "value" : 306,
    "time"  : "5 hr, 06 min",
  },
  {
    "value" : 307,
    "time"  : "5 hr, 07 min",
  },
  {
    "value" : 308,
    "time"  : "5 hr, 08 min",
  },
  {
    "value" : 309,
    "time"  : "5 hr, 09 min",
  },
  {
    "value" : 310,
    "time"  : "5 hr, 10 min",
  },
  {
    "value" : 311,
    "time"  : "5 hr, 11 min",
  },
  {
    "value" : 312,
    "time"  : "5 hr, 12 min",
  },
  {
    "value" : 313,
    "time"  : "5 hr, 13 min",
  },
  {
    "value" : 314,
    "time"  : "5 hr, 14 min",
  },
  {
    "value" : 315,
    "time"  : "5 hr, 15 min",
  },
  {
    "value" : 316,
    "time"  : "5 hr, 16 min",
  },
  {
    "value" : 317,
    "time"  : "5 hr, 17 min",
  },
  {
    "value" : 318,
    "time"  : "5 hr, 18 min",
  },
  {
    "value" : 319,
    "time"  : "5 hr, 19 min",
  },
  {
    "value" : 320,
    "time"  : "5 hr, 20 min",
  },
  {
    "value" : 321,
    "time"  : "5 hr, 21 min",
  },
  {
    "value" : 322,
    "time"  : "5 hr, 22 min",
  },
  {
    "value" : 323,
    "time"  : "5 hr, 23 min",
  },
  {
    "value" : 324,
    "time"  : "5 hr, 24 min",
  },
  {
    "value" : 325,
    "time"  : "5 hr, 25 min",
  },
  {
    "value" : 326,
    "time"  : "5 hr, 26 min",
  },
  {
    "value" : 327,
    "time"  : "5 hr, 27 min",
  },
  {
    "value" : 328,
    "time"  : "5 hr, 28 min",
  },
  {
    "value" : 329,
    "time"  : "5 hr, 29 min",
  },
  {
    "value" : 330,
    "time"  : "5 hr, 30 min",
  },
  {
    "value" : 331,
    "time"  : "5 hr, 31 min",
  },
  {
    "value" : 332,
    "time"  : "5 hr, 32 min",
  },
  {
    "value" : 333,
    "time"  : "5 hr, 33 min",
  },
  {
    "value" : 334,
    "time"  : "5 hr, 34 min",
  },
  {
    "value" : 335,
    "time"  : "5 hr, 35 min",
  },
  {
    "value" : 336,
    "time"  : "5 hr, 36 min",
  },
  {
    "value" : 337,
    "time"  : "5 hr, 37 min",
  },
  {
    "value" : 338,
    "time"  : "5 hr, 38 min",
  },
  {
    "value" : 339,
    "time"  : "5 hr, 39 min",
  },
  {
    "value" : 340,
    "time"  : "5 hr, 40 min",
  },
  {
    "value" : 341,
    "time"  : "5 hr, 41 min",
  },
  {
    "value" : 342,
    "time"  : "5 hr, 42 min",
  },
  {
    "value" : 343,
    "time"  : "5 hr, 43 min",
  },
  {
    "value" : 344,
    "time"  : "5 hr, 44 min",
  },
  {
    "value" : 345,
    "time"  : "5 hr, 45 min",
  },
  {
    "value" : 346,
    "time"  : "5 hr, 46 min",
  },
  {
    "value" : 347,
    "time"  : "5 hr, 47 min",
  },
  {
    "value" : 348,
    "time"  : "5 hr, 48 min",
  },
  {
    "value" : 349,
    "time"  : "5 hr, 49 min",
  },
  {
    "value" : 350,
    "time"  : "5 hr, 50 min",
  },
  {
    "value" : 351,
    "time"  : "5 hr, 51 min",
  },
  {
    "value" : 352,
    "time"  : "5 hr, 52 min",
  },
  {
    "value" : 353,
    "time"  : "5 hr, 53 min",
  },
  {
    "value" : 354,
    "time"  : "5 hr, 54 min",
  },
  {
    "value" : 355,
    "time"  : "5 hr, 55 min",
  },
  {
    "value" : 356,
    "time"  : "5 hr, 56 min",
  },
  {
    "value" : 357,
    "time"  : "5 hr, 57 min",
  },
  {
    "value" : 358,
    "time"  : "5 hr, 58 min",
  },
  {
    "value" : 359,
    "time"  : "5 hr, 59 min",
  },
  {
    "value" : 360,
    "time"  : "6 hours",
  },
  {
    "value" : 361,
    "time"  : "6 hr, 01 min",
  },
  {
    "value" : 362,
    "time"  : "6 hr, 02 min",
  },
  {
    "value" : 363,
    "time"  : "6 hr, 03 min",
  },
  {
    "value" : 364,
    "time"  : "6 hr, 04 min",
  },
  {
    "value" : 365,
    "time"  : "6 hr, 05 min",
  },
  {
    "value" : 366,
    "time"  : "6 hr, 06 min",
  },
  {
    "value" : 367,
    "time"  : "6 hr, 07 min",
  },
  {
    "value" : 368,
    "time"  : "6 hr, 08 min",
  },
  {
    "value" : 369,
    "time"  : "6 hr, 09 min",
  },
  {
    "value" : 370,
    "time"  : "6 hr, 10 min",
  },
  {
    "value" : 371,
    "time"  : "6 hr, 11 min",
  },
  {
    "value" : 372,
    "time"  : "6 hr, 12 min",
  },
  {
    "value" : 373,
    "time"  : "6 hr, 13 min",
  },
  {
    "value" : 374,
    "time"  : "6 hr, 14 min",
  },
  {
    "value" : 375,
    "time"  : "6 hr, 15 min",
  },
  {
    "value" : 376,
    "time"  : "6 hr, 16 min",
  },
  {
    "value" : 377,
    "time"  : "6 hr, 17 min",
  },
  {
    "value" : 378,
    "time"  : "6 hr, 18 min",
  },
  {
    "value" : 379,
    "time"  : "6 hr, 19 min",
  },
  {
    "value" : 380,
    "time"  : "6 hr, 20 min",
  },
  {
    "value" : 381,
    "time"  : "6 hr, 21 min",
  },
  {
    "value" : 382,
    "time"  : "6 hr, 22 min",
  },
  {
    "value" : 383,
    "time"  : "6 hr, 23 min",
  },
  {
    "value" : 384,
    "time"  : "6 hr, 24 min",
  },
  {
    "value" : 385,
    "time"  : "6 hr, 25 min",
  },
  {
    "value" : 386,
    "time"  : "6 hr, 26 min",
  },
  {
    "value" : 387,
    "time"  : "6 hr, 27 min",
  },
  {
    "value" : 388,
    "time"  : "6 hr, 28 min",
  },
  {
    "value" : 389,
    "time"  : "6 hr, 29 min",
  },
  {
    "value" : 390,
    "time"  : "6 hr, 30 min",
  },
  {
    "value" : 391,
    "time"  : "6 hr, 31 min",
  },
  {
    "value" : 392,
    "time"  : "6 hr, 32 min",
  },
  {
    "value" : 393,
    "time"  : "6 hr, 33 min",
  },
  {
    "value" : 394,
    "time"  : "6 hr, 34 min",
  },
  {
    "value" : 395,
    "time"  : "6 hr, 35 min",
  },
  {
    "value" : 396,
    "time"  : "6 hr, 36 min",
  },
  {
    "value" : 397,
    "time"  : "6 hr, 37 min",
  },
  {
    "value" : 398,
    "time"  : "6 hr, 38 min",
  },
  {
    "value" : 399,
    "time"  : "6 hr, 39 min",
  },
  {
    "value" : 400,
    "time"  : "6 hr, 40 min",
  },
  {
    "value" : 401,
    "time"  : "6 hr, 41 min",
  },
  {
    "value" : 402,
    "time"  : "6 hr, 42 min",
  },
  {
    "value" : 403,
    "time"  : "6 hr, 43 min",
  },
  {
    "value" : 404,
    "time"  : "6 hr, 44 min",
  },
  {
    "value" : 405,
    "time"  : "6 hr, 45 min",
  },
  {
    "value" : 406,
    "time"  : "6 hr, 46 min",
  },
  {
    "value" : 407,
    "time"  : "6 hr, 47 min",
  },
  {
    "value" : 408,
    "time"  : "6 hr, 48 min",
  },
  {
    "value" : 409,
    "time"  : "6 hr, 49 min",
  },
  {
    "value" : 410,
    "time"  : "6 hr, 50 min",
  },
  {
    "value" : 411,
    "time"  : "6 hr, 51 min",
  },
  {
    "value" : 412,
    "time"  : "6 hr, 52 min",
  },
  {
    "value" : 413,
    "time"  : "6 hr, 53 min",
  },
  {
    "value" : 414,
    "time"  : "6 hr, 54 min",
  },
  {
    "value" : 415,
    "time"  : "6 hr, 55 min",
  },
  {
    "value" : 416,
    "time"  : "6 hr, 56 min",
  },
  {
    "value" : 417,
    "time"  : "6 hr, 57 min",
  },
  {
    "value" : 418,
    "time"  : "6 hr, 58 min",
  },
  {
    "value" : 419,
    "time"  : "6 hr, 59 min",
  },
  {
    "value" : 420,
    "time"  : "7 hours",
  },
  {
    "value" : 421,
    "time"  : "7 hr, 01 min",
  },
  {
    "value" : 422,
    "time"  : "7 hr, 02 min",
  },
  {
    "value" : 423,
    "time"  : "7 hr, 03 min",
  },
  {
    "value" : 424,
    "time"  : "7 hr, 04 min",
  },
  {
    "value" : 425,
    "time"  : "7 hr, 05 min",
  },
  {
    "value" : 426,
    "time"  : "7 hr, 06 min",
  },
  {
    "value" : 427,
    "time"  : "7 hr, 07 min",
  },
  {
    "value" : 428,
    "time"  : "7 hr, 08 min",
  },
  {
    "value" : 429,
    "time"  : "7 hr, 09 min",
  },
  {
    "value" : 430,
    "time"  : "7 hr, 10 min",
  },
  {
    "value" : 431,
    "time"  : "7 hr, 11 min",
  },
  {
    "value" : 432,
    "time"  : "7 hr, 12 min",
  },
  {
    "value" : 433,
    "time"  : "7 hr, 13 min",
  },
  {
    "value" : 434,
    "time"  : "7 hr, 14 min",
  },
  {
    "value" : 435,
    "time"  : "7 hr, 15 min",
  },
  {
    "value" : 436,
    "time"  : "7 hr, 16 min",
  },
  {
    "value" : 437,
    "time"  : "7 hr, 17 min",
  },
  {
    "value" : 438,
    "time"  : "7 hr, 18 min",
  },
  {
    "value" : 439,
    "time"  : "7 hr, 19 min",
  },
  {
    "value" : 440,
    "time"  : "7 hr, 20 min",
  },
  {
    "value" : 441,
    "time"  : "7 hr, 21 min",
  },
  {
    "value" : 442,
    "time"  : "7 hr, 22 min",
  },
  {
    "value" : 443,
    "time"  : "7 hr, 23 min",
  },
  {
    "value" : 444,
    "time"  : "7 hr, 24 min",
  },
  {
    "value" : 445,
    "time"  : "7 hr, 25 min",
  },
  {
    "value" : 446,
    "time"  : "7 hr, 26 min",
  },
  {
    "value" : 447,
    "time"  : "7 hr, 27 min",
  },
  {
    "value" : 448,
    "time"  : "7 hr, 28 min",
  },
  {
    "value" : 449,
    "time"  : "7 hr, 29 min",
  },
  {
    "value" : 450,
    "time"  : "7 hr, 30 min",
  },
  {
    "value" : 451,
    "time"  : "7 hr, 31 min",
  },
  {
    "value" : 452,
    "time"  : "7 hr, 32 min",
  },
  {
    "value" : 453,
    "time"  : "7 hr, 33 min",
  },
  {
    "value" : 454,
    "time"  : "7 hr, 34 min",
  },
  {
    "value" : 455,
    "time"  : "7 hr, 35 min",
  },
  {
    "value" : 456,
    "time"  : "7 hr, 36 min",
  },
  {
    "value" : 457,
    "time"  : "7 hr, 37 min",
  },
  {
    "value" : 458,
    "time"  : "7 hr, 38 min",
  },
  {
    "value" : 459,
    "time"  : "7 hr, 39 min",
  },
  {
    "value" : 460,
    "time"  : "7 hr, 40 min",
  },
  {
    "value" : 461,
    "time"  : "7 hr, 41 min",
  },
  {
    "value" : 462,
    "time"  : "7 hr, 42 min",
  },
  {
    "value" : 463,
    "time"  : "7 hr, 43 min",
  },
  {
    "value" : 464,
    "time"  : "7 hr, 44 min",
  },
  {
    "value" : 465,
    "time"  : "7 hr, 45 min",
  },
  {
    "value" : 466,
    "time"  : "7 hr, 46 min",
  },
  {
    "value" : 467,
    "time"  : "7 hr, 47 min",
  },
  {
    "value" : 468,
    "time"  : "7 hr, 48 min",
  },
  {
    "value" : 469,
    "time"  : "7 hr, 49 min",
  },
  {
    "value" : 470,
    "time"  : "7 hr, 50 min",
  },
  {
    "value" : 471,
    "time"  : "7 hr, 51 min",
  },
  {
    "value" : 472,
    "time"  : "7 hr, 52 min",
  },
  {
    "value" : 473,
    "time"  : "7 hr, 53 min",
  },
  {
    "value" : 474,
    "time"  : "7 hr, 54 min",
  },
  {
    "value" : 475,
    "time"  : "7 hr, 55 min",
  },
  {
    "value" : 476,
    "time"  : "7 hr, 56 min",
  },
  {
    "value" : 477,
    "time"  : "7 hr, 57 min",
  },
  {
    "value" : 478,
    "time"  : "7 hr, 58 min",
  },
  {
    "value" : 479,
    "time"  : "7 hr, 59 min",
  },
  {
    "value" : 480,
    "time"  : "8 hours",
  },
  {
    "value" : 481,
    "time"  : "8 hr, 01 min",
  },
  {
    "value" : 482,
    "time"  : "8 hr, 02 min",
  },
  {
    "value" : 483,
    "time"  : "8 hr, 03 min",
  },
  {
    "value" : 484,
    "time"  : "8 hr, 04 min",
  },
  {
    "value" : 485,
    "time"  : "8 hr, 05 min",
  },
  {
    "value" : 486,
    "time"  : "8 hr, 06 min",
  },
  {
    "value" : 487,
    "time"  : "8 hr, 07 min",
  },
  {
    "value" : 488,
    "time"  : "8 hr, 08 min",
  },
  {
    "value" : 489,
    "time"  : "8 hr, 09 min",
  },
  {
    "value" : 490,
    "time"  : "8 hr, 10 min",
  },
  {
    "value" : 491,
    "time"  : "8 hr, 11 min",
  },
  {
    "value" : 492,
    "time"  : "8 hr, 12 min",
  },
  {
    "value" : 493,
    "time"  : "8 hr, 13 min",
  },
  {
    "value" : 494,
    "time"  : "8 hr, 14 min",
  },
  {
    "value" : 495,
    "time"  : "8 hr, 15 min",
  },
  {
    "value" : 496,
    "time"  : "8 hr, 16 min",
  },
  {
    "value" : 497,
    "time"  : "8 hr, 17 min",
  },
  {
    "value" : 498,
    "time"  : "8 hr, 18 min",
  },
  {
    "value" : 499,
    "time"  : "8 hr, 19 min",
  },
  {
    "value" : 500,
    "time"  : "8 hr, 20 min",
  },
  {
    "value" : 501,
    "time"  : "8 hr, 21 min",
  },
  {
    "value" : 502,
    "time"  : "8 hr, 22 min",
  },
  {
    "value" : 503,
    "time"  : "8 hr, 23 min",
  },
  {
    "value" : 504,
    "time"  : "8 hr, 24 min",
  },
  {
    "value" : 505,
    "time"  : "8 hr, 25 min",
  },
  {
    "value" : 506,
    "time"  : "8 hr, 26 min",
  },
  {
    "value" : 507,
    "time"  : "8 hr, 27 min",
  },
  {
    "value" : 508,
    "time"  : "8 hr, 28 min",
  },
  {
    "value" : 509,
    "time"  : "8 hr, 29 min",
  },
  {
    "value" : 510,
    "time"  : "8 hr, 30 min",
  },
  {
    "value" : 511,
    "time"  : "8 hr, 31 min",
  },
  {
    "value" : 512,
    "time"  : "8 hr, 32 min",
  },
  {
    "value" : 513,
    "time"  : "8 hr, 33 min",
  },
  {
    "value" : 514,
    "time"  : "8 hr, 34 min",
  },
  {
    "value" : 515,
    "time"  : "8 hr, 35 min",
  },
  {
    "value" : 516,
    "time"  : "8 hr, 36 min",
  },
  {
    "value" : 517,
    "time"  : "8 hr, 37 min",
  },
  {
    "value" : 518,
    "time"  : "8 hr, 38 min",
  },
  {
    "value" : 519,
    "time"  : "8 hr, 39 min",
  },
  {
    "value" : 520,
    "time"  : "8 hr, 40 min",
  },
  {
    "value" : 521,
    "time"  : "8 hr, 41 min",
  },
  {
    "value" : 522,
    "time"  : "8 hr, 42 min",
  },
  {
    "value" : 523,
    "time"  : "8 hr, 43 min",
  },
  {
    "value" : 524,
    "time"  : "8 hr, 44 min",
  },
  {
    "value" : 525,
    "time"  : "8 hr, 45 min",
  },
  {
    "value" : 526,
    "time"  : "8 hr, 46 min",
  },
  {
    "value" : 527,
    "time"  : "8 hr, 47 min",
  },
  {
    "value" : 528,
    "time"  : "8 hr, 48 min",
  },
  {
    "value" : 529,
    "time"  : "8 hr, 49 min",
  },
  {
    "value" : 530,
    "time"  : "8 hr, 50 min",
  },
  {
    "value" : 531,
    "time"  : "8 hr, 51 min",
  },
  {
    "value" : 532,
    "time"  : "8 hr, 52 min",
  },
  {
    "value" : 533,
    "time"  : "8 hr, 53 min",
  },
  {
    "value" : 534,
    "time"  : "8 hr, 54 min",
  },
  {
    "value" : 535,
    "time"  : "8 hr, 55 min",
  },
  {
    "value" : 536,
    "time"  : "8 hr, 56 min",
  },
  {
    "value" : 537,
    "time"  : "8 hr, 57 min",
  },
  {
    "value" : 538,
    "time"  : "8 hr, 58 min",
  },
  {
    "value" : 539,
    "time"  : "8 hr, 59 min",
  },
  {
    "value" : 540,
    "time"  : "9 hours",
  },
  {
    "value" : 541,
    "time"  : "9 hr, 01 min",
  },
  {
    "value" : 542,
    "time"  : "9 hr, 02 min",
  },
  {
    "value" : 543,
    "time"  : "9 hr, 03 min",
  },
  {
    "value" : 544,
    "time"  : "9 hr, 04 min",
  },
  {
    "value" : 545,
    "time"  : "9 hr, 05 min",
  },
  {
    "value" : 546,
    "time"  : "9 hr, 06 min",
  },
  {
    "value" : 547,
    "time"  : "9 hr, 07 min",
  },
  {
    "value" : 548,
    "time"  : "9 hr, 08 min",
  },
  {
    "value" : 549,
    "time"  : "9 hr, 09 min",
  },
  {
    "value" : 550,
    "time"  : "9 hr, 10 min",
  },
  {
    "value" : 551,
    "time"  : "9 hr, 11 min",
  },
  {
    "value" : 552,
    "time"  : "9 hr, 12 min",
  },
  {
    "value" : 553,
    "time"  : "9 hr, 13 min",
  },
  {
    "value" : 554,
    "time"  : "9 hr, 14 min",
  },
  {
    "value" : 555,
    "time"  : "9 hr, 15 min",
  },
  {
    "value" : 556,
    "time"  : "9 hr, 16 min",
  },
  {
    "value" : 557,
    "time"  : "9 hr, 17 min",
  },
  {
    "value" : 558,
    "time"  : "9 hr, 18 min",
  },
  {
    "value" : 559,
    "time"  : "9 hr, 19 min",
  },
  {
    "value" : 560,
    "time"  : "9 hr, 20 min",
  },
  {
    "value" : 561,
    "time"  : "9 hr, 21 min",
  },
  {
    "value" : 562,
    "time"  : "9 hr, 22 min",
  },
  {
    "value" : 563,
    "time"  : "9 hr, 23 min",
  },
  {
    "value" : 564,
    "time"  : "9 hr, 24 min",
  },
  {
    "value" : 565,
    "time"  : "9 hr, 25 min",
  },
  {
    "value" : 566,
    "time"  : "9 hr, 26 min",
  },
  {
    "value" : 567,
    "time"  : "9 hr, 27 min",
  },
  {
    "value" : 568,
    "time"  : "9 hr, 28 min",
  },
  {
    "value" : 569,
    "time"  : "9 hr, 29 min",
  },
  {
    "value" : 570,
    "time"  : "9 hr, 30 min",
  },
  {
    "value" : 571,
    "time"  : "9 hr, 31 min",
  },
  {
    "value" : 572,
    "time"  : "9 hr, 32 min",
  },
  {
    "value" : 573,
    "time"  : "9 hr, 33 min",
  },
  {
    "value" : 574,
    "time"  : "9 hr, 34 min",
  },
  {
    "value" : 575,
    "time"  : "9 hr, 35 min",
  },
  {
    "value" : 576,
    "time"  : "9 hr, 36 min",
  },
  {
    "value" : 577,
    "time"  : "9 hr, 37 min",
  },
  {
    "value" : 578,
    "time"  : "9 hr, 38 min",
  },
  {
    "value" : 579,
    "time"  : "9 hr, 39 min",
  },
  {
    "value" : 580,
    "time"  : "9 hr, 40 min",
  },
  {
    "value" : 581,
    "time"  : "9 hr, 41 min",
  },
  {
    "value" : 582,
    "time"  : "9 hr, 42 min",
  },
  {
    "value" : 583,
    "time"  : "9 hr, 43 min",
  },
  {
    "value" : 584,
    "time"  : "9 hr, 44 min",
  },
  {
    "value" : 585,
    "time"  : "9 hr, 45 min",
  },
  {
    "value" : 586,
    "time"  : "9 hr, 46 min",
  },
  {
    "value" : 587,
    "time"  : "9 hr, 47 min",
  },
  {
    "value" : 588,
    "time"  : "9 hr, 48 min",
  },
  {
    "value" : 589,
    "time"  : "9 hr, 49 min",
  },
  {
    "value" : 590,
    "time"  : "9 hr, 50 min",
  },
  {
    "value" : 591,
    "time"  : "9 hr, 51 min",
  },
  {
    "value" : 592,
    "time"  : "9 hr, 52 min",
  },
  {
    "value" : 593,
    "time"  : "9 hr, 53 min",
  },
  {
    "value" : 594,
    "time"  : "9 hr, 54 min",
  },
  {
    "value" : 595,
    "time"  : "9 hr, 55 min",
  },
  {
    "value" : 596,
    "time"  : "9 hr, 56 min",
  },
  {
    "value" : 597,
    "time"  : "9 hr, 57 min",
  },
  {
    "value" : 598,
    "time"  : "9 hr, 58 min",
  },
  {
    "value" : 599,
    "time"  : "9 hr, 59 min",
  },
  {
    "value" : 600,
    "time"  : "10 hours",
  },
  {
    "value" : 601,
    "time"  : "10 hr, 01 min",
  },
  {
    "value" : 602,
    "time"  : "10 hr, 02 min",
  },
  {
    "value" : 603,
    "time"  : "10 hr, 03 min",
  },
  {
    "value" : 604,
    "time"  : "10 hr, 04 min",
  },
  {
    "value" : 605,
    "time"  : "10 hr, 05 min",
  },
  {
    "value" : 606,
    "time"  : "10 hr, 06 min",
  },
  {
    "value" : 607,
    "time"  : "10 hr, 07 min",
  },
  {
    "value" : 608,
    "time"  : "10 hr, 08 min",
  },
  {
    "value" : 609,
    "time"  : "10 hr, 09 min",
  },
  {
    "value" : 610,
    "time"  : "10 hr, 10 min",
  },
  {
    "value" : 611,
    "time"  : "10 hr, 11 min",
  },
  {
    "value" : 612,
    "time"  : "10 hr, 12 min",
  },
  {
    "value" : 613,
    "time"  : "10 hr, 13 min",
  },
  {
    "value" : 614,
    "time"  : "10 hr, 14 min",
  },
  {
    "value" : 615,
    "time"  : "10 hr, 15 min",
  },
  {
    "value" : 616,
    "time"  : "10 hr, 16 min",
  },
  {
    "value" : 617,
    "time"  : "10 hr, 17 min",
  },
  {
    "value" : 618,
    "time"  : "10 hr, 18 min",
  },
  {
    "value" : 619,
    "time"  : "10 hr, 19 min",
  },
  {
    "value" : 620,
    "time"  : "10 hr, 20 min",
  },
  {
    "value" : 621,
    "time"  : "10 hr, 21 min",
  },
  {
    "value" : 622,
    "time"  : "10 hr, 22 min",
  },
  {
    "value" : 623,
    "time"  : "10 hr, 23 min",
  },
  {
    "value" : 624,
    "time"  : "10 hr, 24 min",
  },
  {
    "value" : 625,
    "time"  : "10 hr, 25 min",
  },
  {
    "value" : 626,
    "time"  : "10 hr, 26 min",
  },
  {
    "value" : 627,
    "time"  : "10 hr, 27 min",
  },
  {
    "value" : 628,
    "time"  : "10 hr, 28 min",
  },
  {
    "value" : 629,
    "time"  : "10 hr, 29 min",
  },
  {
    "value" : 630,
    "time"  : "10 hr, 30 min",
  },
  {
    "value" : 631,
    "time"  : "10 hr, 31 min",
  },
  {
    "value" : 632,
    "time"  : "10 hr, 32 min",
  },
  {
    "value" : 633,
    "time"  : "10 hr, 33 min",
  },
  {
    "value" : 634,
    "time"  : "10 hr, 34 min",
  },
  {
    "value" : 635,
    "time"  : "10 hr, 35 min",
  },
  {
    "value" : 636,
    "time"  : "10 hr, 36 min",
  },
  {
    "value" : 637,
    "time"  : "10 hr, 37 min",
  },
  {
    "value" : 638,
    "time"  : "10 hr, 38 min",
  },
  {
    "value" : 639,
    "time"  : "10 hr, 39 min",
  },
  {
    "value" : 640,
    "time"  : "10 hr, 40 min",
  },
  {
    "value" : 641,
    "time"  : "10 hr, 41 min",
  },
  {
    "value" : 642,
    "time"  : "10 hr, 42 min",
  },
  {
    "value" : 643,
    "time"  : "10 hr, 43 min",
  },
  {
    "value" : 644,
    "time"  : "10 hr, 44 min",
  },
  {
    "value" : 645,
    "time"  : "10 hr, 45 min",
  },
  {
    "value" : 646,
    "time"  : "10 hr, 46 min",
  },
  {
    "value" : 647,
    "time"  : "10 hr, 47 min",
  },
  {
    "value" : 648,
    "time"  : "10 hr, 48 min",
  },
  {
    "value" : 649,
    "time"  : "10 hr, 49 min",
  },
  {
    "value" : 650,
    "time"  : "10 hr, 50 min",
  },
  {
    "value" : 651,
    "time"  : "10 hr, 51 min",
  },
  {
    "value" : 652,
    "time"  : "10 hr, 52 min",
  },
  {
    "value" : 653,
    "time"  : "10 hr, 53 min",
  },
  {
    "value" : 654,
    "time"  : "10 hr, 54 min",
  },
  {
    "value" : 655,
    "time"  : "10 hr, 55 min",
  },
  {
    "value" : 656,
    "time"  : "10 hr, 56 min",
  },
  {
    "value" : 657,
    "time"  : "10 hr, 57 min",
  },
  {
    "value" : 658,
    "time"  : "10 hr, 58 min",
  },
  {
    "value" : 659,
    "time"  : "10 hr, 59 min",
  },
  {
    "value" : 660,
    "time"  : "11 hours",
  },
  {
    "value" : 661,
    "time"  : "11h, 01 min",
  },
  {
    "value" : 662,
    "time"  : "11h, 02 min",
  },
  {
    "value" : 663,
    "time"  : "11h, 03 min",
  },
  {
    "value" : 664,
    "time"  : "11h, 04 min",
  },
  {
    "value" : 665,
    "time"  : "11h, 05 min",
  },
  {
    "value" : 666,
    "time"  : "11h, 06 min",
  },
  {
    "value" : 667,
    "time"  : "11h, 07 min",
  },
  {
    "value" : 668,
    "time"  : "11h, 08 min",
  },
  {
    "value" : 669,
    "time"  : "11h, 09 min",
  },
  {
    "value" : 670,
    "time"  : "11h, 10 min",
  },
  {
    "value" : 671,
    "time"  : "11h, 11 min",
  },
  {
    "value" : 672,
    "time"  : "11h, 12 min",
  },
  {
    "value" : 673,
    "time"  : "11h, 13 min",
  },
  {
    "value" : 674,
    "time"  : "11h, 14 min",
  },
  {
    "value" : 675,
    "time"  : "11h, 15 min",
  },
  {
    "value" : 676,
    "time"  : "11h, 16 min",
  },
  {
    "value" : 677,
    "time"  : "11h, 17 min",
  },
  {
    "value" : 678,
    "time"  : "11h, 18 min",
  },
  {
    "value" : 679,
    "time"  : "11h, 19 min",
  },
  {
    "value" : 680,
    "time"  : "11h, 20 min",
  },
  {
    "value" : 681,
    "time"  : "11h, 21 min",
  },
  {
    "value" : 682,
    "time"  : "11h, 22 min",
  },
  {
    "value" : 683,
    "time"  : "11h, 23 min",
  },
  {
    "value" : 684,
    "time"  : "11h, 24 min",
  },
  {
    "value" : 685,
    "time"  : "11h, 25 min",
  },
  {
    "value" : 686,
    "time"  : "11h, 26 min",
  },
  {
    "value" : 687,
    "time"  : "11h, 27 min",
  },
  {
    "value" : 688,
    "time"  : "11h, 28 min",
  },
  {
    "value" : 689,
    "time"  : "11h, 29 min",
  },
  {
    "value" : 690,
    "time"  : "11h, 30 min",
  },
  {
    "value" : 691,
    "time"  : "11h, 31 min",
  },
  {
    "value" : 692,
    "time"  : "11h, 32 min",
  },
  {
    "value" : 693,
    "time"  : "11h, 33 min",
  },
  {
    "value" : 694,
    "time"  : "11h, 34 min",
  },
  {
    "value" : 695,
    "time"  : "11h, 35 min",
  },
  {
    "value" : 696,
    "time"  : "11h, 36 min",
  },
  {
    "value" : 697,
    "time"  : "11h, 37 min",
  },
  {
    "value" : 698,
    "time"  : "11h, 38 min",
  },
  {
    "value" : 699,
    "time"  : "11h, 39 min",
  },
  {
    "value" : 700,
    "time"  : "11h, 40 min",
  },
  {
    "value" : 701,
    "time"  : "11h, 41 min",
  },
  {
    "value" : 702,
    "time"  : "11h, 42 min",
  },
  {
    "value" : 703,
    "time"  : "11h, 43 min",
  },
  {
    "value" : 704,
    "time"  : "11h, 44 min",
  },
  {
    "value" : 705,
    "time"  : "11h, 45 min",
  },
  {
    "value" : 706,
    "time"  : "11h, 46 min",
  },
  {
    "value" : 707,
    "time"  : "11h, 47 min",
  },
  {
    "value" : 708,
    "time"  : "11h, 48 min",
  },
  {
    "value" : 709,
    "time"  : "11h, 49 min",
  },
  {
    "value" : 710,
    "time"  : "11h, 50 min",
  },
  {
    "value" : 711,
    "time"  : "11h, 51 min",
  }, 
  {
    "value" : 712,
    "time"  : "11h, 52 min",
  }, 
  {
    "value" : 713,
    "time"  : "11h, 53 min",
  }, 
  {
    "value" : 714,
    "time"  : "11h, 54 min",
  }, 
  {
    "value" : 715,
    "time"  : "11h, 55 min",
  }, 
  {
    "value" : 716,
    "time"  : "11h, 56 min",
  }, 
  {
    "value" : 717,
    "time"  : "11h, 57 min",
  }, 
  {
    "value" : 718,
    "time"  : "11h, 58 min",
  }, 
  {
    "value" : 719,
    "time"  : "11h, 59 min",
  }, 
  {
    "value" : 720,
    "time"  : "12 hours",
  },
  {
    "value" : 1440,
    "time"  : "All day",
  },
]

export const closed = {
  sunStart: 0,
  sunEnd: 0,
  monStart: 0,
  monEnd: 0,
  tueStart: 0,
  tueEnd: 0,
  wedStart: 0,
  wedEnd: 0,
  thuStart: 0,
  thuEnd: 0,
  friStart: 0,
  friEnd: 0,
  satStart: 0,
  satEnd: 0,
}

export const defaultBusinessHours = {
  mon: {
    endTime: 1700,
    is24Hours: false,
    isOpen: true,
    startTime: 900
  },
  tue: {
    endTime: 1700,
    is24Hours: false,
    isOpen: true,
    startTime: 900
  },
  wed: {
    endTime: 1700,
    is24Hours: false,
    isOpen: true,
    startTime: 900
  },
  thu: {
    endTime: 1700,
    is24Hours: false,
    isOpen: true,
    startTime: 900
  },
  fri: {
    endTime: 1700,
    is24Hours: false,
    isOpen: true,
    startTime: 900
  },
  sat: {
    endTime: 0,
    is24Hours: false,
    isOpen: false,
    startTime: 0
  },
  sun: {
    endTime: 0,
    is24Hours: false,
    isOpen: false,
    startTime: 0
  },
}

export const defaultServiceAvailability = {
  mon: {
    endTime:   2400,
    is24Hours: true,
    isOpen:    true,
    startTime: 0
  },
  tue: {
    endTime:   2400,
    is24Hours: true,
    isOpen:    true,
    startTime: 0
  },
  wed: {
    endTime:   2400,
    is24Hours: true,
    isOpen:    true,
    startTime: 0
  },
  thu: {
    endTime:   2400,
    is24Hours: true,
    isOpen:    true,
    startTime: 0
  },
  fri: {
    endTime:   2400,
    is24Hours: true,
    isOpen:    true,
    startTime: 0
  },
  sat: {
    endTime:   2400,
    is24Hours: true,
    isOpen:    true,
    startTime: 0
  },
  sun: {
    endTime:   2400,
    is24Hours: true,
    isOpen:    true,
    startTime: 0
  }
}

export const closedServiceAvailability = {
  mon: {
    endTime:   0,
    is24Hours: false,
    isOpen:    false,
    startTime: 0
  },
  tue: {
    endTime:   0,
    is24Hours: false,
    isOpen:    false,
    startTime: 0
  },
  wed: {
    endTime:   0,
    is24Hours: false,
    isOpen:    false,
    startTime: 0
  },
  thu: {
    endTime:   0,
    is24Hours: false,
    isOpen:    false,
    startTime: 0
  },
  fri: {
    endTime:   0,
    is24Hours: false,
    isOpen:    false,
    startTime: 0
  },
  sat: {
    endTime:   0,
    is24Hours: false,
    isOpen:    false,
    startTime: 0
  },
  sun: {
    endTime:   0,
    is24Hours: false,
    isOpen:    false,
    startTime: 0
  }
}

export const convertTime = appt => {
  const { duration } = appt
  
  let start = {}
  let end = {}
  let timeString = appt.time.toString()

  // All day appointments
  if (duration === 1440) {
    return <div>All day</div>
  }

  let durationHours = parseInt(duration / 60)
  let durationMinutes = duration % 60
  
  // Set meridiem of start time based on military time
  start.meridiem = appt.time < 1200 ? 'am' : 'pm'

  switch (timeString.length) {
    case 1:
    case 2:
      // Time is between 12am - 1am
      start.hour = '12'
      start.minute = timeString
      // Since start hour is zero, add duration hours
      // Add duration minutes past the hour to the start time minutes
      end.hour = durationHours
      end.minute = parseInt(timeString) + durationMinutes

      break
    
    case 3:
      // Time is between 1am - 10am
      start.hour = timeString.slice(0, 1)
      start.minute = timeString.slice(-2)

      end.hour = parseInt(start.hour) + durationHours
      end.minute = parseInt(start.minute) + durationMinutes
      
      break
  
    case 4:
      // Time is between 10am - end of day
      start.hour = timeString.slice(0, 2)
      start.minute = timeString.slice(-2)

      end.hour = parseInt(start.hour) + durationHours
      end.minute = parseInt(start.minute) + durationMinutes
      
      break
  
    default:
      break
  }

  // If end hour is earlier than noon, set meridiem to am otherwise set to pm
  // Must set at the end to account for change in end hour
  if (parseInt(end.hour) < 12) {
    end.meridiem = 'am'
  }
  else {
    end.meridiem = 'pm'
  }

  // If end minutes is over 60 add the remainder hours to end hour
  // and set end minutes equal to the remainder of minutes
  if (end.minute / 60 >= 1) {
    end.hour += parseInt(end.minute / 60)
    end.minute = end.minute % 60
  }

  // Show two zeros if end minute or start minute are zero
  if (!parseInt(start.minute)) {
    start.minute = '00'
  }
  if (!parseInt(end.minute)) {
    end.minute = '00'
  }

  // Change from 24hour clock to 12hour clock
  if (parseInt(start.hour) > 12) {
    start.hour = parseInt(start.hour) - 12
  }
  if (parseInt(end.hour) > 12) {
    end.hour = parseInt(end.hour) - 12
  }

  return (
    <div>
      {`${start.hour}:${start.minute}${start.meridiem} `}
      -
      {` ${end.hour}:${end.minute}${end.meridiem}`}
    </div>
  )
}

export const bookingsPerDay = () => {
  const result = [{ value: 0, label: 'No limit' }]
  
  for (let i = 1; i < 100; i++) {
    result.push({ value: i, label: i })
  }
  return result
}
