import axios from 'axios'
import { toggleError } from './toastActions'

export const INVITE_USER = 'INVITE_USER'

export const inviteUser = ( data ) => {
  const url = '/auth/invite'
  const config = { headers: { accept: 'application/json' } }

  return {
    type: INVITE_USER,
    promise: axios.post( url, data, config )
  }
}

export const SIGN_UP_USER = 'SIGN_UP_USER'

export const signUpUser = (user) => {
  const url = `/auth/signup`
  const config = { headers: { accept: 'application/json' } }

  return {
    type:    SIGN_UP_USER,
    promise: axios.post( url, user, config)
  }
}

export const SIGN_IN_USER = 'SIGN_IN_USER'

export const signIn = (email, password) => {
  const url = '/auth/signin'
  const user = { email, password }
  const config = { headers: { accept: 'application/json' } }

  return {
    type:    SIGN_IN_USER,
    promise: axios.post( url, user, config )
  }
}

export const SIGN_OUT_USER = 'SIGN_OUT_USER'

export const signOut = () => {
  const url = '/auth/signout'
  const config = { headers: { accept: 'application/json' } }

  return {
    type:    SIGN_OUT_USER,
    promise: axios.delete( url, config )
  }
}

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'

export const forgotPassword = (email) => {
  const url = '/auth/forgot_password'
  const config = { headers: { accept: 'application/json' } }

  return {
    type:    FORGOT_PASSWORD,
    promise: axios.post( url, {email}, config )
  }
}

export const RESET_PASSWORD = 'RESET_PASSWORD'

export const resetPassword = (user) => {
  const url = '/auth/reset_password'
  const config = { headers: { accept: 'application/json' } }

  return {
    type:    RESET_PASSWORD,
    promise: axios.post( url, user, config )
  }
}

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'

export const changePassword = (user) => {
  const url = '/auth/change_password'
  const config = { headers: { accept: 'application/json' } }

  return {
    type:    CHANGE_PASSWORD,
    promise: axios.post( url, user, config )
  }
}

export const SET_TOKEN = 'SET_TOKEN'

export const setToken = (jwtToken) => {
  // Sign out if token = null
  if (!jwtToken) {
    window.localStorage.removeItem('jwt')
    window.localStorage.removeItem('OnSchedEmail')
    window.localStorage.removeItem('OnSchedPw')
    window.localStorage.removeItem('OnSchedRemember')
  }
  
  return {
    type: SET_TOKEN,
    jwtToken
  }
}

export const SET_ACCOUNT_TOKEN = 'SET_ACCOUNT_TOKEN'

export const setAccountToken = accountToken => ({
  type: SET_ACCOUNT_TOKEN,
  accountToken
})
