import React from 'react'
import SaveButton from '../SaveButton/SaveButton'
import './BottomOverlay.css'

const BottomOverlay = ({ action, active, type }) => {
  return (
    <div className={`overlay ${active ? 'active' : ''}`}>
      <SaveButton action={action} type={type}/>
      <p>Save the changes made!</p>
    </div>
  )
}

export default BottomOverlay