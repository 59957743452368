import React                from 'react'
import { Router, Switch, 
          Route }           from 'react-router-dom'

import history              from '../../utils/history'
import isAuthenticated      from '../../utils/isAuthenticated'

import ToastList            from '../Toasts/ToastList/ToastList'
import ToastError           from '../Toasts/ToastError/ToastError'
import Dashboard            from '../Dashboard/Dashboard'
import SignIn               from '../SignIn/SignIn'
import CompanySelect        from '../CompanySelect/CompanySelect'
import ForgotPassword       from '../ForgotPassword/ForgotPassword'
import ResetPassword        from '../ResetPassword/ResetPassword'
import NewPassword          from '../NewPassword/NewPassword'
import VerifyPassword       from '../VerifyPassword/VerifyPassword'
import SignUp               from '../SignUp/SignUp'
import SignUpSuccess        from '../SignUpSuccess/SignUpSuccess'
import Verified             from '../SignUpSuccess/Verified/Verified'

import './App.css'


const App = () => {
  return (
    <div className="App">
      <ToastList />
      <ToastError />
      <Router history={history}>
        <Switch>
          <Route exact path="/SignIn"         component={SignIn} />
          <Route exact path="/SignUp"         component={SignUp} />
          <Route exact path="/Verified"       component={Verified} />
          <Route exact path="/Success"        component={SignUpSuccess} />
          <Route exact path="/SelectCompany"  component={isAuthenticated(CompanySelect)} />
          <Route exact path="/ForgotPassword" component={ForgotPassword} />
          <Route exact path="/ResetPassword"  component={ResetPassword} />
          <Route exact path="/NewPassword"    component={NewPassword} />
          <Route exact path="/VerifyPassword" component={VerifyPassword} />
          <Route path="/"                     component={isAuthenticated(Dashboard)} />
        </Switch>
      </Router>
    </div>
  )
}

export default App
