import { SEND_MODAL_DATA } from '../actions/modalActions'

const INITIAL_STATE = {
  redirect: false
}

const sendModalDataReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SEND_MODAL_DATA:
      return {
        modalData: action.modalData,
        redirect:  action.redirect
      }
    default:
      return state
  }
}

export default sendModalDataReducer