import { TOGGLE_USER_MODAL } from '../actions/modalActions'

const userModalReducer = (state = {}, action) => {
  switch (action.type) {
    case TOGGLE_USER_MODAL:
      return {
          modalData:   action.modalData,
          modalAction: action.showModal,
        }
    default:
      return state
  }
}

export default userModalReducer