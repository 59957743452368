import React                 from 'react'
import { connect }           from 'react-redux'
import { reduxForm, 
         formValueSelector } from 'redux-form'

import './WeekDayItem.css'


const WeekDayItem = props => {
  const {letter, number, change, weekdays, isEvent } = props

  let weekdaysVar = ''
  if (weekdays)
  weekdaysVar = props.weekdays

  const addWeekDay = day => {
    if (weekdaysVar && weekdaysVar.includes(day))
      weekdaysVar = weekdaysVar.replace(day, '')
    
    else
      weekdaysVar = weekdaysVar + day
    
    // Sort string of days
    weekdaysVar = weekdaysVar.split('')
                       .sort()
                       .join()
                       .replace(/[\s,]+/g,'')
                       .trim()

    if (isEvent) {
      change('repeat.weekdays', weekdaysVar) 
    } else {
      change('repeatBlock.weekdays', weekdaysVar)
    }                
  
  }

  let active = ''
  if (weekdaysVar && weekdaysVar.includes(number))
    active = 'active'
  
  return (
    <li 
      className={`WeekDayItem ${active}`} 
      onClick={() => addWeekDay(number)}
    >
      {letter}
    </li>
  )
}

export default connect()(reduxForm({})(WeekDayItem))
