import { COMPRESS_SIDE_NAV } from '../actions/sideNavActions'

const INITIAL_STATE = {
  action: false
}

const compressSideNavReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case COMPRESS_SIDE_NAV:
      return {
          compressed: action.compressed
        }
    default:
      return state
  }
}

export default compressSideNavReducer