import React from 'react'

import ParametersList from './ParametersList/ParametersList'

import './EditorTabs.css'


const EditorTabs = ({selected, changeLanguage}) => {
  const tabs = [
    {
      name:  'html',
      value: 'markup'
    },
    {
      name:  'master',
      value: 'master'
    }, 
  ]

  return (
    <ul className="EditorTabs">
      { tabs.map((tab, i) => 
        <li 
          key={i}
          className={selected === tab.value ? 'selected' : ''}
          onClick={() => changeLanguage(tab.value)}
        >
          { tab.name.toUpperCase() }
        </li>
      )}
      <ParametersList />
    </ul>
  )
}

export default EditorTabs