import React, { Component } from 'react'
import { connect }          from 'react-redux'
import { Redirect }         from 'react-router-dom'
import querystring          from 'querystring'

import { setAccountToken } from '../../actions/authActions'
import Loading             from '../Loading/Loading'

import './ResetPassword.css'


class ResetPassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      redirect: false
    }
  }
  
  componentDidMount() {
    const { search } = window.location
    let accountToken = querystring.parse(search.replace('?', '')).accountToken
    this.props.setAccountToken(accountToken)
  }

  componentDidUpdate(prevProps) {
    const { accountToken } = this.props

    if (prevProps.accountToken !== accountToken && accountToken)
      this.setState({ redirect: true })
  }

  render() {
    if (this.state.redirect) 
      return <Redirect to="/NewPassword" />
    
    return <Loading />
  }
}

const mapStateToProps = state => ({
  accountToken: state.accountToken.accountToken
})

export default connect(mapStateToProps, { setAccountToken })(ResetPassword)