import { viewTypes } from '../actions/viewActions'

export const structureBlockData = props => {
    let block = {}
    
    if (window.location.pathname.includes('calendarBlocks')) {
      block = {
        viewType:  viewTypes.locations,
        action:    props.loadCalendarBlock,
        blockData: props.calendarBlock,
        update:    props.updateCalendarBlock,
        path:      'calendarBlocks'
      }
    }
    else if (window.location.pathname.includes('serviceBlocks')) {
      block = {
        viewType:  viewTypes.services,
        action:    props.loadServiceBlock,
        blockData: props.serviceBlock,
        update:    props.updateServiceBlock,
        path:      'serviceBlocks'
      }
    }
    else if (window.location.pathname.includes('resourceBlocks')) {
      block = {
        viewType:  viewTypes.resources,
        action:    props.loadResourceBlock,
        blockData: props.resourceBlock,
        update:    props.updateResourceBlock,
        path:      'resourceBlocks'
      }
    }
    
    return block
  }
