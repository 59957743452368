import React    from 'react'
import { Link } from 'react-router-dom'
import { Card } from 'react-bootstrap'

import './MenuCard.css'

const MenuCard = props => {
  let status = {
    'color' : 'var(--onschedLightGrey)'
  }

  if ( props.status ) status = {
    'animation' : 'onboard 300ms 1 forwards'
  }

  return (
    <Card className="MenuCard">
      <Link to={`/${props.view}`} onClick={() => props.switchView(props.view)}>
        <i style={status} className={props.icon} />

        <Card.Body>{props.body}</Card.Body>
      </Link>


      {/* <span>{props.action}</span> */}
    </Card>
  )
}

export default MenuCard