import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'

import { validateResourceModal } from '../../../utils/validate'
import { en }                    from '../../../utils/languages'

import './ResourceModalCards.css'

class ResourceModalCards extends Component {

  render() {
    const { resourceType, handleChange, forOption } = this.props

    let person = ''
    let asset  = ''
    if ( resourceType === 'person' ) person = 'selected'
    if ( resourceType === 'asset' )   asset = 'selected'

    if ( forOption !== 'resource' ) return null
    
    return (
      <div className="ResourceModalCards">
        <label
          className={person}
        >
          <Field
            name="resourceType"
            type="radio"
            component="input"
            value="person"
            onChange={e => handleChange(e)}
            />
  
          <div>
            <i className="fad fa-user-tie" />
            <i className="fad fa-user-headset" />
            <i className="fad fa-person-dolly-empty" />
          </div>
          <h5>{en.resources.resourceCards.person.title}</h5>
          <p>{en.resources.resourceCards.person.description}</p>
        </label>
        
        <label
          className={asset}
          >
          <Field
            name="resourceType"
            type="radio"
            component="input"
            value="asset"
            onChange={e => handleChange(e)}
          />
  
          <div>
            <i className="fad fa-person-booth" />
            <i className="fad fa-table-tennis" />
            <i className="fad fa-garage-car" />
          </div>
          <h5>{en.resources.resourceCards.asset.title}</h5>
          <p>{en.resources.resourceCards.asset.description}</p>
        </label>
      </div>
    )
  }
}


export default reduxForm({
  initialValues: {
    resourceType: 'person'
  },
  form: 'create-modal', //                 <------ same form name
  destroyOnUnmount: true, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate: validateResourceModal
})(ResourceModalCards)