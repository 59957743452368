import React                 from 'react'
import Hotkeys               from 'react-hot-keys'
import { connect }           from 'react-redux'
     
import { loadLocationById }  from '../../actions/locationActions'
import { toggleCreateModal } from '../../actions/modalActions'
import { en }                from '../../utils/languages'


const Shortcuts = props =>  {
  function createNew() {
    const { view, toggleCreateModal } = props

    switch (view) {
      case 'Locations':
        const locationData = {
          forOption: 'location',
          headerTitle: en.locations.modalData.headerTitle,
          bodyTitle: en.locations.modalData.bodyTitle,
        }
    
        toggleCreateModal(locationData, true)
        
        break
    
      case 'Resources':
        const resourceData = {
          forOption: 'resource',
          headerTitle: en.resources.modalData.headerTitle,
          bodyTitle: en.resources.modalData.bodyTitle,
          action: 'radio'
        }
    
        toggleCreateModal(resourceData, true)
        
        break
    
      case 'Services':
        const serviceData = {
          forOption: 'service',
          headerTitle: en.services.modalData.headerTitle,
        }
    
        toggleCreateModal(serviceData, true)
        
        break
    
      default:
        break
    }
  }

  const { primaryLoading, primarySuccess, primary } = props
  
  if ( primaryLoading || !primarySuccess || !primary.id ) return null

  return (
    <div>
      <Hotkeys
        keyName="alt+shift+c"
        onKeyDown={() => props.loadLocationById(primary.id)}
      />

      <Hotkeys
        keyName="alt+shift+n"
        onKeyDown={createNew.bind(this)}
      />
    </div>
  )
}

function mapStateToProps(state) {
  return {
    primary:          state.primary.primary,
    primaryLoading:   state.primary.isLoading,
    primarySuccess:   state.primary.success,
    view:             state.view.view
  }
}

export default connect(mapStateToProps, { loadLocationById, toggleCreateModal })(Shortcuts)