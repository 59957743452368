import React, { Component } from 'react'
import { connect }          from 'react-redux'

import ToastItem            from '../ToastItem/ToastItem'

import './ToastList.css'

class ToastList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      toasts : []
    }
  }

  componentDidUpdate(prevProps) {
    const { toasts } = this.props

    if (prevProps.toasts !== toasts) {
      let newToasts = [
        ...this.state.toasts,
        toasts
      ]
      this.setState({ toasts: newToasts })
    }
  }

  render() {
    if ( !this.props.toasts ) return null

    let toasts = []
    if (this.state.toasts) {
      toasts = this.state.toasts.map(
        (toast,i) => <ToastItem key={i} toast={toast} />
      )
    }
  
    return (
      <div className="ToastList">
        { toasts }
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    toasts: state.toasts
  }
}

export default connect(mapStateToProps, {})(ToastList)