import { FormSection } from 'redux-form'

import { renderCheckbox, 
         renderField,
         renderRadio,
         renderLabelField }             from './renderField'
import { renderDropdownField }    from './renderDropdownField'
import renderPasswordField        from './renderPasswordField'
import renderPhoneField           from './renderPhoneField'
import { renderDescField }        from './handleDesc'
import renderProvincesField       from './renderProvincesField'
import renderTimezonesField       from './renderTimezonesField'
import { countries }              from './countries'
import { times, 
         durations, 
         paddingDurations,
         intervalDurations,
         bookingsPerDay }         from './businessHours'
import { dailyBookingLimitCount } from './bookingLimits'
import { en }                     from './languages'

const minutesInDay = 1440

export const businessUserForm = [
  {
    name: 'name',
    label: 'Name',
    component: renderField,
    type: 'text',
  },
  {
    name: 'email',
    label: 'Email',
    component: renderField,
    type: 'text',
  },
]

export const timeOffCardForm = [
  {
    name: 'reasonBlock',
    label: 'Block Reason',
    component: renderField,
    type: 'text',
    placeholder: 'Time block',
    customClass: 'fullWidth'
  },
  {
    type: 'date',
    name: 'startDateBlock',
    label: 'starting',
    className: 'datepicker',
    customClass: 'fullWidth',
    component: renderField,  
  },
  {
    name: 'startTimeBlock',
    label: 'From',
    component: renderDropdownField,
    options: times.map(time => 
              ({
                label: `${time.time} ${time.meridiem}`, 
                value: time.value
              })
            ),
  },  
  {
    name: 'endTimeBlock',
    label: 'To',
    component: renderDropdownField,
    options: times.map(time => 
              ({
                label: `${time.time} ${time.meridiem}`, 
                value: time.value
              })
            ),
  },
  {
    name: 'repeatsBlock' ,
    type: 'checkbox' ,
    customClass: 'split',
    label: 'Repeat' ,
    defaultValue: 'false',
    component: renderCheckbox
  },
  {
    type: 'date',
    name: 'endDateBlock',
    label: 'ending',
    className: 'datepicker',
    component: renderField,
    dynamicVisibility: [
      {
        trigger: 'repeatsBlock',
        match: true
      }
    ],
  },
]

export const timeOffRepeatSection = [
  {
    name:      'repeatBlock',
    component: FormSection,
    fields: [
      {
        name: 'frequency',
        component: renderDropdownField,
        label: 'Repeats',
        options: [
          { label: 'Daily', value: 'D' },
          { label: 'Weekly', value: 'W' },
          { label: 'Monthly', value: 'M' },
        ]
      },
      {
        name: 'interval',
        component: renderDropdownField,
        label: 'Every',
        options: [
          { label: 1, value: 1 },
          { label: 2, value: 2 },
          { label: 3, value: 3 },
          { label: 4, value: 4 },
          { label: 5, value: 5 },
          { label: 6, value: 6 },
          { label: 7, value: 7 },
          { label: 8, value: 8 },
          { label: 9, value: 9 },
          { label: 10, value: 10 },
          { label: 11, value: 11 },
          { label: 12, value: 12 },
          { label: 13, value: 13 },
          { label: 14, value: 14 },
          { label: 15, value: 15 } 
        ],
      },
    ]
  },  
]

export const eventRepeatSection = [
  {
    name:      'repeat',
    component: FormSection,
    fields: [
      {
        name: 'frequency',
        component: renderDropdownField,
        label: 'Repeats',
        options: [
          { label: 'Daily', value: 'D' },
          { label: 'Weekly', value: 'W' },
          { label: 'Monthly', value: 'M' },
        ]
      },
      {
        name: 'interval',
        component: renderDropdownField,
        label: 'Every',
        options: [
          { label: 1, value: 1 },
          { label: 2, value: 2 },
          { label: 3, value: 3 },
          { label: 4, value: 4 },
          { label: 5, value: 5 },
          { label: 6, value: 6 },
          { label: 7, value: 7 },
          { label: 8, value: 8 },
          { label: 9, value: 9 },
          { label: 10, value: 10 },
          { label: 11, value: 11 },
          { label: 12, value: 12 },
          { label: 13, value: 13 },
          { label: 14, value: 14 },
          { label: 15, value: 15 } 
        ],
      },
    ]
  },  
]

export const serviceForm = [
  {
    name:      'name',
    type:      'text',
    label:     'Service name',
    component: renderField,
    customClass: 'fullWidth'
  },
  {
    name:      'description',
    label:     'Description',
    component: renderDescField,
    className: 'textArea',
    customClass: 'fullWidth'
  },
  {
    name: 'fees',
    component: FormSection,
    fields: [
      {
        type:  'number',
        name:  'feeAmount',
        label: 'Fee Amount',
        iconId: 'fal fa-dollar-sign',
        className: 'text-right',
        customClass: 'icon',
        component: renderField,
        placeholder: '0.00'
      },
      {
        type:  'number',
        name:  'cancellationFeeAmount',
        label: 'Cancellation Fee',
        customClass: 'icon',
        iconId: 'fad fa-dollar-sign',
        component: renderField,
        placeholder: '0.00'
      }
    ]
  },
  {
    name: 'type',
    label: 'Type',
    component: renderDropdownField,
    options: [
      {
        label: 'Appointment',
        value: 1
      },
      {
        label: "Event",
        value: 2
      }
    ]
  },
  {
    label: "Service Duration",
    name: "duration",
    component: renderDropdownField,
    tip: "Enter the duration of this service. Note: If variable duration is enabled, it must be within the variable duration settings' rules.",
    options: durations.map(time => ({
      value: time.value,
      label: time.time
    }))
  },
  {
    name: 'bookingLimit',
    label: "Booking Limit",
    component: renderDropdownField,
    options: dailyBookingLimitCount.map(limit => {
      let option = limit
      if (limit === 0) option = 'Unlimited'
  
      let text = ''
      if ( limit === 1 ) text = 'booking'
      if ( limit > 1 )   text = 'bookings'
  
      return {
        value: limit,
        label: option + ' ' + text
      }
    }),
  },
  {
    name: 'bookingInterval',
    tip: "By default the interval uses the the Service Duration defined above, otherwise select a different interval from the dropdown.",
    label: "Booking Interval",
    component: renderDropdownField,
    options: paddingDurations.map((time, i) => {
      if(i === 0) {

        return {
          value: time.value,
          label: 'Use service duration'
        }
      }
      else {

        return {
          value: time.value,
          label: time.time
        } 
      }  
    }),
  },
  {
    label: "Padding",
    name: "padding",
    tip: "Padding is time added after an appointment for preparation or travel time.",
    component: renderDropdownField,
    options: paddingDurations.map(time => ({
      value: time.value,
      label: time.time
    }))
  },
  {
    label:"Default service",
    name:"defaultService",
    type:"checkbox",
    component: renderCheckbox,
    customClass: 'split',
    tip: "Select this option if your location offers multiple services and wants to define a service as a default."
  },
  {
    name: "durationSelect",
    label: "Variable Duration",
    component: renderCheckbox,
    customClass: 'split',
    type: "checkbox",
    tip: "Variable duration allows the booker to select between different durations. For example: with a min duration of 30 min, a max duration of 60 min and an interval of 15 min the bookers options would be 30 min, 45 min, and 60 min.",
  },
  {
    name: "durationInterval",
    label: "Interval",
    component: renderDropdownField,
    options: durations.map(time => ({
      value: time.value,
      label: time.time 
    })),
    dynamicVisibility: [ {trigger: 'durationSelect', match: true} ]
  },
  {
    name: "durationMin",
    label: "Min. Duration",
    component: renderDropdownField,
    options: durations.map(time => ({
      value: time.value,
      label: time.time 
    })),
    dynamicVisibility: [ {trigger: 'durationSelect', match: true} ]
  },
  {
    name: "durationMax",
    label: "Max. Duration",
    component: renderDropdownField,
    options: durations.map(time => ({
      value: time.value,
      label: time.time 
    })),
    dynamicVisibility: [ {trigger: 'durationSelect', match: true} ]
  },
  {
    type:        'text',
    name:        'maxGroupSize',
    label:       'Max. Group Size',
    component:   renderField, 
    customClass: "",
    tip:         'Maximum group size per booking. How many people can one booker include in an appointment?'
  }, 
  {
    type:        'text',
    name:        'maxCapacity',
    label:       'Max. Capacity',
    component:   renderField, 
    customClass: "",
    tip:         'Maximum capacity per booking slot. For example a private screening room may only have 12 seats, the max capacity is 12.'
  },
  
]

export const resourceForm = [
  [
    {
      type:        'text',
      name:        'name',
      label:       'Name',
      component:   renderField,
      customClass: "fullWidth"
    }, 
    {
      name:      'contact',
      component: FormSection,
      fields: [
        {
          type:        "tel",
          name:        "mobilePhone",
          label:       "Phone number",
          component:   renderPhoneField,     
          customClass: "fullWidth"
        },
        {
          type:        'text',
          name:        'conferenceInfo',
          label:       'Conference Info',
          component:   renderField,
          customClass: "fullWidth"
        }
      ]
    },
    {
      type:        'text',
      name:        'email',
      label:       'Email',
      component:   renderField,
      customClass: "fullWidth"
    },
    {
      name:      'address',
      component: FormSection,
      fields: [
        {
          type:        "text",
          name:        "addressLine1",
          label:       "Address Line 1",   
          component:   renderField,
          customClass: "fullWidth" 
        },
        {
          type:        "text",
          name:        "addressLine2",
          label:       "Address Line 2",   
          component:   renderField,
          customClass: "fullWidth" 
        },
        {
          name:        "country",
          label:       "Country",
          component:   renderDropdownField,
          customClass: 'fullWidth',
          options:     countries
        },
        {
          type:        "text",
          name:        "city",
          label:       "City",  
          component:   renderField,
        },
        {
          type:        "text",
          name:        "state",
          label:       "State/Province",  
          component:   renderProvincesField
        },
        
        {
          name:        "postalCode",
          label:       "ZIP/Postal Code",
          component:   renderField,
          required:    false
        },
      ]
    },
    {
      name:        "timezoneId",
      type:        "text",
      label:       "Timezone",
      component:   renderTimezonesField,
    },
  ],
  [
    {
      name:        'description',
      label:       'Description',
      component:   renderDescField,
      customClass:   'fullWidth',
    }
  ],
  [
    {
      name:        'notifications',
      component:   FormSection,
      customClass: 'notifications',
      fields: [
        {
          label:       'Notify me by',
          name:        'notifications',
          component:   renderLabelField,
          customClass: 'notification-label'
  
        },
        {
          name:      'notificationType1',
          label:     'Email',
          type:      'checkbox',
          defaultChecked: true,
          component: renderCheckbox,
        },
        {
          name:      'notificationType2',
          label:     'Text message',
          type:      'checkbox',
          component: renderCheckbox,
        },
      ]
    },
  ],
  [
    {
      name:        'calendarSync',
      label:       'Calendar Synchronized',
      customClass: 'fullWidth',
      component:   renderDropdownField,
      options: [
        {
          label: 'None',
          value: 'none'
        },
        {
          label: 'Google',
          value: 'google'
        },
        {
          label: 'Outlook',
          value: 'outlook'
        },
      ]
    },
    {
      name:        'calendarId',
      label:       'Calendar Id',
      component:   renderField,
      customClass: 'fullWidth',
      tip:         'Calendar Id is the calendar`s email that will be synchronized with OnSched',
      dynamicVisibility: [
        {
          trigger: "calendarSync",
          match:   "google" 
        },
        {
          trigger: "calendarSync",
          match:   "outlook" 
        }
      ],
    },
    {
      name:        'returnUrl',
      label:       'Return URL',
      component:   renderField,
      customClass: 'fullWidth',
      tip:         'Define URL to redirect after the calendar has been authenticated. If left empty it will redirect to OnSched Dashboard',
      dynamicVisibility: [
        {
          trigger: "calendarSync",
          match:   "google" 
        },
        {
          trigger: "calendarSync",
          match:   "outlook" 
        }
      ],
    },
    
    {
      name:      'calendarAvailability',
      label:     'Use External Calendar for Availability',
      type:      'checkbox',
      component: renderCheckbox,
      tip:       'If checked OnSched will be able to read your availability and also push appointments into your calendar. Otherwise, we will only be able to push appointments, but not read your calendar',
    }
  ]

]

export const locationForm = [
  {
    type:         'text',
    name:         'name',
    label:        'Name',
    component:    renderField,
    placeholder:  en.locations.placeholders.name,
  },
  {
    type:         'text',
    name:         "website",
    label:        "Website",
    placeholder:  en.locations.placeholders.website,
    component:    renderField,
    className:    'RightField'
  },
  {
    type:        "tel",
    name:        "phone",
    label:       "Phone number",
    component:   renderPhoneField,
    placeholder: "(888) 867-5309"
  },
  {
    type:         "email",
    name:         "email",
    label:        "Email",
    placeholder:  en.locations.placeholders.email,
    component:    renderField,
    myId:         "locationEmail",
    className:    "RightField"
  },
  {
    name:      'address',
    component: FormSection,
    fields: [
      {
        name:         "addressLine1",
        component:    renderField,
        label:        "Street address line 1",
        type:         "text",
        customClass:  "fullWidth"
      },
      {
        name:         "addressLine2",
        component:    renderField,
        label:        "Street address line 2",
        type:         "text",
        customClass:  "fullWidth"
      },
      {
        name:        "country",
        label:       "Country",
        component:   renderDropdownField,
        customClass: 'fullWidth',
        options:     countries
      },
      {
        name:         "city",
        component:    renderField,
        label:        "City",
        type:         "text"
      },
      {
        name:         "state",
        component:    renderProvincesField,
        label:        "State/Province",
        type:         "text"
      },
      {
        name:         "postalCode",
        component:    renderField,
        label:        "ZIP/Postal Code",
        type:         "text",
        maxLimit:     5,
        showError:    true
      },
    ]
  },
  {
    name:        "timezoneName",
    component:   renderTimezonesField,
    className:   "split",
    label:       "Timezone",
    type:        "text"
  }
]

export const locationCapacityFieldsForm = [
  // {
  //   type:        'text',
  //   name:        'maxGroupSize',
  //   label:       'Maximum Group Size Per Booking',
  //   component:   renderField,
  //   tip:         'This field will not be affected by the location scope'
  // }, 
  // {
  //   type:        'text',
  //   name:        'maxCapacity',
  //   label:       'Maximum Capacity Per Booking Slot',
  //   component:   renderField,
  //   tip:         'This field will not be affected by the location scope'
  // }, 
  {
    type:        'text',
    name:        'bookingsPerSlot',
    label:       'Maximum Bookings Per Booking Slot',
    component:   renderField,
    tip:         'The number of bookings allowed per time slot at this location',
    customClass: 'fullWidth'
  }, 
]

export const LocationSettingsForm = [
  { 
    name:      'settings',
    component: FormSection,
    fields: [
      {
        name:        'customerBookingsPerDay',
        label:       'Customer Bookings Per Day',
        component:   renderDropdownField,
        placeholder: 'Customer Bookings Per Day',
        customClass: 'fullWidth',
        options:     bookingsPerDay(),
        tip:         'The number of times a customer with the same email address can book an appointment, on the same day.',
      },
      {
        name:        'bookAheadUnit',
        label:       'Book Ahead limit',
        component:   renderDropdownField,
        placeholder: 'Units',
        customClass: 'RightField',
        tip:         'The number of days, weeks or months in advance that an appointment can be booked. Default is (1) week, meaning availability will not show beyond (1) week.',
        options: [
          {
            value: 1,
            label: 'Days'
          },
          {
            value: 2,
            label: 'Weeks'
          },
          {
            value: 3,
            label: 'Months'
          },
        ]
      },
      {
        name:        'bookAheadValue',
        component:   renderField,
        placeholder: '0'
      },
      {
        name:  'bookingTimerMins',
        label: 'Booking Timer Minutes',
        customClass: 'fullWidth',
        component: renderField,
        placeholder: 'Value in minutes',
        tip:         'Appointments in "Initial Status" will timeout and availability will reopen after the specified time.',
      },
      {
        name:        'bookInAdvance',
        label:       'Book in Advance Restriction (Minutes)',
        component:   renderField,
        placeholder: 'Value in minutes',
        customClass: 'fullWidth',
        tip:         'The minimum amount of time required before an appointment can be booked. Value is in minutes. If not set the first available time slot will be returned.'
      },
      {
        name:        "enableWorldTimezones",
        label:       "Enable world time zones",
        component:   renderCheckbox,
        customClass: '',
        type:        "checkbox",
      },
    ],

  },
  {
    name:      'defaults',
    component: FormSection,
    fields: [
      {
        name:        "customerState",
        label:       "Automatically default Customer State / Province to the Business State / Province",
        component:   renderCheckbox,
        customClass: '',
        type:        "checkbox",
      },
      {
        name:        "customerCity",
        label:       "Automatically default Customer City to the Business City",
        component:   renderCheckbox,
        customClass: '',
        type:        "checkbox",
      },
      {
        name:        "autoUpdateCustomer",
        label:       "Automatically create a new Customer entry when Appointment is booked",
        component:   renderCheckbox,
        customClass: '',
        type:        "checkbox",
      },
      {
        name:        "businessNotification",
        label:       "Send Business Online Booking Notifications",
        component:   renderCheckbox,
        customClass: '',
        type:        "checkbox",
      },
      {
        name:        "emailInfo",
        label:       "Automatically default yes for Customer permission to send Notifications and Reminders",
        component:   renderCheckbox,
        customClass: '',
        type:        "checkbox",
      },
    ]
  }
  
]

export const changePasswordForm = [
  {
    name:        'oldPassword',
    label:       'Old password',
    component:   renderPasswordField,
    placeholder: '**********',
    customClass: 'fullWidth'
  },
  {
    name:        'newPassword',
    label:       'New password',
    component:   renderPasswordField,
    placeholder: '**********',
    customClass: ''
  },
  {
    name:        'confirmPassword',
    label:       'Confirm password',
    component:   renderPasswordField,
    placeholder: '**********',
    customClass: ''
  },
]

export const signInForm = [
  {
    name:        "email",
    label:       "Email",
    component:   renderField,
    placeholder: "doc@brown.bsu",
    customClass: 'fullWidth',
    autoFocus:   true,
  },
  {
    name:        'password',
    label:       'Password',
    component:   renderPasswordField,
    placeholder: '**********',
    customClass: 'fullWidth'
  },
]

export const customerForm = [
  {
    name: 'name',
    label: 'Name',
    component: renderField,
    type: 'text',
    customClass: 'fullWidth'
  },
  {
    name:        "email",
    label:       "Email",
    component:   renderField,
    placeholder: "doc@brown.bsu",
    customClass:  "fullWidth"
  },
  {
    name:      'address',
    component: FormSection,
    fields: [
      {
        name:         "addressLine1",
        component:    renderField,
        label:        "Street address line 1",
        placeholder:  "Street address line 1",
        type:         "text",
        customClass:  "fullWidth"
      },
      {
        name:         "addressLine2",
        component:    renderField,
        label:        "Street address line 2",
        placeholder:  "Street address line 2",
        type:         "text",
        customClass:  "fullWidth"
      },
      {
        name:        "country",
        label:       "Country",
        component:   renderDropdownField,
        customClass: 'fullWidth',
        options:     countries
      },
      {
        name:         "city",
        component:    renderField,
        label:        "City",
        placeholder:  "City",
        type:         "text"
      },
      {
        name:         "state",
        component:    renderProvincesField,
        placeholder:  "State/Province",
        label:        "State/Province",
        type:         "text"
      },
      {
        name:         "postalCode",
        component:    renderField,
        placeholder:  "ZIP/Postal Code",
        label:        "ZIP/Postal Code",
        type:         "text",
        maxLimit:     5,
        showError:    true
      },
    ]
  },
  {
    name:      'contact',
    component: FormSection,
    fields: [
      {
        type:        "tel",
        name:        "homePhone",
        label:       "Home number",
        component:   renderPhoneField,
        placeholder: "(888) 867-5309"
      },
      {
        type:        "tel",
        name:        "mobilePhone",
        label:       "Mobile number",
        component:   renderPhoneField,
        placeholder: "(888) 867-5309"
      },
      // {
      //   type:        "tel",
      //   name:        "businessPhone",
      //   label:       "business Phone",
      //   component:   renderPhoneField,
      //   placeholder: "(888) 867-5309"
      // } ---------------> Waiting for businessPhone fix in the API
    ]
  }
]

export const profileCompanyFields = [
  {
    type:        "name",
    name:        "name",
    label:       "Name",
    component:   renderField,
    customClass: "fullWidth" 
  },
  {
    type:         "email",
    name:         "email",
    label:        "Email",
    placeholder:  en.locations.placeholders.email,
    component:    renderField,
    myId:         "locationEmail",
    className:    "RightField"
  },
  {
    type:         'text',
    name:         "website",
    label:        "Website",
    placeholder:  en.locations.placeholders.website,
    component:    renderField,
    className:    'RightField'
  },
  {
    type:        "text",
    name:        "addressLine1",
    label:       "Address Line 1",
    placeholder: '1 Hollywood Blvd',
    component:   renderField,
    customClass: "fullWidth" 
  },
  {
    type:        "text",
    name:        "addressLine2",
    label:       "Address Line 2",
    placeholder: 'Unit B',
    component:   renderField,
    customClass: "fullWidth" 
  },
  {
    name:        "country",
    label:       "Country",
    component:   renderDropdownField,
    customClass: 'fullWidth',
    options:     countries
  },
  {
    type:        "text",
    name:        "city",
    label:       "City",
    placeholder: 'Los Angeles',
    component:   renderField,
  },
  {
    type:        "text",
    name:        "state",
    label:       "State/Province",
    placeholder: "State/Province",
    component:   renderProvincesField
  },
  
  {
    name:        "postalCode",
    label:       "ZIP/Postal Code",
    component:   renderField,
    required:    false
  },
  {
    name:        "timezoneName",
    component:   renderTimezonesField,
    className:   "split",
    placeholder: "Timezone",
    label:       "Timezone",
    type:        "text"
  }
]

export const settingsCompanyFields = [
  {
    name: 'disableEmailAndSmsNotifications' ,
    type: 'checkbox' ,
    label: 'Disable Notifications',
    tip: 'This will disable all Email and SMS notifications that are configured. Webhooks will still be triggered.',
    component: renderCheckbox
  },
  {
    name: 'reminderWebhookUrl',
    label: 'Reminder Webhook Url',
    component: renderField,
    type: 'text',
    customClass: 'fullWidth'
  },
  {
    name: 'resourceWebhookUrl',
    label: 'Resource Webhook Url',
    component: renderField,
    type: 'text',
    customClass: 'fullWidth'
  },
  {
    name: 'bookingWebhookUrl',
    label: 'Booking Webhook Url',
    component: renderField,
    type: 'text',
    customClass: 'fullWidth'
  },
  {
    name: 'customerWebhookUrl',
    label: 'Customer Webhook Url',
    component: renderField,
    type: 'text',
    customClass: 'fullWidth'
  },
  {
    name: 'webhookSignatureHash',
    label: 'Webhook Signature Hash',
    component: renderField,
    type: 'text',
    customClass: 'fullWidth'
  },
]

export const signUpFields = [
  {
    name: "firstName",
    component: renderField,
    label: "First name",
    type: "text",
  },
  {
    name: "lastName",
    component: renderField,
    label: "Last name",
    type: "text"
  },
  {
    name: "email",
    component: renderField,
    label: "Email",
    type: "text",
    customClass: 'fullWidth mt-2'
  },
  {
    name: "password",
    component: renderPasswordField,
    label: "Password",
    type: "password",
    showError: true,
    customClass: 'fullWidth'
  },
  {
    name: "confirmPassword",
    component: renderPasswordField,
    label: "Re-type password",
    type: "password",
    showError: true,
    customClass: 'fullWidth'
  }
]
