import React, { useEffect }  from 'react'
import { connect }           from 'react-redux'
import { reduxForm, 
         formValueSelector } from 'redux-form'
import { useParams }         from 'react-router'
import { modTypes, 
         viewTypes }             from '../../actions/viewActions'
import { toastMessages,
         toggleError,
         toggleToast }           from '../../actions/toastActions'
import { loadAllocations,
         addServiceAllocations } from '../../actions/serviceActions'
import { loadResourceAllocations,
         addResourceAllocations } from '../../actions/resourceActions'

import { eventFields, 
         eventFieldsResource }   from '../../utils/eventUtils'
import { en }                    from '../../utils/languages'
import { eventRepeatSection }    from '../../utils/formFields'
import { toggleCheckErrors }     from '../../utils/checkErrors'

import AddedList                 from './AddedList/AddedList'
import WeekDays                  from './WeekDays/WeekDays'
import ResourcesField            from './ResourcesField/ResourcesField'
import FormFields                from '../FormFields/FormFields'
import HelpTip                   from '../HelpTip/HelpTip'


import './EventsTab.css'

const EventsTab = props => {
  const { addResourceAllocations,
          addServiceAllocations,
          bookingLimit,
          display,
          endDate,
          endTime,
          form,
          loadAllocations,
          loadResourceAllocations,
          modType,
          reason,
          repeat,
          repeats,
          resource,
          resourceId,
          resourceName,
          selectedLocation,
          service,
          startDate,
          startTime,
          toggleError,
          toggleToast,
          view,
        } = props
  
  const params = useParams()

  useEffect(() => {
    if (modType === modTypes.edit) {
      if (view === viewTypes.services) {
        if (service) {  
           loadAllocations(params?.id).then((resp) => {
            toggleCheckErrors(resp, toggleError)
          })
        }
      }
  
      if (view === viewTypes.resources) {
        if (resource) {
           loadResourceAllocations(params.id).then((resp) => {  
            toggleCheckErrors(resp, toggleError)
          })
        }
      }
    }
  }, [view, params, service, resource, toggleError, loadResourceAllocations, loadAllocations])


  const addEvent = () => {
    let monthDay = new Date().toISOString().slice(0,10)
    if (startDate)
      monthDay = parseInt(startDate.slice(-2,10))

    repeat.monthType = 'D'
    
    let allocation = {
      locationId : selectedLocation?.id,
      startDate,
      endDate: repeats ? endDate : startDate,
      startTime,
      endTime,
      reason,
      repeats,
      repeat,
      // conditionally add properties
      ...(view === viewTypes.services && {
        serviceId: service.id,
        resourceId, 
        resourceName, 
        bookingLimit, 
      })
    }

    if (view === viewTypes.services) {
      addServiceAllocations(service.id, allocation).then((res) => {
        toggleCheckErrors(res, toggleError, () => {
          loadAllocations(service.id)
          toggleToast(toastMessages.create_allocation, res.payload)
        })
      })
    }

    if (view === viewTypes.resources) {
      addResourceAllocations(resource.id, allocation).then((res) => {
        toggleCheckErrors(res, toggleError, () => {
          loadResourceAllocations(resource.id)
          toggleToast(toastMessages.create_resource_allocation, res.payload)
        })
      })
    }
  }

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      e.preventDefault()
      addEvent()
      props.change('reason', '')
    }
  }


  let { title, subtitle, cta } = en.services.allocations
  if (view === viewTypes.resources) {
    title =    en.resources.allocations.title
    subtitle = en.resources.allocations.subtitle
    cta =      en.resources.allocations.cta
  }

  return (
    <div className="EventsTab" style={{ ...(display === false && { display: 'none' }) }}>
      
      <div className='eventsTab row'>

          <div className="options col-5">

            <div className='help-tip-title'>
              <h5>{title}</h5>
              <HelpTip tip={subtitle}/>
            </div>

            <FormFields  form={form} fields={view === viewTypes.resources ? eventFieldsResource : eventFields}/>

            <FormFields disabled={!repeats} form={form} fields={eventRepeatSection} />
            <WeekDays 
              form={form} 
              repeat={repeat}
              repeats={repeats}
              isEvent={true}
            />

            { view === viewTypes.services && <ResourcesField form={form} /> }  

            <button
              type="button"
              className="btn btn-primary allocation-button"
              onClick={addEvent}
            >
              {cta}
            </button>
              
          </div>


        <div className='col-7'>
           <AddedList /> 
        </div>

      </div>
         
    </div>
  )
}

const mapStateToProps = (state, props) => ({
  service:          state.service.service,
  selectedLocation: state.selectedLocation.selectedLocation,
  loading:          state.allocationDeleted.isLoading,
  resource:         state.selectedResource.selectedResource,
  view:             state.view.view,
  resAllocations:   state.resourceAllocations.resourceAllocations,
  allocations:      state.allocations.allocations,  
  modType:          state.modType.modType,

  // Current redux form values
  reason:           formValueSelector(props.form)(state, 'reason'), 
  repeats:          formValueSelector(props.form)(state, 'repeats'), 
  startDate:        formValueSelector(props.form)(state, 'startDate'), 
  endDate:          formValueSelector(props.form)(state, 'endDate'), 
  startTime:        formValueSelector(props.form)(state, 'startTime'), 
  endTime:          formValueSelector(props.form)(state, 'endTime'),
  repeat:           formValueSelector(props.form)(state, 'repeat'),
  resourceId:       formValueSelector(props.form)(state, 'resourceId'),
  resourceName:     formValueSelector(props.form)(state, 'resourceName'),
  bookingLimit:     formValueSelector(props.form)(state, 'bookingLimit')
})

export default reduxForm({ destroyOnUnmount: false })(
  connect(
    mapStateToProps, 
    { loadAllocations,
      addServiceAllocations,
      toggleError,
      toggleToast,
      loadResourceAllocations,
      addResourceAllocations,
    })(EventsTab)
)