import { handle } from 'redux-pack'

import { UPDATE_SERVICE_AVAILABILITY } from '../actions/serviceActions'


const INITIAL_STATE = { success: false }


export default function updateServiceAvailReducer( state = INITIAL_STATE, action ) {
  const { type, payload } = action

  switch ( type ) {
    
  case UPDATE_SERVICE_AVAILABILITY:
    return handle( state, action, {
      start:   previousState => ( { ...previousState, isLoading: true, updatedServiceAvailError: null, success: false } ),
      finish:  previousState => ( { ...previousState, isLoading: false } ),
      failure: previousState => ( { ...previousState, updatedServiceAvailError: payload } ),
      success: previousState => ( { ...previousState, success: true, updatedServiceAvail: payload.data } )
    })

  default:
    return state

  }

}
