import { NAVIGATE_LIST } from '../actions/searchActions'

const INITIAL_STATE = { listItem: 0 }

const navigateListReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case NAVIGATE_LIST:
      return {
          id:        action.id,
          listItem:  action.listItem
        }
    default:
      return state
  }
}

export default navigateListReducer