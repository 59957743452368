import { handle } from 'redux-pack'

import { LOAD_RESOURCE_ALLOCATIONS,
         ADD_RESOURCE_ALLOCATION,
         DELETE_RESOURCE_ALLOCATION,
         LOAD_RESOURCE_ALLOCATION,
         UPDATE_RESOURCE_ALLOCATION } from '../actions/resourceActions'
 
const INITIAL_STATE = { success: false }


export const loadResourceAllocationsReducer = ( state = INITIAL_STATE, action )  => {
  const { type, payload } = action

  switch ( type ) {
    
  case LOAD_RESOURCE_ALLOCATIONS:
    return handle( state, action, {
      start:   previousState => ( { ...previousState, isLoading: true, resourceAllocationsError: null, success: false } ),
      finish:  previousState => ( { ...previousState, isLoading: false } ),
      failure: previousState => ( { ...previousState, resourceAllocationsError: payload } ),
      success: previousState => ( { ...previousState, success: true, resourceAllocations: payload.data.data } )
    })

  default:
    return state

  }

}

export const addResourceAllocationsReducer = ( state = INITIAL_STATE, action ) => {
  const { type, payload } = action

  switch ( type ) {
    
  case ADD_RESOURCE_ALLOCATION:
    return handle( state, action, {
      start:   previousState => ( { ...previousState, isLoading: true, resourceAllocationError: null, success: false } ),
      finish:  previousState => ( { ...previousState, isLoading: false } ),
      failure: previousState => ( { ...previousState, resourceAllocationError: payload } ),
      success: previousState => ( { ...previousState, success: true, resourceAllocation: payload.data } )
    })

default:
    return state

  }

}

export const deleteResourceAllocationReducer = ( state = INITIAL_STATE, action ) => {
  const { type, payload } = action

  switch ( type ) {
    
  case DELETE_RESOURCE_ALLOCATION:
    return handle( state, action, {
      start:   previousState => ( { ...previousState, isLoading: true, resourceAllocationDeletedError: null, success: false } ),
      finish:  previousState => ( { ...previousState, isLoading: false } ),
      failure: previousState => ( { ...previousState, resourceAllocationDeletedError: payload } ),
      success: previousState => ( { ...previousState, success: true, resourceAllocationDeleted: payload.data } )
    })

  default:
    return state

  }

}


export const loadResourceAllocationReducer = ( state = INITIAL_STATE, action ) => {
  const { type, payload } = action

  switch ( type ) {
    
  case LOAD_RESOURCE_ALLOCATION:
    return handle( state, action, {
      start:   previousState => ( { ...previousState, isLoading: true, resourceAllocationError: null, success: false } ),
      finish:  previousState => ( { ...previousState, isLoading: false } ),
      failure: previousState => ( { ...previousState, resourceAllocationError: payload } ),
      success: previousState => ( { ...previousState, success: true, resourceAllocationError: payload.data } )
    })

  default:
    return state

  }

}


export const updateResourceAllocationReducer = ( state = INITIAL_STATE, action ) => {
  const { type, payload } = action
  switch ( type ) {
    
    case UPDATE_RESOURCE_ALLOCATION:
      return handle( state, action, {
        start:   previousState => ( { ...previousState, isLoading: true, updatedAllocationError: null, updatedAllocation: null } ),
        finish:  previousState => ( { ...previousState, isLoading: false } ),
        failure: previousState => ( { ...previousState, updatedAllocationError: payload } ),
        success: previousState => ( { ...previousState, success: true, updatedAllocation: payload.data } )
      })

    default:
      return state

  }

}