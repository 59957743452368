import { combineReducers } from 'redux'

import { reducer as formReducer }     from 'redux-form'
import signInReducer                  from './signInReducer'
import setTokenReducer                from './setTokenReducer'
import setAccountTokenReducer         from './setAccountTokenReducer'
import loadLocationsReducer           from './loadLocationsReducer'
import loadLocationByIdReducer        from './loadLocationByIdReducer'
import saveLocationReducer            from './saveLocationReducer'
import createLocationReducer          from './createLocationReducer'
import loadUsersReducer               from './loadUsersReducer'
import deleteUserReducer              from './deleteUserReducer'
import updateUserReducer              from './updateUserReducer'
import addUserReducer                 from './addUserReducer'
import toastReducer                   from './toastReducer'
import loadResourcesReducer           from './loadResourcesReducer'
import createResourceReducer          from './createResourceReducer'
import exitIntentReducer              from './exitIntentReducer'
import deleteResourceReducer          from './deleteResourceReducer'
import loadResourceByIdReducer        from './loadResourceByIdReducer'
import updateResourceReducer          from './updateResourceReducer'
import pageReducer                    from './pageReducer'
import unsavedReducer                 from './unsavedReducer'
import switchViewReducer              from './switchViewReducer'
import sideNavReducer                 from './sideNavReducer'
import loadPrimaryLocationReducer     from './loadPrimaryLocationReducer'
import createModalReducer             from './createModalReducer'
import loadServicesReducer            from './loadServicesReducer'
import companyServicesReducer         from './companyServicesReducer'
import deleteLocationReducer          from './deleteLocationReducer'
import loadServiceByIdReducer         from './loadServiceByIdReducer'
import loadServiceAvailReducer        from './loadServiceAvailReducer'
import deleteServiceReducer           from './deleteServiceReducer'
import createServiceReducer           from './createServiceReducer'
import customFieldsReducer            from './customFieldsReducer'
import bookingFieldsReducer           from './bookingFieldsReducer'
import updateServiceReducer           from './updateServiceReducer'
import updateServiceAvailReducer      from './updateServiceAvailReducer'
import serviceGroupsReducer           from './serviceGroupsReducer'
import loadResourceAvailReducer       from './loadResourceAvailReducer'
import updateResourceAvailReducer     from './updateResourceAvailReducer'
import loadCalendarsReducer           from './loadCalendarsReducer'
import loadCalendarByIdReducer        from './loadCalendarByIdReducer'
import loadServiceResourcesReducer    from './loadServiceResourcesReducer'
import loadSettingsReducer            from './loadSettingsReducer'
import bookingModalReducer            from './bookingModalReducer'
import linkedServiceReducer           from './linkedServiceReducer'
import unlinkedServiceReducer         from './unlinkedServiceReducer'
import loadLinkedServiceReducer       from './loadLinkedServiceReducer'
import userModalReducer               from './userModalReducer'
import resourceServicesReducer        from './resourceServicesReducer'
import searchingReducer               from './searchingReducer'
import navigateListReducer            from './navigateListReducer'
import selectItemReducer              from './selectItemReducer'
import selectSearchCategoryReducer    from './selectSearchCategoryReducer'
import setActiveUserReducer           from './setActiveUserReducer'
import loadCompaniesReducer           from './loadCompaniesReducer'
import setActiveCompanyReducer        from './setActiveCompanyReducer'
import signOutReducer                 from './signOutReducer'
import forgotPasswordReducer          from './forgotPasswordReducer'
import clientCredentialsReducer       from './clientCredentialsReducer'
import initialResourcesReducer        from './initialResourcesReducer'
import initialServicesReducer         from './initialServicesReducer'
import setRecentsReducer              from './setRecentsReducer'
import sendModalDataReducer           from './sendModalDataReducer'
import loadCalendarServicesReducer    from './loadCalendarServicesReducer'
import resetPasswordReducer           from './resetPasswordReducer'
import updateResServicesReducer       from './updateResServicesReducer'
import deleteResourceServicesReducer  from './deleteResourceServicesReducer'
import authorizeTokenReducer          from './authorizeTokenReducer'
import loadUserByIdReducer            from './loadUserByIdReducer'
import loadEmailTemplateReducer       from './loadEmailTemplateReducer'
import updateMasterTemplateReducer    from './updateMasterTemplateReducer'
import setCaretPositionReducer        from './setCaretPositionReducer'
import addParameterReducer            from './addParameterReducer'
import loadEmailTemplatesReducer      from './loadEmailTemplatesReducer'
import loadMasterTemplateReducer      from './loadMasterTemplateReducer'
import updateEmailPreviewReducer      from './updateEmailPreviewReducer'
import updateEmailTemplateReducer     from './updateEmailTemplateReducer'
import deleteEmailTemplateReducer     from './deleteEmailTemplateReducer'
import togglePreviewSizeReducer       from './togglePreviewSizeReducer'
import loadDomainsReducer             from './loadDomainsReducer'
import addDomainReducer               from './addDomainReducer' 
import updateDomainReducer            from './updateDomainReducer' 
import deleteDomainReducer            from './deleteDomainReducer'
import addAllocationsReducer          from './addAllocationsReducer'
import loadAllocationsReducer         from './loadAllocationsReducer'
import deleteAllocationReducer        from './deleteAllocationReducer'
import compressSideNavReducer         from './compressSideNavReducer'
import loadAllocationReducer          from './loadAllocationReducer'
import updateAllocationReducer        from './updateAllocationReducer'
import uploadLocationImageReducer     from './uploadLocationImageReducer'
import deleteLocationImageReducer     from './deleteLocationImageReducer'
import uploadServiceImageReducer      from './uploadServiceImageReducer'
import deleteServiceImageReducer      from './deleteServiceImageReducer'
import uploadResourceImageReducer     from './uploadResourceImageReducer'
import deleteResourceImageReducer     from './deleteResourceImageReducer'
import updateSearchTextReducer        from './updateSearchTextReducer'
import addResourceBlockReducer        from './addResourceBlockReducer'
import loadResourceBlocksReducer      from './loadResourceBlocksReducer'
import deleteResourceBlockReducer     from './deleteResourceBlockReducer'
import addCalendarBlockReducer        from './addCalendarBlockReducer'
import loadCalendarBlocksReducer      from './loadCalendarBlocksReducer'
import deleteCalendarBlockReducer     from './deleteCalendarBlockReducer'
import loadCompanyTimezonesReducer    from './loadCompanyTimezonesReducer'
import modTypeReducer                 from './modTypeReducer'
import updateCalendarReducer          from './updateCalendarReducer'
import { searchTextReducer }          from './searchReducer'
import { confirmModalReducer }        from './modalReducers'
import calendarModalReducer           from './calendarModalReducers' 
import changePasswordReducer          from './changePasswordReducer'
import errorReducer                   from './errorReducer'
import reportErrorReducer             from './reportErrorReducer'
import loadProvincesReducer           from './loadProvincesReducer'
import deleteApiKeysReducer           from './deleteApiKeysReducer'
import { addServiceBlockReducer,
         deleteServiceBlockReducer,
         loadServiceBlocksReducer,
         loadServiceBlockReducer,
         updateServiceBlockReducer }  from './serviceBlockReducers'
import { loadCalendarBlockReducer, 
         updateCalendarBlockReducer } from './calendarBlocksReducers'
import { loadResourceBlockReducer,
         updateResourceBlockReducer } from './resourceBlocksReducers'

import { loadAppointmentsReducer,
         loadAppointmentByIdReducer } from './appointmentsReducers'
import { loadCustomersReducer,
         loadCustomerByIdReducer }    from './customerReducer'
import { loadResourceAllocationsReducer,
         addResourceAllocationsReducer,
         deleteResourceAllocationReducer,
         loadResourceAllocationReducer } from './resourceAllocationsReducers'
import { loadCompanyReducer,
         updateCompanyReducer }          from './companyReducers'
import { singUpReducer }                 from './authReducers'
import { toggleRecurringAvaillReducer }  from './resourceReducers'
import { toggleLocationScopeReducer }    from './locationReducers'
import authorizeApiDocsReducer           from './authorizeApiDocsReducer'


export default combineReducers(
  {
    form :                formReducer,
    idToken :             signInReducer,
    signedOut :           signOutReducer,
    jwtToken :            setTokenReducer,
    accountToken :        setAccountTokenReducer,
    user :                setActiveUserReducer,
    clientCredentials :   clientCredentialsReducer,
    companies :           loadCompaniesReducer,
    activeCompany :       setActiveCompanyReducer,
    company :             loadCompanyReducer,
    updatedCompany:       updateCompanyReducer,
    locations :           loadLocationsReducer,
    recentLocations :     setRecentsReducer,
    selectedLocation :    loadLocationByIdReducer,
    locationScope :       toggleLocationScopeReducer,
    locationSaved :       saveLocationReducer,
    locationCreated :     createLocationReducer,
    locationDeleted :     deleteLocationReducer,
    users :               loadUsersReducer,
    businessUser :        loadUserByIdReducer,
    userDeleted :         deleteUserReducer,
    userUpdated :         updateUserReducer,
    forgottenPassword:    forgotPasswordReducer,
    resetPassword :       resetPasswordReducer,
    newUser :             addUserReducer,
    toasts :              toastReducer,
    resources:            loadResourcesReducer,
    resourceAvail:        loadResourceAvailReducer,
    updatedResAvail:      updateResourceAvailReducer,
    resourceCreated:      createResourceReducer,
    resourceDeleted:      deleteResourceReducer,
    selectedResource:     loadResourceByIdReducer,
    recurringAvailRes:    toggleRecurringAvaillReducer,
    updatedResource:      updateResourceReducer,
    resourceAllocations:  loadResourceAllocationsReducer,
    resourceAllocation:   addResourceAllocationsReducer,
    resAlllocationDel:    deleteResourceAllocationReducer,
    resAllocationLoad:    loadResourceAllocationReducer,
    exit:                 exitIntentReducer,
    page:                 pageReducer,
    unsaved:              unsavedReducer,
    view:                 switchViewReducer,
    searching:            searchingReducer,
    navigateList:         navigateListReducer,
    selectedSearchItem:   selectItemReducer,
    searchCategory:       selectSearchCategoryReducer,
    searchText:           updateSearchTextReducer,
    sideNav:              sideNavReducer,
    primary:              loadPrimaryLocationReducer,
    createModal:          createModalReducer,
    sendModalData:        sendModalDataReducer,
    bookModal:            bookingModalReducer,
    calendarModal:        calendarModalReducer,
    services:             loadServicesReducer,
    service:              loadServiceByIdReducer,
    serviceAvail:         loadServiceAvailReducer,
    serviceCreated:       createServiceReducer,
    serviceDeleted:       deleteServiceReducer,
    serviceGroups:        serviceGroupsReducer,
    companyServices:      companyServicesReducer,
    cFields:              customFieldsReducer,
    bFields:              bookingFieldsReducer,
    updatedService:       updateServiceReducer,
    updatedServiceAvail:  updateServiceAvailReducer,
    calendars:            loadCalendarsReducer,
    calendar:             loadCalendarByIdReducer,
    serviceRes:           loadServiceResourcesReducer,
    settings:             loadSettingsReducer,
    linked:               linkedServiceReducer,
    unlinked:             unlinkedServiceReducer,
    linkedLoaded:         loadLinkedServiceReducer,
    userModal:            userModalReducer,
    resServices:          resourceServicesReducer,
    updatedResServices:   updateResServicesReducer,
    deletedResServices:   deleteResourceServicesReducer,
    initialResources:     initialResourcesReducer,
    initialServices:      initialServicesReducer,
    calendarServices:     loadCalendarServicesReducer,
    onschedJsToken:       authorizeTokenReducer,
    eTemplate:            loadEmailTemplateReducer,
    eTemplates:           loadEmailTemplatesReducer,
    masterTemplate:       loadMasterTemplateReducer,
    editorCaretPosition:  setCaretPositionReducer,
    parameterAdded:       addParameterReducer,
    emailPreview:         updateEmailPreviewReducer,
    updatedEmailTemplate: updateEmailTemplateReducer,
    eTemplateDeleted:     deleteEmailTemplateReducer,
    updatedMaster:        updateMasterTemplateReducer,
    screenSize:           togglePreviewSizeReducer,
    domains:              loadDomainsReducer,
    domainAdded:          addDomainReducer,
    domainUpdated:        updateDomainReducer,
    domainDeleted:        deleteDomainReducer,
    allocationsAdded:     addAllocationsReducer,
    allocations:          loadAllocationsReducer,
    allocationDeleted:    deleteAllocationReducer,
    allocation:           loadAllocationReducer,
    updatedAllocation:    updateAllocationReducer,
    compressed:           compressSideNavReducer,
    appointments:         loadAppointmentsReducer,
    appointment:          loadAppointmentByIdReducer,
    customers:            loadCustomersReducer,
    customer:             loadCustomerByIdReducer,
    locationImg:          uploadLocationImageReducer,
    locationImgDeleted:   deleteLocationImageReducer,
    serviceImg:           uploadServiceImageReducer,
    serviceImgDeleted:    deleteServiceImageReducer,
    resourceImg:          uploadResourceImageReducer,
    resourceImgDeleted:   deleteResourceImageReducer,
    resourceBlock:        addResourceBlockReducer,
    resourceBlocks:       loadResourceBlocksReducer,
    resourceBlockDeleted: deleteResourceBlockReducer,
    resourceBlockLoaded:  loadResourceBlockReducer,
    resourceBlockUpdated: updateResourceBlockReducer,
    calendarBlock:        addCalendarBlockReducer,
    calendarBlocks:       loadCalendarBlocksReducer,
    calendarBlockDeleted: deleteCalendarBlockReducer,
    calendarBlockLoaded:  loadCalendarBlockReducer,
    calendarBlockUpdated: updateCalendarBlockReducer,
    timezones:            loadCompanyTimezonesReducer,
    modType:              modTypeReducer,
    calendarUpdated:      updateCalendarReducer,
    searchTextFilter:     searchTextReducer,
    changePassword:       changePasswordReducer,
    error:                errorReducer,
    errorReported:        reportErrorReducer,
    confirmModalReducer:  confirmModalReducer,
    provinces:            loadProvincesReducer,
    revokeApiSecret:      deleteApiKeysReducer,
    serviceBlocks:        loadServiceBlocksReducer,
    serviceBlock:         addServiceBlockReducer,
    serviceBlockDeleted:  deleteServiceBlockReducer,
    serviceBlockLoaded:   loadServiceBlockReducer,
    serviceBlockUpdated:  updateServiceBlockReducer,
    accountCreated:       singUpReducer,        
    referenceApiUrl:      authorizeApiDocsReducer,
  }
)
