import React, { Component } from "react"
import { connect }          from "react-redux"
import { Field, reduxForm } from 'redux-form'
import Prism                from "prismjs"

import { handleAutosize }     from '../../../../utils/codeEditor'
import { renderField }        from '../../../../utils/renderField'
import { updateEmailPreview } from '../../../../actions/notificationActions'
import EditorTabs             from './EditorTabs/EditorTabs'
import CodeArea               from './CodeArea/CodeArea'
import MasterTemplateOptions  from './MasterTemplateOptions/MasterTemplateOptions'

import './CodeEditor.css'

class CodeEditor extends Component {
  constructor(props) {
    super(props)
    this.codeInputRef = React.createRef()
    this.state = {
      language:    "markup"
    }
  }

  componentDidMount() {
    const { HTML } = this.props
    
    this.setContent(HTML, false)
    Prism.highlightAll()
  }

  componentDidUpdate(prevProps) {
    const { HTML, parameter } = this.props
    
    Prism.highlightAll()

    if (prevProps.HTML !== HTML && HTML)
      this.setContent(HTML, false)

    if (prevProps.parameter !== parameter) {
      let addParam = HTML.substring(0, parameter.position.start) +
                     '{' + parameter.param + '}' +
                     HTML.substring(parameter.position.end)
      this.setContent(addParam, false)
    }
  }

  changeLanguage = language => {
    const { HTML } = this.props

    this.setState({ language })

    if (language === "markup")
      handleAutosize(HTML)
  }

  handleChange = value => {
    const { HTML } = this.props
    const { language } = this.state

    if (language === "markup")
      this.setContent(value)
  }

  setContent = (content) => {
    const { updateCode, change } = this.props
        
    updateCode(content)
    change('HTML', content)

    handleAutosize(content)
  }

  
  handleKeyDown = e => {
    const { selectionStart, selectionEnd } = e.target
    const { current } = this.codeInputRef
    const { HTML } = this.props
    
    const addChar = char => (
      HTML.substring(0, selectionEnd) +
      char +
      HTML.substring(selectionEnd)
    )

    const setRefValue = val => {
      current.value = val
      current.selectionStart = current.selectionEnd =
      selectionStart
    }

    switch (e.key) {
      case '{':
        this.setContent(addChar("}"))
        setRefValue(addChar("}"))
        break
        
      case '[':
        this.setContent(addChar("]"))
        setRefValue(addChar("]"))
        break
        
      case '(':
        this.setContent(addChar(")"))
        setRefValue(addChar(")"))
        break
      
      case '"':
        this.setContent(addChar('"'))
        setRefValue(addChar('"'))
        break

      case 'Tab':
        e.preventDefault()
    
        let newValue = HTML.substring(0, selectionStart) +
                      "  " +
                      HTML.substring(selectionEnd)
    
        this.setContent(newValue)
    
        if (e.key === 'Tab' && e.shiftKey) {
          e.preventDefault()
      
          const beforeStart = HTML
            .substring(0, selectionStart)
            .split('')
            .reverse()
            .join('')
          const indexOfTab = beforeStart.indexOf('  ')
          const indexOfNewline = beforeStart.indexOf('\n')
      
          if (indexOfTab !== -1 && indexOfTab < indexOfNewline) {
            newValue =
              beforeStart
                .substring(indexOfTab + 2)
                .split('')
                .reverse()
                .join('') +
              beforeStart
                .substring(0, indexOfTab)
                .split('')
                .reverse()
                .join('') +
              HTML.substring(selectionEnd)
          }
            
          this.setContent(newValue)
          
          current.value = newValue
          current.selectionStart = current.selectionEnd =
            selectionStart - 2
        }
    
        else if (current) {
          current.value = newValue
          current.selectionStart = current.selectionEnd =
            selectionStart + 2
        }
        break

      default:
        break
    } 
  }

  handleTitle = e => {
    const { emailPreview, updateEmailPreview } = this.props

    updateEmailPreview({
      ...emailPreview,
      title: e.target.value
    })
  }

  render() {
    const { language } = this.state
    const { HTML, templateName, title } = this.props

    let emailTitle = null
    if (language === "markup")
      emailTitle = (
        <Field 
          name="title"
          component={renderField}
          placeholder="Email title"
          defaultValue={title}
          className="email-title"
          customClass='fullWidth'
          onChange={this.handleTitle}
        />
      )

    return (
      <div className="CodeEditor">
        <h5>{templateName}</h5>

        <EditorTabs 
          changeLanguage={this.changeLanguage} 
          selected={language} 
        />
        
        { emailTitle }
        
        <CodeArea 
          HTML={HTML}
          language={language}
          setContent={this.setContent}
          codeInputRef={this.codeInputRef}
          handleKeyDown={this.handleKeyDown}
        />

        <div className={language !== "master" ? 'hide' : '' } >
          <MasterTemplateOptions />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  parameter: state.parameterAdded,
  emailPreview: state.emailPreview.options
})

export default connect(mapStateToProps, { updateEmailPreview })(
  reduxForm({form: 'master-email-template'})(CodeEditor)
)