import React               from 'react'
import { connect }         from 'react-redux'
import { Link }            from 'react-router-dom'

import InitialsIcon        from '../../InitialsIcon/InitialsIcon'
import { toggleUserModal } from '../../../actions/modalActions'

import './UserSettings.css'


const UserSettings = props => {
  const { localStorage } = window
  const { toggleUserModal, 
          user, 
          compressed
        } = props

  let companyCount = 1
    
  if (localStorage.onschedCompanyCount) {
    companyCount = localStorage.onschedCompanyCount
  }

  let name = ''
  
  if (user) 
    name = `${user.given_name} ${user.family_name}`
  
  else if ( localStorage.userFirstname &&
            localStorage.userLastname 
          ) {
    const { userFirstname, userLastname } = localStorage
    name = `${userFirstname} ${userLastname}`
  } 

  let switchCompanies = (
    <Link 
      className="SideNavItem nav-item" 
      to={{
        pathname:"/SelectCompany",
        forceSelect: true
      }}
    >
      <i className="fad fa-globe-americas" />
      { !compressed && 'Companies' }
    </Link>
  )

  if (companyCount <= 1)
    switchCompanies = null

  return (
    <div 
      className={`UserSettings ${compressed ? 'compressed' : ''}`}
    >
      { switchCompanies }
      <div 
        className="SettingsCard" 
        onClick={() => toggleUserModal(null, true)}
      >
        <InitialsIcon name={name} img="" />
        { !compressed && name }
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  user:       state.user.user,
  compressed: state.compressed.compressed
})

export default connect(mapStateToProps, { toggleUserModal })(UserSettings)