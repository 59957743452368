import React, { Component } from 'react'
import { connect }          from 'react-redux'

import { resetPassword } from '../../actions/authActions'
import NewPasswordForm   from './NewPasswordForm/NewPasswordForm'
import Loading           from '../Loading/Loading'

import './NewPassword.css'

class NewPassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      failed: false,
      noToken: false
    }
  }

  render() {
    const handleSubmit = values => {
      // Start loading
      this.setState({ loading: true })
      
      // Add accountToken to submit values
      values.accountToken = this.props.accountToken

      // Reset password
      this.props.resetPassword(values)
      .then(resp => {
        // Stop loading
        this.setState({ loading: false })
        
        // Redirect to /VerifyPassword confirmation page
        if (resp.payload.status === 200) {
          this.setState({ loading: false })
          this.props.history.push('/')
        }
        else if (resp.error) {
          if (resp.payload.response.status === 404) {
            this.setState({ failed: true })
          }
          if (resp.payload.response.status === 400) {
            if (resp.payload.response.data.errors[0].detail === 'The AccountToken field is required.')
              this.setState({ noToken: true, failed: true })
            else
              this.setState({ failed: true })
          }
        }
      })
    }
  
    const { loading, failed, noToken } = this.state
    const { history } = this.props

    const signupLink = "https://signup.onsched.com/"
    const logo = 'https://cdn.public.onsched.com/content/assets/img/transpurple.png'

    if (loading) return <Loading />

    return (
      <div className="NewPassword">
        <div className="NewPasswordNav">
          <img 
            src={logo} 
            className="Logo" 
            alt="OnSched" 
            onClick={() => history.push('/')}
          />
        </div>
  
        <NewPasswordForm 
          history={this.props.history} 
          onSubmit={handleSubmit} 
          failed={failed}
          noToken={noToken}
        />
        {/* <p>Don't have an account? <a href={signupLink}>Sign up</a> now</p> */}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  accountToken: state.accountToken.accountToken
})

export default connect(mapStateToProps, { resetPassword })(NewPassword)
