import React, { Component } from 'react'
import { connect }          from 'react-redux'
import jwt_decode           from 'jwt-decode'
import { Link }             from 'react-router-dom'

import Loading                   from '../Loading/Loading'
import SignInForm                from './SignInForm/SignInForm'
import { signIn, 
         setToken }              from '../../actions/authActions'
import { setActiveUser }         from '../../actions/userActions'
import { localJwt, localSetUser,
         localRememberUser }     from '../../utils/localStorage'
import HeaderLogin               from '../HeaderLogin/HeaderLogin'

import './SignIn.css'

class SignIn extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      failed: false,
      wrongPw: false
    }
  }

  componentDidMount() {
    const { OnSchedEmail, OnSchedRemember } = window.localStorage

    if (OnSchedRemember) {
      let User = {
        email: OnSchedEmail,
        rememberMe: OnSchedRemember
      }

      if (User.rememberMe) {
        this.handleSubmit(User)
      }
    }
    // Auto sign-in if Remember me is checked
  }

  handleSubmit = values => {
    const params = new URLSearchParams(window.location.search)

    const { email, password, rememberMe } = values
    const { signIn, setToken, setActiveUser, history } = this.props

    this.setState({ loading: true })

    signIn(email, password)
    .then(resp => {
      if (resp.error) {
        if (resp.payload.response.status === 400) {
          // WRONG PASSWORD
          this.setState({ 
            loading: false, 
            failed: true, 
            wrongPw: true 
          })
        } 
        else if (resp.payload.response.status === 404) {
          // USER NOT FOUND
          this.setState({ loading: false, failed: true, wrongPw: false })
        }
      }
      // SUCCESS
      else {
        const { token } = resp.payload.data
        
        // Save token in redux
        setToken(token)
        
        // Save token locally
        localJwt(token)

        // Save user
        var user
        if (token) {
          user = jwt_decode(token)

          setActiveUser(user)
          localSetUser(user)
        }

        // Save user if remember me is checked
        if (rememberMe) localRememberUser(values)

        else {
          this.setState({ loading: false })
          history.push('/SelectCompany' + window.location.search)
        }
      }
    })
  }
  
  render() {
    const { loading, failed, wrongPw } = this.state

    if ( loading ) return <Loading />
    return (
      <div className="SignIn">
        <HeaderLogin />
        <SignInForm 
          history={this.props.history} 
          failed={failed} 
          wrongPw={wrongPw} 
          onSubmit={this.handleSubmit} 
        />

        <p>Don't have an account? <Link to='/SignUp'>Sign up</Link> now</p>
      </div>
    )
  }
}

export default connect(null, { signIn, setToken, setActiveUser })(SignIn)
