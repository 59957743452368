import React                from 'react'
import { Nav, Dropdown }    from 'react-bootstrap'
import { connect }          from 'react-redux'

import { en }               from '../../utils/languages'
import { viewTypes }        from '../../actions/viewActions'
import { compressSideNav }  from '../../actions/sideNavActions'
import SideNavItem          from '../SideNavItem/SideNavItem'
import UserSettings         from './UserSettings/UserSettings'
import ResizeToggle         from './ResizeToggle/ResizeToggle'

import './SideNav.css'

function debounce(fn, ms) {
  let timer
  return _ => {
    clearTimeout(timer)
    timer = setTimeout(_ => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  };
}

const SideNav = props => {
  const { compressed, compressSideNav } = props

  let scrollable
  if (window.innerHeight < 600)
    scrollable = true
  
  const [windowHeight, setHeight] = React.useState({ scrollable })
  
  React.useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      if (window.innerHeight < 600)
        setHeight({ scrollable: true })
      else
        setHeight({ scrollable: false })
    }, 500)
    
    window.addEventListener('resize', debouncedHandleResize)
  })

  let showNav = ''
  if ( !props.sideNav ) showNav = 'show'

  return (
    <Nav
      variant="pills"
      className={`SideNav 
                  flex-column 
                  ${showNav} 
                  ${compressed ? 'compressed' : ''} 
                  ${windowHeight.scrollable ? 'scrollable' : ''}`
                }
    >
      <ResizeToggle />
      <SideNavItem
        to={`/`}
        icon="fad fa-tasks"
        eventKey={viewTypes.get_started}
        label={en.sidenav.get_started}
        />
      {/* <SideNavItem
        to={`/${viewTypes.activate_account}`}
        icon="fad fa-bullseye"
        eventKey={viewTypes.activate_account}
        label={en.sidenav.go_live}
        /> */}

      <Dropdown.Divider />
      
      <SideNavItem
        to={`/${viewTypes.locations}`}
        icon="fad fa-map-marked-alt"
        eventKey={viewTypes.locations}
        label={en.sidenav.locations}
        />
      <SideNavItem
        to={`/${viewTypes.services}`}
        icon="fad fa-hand-holding-usd"
        eventKey={viewTypes.services}
        label={en.sidenav.services}
        />
      <SideNavItem
        to={`/${viewTypes.resources}`}
        icon="fad fa-poll-people"
        eventKey={viewTypes.resources}
        label={en.sidenav.resources}
        />
      <SideNavItem
        to={`/${viewTypes.company_settings}`}
        icon="fad fa-building"
        eventKey={viewTypes.company_settings}
        label={en.sidenav.company_settings}
        />

      <Dropdown.Divider />
      
      {/* <SideNavItem
        to={`/${viewTypes.custom_fields}`}
        icon="fad fa-puzzle-piece"
        eventKey={viewTypes.custom_fields}
        label={en.sidenav.custom_fields}
      /> */}
      <SideNavItem
        to={`/${viewTypes.logs}`}
        icon="fad fa-stream"
        eventKey={viewTypes.logs}
        label={en.sidenav.logs}
      />
      <SideNavItem
        to={`/${viewTypes.notifications}`}
        icon="fad fa-mail-bulk"
        eventKey={viewTypes.notifications}
        label={en.sidenav.notifications}
      />
      {/* <SideNavItem
        to={`/${viewTypes.request_log}`}
        icon="fad fa-align-left"
        eventKey={viewTypes.request_log}
        label={en.sidenav.request_log}
        />
      */}
      <SideNavItem
        to={`/${viewTypes.api_settings}`}
        icon="fad fa-code-branch"
        eventKey={viewTypes.api_settings}
        label={en.sidenav.api_settings}
      />
      <SideNavItem
        to={`/${viewTypes.business_users}`}
        icon="fad fa-users"
        eventKey={viewTypes.business_users}
        label={en.sidenav.business_users}
      />

      <Dropdown.Divider />
      
      {/* <SideNavItem
        to={`/${viewTypes.preferences}`}
        icon="fad fa-cogs"
        eventKey={viewTypes.preferences}
        label={en.sidenav.preferences}
      /> */}
      {/* <SideNavItem
        to={`/${viewTypes.company_settings}`}
        icon="fad fa-building"
        eventKey={viewTypes.company_settings}
        label={en.sidenav.company_settings}
      /> */}

      <UserSettings />
    </Nav>
  )
}

function mapStateToProps(state) {
  return {
    sideNav:    state.sideNav.action,
    compressed: state.compressed.compressed
  }
}

export default connect( mapStateToProps, { compressSideNav } )(SideNav)