export const SET_LOCATIONS_LIST_SEARCH_TEXT = 'SET_LOCATIONS_LIST_SEARCH_TEXT'
export const RESET_LIST_SEARCH_TEXT = 'RESET_LIST_SEARCH_TEXT'
export const SET_RESOURCES_LIST_SEARCH_TEXT = 'SET_RESOURCES_LIST_SEARCH_TEXT'
export const SET_SERVICE_LIST_SEARCH_TEXT = 'SET_SERVICE_LIST_SEARCH_TEXT'

export const setLocationText = searchText => ({
    type: SET_LOCATIONS_LIST_SEARCH_TEXT,
    searchText
})

export const setResourceText = searchText => ({
    type: SET_RESOURCES_LIST_SEARCH_TEXT,
    searchText
})

export const setServiceText = searchText => ({
  type: SET_SERVICE_LIST_SEARCH_TEXT,
  searchText
})

export const resetSearchText = () => ({
    type: RESET_LIST_SEARCH_TEXT
})
