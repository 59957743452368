import { handle } from 'redux-pack'

import { LOAD_INITIAL_RESOURCES } from '../actions/resourceActions'


const INITIAL_STATE = { success: false }


export default function initialResourcesReducer( state = INITIAL_STATE, action ) {
  const { type, payload } = action

  switch ( type ) {
    
  case LOAD_INITIAL_RESOURCES:
    return handle( state, action, {
      start:   previousState => ( { ...previousState, isLoading: true, initialResourcesError: null, success: false } ),
      finish:  previousState => ( { ...previousState, isLoading: false } ),
      failure: previousState => ( { ...previousState, initialResourcesError: payload } ),
      success: previousState => ( { ...previousState, success: true, initialResources: payload.data } )
    })

  default:
    return state

  }

}