import React, { Component }  from 'react'
import { connect }           from 'react-redux'
import resizebase64          from 'resize-base64'
import { Field, reduxForm, 
         formValueSelector } from 'redux-form'

import history                        from '../../../utils/history'
import renderFileUpload               from '../../../utils/renderFileUpload'
import { toastMessages, toggleToast } from '../../../actions/toastActions'
import { deleteLocationImage }        from '../../../actions/locationActions'
import { deleteServiceImage }         from '../../../actions/serviceActions'
import { deleteResourceImage }        from '../../../actions/resourceActions'

import './Upload.css'


const Upload = props => {
const getBase64 = (file, cb) => {
    let reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = e => {
      var image = new Image()
      image.src = e.target.result
      
      image.onload = () => {
          cb(reader.result, image.width, image.height)
      }
    }
  }

  const showPreviewImg = (e) => {
    const { files }  = e.target
    const { change, toggleToast, previewImg }  = props

    const toast = {
      response: {
        status: 200
      }
    }
  
    let created      = ''
    if ( files.length > 0 ) {
      created = URL.createObjectURL(files[0])
      getBase64(files[0], (result, width, height) => {
        const saveBase64Str = () => {
          let base64Str = result.split('base64,')[1]
  
          // Resize image if too large
          if (width > 500 || height > 500) {
            base64Str = resizebase64(result, 500, 500).split('base64,')[1]
            toggleToast(toastMessages.resize_image, toast)
          }
  
          change('base64Image', base64Str)
          change('previewImg', window.btoa(created))
        }
        
        if (previewImg && previewImg.length) {
          deleteImage().then(() => saveBase64Str())
        }
        else {
          saveBase64Str()
        }
      })
    }
  }

  const deleteImage = () => {
    const { previewImg,
            change,
            selected,
            service,
            resource,
            deleteLocationImage,
            deleteServiceImage,
            deleteResourceImage
          } = props

    const object = history.location.pathname.split('/')[1].toLowerCase()

    return new Promise((resolve) => {
      if (previewImg) {
        if (object === 'locations')
          deleteLocationImage(selected.id).then(() => resolve(removeImg()))
        if (object === 'services')
          deleteServiceImage(service.id).then(() => resolve(removeImg()))
        if (object === 'resources')
          deleteResourceImage(resource.id).then(() => resolve(removeImg()))
        }
      const removeImg = () => {
        change('previewImg', null)
        change('base64Image', null)
      }
    })
  }

  const { previewImg } = props
  
  let image = <i className="fad fa-image fa-3x" />
  
  if (previewImg) {
    image = <img src={window.atob(previewImg)} alt="Logo" />
  }
  
  return (
    <div className="Upload">
      <div className="Image">
        { image }
        <canvas id="canvas" width={200} height={200} />
      </div>

      <div className="UploadButtons">
        <button className="btn btn-logo-upload"> Change </button>
        
        <Field
          type="file"
          name="previewImgInput"
          className="FileUploadInput"
          placeholder="www.mydelorian.com"
          component={renderFileUpload}
          showPreviewImg={showPreviewImg}
        />

        <button
          onClick={deleteImage}
          type="button"
          className="btn btn-logo-remove"
        >
          Remove photo
        </button>
      </div>
    </div>
  )
}

const mapStateToProps = (state, props) => ({
  previewImg: formValueSelector(props.form)(state, 'previewImg'),
  selected:   state.selectedLocation.selectedLocation,
  service:    state.service.service,
  resource:   state.selectedResource.selectedResource
})

const connectedUpload = connect(mapStateToProps, {
  deleteLocationImage,
  deleteServiceImage,
  deleteResourceImage,
  toggleToast
})(Upload)

export default reduxForm({})(connectedUpload)
