import React, { Component} from 'react'
import { connect }         from 'react-redux'

import { loadUsers, 
         addUser, 
         updateUser,
         deleteUser }              from '../../actions/userActions'
import { EmptyList }               from '../../utils/emptyList'
import { checkErrors }             from '../../utils/checkErrors'
import { toggleToast, 
         toastMessages }           from '../../actions/toastActions'
import { toggleCreateModal,
         userModalData,
         toggleConfirmationModal } from '../../actions/modalActions'
import { switchView, 
         viewTypes }               from '../../actions/viewActions'
import { en }                      from '../../utils/languages'
import Loading                     from '../Loading/Loading'
import Header                      from '../Header/Header'
import DataList                    from '../DataList/DataList'
import DataListItem                from '../DataList/DataListItem/DataListItem'
import Pages                       from '../Pages/Pages'

import './BusinessUsers.css'

class BusinessUsers extends Component {
  constructor(props) {
    super(props)
    this.state = {
      copied: false,
      field: null
    }
  }

  componentDidMount() {
    const { switchView, loadUsers, selected } = this.props
    const { add_user } = toastMessages
    
    switchView(viewTypes.business_users)
    
    if (selected)
      loadUsers({ locationId: selected.id, limit: 10 })
        .then(resp => checkErrors(resp, null, add_user))
  }

  componentDidUpdate(prevProps) {
    const { selected, 
            loadUsers, 
            sendModal, 
            addUser, 
            updateUser,
            toggleToast,
            confirmed,
            modalData,
            deleteUser,
            toggleConfirmationModal
          } = this.props
    const { add_user } = toastMessages

    if (prevProps.sendModal !== sendModal) {
      if (sendModal && sendModal.modalData) {
        // Force role of bizadmin for team members
        sendModal.modalData.role = 'bizadmin'
        
        sendModal.modalData.locationId = selected.id
        
        if (sendModal.modalData.id) {
          updateUser(sendModal.modalData)
            .then(() => {
              toggleToast(toastMessages.update_user)
              loadUsers({locationId: selected.id})
            })
        }
        else {
          addUser(sendModal.modalData)
            .then(() => {
              toggleToast(toastMessages.add_user)
              loadUsers({locationId: selected.id})
          })
        }
      }
    }

    if (prevProps.selected !== selected && selected)
      loadUsers({ locationId: selected.id, limit: 10 })
        .then(resp => checkErrors(resp, null, add_user))

    if (prevProps.confirmed !== confirmed) {
      if (confirmed && modalData) {
        
        deleteUser(modalData.id)

          .then(user => {
            loadUsers({locationId: modalData.locationId})

            if ( user.payload.status === 200 ) {
              // Success
              toggleToast(toastMessages.delete_user, user.payload)
            }
            // Failure
            else toggleToast(toastMessages.error, user.payload)
          })

          toggleConfirmationModal(false)
      }
    }
  }

  listAction = offset => {
    const { selected, loadUsers } = this.props
            
    if ( selected )
      loadUsers({locationId: selected.id, limit: 10, offset})
  }

  copy = field => {
    this.setState({ field, copied: true })
    
    if (!this.state.copied) {
      setTimeout(() => {
        this.setState({ field, copied: false })
      }, 3000);
    }
  }

  render() {
    const { selected, 
            isLoading, 
            success, 
            users,
            toggleCreateModal
          } = this.props

    if (isLoading || !selected)
      return <Loading />

    let list = <EmptyList type={en.businessUsers.businessUsers} />
    
    if (users) {
      if (users.count > 0 && users.data) {
        list = users.data.map(user => {
          const userData = {
            img   : user.resourceImageUrl,
            id    : user.id,
            name  : user.name ? user.name : user.email.split('@')[0],
            cells : [
              {
                title : en.businessUsers.itemTitles[0],
                cell  : user.email
              },
            ]}
    
          return (
            <DataListItem 
              key={user.id} 
              resource={user} 
              data={userData} 
            />
          )
        })
      }
    }
  
    let dataList = (
      <DataList
        itemTitles={en.businessUsers.itemTitles}
        list={list}
      />
    )

    const title = en.businessUsers.header + selected.name

    return (
      <div className="BusinessUsers">
        <Header 
          title={title} 
          cta={en.ctas.create}
          action={() => toggleCreateModal(userModalData, true)} 
        />
        
        { dataList }
   
        <Pages 
          success={success}
          isLoading={isLoading}
          listAction={this.listAction}
          total={users ? users.total : 0}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  users:     state.users.users,
  isLoading: state.users.isLoading,
  success:   state.users.success,
  selected:  state.selectedLocation.selectedLocation,
  sendModal: state.sendModalData,
  confirmed: state.confirmModalReducer.confirmed,
  modalData: state.confirmModalReducer.modalData
})

export default connect(
  mapStateToProps, 
  { loadUsers, 
    switchView, 
    toggleCreateModal,
    addUser,
    updateUser,
    toggleToast,
    deleteUser,
    toggleConfirmationModal
  })(BusinessUsers)
  