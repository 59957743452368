export const loadOnSchedJS = (callback) => {
  const existingScript = document.getElementById('OnSched');

  if (!existingScript) {
    const script = document.createElement('script')
    script.src = 'https://sandbox.onsched.com/js/onsched.min.js'
    script.id = 'OnSched'
    document.body.appendChild(script)

    script.onload = () => {
      if (callback) callback()
    }
  }

  if (existingScript && callback) callback()
}