import { handle } from 'redux-pack'

import { LOAD_LOCATION_BY_ID, RESET_SELECTED_LOCATION } from '../actions/locationActions'


const INITIAL_STATE = { success: false, selectedLocation: {} }


export default function( state = INITIAL_STATE, action ) {
  const { type, payload } = action
  switch ( type ) {
    
    case LOAD_LOCATION_BY_ID:
      return handle( state, action, {
        start:   previousState => ( { ...previousState, isLoading: true, selectedLocationError: null, selectedLocation: null } ),
        finish:  previousState => ( { ...previousState, isLoading: false } ),
        failure: previousState => ( { ...previousState, selectedLocationError: payload } ),
        success: previousState => ( { ...previousState, success: true, selectedLocation: payload.data } )
      })
    
    case RESET_SELECTED_LOCATION:
      return INITIAL_STATE
    
    default:
      return state

  }

}