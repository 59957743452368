import React, { Component } from 'react'
import { connect }          from 'react-redux'

import { clientCredentials } from '../../actions/userActions'
import { toggleBookModal }   from '../../actions/modalActions'
import OnSchedJS             from './OnSchedJS/OnSchedJS'

import './BookingModal.css'


class BookingModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false
    }
  }

  componentDidMount() {
    const { modal, clientCredentials } = this.props

    clientCredentials()

    if (modal && modal.showModal)
      this.setState({ show: modal.showModal })
  }

  componentDidUpdate(prevProps) {
    const { modal } = this.props
    
    if (prevProps.modal !== modal && modal)
      if (modal.showModal)
        this.setState({ show: modal.showModal })
  }

  render() {
    const { show } = this.state
    const { client } = this.props

    return (
      <div className={`BookingModal ${show ? 'show' : 'hide'}`}>
        <OnSchedJS show={show} client={client} />
        
        <div 
          onClick={() => this.setState({ show: false })} 
          className="modal-bg" 
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  modal: state.bookModal,
  client: state.clientCredentials.clientCredentials
})

export default connect(mapStateToProps, { toggleBookModal, clientCredentials })(BookingModal)