import React               from 'react'
import { connect }         from 'react-redux'
import { Link }            from 'react-router-dom'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { deleteLocation,
         loadLocationById, 
         loadLocations }           from '../../../../actions/locationActions'
import { localSetLocation }        from '../../../../utils/localStorage'
import { toggleConfirmationModal } from '../../../../actions/modalActions'
import InitialsIcon                from '../../../InitialsIcon/InitialsIcon'

import './LocationCard.css'

const LocationCard = props => {
  const { selectedLocation, 
          loading, 
          location, 
          history, 
          primaryLocationID,
          toggleConfirmationModal ,
          localSetLocation
        } = props

  const loadLocation = () => {
    if (!loading) {
      if ( !selectedLocation || 
           selectedLocation.id !== location.id ) {
        props.loadLocationById(location.id)
        localSetLocation(location.id)
      }
      else {
        history.push(`/locations/edit/${location.id}#location-profile`)
      }
    }
  }

  let selected = ''
  let icon = ''
  let primaryLocation = ''
  let isNotPrimary = true

  if ( selectedLocation && selectedLocation.id === location.id ) {
    selected = 'selected'
  }

  if (primaryLocationID === location.id) {
    primaryLocation = 'primary'
    icon = <i className="far fa-check" />
    isNotPrimary = false
  }

  let trimmedID = location.id

  if (location.id.length > 6)
    trimmedID = location.id.slice(0, 4) + '...'

  return (
    <tr className={`LocationCard ${selected} ${primaryLocation}`}>
      <CopyToClipboard text={location.id}>
        <td>
            <span>{trimmedID}</span>
            <i className="far fa-copy" />
        </td>
      </CopyToClipboard>
      <td onClick={() => loadLocation()}>
        <InitialsIcon 
          name={location.name} 
          img={location.imageUrl} 
        />
      </td>

      <td onClick={() => loadLocation()}>
        {location.name}
      </td>

      <td className="cells" onClick={() => loadLocation()}>
        {icon}
      </td>
      
      <td>
        <div className="DataListAction">
            <Link to={`Locations/edit/${location.id}#location-profile`}>
              <i className="fad fa-pencil edit" />
            </Link>

            {isNotPrimary && 
              <i 
                className='fad fa-trash delete'
                onClick={() => toggleConfirmationModal(true, { name: location.name, id: location.id })} 
              />
            } 
            
        </div>
      </td>
    </tr>
  )
}

function mapStateToProps(state) {
  return {
    selectedLocation:  state.selectedLocation.selectedLocation,
    primaryLocationID: state.primary.primary.id,
    loading:           state.selectedLocation.isLoading,
  }
}

export default connect(mapStateToProps, { 
  loadLocationById, 
  toggleConfirmationModal, 
  deleteLocation,
  loadLocations,
  localSetLocation
})(LocationCard)
