import { handle } from 'redux-pack'

import { LOAD_PROVINCES } from '../actions/provinceActions'


const INITIAL_STATE = { success: false }


export default function( state = INITIAL_STATE, action ) {
  const { type, payload } = action
  switch ( type ) {
    
    case LOAD_PROVINCES:
      return handle( state, action, {
        start:   previousState => ( { ...previousState, isLoading: true, provincesError: null, provinces: null } ),
        finish:  previousState => ( { ...previousState, isLoading: false } ),
        failure: previousState => ( { ...previousState, provincesError: payload } ),
        success: previousState => ( { ...previousState, success: true, provinces: payload.data } )
      })

    default:
      return state

  }

}