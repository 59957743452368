import React, { Component }             from 'react'
import { connect }                      from 'react-redux'
import { reduxForm, formValueSelector } from 'redux-form'
import moment                           from 'moment'

import { viewTypes }                  from '../../../actions/viewActions'
import { toggleToast, 
         toastMessages,
         toggleError }                from '../../../actions/toastActions'
import { addResourceBlock, 
        loadResourceBlocks }          from '../../../actions/resourceActions'
import { addCalendarBlock, 
        loadCalendarBlocks }          from '../../../actions/calendarActions'
import { loadServiceBlocks, 
         addServiceBlock }            from '../../../actions/serviceActions'        
import { timeOffCardForm, 
        timeOffRepeatSection }        from '../../../utils/formFields'
import { checkErrors }                from '../../../utils/checkErrors'
import FormFields                     from '../../FormFields/FormFields'
import AddedTimeBlocks                from './AddedTimeBlocks/AddedTimeBlocks'
import WeekDays                       from './WeekDays/WeekDays'
import HelpTip                        from '../../HelpTip/HelpTip'

import './TimeOffCard.css'


class TimeOffCard extends Component {
  componentDidMount() {
    const { 
            loadCalendarBlocks, 
            loadResourceBlocks,
            loadServiceBlocks, 
            view, 
            selectedLocation, 
            selectedResource,
            selectedService,
          } = this.props

    if (view === viewTypes.locations) {

      if (selectedLocation) {
        loadCalendarBlocks(selectedLocation.primaryCalendarId).then(response => checkErrors(response))
      }
    } 
    else if (view === viewTypes.resources) {

      if (selectedResource) {
        loadResourceBlocks(selectedResource.id).then(response => checkErrors(response))
      }
    }
    else if (view === viewTypes.services) {

      if (selectedService) {
        loadServiceBlocks(selectedService.id).then(response => checkErrors(response))
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { noEndDate,
            change,
            calendarBlocks,
            view ,
            selectedLocation,
            loadCalendarBlocks
          } = this.props

    if (prevProps.noEndDate !== noEndDate) {
      change('endDate', noEndDate ? '9999-12-31' : moment().format('YYYY-MM-DD'))
    }

    if (view === viewTypes.locations) {
      if (selectedLocation && !calendarBlocks) {
        loadCalendarBlocks(selectedLocation.primaryCalendarId).then(response => checkErrors(response))
      }
    } 

  }

  render() {
    const{ view, 
           selectedLocation, 
           selectedResource,
           selectedService,
           form, 
           frequency, 
           interval, 
           startDate,
           startTime,
           endDate,
           endTime,
           repeat,
           repeats,
           reason,
           weekdays,
           toggleToast,
           toggleError,
           loadResourceBlocks,
           addResourceBlock,
           loadCalendarBlocks,
           addCalendarBlock,
           addServiceBlock,
           loadServiceBlocks
          } = this.props
    
    let endTimeGreater = parseInt(endTime) >= parseInt(startTime)      
    let endDateGreater = moment(endDate).isSameOrAfter(moment(startDate))
    let today = moment().format('YYYY-MM-DD')
    let now = parseInt(moment().format('HHmm'))
    let dateInFuture = false
    let dateIsToday = false
    let timeInFuture = false
    let disabled = true
    
    if (startDate) {
      dateInFuture = parseInt(startDate.replace(/-/g,"")) >= parseInt(today.replace(/-/g,""))
      dateIsToday = parseInt(startDate.replace(/-/g,"")) === parseInt(today.replace(/-/g,""))
    }

    if (startTime) {
      timeInFuture = startTime >= now
    }

    if (repeats) {
      if (endDateGreater && endTimeGreater && dateInFuture) {
        if (dateIsToday) {
          if (timeInFuture) {
            disabled = false
          }
        }
        else {
          disabled = false
        }
      }
    }
    else if (endTimeGreater && dateInFuture) {
      if (dateIsToday) {
        if (timeInFuture) {
          disabled = false
        }
      }
      else {
        disabled = false
      }
    }
  
    const addBlock = () => {

      let timeBlock = {
        locationId : selectedLocation.id,
        startDate : startDate,
        endDate : repeats ? endDate : startDate,
        startTime : startTime,
        endTime : endTime,
        reason : reason ? reason : 'Time block',
        repeats : repeats,
        repeat : {
          frequency: frequency,
          interval: interval,
          weekdays: weekdays ? weekdays : '',
          monthDay: new Date(startDate).toISOString().slice(8,10),
          monthType: "D"
        }
      }
      
      if (view === viewTypes.locations) {
        if (selectedLocation) {
          addCalendarBlock(selectedLocation.primaryCalendarId, timeBlock)
                .then(resp => {
                  if (resp.payload.status === 200) {
                    loadCalendarBlocks(selectedLocation.primaryCalendarId).then(resp2 => checkErrors(resp2, () => {
                      toggleToast(toastMessages.add_time_block)
                    }))
                  }
                  else toggleError(resp.payload)
          })
        }
      }
  
      else if (view === viewTypes.resources) {
        if (selectedResource) {
          addResourceBlock(selectedResource.id, timeBlock)
                .then(resp => {
                  if (resp.payload.status === 200) {
                    loadResourceBlocks(selectedResource.id).then(resp2 => checkErrors(resp2, () => {
                      toggleToast(toastMessages.add_time_block)
                    }))
                  }
                  else toggleError(resp.payload)
              })
        }
      }

      else if (view === viewTypes.services) {
        if (selectedService) {
          addServiceBlock(selectedService.id, timeBlock)
                .then(resp => {
                  if (resp.payload.status === 200) {
                    loadServiceBlocks(selectedService.id).then(resp2 => checkErrors(resp2, () => {
                      toggleToast(toastMessages.add_time_block)
                    }))
                  }
                  else toggleError(resp.payload)
              })
        }
      }
    }
  
    let plural = ''
    let intervalNodeList = document.getElementsByName('interval')
  
    if (frequency && interval) {
      if (parseInt(interval) > 1 ) {
        plural = 'plural'
      }
      if (intervalNodeList.length) {
        intervalNodeList[0].parentElement.className = `renderField renderDropdownField split ${frequency} ${plural}`
      }
    }
    
    let title = "Block dates & times"
    let subtitle = "Add a single or recurring time off block (e.g. company retreat, lunch break). Blocks will restrict appointments from being booked/available during these blocked dates/times."
    let btn = "Block time"
  
    return (
      <div className="TimeOffCard row">
        <div className='options col-5'>
          <div className='help-tip-title'>
            <h5>{title}</h5>
            <HelpTip tip={subtitle} />
          </div>
    
          <FormFields form={form} fields={timeOffCardForm} />
          <FormFields disabled={!repeats} form={form} fields={timeOffRepeatSection} />
          <WeekDays form={form} repeats={repeats} repeat={repeat} isEvent={false} />
          <button
            type="button"
            disabled={disabled}
            className="btn btn-primary block-button"
            onClick={addBlock}
          >
            {btn}
          </button>
        </div>
  
        <div className='col-7'>
          <AddedTimeBlocks form={form} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => ({
  repeats:          formValueSelector(props.form)(state, 'repeatsBlock'),
  weekdays:         formValueSelector(props.form)(state, 'repeatBlock.weekdays'),
  startDate:        formValueSelector(props.form)(state, 'startDateBlock'),
  endDate:          formValueSelector(props.form)(state, 'endDateBlock'),
  startTime:        formValueSelector(props.form)(state, 'startTimeBlock'),
  endTime:          formValueSelector(props.form)(state, 'endTimeBlock'),
  repeat:           formValueSelector(props.form)(state, 'repeatBlock'),
  frequency:        formValueSelector(props.form)(state, 'repeatBlock.frequency'),
  interval:         formValueSelector(props.form)(state, 'repeatBlock.interval'),
  reason:           formValueSelector(props.form)(state, 'reason'),
  noEndDate:        formValueSelector(props.form)(state, 'noEndDate'),
  selectedLocation: state.selectedLocation.selectedLocation,
  selectedResource: state.selectedResource.selectedResource,
  selectedService:  state.service.service,
  view:             state.view.view
})

export default reduxForm({ destroyOnUnmount: false })(
  connect(
    mapStateToProps, 
    { addResourceBlock, 
      addCalendarBlock,
      toggleToast, 
      toggleError, 
      loadResourceBlocks, 
      loadCalendarBlocks,
      addServiceBlock,
      loadServiceBlocks 
    }
  )(TimeOffCard))
