import { handle } from 'redux-pack'

import { LOAD_CALENDAR_BLOCK,
         UPDATE_CALENDAR_BLOCK } from '../actions/calendarActions'


const INITIAL_STATE = { success: false }

export const loadCalendarBlockReducer = ( state = INITIAL_STATE, action ) => {
  const { type, payload } = action

  switch ( type ) {  
    case LOAD_CALENDAR_BLOCK:
      return handle( state, action, {
        start:   previousState => ( { ...previousState, isLoading: true, calendarBlockError: null, calendarBlock: null } ),
        finish:  previousState => ( { ...previousState, isLoading: false } ),
        failure: previousState => ( { ...previousState, calendarBlockError: payload } ),
        success: previousState => ( { ...previousState, success: true, calendarBlock: payload.data } )
      })

    default:
      return state
  }
}

export const updateCalendarBlockReducer = ( state = INITIAL_STATE, action ) => {
  const { type, payload } = action

  switch ( type ) {
    case UPDATE_CALENDAR_BLOCK:
      return handle( state, action, {
        start:   previousState => ( { ...previousState, isLoading: true, updatedCalendarBlockError: null, updatedCalendarBlock: null } ),
        finish:  previousState => ( { ...previousState, isLoading: false } ),
        failure: previousState => ( { ...previousState, updatedCalendarBlockError: payload } ),
        success: previousState => ( { ...previousState, success: true, updatedCalendarBlock: payload.data } )
      })

    default:
      return state
  }
}
