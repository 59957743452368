import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { toggleError } from '../actions/toastActions'

const useLoadData = (object, action, isLoading) => {
  const params   = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    const abortController = new AbortController()

    const loadObject = async () => {
      const { payload } =  await dispatch(action(params.id))
      if (payload.status !== 200) {
        dispatch(toggleError(payload))
      }
    }
    
    if ((!object || object.id !== params.id) && !isLoading) {
      loadObject()
    }

    return () => { abortController.abort() }
  }, [object, action, params.id, isLoading, dispatch])
}

export default useLoadData