import React, { useEffect }                  from 'react'
import { reduxForm }                         from 'redux-form'
import { connect, useDispatch, useSelector } from 'react-redux'
import querystring                           from 'querystring'

import FormFields                           from '../FormFields/FormFields'
import SearchOptions                        from './SearchOptions/SearchOptions'
import { setLocationText, 
         setResourceText,
         RESET_LIST_SEARCH_TEXT, 
         setServiceText }                   from '../../actions/searchListActions'
import { setPage }                          from '../../actions/pageActions'

import './OnSchedSearch.css'


const OnSchedSearch = props => {

  const { object,
          fields,
          action,
          selected,
          placeholder,
          handleSubmit,
          icon = 'search',
          initialize,
          setPage,
          history,
          initialValues
        } = props

  const limit = 10

  const dispatch = useDispatch()

  const { searchLocationText, 
          searchResourceText, 
          searchServiceText } = useSelector(state => state.searchTextFilter)
  
  // On mount and on update initialize depending which object is passed
  useEffect(() => {
    if (searchLocationText && object === 'locations') { 
      initialize({ searchText: searchLocationText, ...initialValues })
    }
    else if (searchResourceText && object === 'resources') {
      initialize({ searchText: searchResourceText, ...initialValues})
    }
    else if (searchServiceText && object === 'services') {
      initialize({ searchText: searchServiceText, ...initialValues })
    }
  }, [ searchLocationText, searchResourceText, searchServiceText ])

  const handleSearch = values => {
    const checkEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
    if (values.searchText) {
      if (checkEmail.test(values.searchText)) {
        values.email = values.searchText
      }
      else if (object === 'customers' || object === 'appointments') {
        values.lastname = values.searchText
      }
      else {
        values.name = values.searchText

        if (object === 'locations') {
          dispatch(setLocationText(values.searchText))
        }
        else if (object === 'resources') {
          dispatch(setResourceText(values.searchText))
        }
        else if (object === 'services') {
          dispatch(setServiceText(values.searchText))
        }
      }
    }
    else {
      dispatch({ type: RESET_LIST_SEARCH_TEXT })
    }

    if (selected) {
      values.locationId = selected.id
    }

    setPage(1)

    const valuesWithLimit = { ...values, limit }
    action(valuesWithLimit)

    // remove locationId and filters from the object
    const { locationId, filters, ...FilteredValues } =  valuesWithLimit 

    if (object === 'appointments') {
      history.push(`${window.location.pathname}?${querystring.stringify(FilteredValues)}`)
    }
  }

  return (
    <form onSubmit={handleSubmit(handleSearch)} className="OnSchedSearch">
      <SearchOptions
        filtered={fields.length}
        object={object}
        placeholder={placeholder}
        icon={icon}
      />
      <FormFields form='onsched-search' fields={fields} />
    </form>
  )
}

const mapStateToProps = state => ({
  selected: state.selectedLocation.selectedLocation
})

const connectedOnSchedSearch = connect(mapStateToProps, { setPage })(OnSchedSearch)

export default reduxForm({ form: 'onsched-search' })(connectedOnSchedSearch)
