import React                       from 'react'
import { Card }                    from 'react-bootstrap'
import { connect }                 from 'react-redux'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

import { detectEnv }               from '../../../utils/detectEnv'

import './TestBooking.css'

const TestBooking = props => {
  const { selected, 
          body, 
          icon,
          status, 
          enabled,
          token,
          user,
          client_id
        } = props


  var dashToken
  var locationId
  var userEmail
  let onschedUrl = null
  let target     = null
  let helpTip    = 'Please add at least one resource and one service to launch a test booking.'
  
  if (selected) {
    locationId = selected.id
  }

  if (user)
    userEmail = user.email
  else if (window.localStorage.userEmail)
    userEmail = window.localStorage.userEmail

  if (selected &&
      enabled &&
      client_id &&
      userEmail) {
    helpTip    = null
    target     = '_blank'
    onschedUrl = `https://onsched.com/dashboardjsbooking?userEmail=` +
                  `${userEmail}&client_id=${client_id}&locationId=${locationId}&env=${detectEnv()}`
  }

  let available = {
    'color' : 'var(--onschedLightGrey)'
  }

  if ( status ) available = {
    'animation' : 'onboard 300ms 1 forwards'
  }

  const card = (
    <Card>
      <i style={available} className={icon} />

      <Card.Body>{body}</Card.Body>
    </Card>
  )

  if (helpTip) return (
    <a 
      className="TestBooking disabled"
      target={target} 
      href={onschedUrl}
      // onClick={() => window.Appcues.track(
      //   "Clicked test booking"
      // )}
    >
      <OverlayTrigger
        key="right"
        placement="right"
        overlay={
          <Tooltip className="help" id="tooltip-right">
            {helpTip}
          </Tooltip>
        }
      >
        {card}
      </OverlayTrigger>
    </a>
  )

  return (
    <a 
    className="TestBooking"
    target={target} 
    href={onschedUrl}
  >
    {card}
  </a>
  )
}

const mapStateToProps = state => ({
  selected: state.selectedLocation.selectedLocation,
  user:     state.user.user,
  token:    state.onschedJsToken
})

export default connect(mapStateToProps, {})(TestBooking)
