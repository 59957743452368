import React, { useEffect }  from 'react'
import { Tab, Tabs }         from 'react-bootstrap'
import { connect, useSelector }           from 'react-redux'
import { formValueSelector, 
         reduxForm }         from 'redux-form'

import { loadCompany,
         updateCompany }         from '../../actions/companyActions'
import { toastMessages, 
         toggleError,
         toggleToast }           from '../../actions/toastActions'
import { switchView,
         viewTypes }             from '../../actions/viewActions'
import { useView }               from '../../hooks/useView'
import useCountryChange          from '../../hooks/useCountryChange'
import { en }                    from '../../utils/languages'
import { profileCompanyFields,
         settingsCompanyFields } from '../../utils/formFields'
import { editCompanyForm }       from '../../utils/initializeForm'
import { toggleCheckErrors }     from '../../utils/checkErrors'
import { validateCompany }       from '../../utils/validate'
import FormFields                from '../FormFields/FormFields'
import Header                    from '../Header/Header'
import Loading                   from '../Loading/Loading'

import './ModifyCompany.css'



const ModifyCompany = props => {
  const { loadCompany,
          change,
          company,
          country,
          form,
          handleSubmit,
          initialize,
          loading,
          toggleToast,
          toggleError,
          updateCompany,
          view
        } = props

  useView(view, viewTypes.company_settings)
  useCountryChange(country, change, true)

  useEffect(() => {
    if (!company) {
      loadCompany().then((res) =>  {
        initialize(editCompanyForm(res.payload.data))
      })
    }
    else {
      initialize(editCompanyForm(company))
    }

  }, [company, initialize, loadCompany])

  const submitCompany = values => {
    updateCompany(values)
      .then((res) => {
        toggleCheckErrors(res, toggleError, () =>  {
          toggleToast(toastMessages.save_company, res.payload)
          loadCompany()
        })
      })
    
  }

  return (
    <form onSubmit={handleSubmit(submitCompany)}>
      { loading ? <Loading /> : 
        <>
          <Header  
            title={"Company"}
            btntype="Submit"
            cta={en.company.save}
            form='company'
          />
          <Tabs
            id="company-tabs"
            defaultActiveKey='profile'
          >
            <Tab eventKey="profile" title="Profile">
                <FormFields fields={profileCompanyFields} form={form}/>
            </Tab>
            <Tab eventKey="settings" title="Settings">
                <FormFields fields={settingsCompanyFields} form={form} />
            </Tab>
          </Tabs>
        </>
      }     
    </form>
  )
}

const mapStateToProps = (state, props) => ({
  company:  state.company.company,
  country:  formValueSelector(props.form)(state, 'country'),
  view:     state.view.view,
  loading:  state.company.isLoading ||
            state.updatedCompany.isLoading
})

const connectedCompanySettings = connect(
  mapStateToProps, 
  { loadCompany,
    updateCompany,
    switchView,
    toggleError,
    toggleToast, }
)(ModifyCompany)

export default reduxForm({form: 'company', validate: validateCompany})(connectedCompanySettings)
