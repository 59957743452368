import { handle } from 'redux-pack'

import { UNLINK_SERVICE } from '../actions/serviceActions'


const INITIAL_STATE = { success: false }


export default function unlinkedServiceReducer( state = INITIAL_STATE, action ) {
  const { type, payload } = action

  switch ( type ) {
    
  case UNLINK_SERVICE:
    return handle( state, action, {
      start:   previousState => ( { ...previousState, isLoading: true, unlinkedError: null, success: false } ),
      finish:  previousState => ( { ...previousState, isLoading: false } ),
      failure: previousState => ( { ...previousState, unlinkedError: payload } ),
      success: previousState => ( { ...previousState, success: true, unlinked: payload.data } )
    })

  default:
    return state

  }

}
