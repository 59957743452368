import { handle } from 'redux-pack'

import { LOAD_COMPANIES } from '../actions/companyActions'


const INITIAL_STATE = { success: false }


export default function loadCompaniesReducer( state = INITIAL_STATE, action ) {
  const { type, payload } = action

  switch ( type ) {
    
  case LOAD_COMPANIES:
    return handle( state, action, {
      start:   previousState => ( { ...previousState, isLoading: true, companiesError: null, success: false } ),
      finish:  previousState => ( { ...previousState, isLoading: false } ),
      failure: previousState => ( { ...previousState, companiesError: payload } ),
      success: previousState => ( { ...previousState, success: true, companies: payload.data } )
    })

  default:
    return state

  }

}
