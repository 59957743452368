import { handle } from 'redux-pack'

import { SIGN_UP_USER, 
         INVITE_USER } from '../actions/authActions'


const INITIAL_STATE = { success: false }


export const singUpReducer = ( state = INITIAL_STATE, action ) => {
  const { type, payload } = action
  switch ( type ) {
    
    case SIGN_UP_USER:
      return handle( state, action, {
        start:   previousState => ( { ...previousState, isLoading: true, userError: null, user: null } ),
        finish:  previousState => ( { ...previousState, isLoading: false } ),
        failure: previousState => ( { ...previousState, userError: payload } ),
        success: previousState => ( { ...previousState, success: true, user: payload.data } )
      })

    default:
      return state

  }

}

export const inviteUser = ( state = INITIAL_STATE, action ) => {
  const { type, payload } = action
  switch ( type ) {
    
    case INVITE_USER:
      return handle( state, action, {
        start:   previousState => ( { ...previousState, isLoading: true, userError: null, user: null } ),
        finish:  previousState => ( { ...previousState, isLoading: false } ),
        failure: previousState => ( { ...previousState, userError: payload } ),
        success: previousState => ( { ...previousState, success: true, user: payload.data } )
      })

    default:
      return state

  }

}
