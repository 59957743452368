import React        from 'react'
import { Col } from 'react-bootstrap'

import ForManagers  from './ForManagers/ForManagers'

import './GetStarted.css'

const GetStarted = props => {
  let showView = ''

  if ( props.hide ) showView = 'hideMobile'

  return (
    <div className={`GetStarted ${showView}`}>
      <Col xl={5}>
        <ForManagers />
      </Col>
    </div>
  )
}

export default GetStarted