import React       from "react"
import { connect } from "react-redux"

import Loading from "../components/Loading/Loading"


export default (FormComponent, formType) => {
  const mapStateToProps = state => ({ 
    modType: state.modType.modType,
    view:    state.view.view
  })

  const LoadingDataHOC = props => {
    if (!props.modType) return <Loading />

    return (
      <FormComponent form={`${props.modType}-${formType}`} {...props} />
    )
  }

  return connect(mapStateToProps, {})(LoadingDataHOC)
}