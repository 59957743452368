import React         from 'react'
import { reduxForm } from 'redux-form'

import { validateSignIn } from '../../../utils/validate'
import { signInForm }     from '../../../utils/formFields'
import FormFailedMessage  from '../../FormFailedMessage/FailedMessage'
import FormFields         from '../../FormFields/FormFields'

import './SignInForm.css'

const SignInForm = props => {
  const { handleSubmit, failed, wrongPw, history } = props

  return (
    <form onSubmit={handleSubmit} className="SignInForm">
      <h3>Sign in</h3>
      
      { 
        failed 
          ? 
        <FormFailedMessage wrongPw={wrongPw} /> 
          : 
        null 
      }

      <FormFields form='signin' fields={signInForm} />

      <button 
        type="button"
        className="forgot" 
        onClick={() => history.push('/ForgotPassword')}
      >
        Forgot password
      </button>

      <button 
        type="submit"
        className="btn btn-primary"
      >
        Sign In
      </button> 
    </form>
  )
}

export default reduxForm({ 
  form: 'signin', 
  validate: validateSignIn 
})(SignInForm)
