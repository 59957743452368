import React, { Component }  from 'react'
import { connect }           from 'react-redux'
import { Row, Col }          from 'react-bootstrap'
import { formValueSelector } from 'redux-form'

import Pages                     from '../Pages/Pages'
import Header                    from '../Header/Header'
import Loading                   from '../Loading/Loading'
import OnSchedSearch             from '../OnSchedSearch/OnSchedSearch'
import CompanyCard               from './CompanyCard/CompanyCard'
import LocationList              from './LocationList/LocationList'
import { en }                    from '../../utils/languages'
import { toggleCheckErrors }           from '../../utils/checkErrors'
import { toggleCreateModal }     from '../../actions/modalActions'
import { loadLocations }         from '../../actions/locationActions'
import { switchView, viewTypes } from '../../actions/viewActions'
import { setLastPage, setPage }  from '../../actions/pageActions'
import { resetSearchText }       from '../../actions/searchListActions'
import { toggleError }           from '../../actions/toastActions'

import './Locations.css'


class Locations extends Component {
  constructor(props) {
    super(props)
    this.state = {
      limit: 10
    }
  }

  componentDidMount() {
    const { switchView, 
            setPage, 
            loadLocations, 
            history, 
            setLastPage,
            resetSearchText,
            toggleError, 
            page: { lastPageOffset, lastPage }
          } = this.props

    switchView(viewTypes.locations)
    resetSearchText()     

    // if a second argument was passed into the history.push()
    if (history.location.state) {
      loadLocations({ limit: this.state.limit, offset: lastPageOffset })
        .then((response) => toggleCheckErrors(response, toggleError))

      setPage(lastPage)

      // delete the state from history
      history.push({ state: false })
    }
    else {
      setPage(1)
      loadLocations({ limit: this.state.limit })
        .then((response) => {
            toggleCheckErrors(response, toggleError, () => {
              setLastPage(response.payload?.data?.total)
            })
        })
    }
  }

  handleListAction = offset => {
    const { searchText, loadLocations } = this.props
    
    loadLocations({ limit: this.state.limit, offset, name: searchText })
      .then((response) => toggleCheckErrors(response, toggleError))
  }

  render() {
    const { toggleCreateModal, 
            loadLocations,
            history,
            loading,
            locations: { 
              locations, 
              isLoading,
              success 
            } 
          } = this.props
          
    return (
      <div className="Locations">
        <Header 
          title="Locations"
          action={() => toggleCreateModal(en.locations.modalData, true)}
          cta={en.ctas.new}
        />
        <Row>
          <Col xl={8}>
            <OnSchedSearch 
              object='locations'
              action={loadLocations}
              fields={[]}
              placeholder={en.locations.searchPlaceholder}
              icon='search-location'
            />
          </Col>
        </Row>
        { loading ? <Loading /> : (
          <Row>
            <Col xl={8} className="Secondary">
              <LocationList history={history} />
              <Pages 
                total={locations.total}
                isLoading={isLoading}
                success={success}
                listAction={this.handleListAction}
              />
            </Col>
            <Col className="Company" xl={4}>
              <h5 className="header">{en.locations.company_header}</h5>
              <CompanyCard />
            </Col>
          </Row>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  locations:  state.locations,
  page:       state.page,
  loading :   state.locations.isLoading || 
              state.locationSaved.isLoading || 
              state.locationCreated.isLoading || 
              state.locationDeleted.isLoading || 
              !state.primary.primary,
  searchText: formValueSelector('onsched-search')(state, 'searchText')
})

export default connect(
  mapStateToProps, 
  { toggleCreateModal, 
    loadLocations, 
    switchView,
    setPage,
    setLastPage,
    resetSearchText,
    toggleError 
  })(Locations)
  