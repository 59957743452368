import { TOGGLE_SIDE_NAV } from '../actions/sideNavActions'

const INITIAL_STATE = {
  action: false
}

const sideNavReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TOGGLE_SIDE_NAV:
      return {
          id: action.id,
          action: action.action,
        }
    default:
      return state
  }
}

export default sideNavReducer