import React       from 'react'
import { connect } from 'react-redux'

import { addParameter } from '../../../../../../../actions/notificationActions'

import './ParameterItem.css'


const ParameterItem = ({ param, position, addParameter, active }) => {
  const handleClick = () => {
    if (active) {
      addParameter(
        param, 
        position.start, 
        position.end
      )
    }
  }
  
  return (
    <div 
      onClick={() => handleClick()} 
      className="ParameterItem"
    >
      &#123;{param}&#125;
    </div>
  )
}

const mapStateToProps = state => ({
  position: state.editorCaretPosition.position
})

export default connect(mapStateToProps, { addParameter })(ParameterItem)