import { SET_ACCOUNT_TOKEN } from '../actions/authActions'

const INITIAL_STATE = {
  accountToken: null
}

const setTokenReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_ACCOUNT_TOKEN:
      return {
          accountToken: action.accountToken
        }
    default:
      return state
  }
}

export default setTokenReducer