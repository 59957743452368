import axios from 'axios'

export const REVOKE_CLIENT_SECRET = 'REVOKE_CLIENT_SECRET'

export const revokeAPIKeys = (secret) => {
  const config = {}  
  const deleteClientSecretRoute = `/api/identity/client_secrets/${secret}`

  return {
    type:    REVOKE_CLIENT_SECRET,
    promise: axios.delete( deleteClientSecretRoute, config )
  }
}
