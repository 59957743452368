import { renderDropdownField } from "../../utils/renderDropdownField";

export const resourceSearchFields = [
  {
    name:        'sortOrder',
    label:       'Sort',
    component:   renderDropdownField,
    customClass: 'hide-disabled',
    dynamicVisibility: [{trigger: 'filters', match: true}],
    options: [
      {
        label: 'Chronological',
        value: '2'
      },
      {
        label: 'Name',
        value: '0'
      }
    ]
  },
]

export const initialResourceSearchValues = {
  sortOrder: '0'
}