import { useEffect }   from "react"
import { useState }    from "react"
import { useLocation } from "react-router"

export const useTabParams = (defaultTab) => {
  const [ tab, setTab ] = useState(defaultTab)
  const location = useLocation()

  useEffect(() => {
    if (location.hash) {
      setTab(location.hash.substring(1))
    }
  }, [location])

  return [ tab, setTab ]

}
