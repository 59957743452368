import axios       from 'axios'
import querystring from 'querystring'
import { toggleCalendarModal } from './modalActions'
import { toastMessages, toggleError, toggleToast } from './toastActions'
import { modTypes } from './viewActions'

export const LOAD_INITIAL_RESOURCES = 'LOAD_INITIAL_RESOURCES'

export const loadInitialResources = ( locationId ) => {

  const config = {}

                   
  const resourceData = {
    "locationId" : locationId,
    "offset"     : 0,
    "limit"      : 1
  }
                 
  const queryString = querystring.stringify( resourceData )
  
  const resourcesRoute = `/api/onsched/setup/resources` +
                                          `?${queryString}`

  return {
    type:    LOAD_INITIAL_RESOURCES,
    promise: axios.get( resourcesRoute, config )
  }

}

export const LOAD_RESOURCES = 'LOAD_RESOURCES'

export const loadResources = ( queryParams ) => {

  const config = {}

  queryParams.limit = 10
                 
  const queryString = querystring.stringify( queryParams )
  
    const resourcesRoute = `/api/onsched/consumer/resources` +
                                          `?${queryString}`

  return {
    type:    LOAD_RESOURCES,
    promise: axios.get( resourcesRoute, config )
  }

}

export const CREATE_RESOURCE = 'CREATE_RESOURCE'

export const createResource = ( resourceData = {}, queryParams = {} ) => {

  const config = {}

  const queryString = querystring.stringify( queryParams )
  
  const createResourcesRoute = `/api/onsched/setup/resources` +
                                              `?${queryString}`

  return {
    type:    CREATE_RESOURCE,
    promise: axios.post( createResourcesRoute, resourceData, config )
  }

}

export const DELETE_RESOURCE = 'DELETE_RESOURCE'

export const deleteResource = ( id ) => {

  const config = {}
  
  const deleteResourcesRoute = `/api/onsched/setup/resources/${id}`

  return {
    type:    DELETE_RESOURCE,
    promise: axios.delete( deleteResourcesRoute, config )
  }

}

export const LOAD_RESOURCE_BY_ID = 'LOAD_RESOURCE_BY_ID'

export const loadResourceById = ( resourceId,
                                  queryParams = {} ) => {

  const config = {}
                 
  const queryString = querystring.stringify( queryParams )
  
    const resourceRoute = `/api/onsched/setup/resources/${resourceId}` +
                                              `?${queryString}`

  return {
    type:    LOAD_RESOURCE_BY_ID,
    promise: axios.get( resourceRoute, config )
  }

}

export const UPDATE_RESOURCE = 'UPDATE_RESOURCE'

export const updateResource = ( id, resource,
                                  queryParams = {} ) => {
  const config = {}
                 
  const queryString = querystring.stringify( queryParams )
  
  const resourceRoute = `/api/onsched/setup/resources/${id}` +
                                              `?${queryString}`

  return {
    type:    UPDATE_RESOURCE,
    promise: axios.put( resourceRoute, resource, config )
  }

}

export const LOAD_RESOURCE_AVAILABILITY = 'LOAD_RESOURCE_AVAILABILITY'

export const loadResAvail = ( id, queryParams = {} ) => {

  const config = {}
                 
  const queryString = querystring.stringify( queryParams )
  
    const resourceRoute = `/api/onsched/setup/resources/${id}` +
                                `/availability?${queryString}`

  return {
    type:    LOAD_RESOURCE_AVAILABILITY,
    promise: axios.get( resourceRoute, config )
  }

}

export const LOAD_RESOURCE_SERVICES = 'LOAD_RESOURCE_SERVICES'

export const loadResourceServices = ( id, queryParams = {} ) => {

  const config = {}
                 
  const queryString = querystring.stringify( queryParams )
  
    const resourceRoute = `/api/onsched/consumer/resources/${id}` +
                                `/services?${queryString}`

  return {
    type:    LOAD_RESOURCE_SERVICES,
    promise: axios.get( resourceRoute, config )
  }

}

export const UPDATE_RESOURCE_SERVICES = 'UPDATE_RESOURCE_SERVICES'

export const updateResourceServices = ( id, servList, queryParams = {} ) => {

  const config = {}
                 
  const queryString = querystring.stringify( queryParams )
  
  const resourceRoute = `/api/onsched/setup/resources/${id}` +
                                `/services?${queryString}`

  return {
    type:    UPDATE_RESOURCE_SERVICES,
    promise: axios.post( resourceRoute, servList, config )
  }

}

export const DELETE_RESOURCE_SERVICES = 'DELETE_RESOURCE_SERVICES'

export const deleteResourceServices = ( id, queryParams = {} ) => {

  const config = {}
                 
  const queryString = querystring.stringify( queryParams )
  
  const resourceRoute = `/api/onsched/setup/resources/${id}` +
                                `/services?${queryString}`

  return {
    type:    DELETE_RESOURCE_SERVICES,
    promise: axios.delete( resourceRoute, config )
  }

}

export const UPDATE_RESOURCE_AVAILABILITY = 'UPDATE_RESOURCE_AVAILABILITY'

export const updateResAvail = ( id, avail, queryParams = {} ) => {

  const config = {}
                 
  const queryString = querystring.stringify( queryParams )
  
    const resourceRoute = `/api/onsched/setup/resources/${id}` +
                                `/availability?${queryString}`

  return {
    type:    UPDATE_RESOURCE_AVAILABILITY,
    promise: axios.put( resourceRoute, avail, config )
  }

}

export const UPLOAD_RESOURCE_IMAGE = 'UPLOAD_RESOURCE_IMAGE'

export const uploadResourceImage = ( resourceId, imgData ) => {

  const config = {}
  
  const resourceImgRoute = `/api/onsched/setup/resources/${resourceId}/uploadimage`

  return {
    type:    UPLOAD_RESOURCE_IMAGE,
    promise: axios.post( resourceImgRoute, imgData, config )
  }
}

export const ADD_RESOURCE_BLOCK = 'ADD_RESOURCE_BLOCK'

export const addResourceBlock = ( id, timeBlock = {} ) => {
  const config = {}
  
  const resourceBlockRoute = `/api/onsched/setup/resources/${id}/block`

  return {
    type:    ADD_RESOURCE_BLOCK,
    promise: axios.post( resourceBlockRoute, timeBlock, config )
  }
}

export const DELETE_RESOURCE_IMAGE = 'DELETE_RESOURCE_IMAGE'

export const deleteResourceImage = ( resourceId ) => {

  const config = {}
  
  const resourceImgRoute = `/api/onsched/setup/resources/${resourceId}/deleteimage`

  return {
    type:    DELETE_RESOURCE_IMAGE,
    promise: axios.delete( resourceImgRoute, config )
  }
}

export const LOAD_RESOURCE_BLOCKS = 'LOAD_RESOURCE_BLOCKS'

export const loadResourceBlocks = ( id ) => {
  const config = {}
  
  const resourceBlockRoute = `/api/onsched/setup/resources/${id}/blocks`

  return {
    type:    LOAD_RESOURCE_BLOCKS,
    promise: axios.get( resourceBlockRoute, config )
  }
}

export const DELETE_RESOURCE_BLOCK = 'DELETE_RESOURCE_BLOCK'

export const deleteResourceBlock = ( id ) => {
  const config = {}
  
  const resourceBlockRoute = `/api/onsched/setup/resources/block/${id}`

  return {
    type:    DELETE_RESOURCE_BLOCK,
    promise: axios.delete( resourceBlockRoute, config )
  }
}

export const LOAD_RESOURCE_BLOCK = 'LOAD_RESOURCE_BLOCK'

export const loadResourceBlock = ( id ) => {
  const config = {}
  
  const resourceBlockRoute = `/api/onsched/setup/resources/blocks/${id}`

  return {
    type:    LOAD_RESOURCE_BLOCK,
    promise: axios.get( resourceBlockRoute, config )
  }

}

export const UPDATE_RESOURCE_BLOCK = 'UPDATE_RESOURCE_BLOCK'

export const updateResourceBlock = ( id, data ) => {
  const config = {}
  
  const resourceBlockRoute = `/api/onsched/setup/resources/block/${id}`

  return {
    type:    UPDATE_RESOURCE_BLOCK,
    promise: axios.put( resourceBlockRoute, data, config )
  }

}

export const LOAD_RESOURCE_ALLOCATIONS = 'LOAD_RESOURCE_ALLOCATIONS'

export const loadResourceAllocations = ( id, queryParams = {} ) => {
  const config = {}
  
  const resourceAllocationRoute = `/api/onsched/setup/resources/${id}/allocations`

  return {
    type:    LOAD_RESOURCE_ALLOCATIONS,
    promise: axios.get( resourceAllocationRoute, config )
  }
}

export const ADD_RESOURCE_ALLOCATION = 'ADD_RESOURCE_ALLOCATION'

export const addResourceAllocations = ( id, allocation ) => {
  const config = {}
  const resourceAllocationRoute = `/api/onsched/setup/resources/${id}/allocations`

  return {
    type:    ADD_RESOURCE_ALLOCATION,
    promise: axios.post( resourceAllocationRoute, allocation, config )
  }
}

export const DELETE_RESOURCE_ALLOCATION = 'DELETE_RESOURCE_ALLOCATION'

export const deleteResourceAllocation = ( id ) => {
  const config = {}
  const resourceAllocationRoute = `/api/onsched/setup/resources/allocations/${id}`

  return {
    type:    DELETE_RESOURCE_ALLOCATION,
    promise: axios.delete( resourceAllocationRoute, config )
  }
}

export const LOAD_RESOURCE_ALLOCATION = 'LOAD_RESOURCE_ALLOCATION'

export const loadResourceAllocation = ( id ) => {

  const config = {}                
  const resourceAllocationRoute = `/api/onsched/setup/resources/allocations/${id}`

  return {
    type:    LOAD_RESOURCE_ALLOCATION,
    promise: axios.get( resourceAllocationRoute, config )
  }

}

export const UPDATE_RESOURCE_ALLOCATION = 'UPDATE_RESOURCE_ALLOCATION'

export const updateResourceAllocation = (id, allocation) => {
  const config = {}
  const allocationRoute = `/api/onsched/setup/resources/allocations/${id}`

  return {
    type:    UPDATE_RESOURCE_ALLOCATION,
    promise: axios.put( allocationRoute, allocation, config )
  }

}

export const TOGGLE_RECURRING_AVAIL = 'TOGGLE_RECURRING_AVAIL'

export const toggleRecurringAvail = (id, isRecurringAvail) => {
  const config = {}
  const recurringAvailRoute = `/api/onsched/setup/resources/${id}/recurringAvailability?recurringAvailability=${isRecurringAvail}`

  return {
    type:    UPDATE_RESOURCE_ALLOCATION,
    promise: axios.put( recurringAvailRoute, config )
  }
}

// export const UPDATE_RESOURCE_AVAILABILITY = 'UPDATE_RESOURCE_AVAILABILITY'

// export const updateResourceAvailability = (id, availability) => {
//   const config = {}
//   const resourceAvailabilityRoute = `/api/onsched/setup/v1/resources/${id}/availability`

//   return {
//     type:    UPDATE_RESOURCE_AVAILABILITY,
//     promise: axios.put( resourceAvailabilityRoute, availability, config )
//   }
// }

//-------------------------------------------------------------------//
//                         ASYNC THUNKS
//-------------------------------------------------------------------//

export const UpdateAndLoadResourceAvailability = (id, resAvailability) => async (dispatch, getState) => {
  try {
    const { payload } = await dispatch(updateResAvail(id, resAvailability))
    if (payload.status !== 200) {
      dispatch(toggleError(payload))
    }
    else {
      await dispatch(loadResourceById(id))
    }
  }
  catch (error) {
    console.error({ error })
  }
}

export const ModifyAndLoadResource = (id, resource, returnUrlParameters) => async (dispatch) => {
  const displayModal = (data) => {
    if ( resource.calendarSync !== 'none' &&
         false === data.googleCalendarAuthorized && 
         false === data.outlookCalendarAuthorized ) {
         dispatch(toggleCalendarModal(true, resource.calendarSync === 'google'))
        }
  }

  try {
    const { payload } = await dispatch(updateResource(id, resource, returnUrlParameters))
    if(resource.base64Image) {
      const imageData = {
        imageFileData: resource.base64Image,
        imageFileName: resource.name + new Date().toISOString().slice(-4) + ' image',
      }
      await dispatch(uploadResourceImage(resource.id, imageData))
    }

    if (payload.status === 200) {
      dispatch(toggleToast(toastMessages.save_resource, payload))
      displayModal(payload.data)
      await dispatch(loadResourceById(id))
    }
    else {
      dispatch(toggleError(payload))
    }
    
  }
  catch(err) {
    console.error({ error: err })
  }
}
  
export const updateResourceCustomFields = (id, customFields) => async (dispatch, getState) => {
  const { payload } = await dispatch(updateResource(id, customFields))
  if (payload.status !== 200) {
    dispatch(toggleError(payload))
  }
  else {
    dispatch(toggleToast(toastMessages.update_res_custom_fields, payload))
    dispatch(loadResourceById(id))
  }
}

export const enableLinkedServices = (id) => async (dispatch) => {
  try {
    const { payload } = await dispatch(deleteResourceServices(id))
    if (payload.status === 200) {
      await dispatch(loadResourceById(id))
    }
    else {
      dispatch(toggleError(payload))
    }
  }
  catch (error) {
    console.error({ error })
  }
}


export const toggleRecurringAvailandLoad = (id, recurringAvail) => async (dispatch) => {
  try {
    const { payload } = await dispatch(toggleRecurringAvail(id, recurringAvail))
    if (payload.status === 200) {
      await dispatch(loadResourceById(id))
    }
    else {
      dispatch(toggleError(payload))
    }
  }
  catch (error) {
    console.error({ error })
  }
}

export const unlinkCalendarandLoadRes = (id) => async (dispatch) => {
  const options = {
      googleCalendarId: "",
      outlookCalendarId: "",
      calendarAvailability: 0, 
  }

  try {
    const { payload } = await dispatch(updateResource(id, { options }))
    if (payload.status === 200) {
      await dispatch(loadResourceById(id))
    }
    else {
      dispatch(toggleError(payload))
    }
  }
  catch (error) {
    console.log({ error })
  }
} 
